import Cookies, { CookieAttributes } from 'js-cookie';

import { isDev, LEYA_AI_DOMAIN } from './constants';

export enum COOKIE_KEY {
  pwaInstallationHasBeenPrompted = 'pwaInstallationHasBeenPrompted',
  pwaInstallationPromptCount = 'pwaInstallationPromptCount',
  pushNotificationsHasBeenPrompted = 'pushNotificationsHasBeenPrompted',
  pushNotificationsPromptCount = 'pushNotificationsPromptCount',
  microphoneAccess = 'microphoneAccess',
  wordEvaluationSeen = 'wordEvaluationSeen',
}

export const setCookie = (key: COOKIE_KEY, value: string, options: CookieAttributes = {}) => {
  Cookies.set(key, value, {
    path: '/',
    expires: 30,
    ...(isDev ? {} : { domain: LEYA_AI_DOMAIN }),
    ...options,
  });
};

export const removeCookie = (key: COOKIE_KEY) => {
  Cookies.remove(key);
};

export const getCookie = (key: COOKIE_KEY) => {
  return Cookies.get(key) || '';
};
