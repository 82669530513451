import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { GetActions, getActionTypes } from 'utils/redux';

export interface IAudioInput {
  webAudioAccessGranted: boolean;
  microphoneAccess: PermissionState;
  browserNotSupported: boolean;
  microphoneIsActive: boolean;
  listening: boolean;
}

const initialState: IAudioInput = {
  webAudioAccessGranted: false,
  microphoneAccess: 'prompt',
  microphoneIsActive: false,
  browserNotSupported: false,
  listening: false,
};

export const audioInputSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setWebAudioAccessGranted: (
      state,
      action: PayloadAction<IAudioInput['webAudioAccessGranted']>
    ) => {
      state.webAudioAccessGranted = action.payload;
    },
    setMicrophoneAccess: (state, action: PayloadAction<IAudioInput['microphoneAccess']>) => {
      state.microphoneAccess = action.payload;
    },
    setBrowserNotSupported: (state, action: PayloadAction<IAudioInput['browserNotSupported']>) => {
      state.browserNotSupported = action.payload;
    },
    setMicrophoneIsActive: (state, action: PayloadAction<IAudioInput['microphoneIsActive']>) => {
      state.microphoneIsActive = action.payload;
    },
    setListening: (state, action: PayloadAction<IAudioInput['listening']>) => {
      state.listening = action.payload;
    },
    resetState: state => {
      const { microphoneAccess, ...partialInitialState } = initialState;
      return { ...state, ...partialInitialState };
    },
  },
});

export const {
  setWebAudioAccessGranted,
  setMicrophoneAccess,
  setBrowserNotSupported,
  setMicrophoneIsActive,
  setListening,
  resetState,
} = audioInputSlice.actions;

export const actionTypes = getActionTypes(audioInputSlice);

export type Actions = GetActions<typeof audioInputSlice.actions>;
