import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as TrustPilotSvg } from 'assets/trustPilot.svg';
import Button from 'atoms/Button';

import { usePromptStepService } from './hooks';
import { LINKS } from 'utils/constants';

const areas = {
  title: 'title',
  caption: 'caption',
  review: 'review',
  remind: 'remind',
  never: 'never',
};

const Container = styled.div`
  ${({ theme: { durations, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [areas.title],
      [areas.caption],
      [areas.review],
      [areas.remind],
      [areas.never],
    ])};
    row-gap: 1rem;
    justify-items: center;
    padding: 4rem;
    width: 58rem;
    grid-row: 1/ 1;
    grid-column: 1/ 1;
    transition: opacity ${durations.regular};

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        ['.'],
        [areas.title],
        [areas.caption],
        ['.'],
        [areas.review],
        [areas.remind],
        [areas.never],
      ])};
      grid-template-rows: 1fr min-content min-content 1fr repeat(3, min-content);
      align-items: flex-end;
      justify-items: center;
      padding: 2.5rem 0;
      width: 100%;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1};

    display: grid;
    grid-area: ${areas.title};
    row-gap: 1rem;
    text-align: center;
    margin: 0;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0;
    }
  `}
`;

const Caption = styled.h4`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p1}

    display: table-cell;
    grid-area: ${areas.caption};
    text-align: center;
    margin: 0;
    max-width: 28rem;
    color: ${utils.alphaHex(colors.primary.main, 55)};

    & > svg {
      vertical-align: text-bottom;
      margin-bottom: -0.125rem;
      height: calc(2rem * 1.5);
      width: auto;
    }

    ${mq.md.down} {
      & > svg {
        margin-bottom: -0.125rem;
        height: calc(1.75rem * 1.5);
      }
    }
  `}
`;

const ReviewLink = styled.a`
  display: contents;
`;

const ReviewButton = styled(Button)`
  ${({ theme: { mq } }) => css`
    grid-area: ${areas.review};

    && > svg {
      height: 3rem;
      width: auto;
    }

    ${mq.md.up} {
      margin-top: 5rem;
    }
  `}
`;

const RemindButton = styled(Button)`
  ${({ theme: { colors, mq } }) => css`
    grid-area: ${areas.remind};
    margin: 1rem 0;
    color: ${colors.primary.light};

    ${mq.md.down} {
      margin: 2rem 0 1rem;
    }
  `}
`;

const NeverAskButton = styled(Button)`
  ${({ theme: { colors } }) => css`
    grid-area: ${areas.never};

    color: ${colors.error.main};

    & > span {
      color: ${colors.error.main};
    }

    & > svg {
      fill: ${colors.error.main};
    }
  `}
`;

const PromptStep: React.FC = () => {
  const { handleReview, handleClose } = usePromptStepService();

  return (
    <Container>
      <Title>
        <span>🙌</span>Enjoying Leya?
      </Title>
      <Caption>
        <span>Your&nbsp;</span>
        <TrustPilotSvg />
        <span>&nbsp;review makes a difference!</span>
      </Caption>
      <ReviewLink href={LINKS.TRUST_PILOT_REVIEW} target="_blank" onClick={handleReview}>
        <ReviewButton>
          Review us on
          <TrustPilotSvg />
        </ReviewButton>
      </ReviewLink>
      <RemindButton variant="outlined" transitionText bare onClick={handleClose}>
        Remind me later
      </RemindButton>
      <NeverAskButton variant="outlined" bare onClick={handleReview}>
        Never ask again
      </NeverAskButton>
    </Container>
  );
};

export default PromptStep;
