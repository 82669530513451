import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Formik } from 'formik';

import Button from 'atoms/Button';
import TextArea from 'molecules/TextArea';

import { CLASSNAME_PREFIX } from '../constants';

import {
  CancellationFeedbackSchema,
  FEEDBACK_MAXIMUM_LENGTH,
  ICancellationFeedbackForm,
} from './form';
import { useFeedbackStepService } from './hooks';

const areas = {
  title: 'title',
  description: 'description',
  feedback: 'feedback',
  buttons: 'buttons',
};

const Container = styled.div`
  ${({ theme: { durations, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [areas.title],
      [areas.description],
      [areas.feedback],
      [areas.buttons],
    ])};
    row-gap: 4.5rem;
    justify-items: center;
    padding: 6rem;
    width: 68rem;
    min-height: 34rem;
    grid-area: 1 / 1 / 1 / 1;

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        ['.'],
        [areas.title],
        [areas.description],
        [areas.feedback],
        ['.'],
        [areas.buttons],
      ])};
      grid-template-rows: 1fr repeat(2, min-content) max-content 1fr min-content;
      align-items: center;
      justify-items: center;
      padding: 2.5rem 0 0;
      width: 100%;
    }

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1}

    display: grid;
    grid-area: ${areas.title};
    row-gap: 1rem;
    text-align: center;
    margin: 0;

    ${mq.md.down} {
      ${tp.h2}

      padding: 0 3rem;
    }
  `}
`;

const Description = styled.span`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p1}

    grid-area: ${areas.description};
    color: ${utils.alphaHex(colors.primary.dark, 55)};
    text-align: center;
    margin-top: -3.5rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const ButtonContainer = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-area: ${areas.buttons};
    grid-auto-columns: max-content;
    grid-auto-rows: min-content;
    justify-self: center;
    align-self: flex-end;
    row-gap: 2rem;

    ${mq.md.down} {
    }
  `}
`;

const StyledTextArea = styled(TextArea)`
  grid-area: ${areas.feedback};
`;

const CancelPlanButton = styled(Button)`
  ${({ theme: { colors, mq, utils } }) => css`
    background-color: transparent;
    color: ${colors.error.main};
    align-self: center;
    justify-self: center;
    padding: 1rem;
    margin: -1rem;
    border: transparent;

    & > svg {
      fill: ${colors.error.main};
    }

    &:active {
      background-color: ${utils.alphaHex(colors.primary.main, 10)};
    }

    ${mq.hover} {
      &:hover {
        background-color: ${utils.alphaHex(colors.primary.main, 5)};
      }
    }
  `}
`;

const FeedbackStep: React.FC = () => {
  const { handleFeedback } = useFeedbackStepService();

  return (
    <Container>
      <Formik
        initialValues={{ feedback: '' } as ICancellationFeedbackForm}
        validationSchema={CancellationFeedbackSchema}
        onSubmit={handleFeedback}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <>
            <Title>Why are you cancelling your subscription?</Title>
            <Description>Please provide us with any feedback you may have</Description>
            <StyledTextArea
              name="feedback"
              placeholder="Please provide any feedback you have for us here"
              maxLength={FEEDBACK_MAXIMUM_LENGTH}
            />
            <ButtonContainer>
              <CancelPlanButton
                onClick={() => handleSubmit()}
                disabled={isSubmitting || !!Object.keys(errors).length}
                type="submit"
              >
                Continue and cancel plan
              </CancelPlanButton>
            </ButtonContainer>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default FeedbackStep;
