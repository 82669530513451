import React from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import { ReactComponent as RefreshSvg } from 'assets/refresh.svg';
import { ReactComponent as SpeakerSvg } from 'assets/speaker.svg';
import Button from 'atoms/Button';
import { filterProps } from 'utils/helpers';

import type { TextToSpeechState } from '../../types';

interface ITextToSpeechButton {
  state: TextToSpeechState;
  handleClick: () => void;
}

const rotateAnimation = keyframes`
  ${css`
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  `}
`;

const pulseAnimation = keyframes`
  ${css`
    from {
      transform: translate(-50%, -50%) scale(1);
    }
    to {
      transform: translate(-50%, -50%) scale(1.2);
    }
  `}
`;

const StyledButton = styled(Button, filterProps('animation'))<{
  animation: 'rotate' | 'pulse' | null;
}>`
  ${({ theme: { colors, durations, mq, utils }, animation }) => css`
    position: relative;
    align-self: flex-end;
    background-color: transparent;

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1);
      border-radius: 50%;
      content: '';
      width: 100%;
      height: 100%;
      transition: all ${durations.long} ease-in-out;
    }

    && {
      border-color: ${colors.primary.light};
    }

    ${animation === 'rotate'
      ? css`
          animation: ${rotateAnimation} ${durations.long} linear infinite;
        `
      : ''}

    ${animation === 'pulse'
      ? css`
          &::before {
            background-color: ${utils.alphaHex(colors.primary.light, 25)};
            animation: ${pulseAnimation} ${durations.long} ease-in-out infinite alternate;
          }
        `
      : ''}


    ${mq.md.down} {
    }
  `}
`;

const TextToSpeechButton: React.FC<ITextToSpeechButton> = ({ state, handleClick }) => {
  return (
    <StyledButton
      variant="outlined"
      bare
      onClick={handleClick}
      disabled={state === 'disabled' || state === 'loading'}
      animation={state === 'loading' ? 'rotate' : state === 'active' ? 'pulse' : null}
    >
      {state === 'loading' ? <RefreshSvg /> : <SpeakerSvg />}
    </StyledButton>
  );
};

export default TextToSpeechButton;
