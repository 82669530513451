import React, { PropsWithChildren } from 'react';
import { Button, styled } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import hideable from 'utils/hideable';
import { useFeedbackEvaluation } from 'utils/hooks/useFeedbackEvaluation';

type TChatBackButton = PropsWithChildren & {
  feedbackId?: string;
  conversationId?: string;
} & (
    | { feedbackId: string; conversationId: string }
    | { feedbackId?: never; conversationId?: never }
  );

const StyledButton = styled(Button)({
  marginTop: 24,
  fontSize: '1.75rem',
});

const ChatBackButton: React.FC<TChatBackButton> = ({
  children,
  feedbackId = '',
  conversationId,
}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  const feedbackRef = useFeedbackEvaluation({ conversationId: conversationId ?? '' });

  return (
    <>
      {!!feedbackId ? <div data-feedback-id={feedbackId} ref={feedbackRef} /> : null}
      <StyledButton variant="outlined" onClick={handleClick}>
        {children}
      </StyledButton>
    </>
  );
};

export default hideable(ChatBackButton);
