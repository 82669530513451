import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isInitialized, isPushNotificationsEnabled } from 'features/PushNotifications/selectors';
import { setRetriggerCount } from 'features/PushNotifications/slice';
import { useShowPushNotificationsModal } from 'features/PushNotifications';
import { isIOS } from 'utils/constants';

import { setIsModalOpen } from 'features/Streaks/slice';

export const useReminderButtonService = () => {
  const notificationsInitialized = useSelector(isInitialized);
  const notificationsEnabled = useSelector(isPushNotificationsEnabled);

  const dispatch = useDispatch();

  const showPushNotificationsModal = useShowPushNotificationsModal();

  const handleClick = useCallback(() => {
    dispatch(setRetriggerCount(1));
    showPushNotificationsModal();

    dispatch(setIsModalOpen(false));
  }, [showPushNotificationsModal, dispatch]);

  return {
    shouldRender: !isIOS && notificationsInitialized && !notificationsEnabled,
    handleClick,
  };
};
