import { useDispatch, useSelector } from 'react-redux';

import { isConversationStarted as isConversationStartedSelector } from 'features/Chat/selectors';

import ConversationSpeed from './ConversationSpeed';
import { getConversationSpeed } from '../selectors';
import { handleSpeedSelect } from '../actions';
import { useRemoteConfig } from 'features/RemoteConfig/queries';
import { useDeferredOnboardingCallback } from 'features/Onboarding';

const ConversationSpeedService = () => {
  const dispatch = useDispatch();

  const { data } = useRemoteConfig();

  const value = useSelector(getConversationSpeed);

  const isVisible =
    !useSelector(isConversationStartedSelector) && !!data?.isConversationSpeedEnabled;

  const onboardingCallback = useDeferredOnboardingCallback({
    journey: 'lessons',
    step: 'conversationSpeed',
    remoteTrigger: 'highlight',
  });

  const onRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(handleSpeedSelect(event.target.value));
    onboardingCallback();
  };

  return <ConversationSpeed isVisible={isVisible} value={value} onChange={onRadioChange} />;
};

export default ConversationSpeedService;
