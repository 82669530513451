import { ChangeEvent } from 'react';
import { TextField, styled } from '@mui/material';

import AuthInputsContainer from 'components/AuthInputsContainer';
import AuthLink from 'components/AuthLink';
import AuthPage from 'components/AuthPage';
import FormCard from 'components/FormCard';
import Button from 'components/Button';
import { ROUTES } from 'navigation/constants';

interface ILogin {
  email: string;
  error: string;
  success: string;
  isLoading: boolean;
  onEmailChange: (value: string) => void;
  onSubmit: React.FormEventHandler;
}

const StyledInput = styled(TextField)({
  marginBottom: 12,
});

const ErrorText = styled('div')(props => ({
  fontSize: 14,
  fontWeight: 400,
  color: props.theme.palette.error.light,
}));

const SuccessText = styled('div')(props => ({
  fontSize: 14,
  fontWeight: 400,
  color: props.theme.palette.success.main,
}));

const ForgotPassword = ({ onEmailChange, onSubmit, email, error, success, isLoading }: ILogin) => {
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onEmailChange(e.target.value);
  };

  return (
    <AuthPage>
      <FormCard onSubmit={onSubmit}>
        <AuthInputsContainer>
          <StyledInput
            onChange={handleEmailChange}
            value={email}
            placeholder="Email"
            type="email"
          />
          {!!error && <ErrorText>{error}</ErrorText>}
          {!!success && <SuccessText>{success}</SuccessText>}
          <Button text="Send email" isLoading={isLoading} />
          <AuthLink linkText="Sign in" link={ROUTES.LOGIN.path} />
        </AuthInputsContainer>
      </FormCard>
    </AuthPage>
  );
};

export default ForgotPassword;
