import { useDispatch, useSelector } from 'react-redux';
import { getTextToSpeechButtonState } from 'features/Pronunciation/selectors';
import { useCallback } from 'react';
import { startTextToSpeech, stopTextToSpeech } from 'features/Pronunciation/actions';

export const useTextToSpeechButtonService = () => {
  const state = useSelector(getTextToSpeechButtonState);
  const dispatch = useDispatch();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const isKeyboardEvent = !event.clientX && !event.clientY;
      if (isKeyboardEvent) {
        return;
      }

      if (state === 'idle') {
        dispatch(startTextToSpeech());
      }

      if (state === 'active') {
        dispatch(stopTextToSpeech());
      }
    },
    [state]
  );

  return { state, handleClick };
};
