import { captureException } from '@sentry/react';
import { call, spawn, take } from 'typed-redux-saga/macro';
import { END, MulticastChannel, multicastChannel } from 'redux-saga';

import type {
  ServerEvents as BaseServerEvents,
  ServerCloseEvent,
  ServerErrorEvent,
  ServerOpenEvent,
  SocketEvent,
  ServerRawEvent,
} from './types';

function* consumeServerEvents<ServerEvents extends SocketEvent>(
  socket: WebSocket,
  broadcastChannel: MulticastChannel<ServerEvents>
) {
  const broadcastEvent = (event: ServerEvents | END) => broadcastChannel.put(event);

  const onOpen = () => {
    broadcastEvent({ type: 'open' } as Extract<ServerEvents, ServerOpenEvent>);
  };

  const onError = () => {
    broadcastEvent({ type: 'error' } as Extract<ServerEvents, ServerErrorEvent>);
  };

  const onClose = (closeFrame: CloseEvent) => {
    broadcastEvent({ type: 'close', payload: closeFrame } as Extract<
      ServerEvents,
      ServerCloseEvent
    >);
    broadcastEvent(END);
  };

  const onMessage = ({ data }: MessageEvent) => {
    if (typeof data === 'string') {
      try {
        const message = JSON.parse(data) as Exclude<ServerEvents, BaseServerEvents['type']>;

        if ('type' in message) {
          return broadcastEvent(message);
        }
      } catch {}
    } else {
      return broadcastEvent({ type: 'raw', payload: data } as unknown as Extract<
        ServerEvents,
        ServerRawEvent
      >);
    }
  };

  try {
    yield* call([socket, socket.addEventListener], 'open', onOpen);
    yield* call([socket, socket.addEventListener], 'error', onError);
    yield* call([socket, socket.addEventListener], 'close', onClose);
    yield* call([socket, socket.addEventListener], 'message', onMessage);

    const event = yield* take(broadcastChannel, 'close');

    const { wasClean, reason, code } = (event as Extract<ServerEvents, ServerCloseEvent>).payload;

    if (!wasClean) throw new Error(reason, { cause: 'Unclean close' });

    if (code === 1006) throw new Error(reason, { cause: 'Abnormal close' });
  } catch (error) {
    yield* call(captureException, error);
  } finally {
    yield* call([socket, socket.removeEventListener], 'open', onOpen);
    yield* call([socket, socket.removeEventListener], 'error', onError);
    yield* call([socket, socket.removeEventListener], 'close', onClose);
    yield* call([socket, socket.removeEventListener], 'message', onMessage);
  }
}

function* initializeServerEventsChannel<ServerEvents extends SocketEvent>(socket: WebSocket) {
  const broadcastChannel = yield* call(multicastChannel<ServerEvents>);

  yield* spawn(consumeServerEvents, socket, broadcastChannel);

  return broadcastChannel;
}

export { initializeServerEventsChannel as default, consumeServerEvents };
