import { Button, CircularProgress, styled } from '@mui/material';

interface IOwnProps {
  isLoading?: boolean;
  text: string;
}

const StyledButton = styled(Button)(props => ({
  height: 56,
  marginTop: 12,
  marginBottom: 24,
  backgroundColor: props.theme.palette.primary.main,
}));

const CustomButton = ({ text, isLoading, ...rest }: IOwnProps) => (
  <StyledButton variant="contained" type="submit" {...rest} disabled={isLoading}>
    {isLoading ? <CircularProgress color="inherit" size={18} /> : text}
  </StyledButton>
);

export default CustomButton;
