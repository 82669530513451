import { captureException } from '@sentry/react';
import { all, takeEvery, put, call } from 'typed-redux-saga/macro';

import callApi, { METHODS, PATHS } from 'api';
import { ERROR_MESSAGES, STORAGE_KEYS } from 'utils/constants';
import {
  resetState as resetLoginState,
  setError as setLoginError,
  setLoading as setLoginLoading,
} from 'features/Login/slice';
import { storageGetItem, storageRemoveItem, storageSetItem } from 'utils/localStorage';

import { fetchUserInfo, fetchUserUsage, setAuthData } from './actions';
import {
  SIGN_IN,
  SET_AUTH_DATA,
  INITIAL_AUTH,
  FETCH_USER_INFO,
  FETCH_USER_USAGE,
  LOGOUT,
} from './actionTypes';
import {
  IAuthResponse,
  ISetAuthData,
  TSignIn,
  IUserInfoResponse,
  IUserUsageResponse,
  IUserExperimentsResponse,
  TTier,
} from './types';
import {
  resetState,
  setAccessToken,
  setInitialAuthDone,
  setNotAuthorizedTier,
  setRefreshToken,
  setUserId,
  setUserTier,
  setUserTotalConversationCount,
  setUserExperiments,
} from './slice';
import { setConversationSpeed } from 'features/ConversationControl/slice';
import { setEmail } from 'features/User/slice';

interface ISignInSaga {
  type: typeof SIGN_IN;
  payload: TSignIn;
}

interface ISetAuthDataSaga {
  type: typeof SIGN_IN;
  payload: ISetAuthData;
}

function* fetchUserExperimentsSaga() {
  try {
    const userExperimentsResponse = yield* call(callApi<object, IUserExperimentsResponse>, {
      method: METHODS.GET,
      mainPath: PATHS.USER_EXPERIMENTS,
      authorized: true,
    });

    yield* put(setUserExperiments(userExperimentsResponse.data.experiments));
  } catch (error) {
    captureException(error);
  }
}

function* fetchUserInfoSaga() {
  try {
    const {
      data: { email, id, tier, preferences },
    } = yield* call(callApi<object, IUserInfoResponse>, {
      method: METHODS.GET,
      mainPath: PATHS.USER_INFO,
      authorized: true,
    });

    yield* put(setEmail(email));

    yield* put(setUserId(id));
    yield* put(setUserTier(tier));
    yield* put(
      setNotAuthorizedTier(!(['subscriber', 'free_trial_user'] as TTier[]).includes(tier))
    );

    if (preferences?.bot_speaking_speed) {
      yield* put(setConversationSpeed(preferences?.bot_speaking_speed));
    }
  } catch (error) {
    yield* put(setNotAuthorizedTier(true));

    captureException(error);
  }
}

function* fetchUserUsageSaga() {
  try {
    const userUsageResponse = yield* call(callApi<object, IUserUsageResponse>, {
      method: METHODS.GET,
      mainPath: PATHS.USER_USAGE,
      authorized: true,
      queryParams: [
        { key: 'start_date', value: '2023-01-01' },
        { key: 'end_date', value: '2023-01-01' },
      ],
    });

    yield* put(setUserTotalConversationCount(userUsageResponse.data.total_conversation_count));
  } catch (error) {
    captureException(error);
  }
}

export function* signInSaga(action: ISignInSaga) {
  try {
    const response = yield* call(callApi<{ email: string; password: string }, IAuthResponse>, {
      method: METHODS.POST,
      mainPath: PATHS.LOGIN,
      data: {
        email: action.payload.email,
        password: action.payload.password,
      },
    });

    yield* put(
      setAuthData({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      })
    );

    yield* put(resetLoginState());
  } catch {
    if (action.payload.flow === 'login') {
      yield* put(setLoginError(ERROR_MESSAGES.WRONG_EMAIL_PASS));
    }

    yield* put(setLoginLoading(false));

    if (action.payload.flow === 'registration') {
      throw Error();
    }
  }
}

function* setAuthDataSaga(action: ISetAuthDataSaga) {
  storageSetItem(STORAGE_KEYS.REFRESH_TOKEN, action.payload.refreshToken);

  yield* put(setAccessToken(action.payload.accessToken));
  yield* put(setRefreshToken(action.payload.refreshToken));

  yield* put(fetchUserInfo());
  yield* put(fetchUserUsage());
  yield* call(fetchUserExperimentsSaga);
  yield* put(setInitialAuthDone(true));
}

function* initialAuthSaga() {
  const refreshToken = storageGetItem(STORAGE_KEYS.REFRESH_TOKEN);

  if (!refreshToken) {
    yield* put(setInitialAuthDone(true));

    return;
  }

  try {
    const response = yield* call(callApi<{ refresh_token: string }, IAuthResponse>, {
      method: METHODS.POST,
      mainPath: PATHS.REFRESH_TOKEN,
      data: {
        refresh_token: refreshToken,
      },
    });

    yield* put(
      setAuthData({
        accessToken: response.data.access_token,
        refreshToken: response.data.refresh_token,
      })
    );
  } catch {
    storageRemoveItem(STORAGE_KEYS.REFRESH_TOKEN);
    yield* put(setInitialAuthDone(true));
  }
}

function* logoutSaga() {
  storageRemoveItem(STORAGE_KEYS.REFRESH_TOKEN);

  yield* put(resetState());
}

export function* watchAuth() {
  yield* all([
    takeEvery(SIGN_IN, signInSaga),
    takeEvery(SET_AUTH_DATA, setAuthDataSaga),
    takeEvery(INITIAL_AUTH, initialAuthSaga),
    takeEvery(FETCH_USER_INFO, fetchUserInfoSaga),
    takeEvery(FETCH_USER_USAGE, fetchUserUsageSaga),
    takeEvery(LOGOUT, logoutSaga),
  ]);
}
