import React, { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/react';

import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import { FONT_FAMILIES } from 'theme/emotion/typography';

import styled from '@emotion/styled';

interface IChatHeader extends PropsWithChildren {
  backText: string;
  backTo: string;
  title?: string;
}

enum CHAT_HEADER_AREAS {
  link = 'link',
  title = 'title',
  content = 'content',
}

const Container = styled.header<{ hasChildren: boolean; hasTitle: boolean }>`
  ${({ theme: { colors, mq, utils }, hasChildren, hasTitle }) => css`
    display: grid;
    justify-self: stretch;
    grid-template-areas: ${utils.composeTemplateAreas([
      ...(hasTitle
        ? [[CHAT_HEADER_AREAS.link, CHAT_HEADER_AREAS.title, '.']]
        : [[CHAT_HEADER_AREAS.link, CHAT_HEADER_AREAS.link, CHAT_HEADER_AREAS.link]]),
      ...(hasChildren
        ? [[CHAT_HEADER_AREAS.content, CHAT_HEADER_AREAS.content, CHAT_HEADER_AREAS.content]]
        : []),
    ])};
    grid-template-columns: 1fr 3fr 1fr;
    row-gap: 6rem;
    background-color: ${colors.neutral.grey};
    z-index: 1000;

    ${mq.md.up} {
      grid-auto-flow: column;
    }

    ${mq.md.down} {
      grid-template-columns: 1fr;
      grid-template-areas: ${utils.composeTemplateAreas([
        [CHAT_HEADER_AREAS.link],
        ...(hasTitle ? [[CHAT_HEADER_AREAS.title]] : []),
        ...(hasChildren ? [[CHAT_HEADER_AREAS.content]] : []),
      ])};
      row-gap: 2rem;
    }
  `}
`;

const BackLink = styled(Link)`
  ${({ theme: { colors, mq } }) => css`
    grid-area: ${CHAT_HEADER_AREAS.link};
    display: flex;
    align-items: center;
    column-gap: 1rem;
    align-self: flex-start;
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    color: ${colors.primary.light};
    text-decoration: initial;
    line-height: 1.25;
    font-size: 1.75rem;

    & > svg {
      width: 2.75rem;
      height: 2.75rem;
      fill: ${colors.primary.light};
    }

    ${mq.md.down} {
      width: 100%;
      line-height: 1.35;
      margin-bottom: 2rem;

      & > svg {
        width: 2.25rem;
        height: 2.25rem;
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { tp, mq } }) => css`
    ${tp.h1};

    grid-area: ${CHAT_HEADER_AREAS.title};
    text-align: center;
    margin: 0;

    ${mq.md.down} {
      padding: 0 2rem;
    }
  `}
`;

const Content = styled.div`
  grid-area: ${CHAT_HEADER_AREAS.content};
  display: grid;
  justify-items: center;
`;

const ChatHeader: React.FC<IChatHeader> = ({ backText, backTo, title, children }) => {
  return (
    <Container hasChildren={!!children} hasTitle={!!title}>
      <BackLink to={backTo}>
        <ArrowLeftSvg />
        {backText}
      </BackLink>
      {title ? <Title>{title}</Title> : null}
      {children ? <Content>{children}</Content> : null}
    </Container>
  );
};

export default ChatHeader;
