import { useCallback, useLayoutEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';

import { scrollLockState } from './state';

const MAX_SYNC_RETRIES = 10;
const MAX_SCROLLBAR_WIDTH = 30;

export const useScrollLock = (customId?: string) => {
  const { current: id } = useRef(customId || uuid());

  const setScrollLock = useSetRecoilState(scrollLockState(id));

  const syncScrollWidth = useCallback((retries = 0) => {
    const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

    if (retries < MAX_SYNC_RETRIES && scrollbarWidth > MAX_SCROLLBAR_WIDTH) {
      setTimeout(() => syncScrollWidth(retries + 1), 10);
    } else
      document.body.style.setProperty(GLOBAL_STYLE_VARIABLES.scrollbarWidth, `${scrollbarWidth}px`);
  }, []);

  const lockScroll = useCallback(() => {
    setScrollLock(true);
  }, []);

  const unlockScroll = useCallback(() => {
    setScrollLock(false);
  }, []);

  useLayoutEffect(() => {
    syncScrollWidth();

    return () => {
      unlockScroll();
    };
  }, []);

  return [lockScroll, unlockScroll, syncScrollWidth] as [
    typeof lockScroll,
    typeof unlockScroll,
    typeof syncScrollWidth
  ];
};

export const useScrollbarWidthSync = () => {
  const [syncScrollWidth] = useScrollLock().slice(-1);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => syncScrollWidth());

    observer.observe(document.body);

    return () => {
      observer.disconnect();
    };
  }, []);
};
