import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

import { ReactComponent as UserSvg } from 'assets/user.svg';
import Logo, { LOGO_VARIANTS } from 'atoms/Logo';
import type { Classname } from 'utils/types';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';
import { FONT_FAMILIES } from 'theme/emotion/typography';
import { StreakIndicator } from 'features/Streaks';
import { filterProps } from 'utils/helpers';
import { ReactComponent as ExitSvg } from 'assets/exit.svg';

import LinksContainer from './LinksContainer';
import MenuButton from './MenuButton';
import Wrapper, { embeddedRem } from './Wrapper';
import { useHeaderService } from './hooks';
import { ROUTES } from 'navigation/constants';

const StyledWrapper = styled(Wrapper, filterProps('headerIsSticky'))<{
  headerIsSticky: boolean;
}>`
  ${({ theme: { colors, durations, mq, utils }, headerIsSticky }) => css`
    background-color: ${utils.alphaHex(colors.neutral.grey, 60)};
    backdrop-filter: blur(8px);
    transition: all ${durations.regular};

    ${headerIsSticky
      ? css`
          box-shadow: ${utils.alphaHex(colors.neutral.black, 20)} 0 ${embeddedRem(0.25)}
              ${embeddedRem(0.5)} ${embeddedRem(-0.125)},
            ${utils.alphaHex(colors.neutral.black, 14)} 0 ${embeddedRem(0.5)} ${embeddedRem(0.625)}
              0,
            ${utils.alphaHex(colors.neutral.black, 12)} 0 ${embeddedRem(-0.125)}
              ${embeddedRem(1.25)} 0;
        `
      : ''}

    & ~ div {
      box-sizing: border-box;
      padding-top: calc(var(${GLOBAL_STYLE_VARIABLES.headerHeight}) + 32px);
    }

    ${mq.md.down} {
      & ~ div {
        padding-top: calc(var(${GLOBAL_STYLE_VARIABLES.headerHeight}) + 4px);
      }
    }
  `}
`;

const Container = styled('header', filterProps('headerIsSticky'))<{
  headerIsSticky: boolean;
}>`
  ${({ theme: { durations, mq }, headerIsSticky }) => css`
    display: grid;
    column-gap: ${embeddedRem(3)};
    align-items: center;
    align-self: flex-start;
    justify-content: space-between;
    padding: ${embeddedRem(2)} 0;
    transition: padding ${durations.regular};

    ${mq.md.up} {
      grid-template-columns: min-content 1fr max-content max-content;
    }

    ${mq.md.down} {
      grid-template-columns: min-content 1fr max-content max-content;
      padding: ${embeddedRem(headerIsSticky ? 1 : 2.5)} 0;

      && {
        z-index: 1;
      }
    }
  `}
`;

const LinksWrapper = styled.nav`
  display: flex;
  align-items: center;
  column-gap: ${embeddedRem(5)};
`;

const LogoLink = styled(Link)`
  ${({ theme: { depths, mq } }) => css`
    height: ${embeddedRem(5.5)};
    z-index: ${depths.menu + 1};

    ${mq.md.up} {
      grid-area: 1 / 1 / span 1 / span 1;
    }

    ${mq.md.down} {
      height: ${embeddedRem(4)};
    }
  `}
`;

const StyledLogo = styled(Logo)`
  height: inherit;
`;

export const StyledLink = styled(Link, filterProps('active'))<{ active: boolean }>`
  ${({ theme: { colors, mq }, active }) => css`
    margin: ${embeddedRem(-1)} ${embeddedRem(-2.5)};
    padding: ${embeddedRem(1)} ${embeddedRem(2.5)};
    text-decoration: none;
    color: ${active ? colors.primary.light : 'inherit'};
    line-height: 1.35;
    letter-spacing: 0.02em;
    font-family: ${Object.keys(FONT_FAMILIES).join(',')}, sans-serif;
    font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off, 'liga' off;
    font-size: ${embeddedRem(2)};

    ${mq.md.down} {
      font-size: ${embeddedRem(1.75)};
    }
  `}
`;

const LogoutButton = styled.button`
  ${StyledLink.__emotion_styles}

  ${({ theme: { colors, mq } }) => css`
    display: flex;
    column-gap: ${embeddedRem(1)};
    align-items: center;
    color: ${colors.primary.light};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    background-color: initial;
    border: initial;
    cursor: pointer;

    > svg {
      width: ${embeddedRem(2.75)};
      height: ${embeddedRem(2.75)};
      fill: ${colors.primary.light};
    }

    ${mq.md.down} {
      column-gap: ${embeddedRem(0.5)};

      > svg {
        width: ${embeddedRem(2.25)};
        height: ${embeddedRem(2.25)};
      }
    }
  `}
`;

const MenuPopupContainer = styled('div', filterProps('isOpen'))<{ isOpen: boolean }>`
  ${({ theme: { colors, durations, mq, utils }, isOpen }) => css`
    position: absolute;
    bottom: ${embeddedRem(-2)};
    right: 0;
    transform: translateY(100%);
    box-shadow: ${embeddedRem(-2)} ${embeddedRem(2.5)} ${embeddedRem(-4.5)} ${embeddedRem(-0.25)}
      ${utils.alphaHex(colors.neutral.white, 7)};
    transition: opacity ${durations.regular};
    opacity: ${isOpen ? 1 : 0};

    ${mq.md.up} {
      pointer-events: ${isOpen ? 'initial' : 'none'};
    }

    ${mq.md.down} {
      display: contents;
    }
  `}
`;

const MenuPopup = styled.div`
  ${({ theme: { colors, durations, mq, utils } }) => css`
    position: relative;
    display: grid;
    border-radius: ${embeddedRem(0.5)};
    padding: ${embeddedRem(1)} 0;
    min-width: ${embeddedRem(20)};
    cursor: default;

    ${mq.md.up} {
      background-color: ${colors.neutral.white};

      & > a,
      & > button {
        padding: ${embeddedRem(0.5)} ${embeddedRem(2)};
        margin: 0;
        transition: background-color ${durations.regular};

        &:hover {
          background-color: ${utils.alphaHex(colors.neutral.grey, 60)};
        }
      }
    }

    ${mq.md.down} {
      row-gap: ${embeddedRem(5)};
      justify-items: center;
    }
  `}
`;

const ButtonLink = styled.button`
  text-align: left;
  background: initial;
  border: initial;
  cursor: pointer;

  ${StyledLink.__emotion_styles};
`;

const StyledStreakIndicator = styled(StreakIndicator)`
  ${({ theme: { colors, mq, utils } }) => css`
    line-height: 1.35;
    letter-spacing: 0.02em;
    font-family: ${Object.keys(FONT_FAMILIES).join(',')}, sans-serif;
    font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off, 'liga' off;
    font-size: ${embeddedRem(1.5)};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    height: ${embeddedRem(4)};
    color: ${utils.alphaHex(colors.primary.main, 60)};
    fill: ${utils.alphaHex(colors.primary.main, 12)};

    ${mq.md.down} {
      font-size: ${embeddedRem(1.5)};
      height: ${embeddedRem(4)};
    }
  `}
`;

const DesktopProfileLink = styled(Link)`
  ${({ theme: { colors, mq, utils } }) => css`
    line-height: 1.35;
    letter-spacing: 0.02em;
    font-family: ${Object.keys(FONT_FAMILIES).join(',')}, sans-serif;
    font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off, 'liga' off;
    font-size: ${embeddedRem(2)};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};

    display: grid;
    grid-auto-flow: column;
    column-gap: ${embeddedRem(1)};
    color: ${utils.alphaHex(colors.primary.main, 55)};
    text-decoration: none;
    align-items: center;
    grid-area: 1 / -3 / span 1 / span 1;
    z-index: 1;

    ${mq.md.down} {
      grid-area: 1 / -3 / span 1 / span 1;
    }
  `}
`;

const UserAvatar = styled.button`
  ${({ theme: { colors, durations, mq, utils } }) => css`
    display: grid;
    justify-self: center;
    align-items: center;
    justify-items: center;
    background-color: transparent;
    border-radius: 50%;
    border: ${embeddedRem(0.25)} solid ${utils.alphaHex(colors.primary.main, 12)};
    transition: all ${durations.regular};
    cursor: pointer;
    width: ${embeddedRem(3)};
    height: ${embeddedRem(3)};

    & {
      padding: 0;
    }

    &:active {
      border-color: ${utils.alphaHex(colors.primary.main, 25)};
      background-color: ${utils.alphaHex(colors.primary.main, 1)};
    }

    ${mq.hover} {
      &:hover {
        border-color: ${utils.alphaHex(colors.primary.main, 25)};
      }
    }

    & > svg {
      width: ${embeddedRem(1.5)};
      height: ${embeddedRem(1.5)};
      fill: ${utils.alphaHex(colors.primary.main, 12)};
    }

    ${mq.md.down} {
    }
  `}
`;

const StyledLinksContainer = styled(LinksContainer)`
  ${({ theme: { mq } }) => css`
    ${mq.md.up} {
      grid-area: 1 / 1 / span 1 / -1;
    }
  `}
`;

const StyledMenuButton = styled(MenuButton)`
  ${({ theme: { mq } }) => css`
    grid-area: 1 / -2 / span 1 / span 1;

    ${mq.md.down} {
      grid-area: 1 / -2 / span 1 / span 1;
    }
  `}
`;

const Header: React.FC<Classname> = ({ className }) => {
  const {
    homeUrl,
    links,
    popupLinks,
    isOpen,
    isPopupOpen,
    popupRef,
    handleLogout,
    handleMenu,
    containerRef,
    areLinksVisible,
    headerIsSticky,
    isAuthorizedUser,
    isMobile,
    username,
  } = useHeaderService();

  return (
    <StyledWrapper headerIsSticky={headerIsSticky} ref={containerRef}>
      <Container headerIsSticky={headerIsSticky} className={className}>
        <LogoLink to={homeUrl}>
          <StyledLogo variant={LOGO_VARIANTS.named} />
        </LogoLink>
        {areLinksVisible ? (
          <>
            <StyledLinksContainer isOpen={isOpen} linksCount={links.length + 1}>
              {links.map(({ label, url, active, onClick }) => (
                <StyledLink key={url} to={url} active={active} onClick={onClick}>
                  {label}
                </StyledLink>
              ))}
              <MenuPopupContainer isOpen={isPopupOpen} ref={popupRef}>
                <MenuPopup>
                  {popupLinks.map(({ label, onClick = () => void 0 }) => (
                    <ButtonLink
                      key={label}
                      onClick={() => {
                        onClick();
                        if (!isMobile) handleMenu();
                      }}
                    >
                      {label}
                    </ButtonLink>
                  ))}
                  {!isAuthorizedUser ? (
                    <LogoutButton onClick={handleLogout}>
                      Logout
                      <ExitSvg />
                    </LogoutButton>
                  ) : null}
                </MenuPopup>
              </MenuPopupContainer>
            </StyledLinksContainer>
            <DesktopProfileLink to={ROUTES.PROFILE.path}>
              <StyledStreakIndicator />
              {!isMobile ? (
                <>
                  <UserAvatar>
                    <UserSvg />
                  </UserAvatar>
                  {username}
                </>
              ) : null}
            </DesktopProfileLink>
            {[...popupLinks, ...(isMobile ? [''] : [])].length ? (
              <StyledMenuButton onClick={handleMenu} isOpen={!!isOpen} />
            ) : null}
          </>
        ) : null}
      </Container>
    </StyledWrapper>
  );
};

export default Header;
