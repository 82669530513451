import { all, fork, takeLeading } from 'typed-redux-saga/macro';

import actions from '../actions';
import { watchInput } from '../Input/sagas';

import { initializeAndWatchAudioSession } from './initializeAndWatchAudioSession';
import { initializeWebAudioAccess } from './initializeWebAudioAccess';

export function* watchAudio() {
  yield* all([
    fork(initializeWebAudioAccess),
    fork(initializeAndWatchAudioSession),
    fork(watchInput),
    takeLeading(actions.resetState, initializeWebAudioAccess),
  ]);
}
