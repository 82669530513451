import { channel, END } from 'redux-saga';
import { call, cancel, delay, FixedTask, fork, put, spawn, take } from 'typed-redux-saga/macro';

import {
  transcriptionSocket,
  onCallback,
  onCallbackParameters,
  OnMarkCallback,
  OnTranscriptCallback,
} from '../transcriptionSocket';

import type { ClientEvents, UncontrolledTranscriptionSocketInstanceInfo } from './types';
import { voiceActivityDetectionTimeoutMs } from './constants';

function* uncontrolledTranscriptionSocket({
  id,
  sequenceNumber,
  detectionBufferMs = 200,
}: UncontrolledTranscriptionSocketInstanceInfo) {
  const { on, send, sendStart, sendMark, onTranscript, onMark, ...restTranscriptionSocket } =
    yield* call(transcriptionSocket<ClientEvents>, {
      id,
      sequenceNumber,
    } as const);

  const startChannel = yield* call(channel<'ready'>);

  const startTask = yield* fork<onCallbackParameters<'open'>, onCallback<'open'>>(
    on,
    'open',
    function* () {
      sendStart({
        voiceActivityDetectionEnabled: true,
        voiceActivityDetectionPauseMs: detectionBufferMs,
      });
      yield* put(startChannel, 'ready');
    }
  );

  yield* take(startChannel);
  yield* cancel(startTask);

  yield* spawn(function* sendMarkAfterEmptyTranscriptTimeoutDuration() {
    const transcriptStateChannel = yield* call(channel<'noText' | 'hasText'>);

    const onTranscriptTask = yield* fork(onTranscript, function* updateTranscriptState({ text }) {
      yield* put(transcriptStateChannel, text ? 'hasText' : 'noText');
    } as OnTranscriptCallback);

    const onMarkTask = yield* fork(onMark, function* initiateTranscriptStateTermination() {
      yield* put(transcriptStateChannel, END);
    } as OnMarkCallback);

    try {
      let gotAtLeastOneTextTranscript = false;
      let timerTask: FixedTask<void> | null = null;

      while (true) {
        const latestTranscript = yield* take(transcriptStateChannel);

        if (latestTranscript === 'hasText') {
          gotAtLeastOneTextTranscript = true;
          if (timerTask && timerTask.isRunning()) {
            yield* cancel(timerTask);
          }
        }

        if (
          gotAtLeastOneTextTranscript &&
          (!timerTask || !timerTask.isRunning() || timerTask.isCancelled())
        ) {
          timerTask = yield* fork(function* startTimeout() {
            yield* delay(voiceActivityDetectionTimeoutMs);
            yield* call(sendMark);
            yield* put(transcriptStateChannel, END);
          });
        }
      }
    } finally {
      yield* cancel(onTranscriptTask);
      yield* cancel(onMarkTask);
    }
  });

  return { onTranscript, onMark, ...restTranscriptionSocket };
}

export default uncontrolledTranscriptionSocket;
