import hideable from 'utils/hideable';
import styled from '@emotion/styled';

const List = styled.ul`
  font-size: 2rem;
`;

const ListItem = styled.li`
  font-size: 2rem;
`;

const ChromeInstructions = () => (
  <List>
    <ListItem>
      Open Chrome and click on the three dots icon in the top right corner of the screen.
    </ListItem>
    <ListItem>Select "Settings" from the drop-down menu.</ListItem>
    <ListItem>Scroll down and click on "Privacy and security" in the left sidebar.</ListItem>
    <ListItem>Click on "Site settings" in the "Privacy and security" section.</ListItem>
    <ListItem>
      Click on the "Microphone" section to view the list of websites that have requested microphone
      access.
    </ListItem>
    <ListItem>Find "app.leyaai.com" in the list and click on it.</ListItem>
    <ListItem>
      Click the trash can icon next to the website's name to delete the permission settings.
    </ListItem>
    <ListItem>Close the "Site settings" tab and reload the website.</ListItem>
  </List>
);

export default hideable(ChromeInstructions);
