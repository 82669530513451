import { keysOf } from 'utils/helpers';

import * as types from './types';

export const snakeToCamel = <snakeString extends string>(snakeString: snakeString) =>
  snakeString.replace(/_./g, match =>
    match.charAt(1).toUpperCase()
  ) as types.snakeToCamel<snakeString>;

export const snakeObjectToCamel = <
  snakeObject extends Record<string, unknown>,
  camelObject = types.snakeObjectToCamel<snakeObject>
>(
  obj: snakeObject
): camelObject => {
  const keys = keysOf(obj);

  return keys.reduce((acm, key) => {
    const camelKey = snakeToCamel(key);
    const value = obj[key];

    const shouldRecurse =
      typeof value === 'object' && !Array.isArray(value) && value !== null && value !== undefined;

    return {
      ...acm,
      [camelKey]: shouldRecurse ? snakeObjectToCamel(value as Record<string, unknown>) : value,
    };
  }, {} as camelObject);
};
