import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { isAuthenticated as isAuthenticatedSelector } from 'features/Auth/selectors';

import { ROUTES } from 'navigation/constants';
import Header from 'organisms/Header';

interface ILocationState {
  from?: {
    pathname?: string;
  };
}

const PublicRoute = () => {
  const location = useLocation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);

  const locationState = location.state as ILocationState;
  const redirectDestination = locationState?.from?.pathname || ROUTES.HOME.path;

  if (isAuthenticated) {
    return <Navigate to={redirectDestination} state={{ from: location }} replace />;
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default PublicRoute;
