import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from './actions';
import selectors from './selectors';

export const useProgressiveWebAppPrompt = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(selectors.getModalStep);
  const installable = useSelector(selectors.isInstallable);
  const canPrompt = useSelector(selectors.canPrompt);

  const open = useCallback(() => {
    dispatch(actions.setModalStep('prompt'));
  }, [modalIsOpen, installable]);

  return { installable, canPrompt, open } as const;
};
