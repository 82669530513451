import React, { Children, ComponentProps, PropsWithChildren, ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Button from 'atoms/Button';
import { filterProps } from 'utils/helpers';

interface IModalStep extends PropsWithChildren {
  titleElement: ReactNode;
  description: string;
  buttonElement: ReactNode;
  buttonProps?: Partial<Omit<JSX.IntrinsicElements['button'], 'color'>> &
    ComponentProps<typeof Button>;
}

enum MODAL_STEP_AREAS {
  title = 'title',
  description = 'description',
  content = 'content',
  button = 'button',
}

export const CLASSNAME_PREFIX = 'fade';

const Container = styled.div`
  ${({ theme: { durations, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [MODAL_STEP_AREAS.title],
      [MODAL_STEP_AREAS.description],
      [MODAL_STEP_AREAS.content],
      [MODAL_STEP_AREAS.button],
    ])};
    row-gap: 3rem;
    justify-items: center;
    padding: 4rem;
    width: 58rem;
    grid-row: 1/ 1;
    grid-column: 1/ 1;
    transition: opacity ${durations.regular};

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        ['.'],
        [MODAL_STEP_AREAS.title],
        [MODAL_STEP_AREAS.description],
        [MODAL_STEP_AREAS.content],
        ['.'],
        [MODAL_STEP_AREAS.button],
      ])};
      grid-template-rows: 1fr repeat(3, min-content) 1fr min-content;
      align-items: center;
      justify-items: center;
      padding: 2.5rem 0;
      width: 100%;
    }

    &.${CLASSNAME_PREFIX} {
      &-exit,
      &-enter-active,
      &-enter-done {
        opacity: 1;
      }

      &-enter-active {
        transition-delay: ${durations.regular};
        transition-timing-function: ease-in;
      }

      &-enter,
      &-exit-active,
      &-exit-done {
        opacity: 0;
      }

      &-exit-active {
        transition-timing-function: ease-out;
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1}

    grid-area: ${MODAL_STEP_AREAS.title};
    display: grid;
    row-gap: 1rem;
    text-align: center;
    margin: 0;

    ${mq.md.down} {
      ${tp.h2}

      padding: 0 3rem;
    }
  `}
`;

const Description = styled.span`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p1}

    grid-area: ${MODAL_STEP_AREAS.description};
    color: ${utils.alphaHex(colors.primary.dark, 55)};
    text-align: center;
    margin-top: -2rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const Content = styled('div', filterProps('hasContent'))<{ hasContent: boolean }>`
  ${({ hasContent }) => css`
    grid-area: ${MODAL_STEP_AREAS.content};
    width: 100%;

    ${!hasContent
      ? css`
          margin-top: -3rem;
        `
      : ''}
  `}
`;

const NextButton = styled(Button)`
  grid-area: ${MODAL_STEP_AREAS.button};
`;

const ModalStep: React.FC<IModalStep> = ({
  titleElement,
  description,
  buttonElement,
  children,
  buttonProps = {},
}) => {
  return (
    <Container>
      <Title>{titleElement}</Title>
      <Description>{description}</Description>
      <Content hasContent={!!Children.count(children)}>{children}</Content>
      <NextButton {...buttonProps}>{buttonElement}</NextButton>
    </Container>
  );
};

export default ModalStep;
