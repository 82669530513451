import { useDispatch, useSelector } from 'react-redux';

import ForgotPassword from './ForgotPassword';
import { setEmail, resetState } from '../slice';
import { submitForgotPasswordForm } from '../actions';
import {
  getEmail,
  getErrorText,
  getSuccessText,
  isLoading as isSendEmailLoading,
} from '../selectors';
import { useEffect } from 'react';

const ForgotPasswordService = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  const email = useSelector(getEmail);
  const error = useSelector(getErrorText);
  const success = useSelector(getSuccessText);
  const isLoading = useSelector(isSendEmailLoading);

  const onEmailChange = (value: string) => {
    dispatch(setEmail(value));
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();

    dispatch(submitForgotPasswordForm());
  };

  return (
    <ForgotPassword
      error={error}
      success={success}
      onEmailChange={onEmailChange}
      onSubmit={onSubmit}
      email={email}
      isLoading={isLoading}
    />
  );
};

export default ForgotPasswordService;
