import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

import type { IOnboarding } from './types';

const initialState: IOnboarding = {
  currentJourney: null,
  currentStep: null,
  previousJourneys: [],
  callbackTriggerId: null,
};

export const onboardingSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setCurrentJourney: (state, { payload }: PayloadAction<IOnboarding['currentJourney']>) => {
      if (!payload || !state.previousJourneys.includes(payload)) {
        state.currentJourney = payload;
      }
    },
    setCurrentStep: (state, { payload }: PayloadAction<IOnboarding['currentStep']>) => {
      if (!payload || state.currentJourney) {
        state.currentStep = payload;
      }
    },
    setCallbackTriggerId: (state, { payload }: PayloadAction<IOnboarding['callbackTriggerId']>) => {
      if (!payload || (state.currentJourney && state.currentStep)) {
        state.callbackTriggerId = payload;
      }
    },
    resetState: state => {
      if (state.currentJourney) state.previousJourneys.push(state.currentJourney);
      state.currentJourney = initialState.currentJourney;
      state.currentStep = initialState.currentStep;
      state.callbackTriggerId = initialState.callbackTriggerId;
    },
  },
});

export const { setCurrentJourney, setCurrentStep, setCallbackTriggerId, resetState } =
  onboardingSlice.actions;
