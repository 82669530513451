import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import CancelButton from 'atoms/CancelButton';
import Loader from 'atoms/Loader';
import Modal from 'molecules/Modal';
import hideable from 'utils/hideable';

import LessonEvaluation from './LessonEvaluation';
import TaskEvaluation from './TaskEvaluation';
import { useTaskCompleteService } from './hooks';

import type { InterpolationWithTheme } from 'utils/types';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';

interface ITaskComplete {
  taskId: string;
  lessonId: string;
  handleClose: () => void;
}

const StyledModal = styled(Modal)`
  ${({ theme: { depths, mq } }) => css`
    grid-template-rows: var(${GLOBAL_STYLE_VARIABLES.headerHeight}) 1fr;
    z-index: ${depths.priority};

    ${mq.md.down} {
      grid-template-rows: calc(var(${GLOBAL_STYLE_VARIABLES.headerHeight})) 1fr;
    }
  `}
`;

const modalContent: InterpolationWithTheme = ({ mq }) => css`
  position: relative;
  display: grid;
  grid-auto-rows: min-content;
  align-self: center;
  justify-self: center;
  justify-items: center;
  background-color: initial;
  overflow-y: auto;
  grid-row-start: 2;
  max-height: calc(100vh - var(${GLOBAL_STYLE_VARIABLES.headerHeight}));

  ${mq.md.up} {
    border-radius: 4rem;
    overflow-x: hidden;
  }

  ${mq.md.down} {
    align-self: flex-end;
    grid-template-rows: 1fr;
    width: 100%;
    height: 100%;
  }
`;

const StyledCancelButton = styled(CancelButton)`
  ${({ theme: { mq } }) => css`
    position: absolute;
    top: 3.5rem;
    right: 3rem;

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const OverlayLoader = styled.div`
  ${({ theme: { colors } }) => css`
    position: absolute;
    display: grid;
    align-items: center;
    justify-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: ${colors.primary.dark};
  `}
`;

const TaskComplete: React.FC<ITaskComplete> = ({ taskId, lessonId, handleClose }) => {
  const { isLoading, pointsEarned, taskName, lessonName, isLessonFinished, taskEvaluationId } =
    useTaskCompleteService({
      taskId,
      lessonId,
    });

  return (
    <StyledModal isOpen onClick={handleClose} classNames={{ modal: modalContent }}>
      {isLoading ? (
        <OverlayLoader>
          <Loader />
        </OverlayLoader>
      ) : null}
      <StyledCancelButton onClick={handleClose} />
      {isLessonFinished ? (
        <LessonEvaluation
          lessonName={lessonName}
          handleClose={handleClose}
          taskName={taskName}
          pointsEarned={pointsEarned}
          taskEvaluationId={taskEvaluationId}
        />
      ) : (
        <TaskEvaluation
          lessonId={lessonId}
          handleClose={handleClose}
          taskName={taskName}
          pointsEarned={pointsEarned}
          taskEvaluationId={taskEvaluationId}
        />
      )}
    </StyledModal>
  );
};

export default hideable(TaskComplete);
