import { useDispatch } from 'react-redux';
import { styled } from '@mui/material';
import { red } from '@mui/material/colors';
import { useSearchParams } from 'react-router-dom';
import { Formik } from 'formik';

import AuthInputsContainer from 'components/AuthInputsContainer';
import AuthLink from 'components/AuthLink';
import AuthPage from 'components/AuthPage';
import FormCard from 'components/FormCard';
import Button from 'components/Button';
import { ROUTES } from 'navigation/constants';

import { submitRegistrationForm } from '../actions';
import { initialValues, RegistrationSchema } from '../form';
import { IRegistrationForm } from '../types';

import EmailField from './EmailField';
import PasswordField from './PasswordField';

const Headline = styled('h4')(({ theme }) => ({
  ...theme.typography.h5,
  margin: '0 0 32px',
}));

const ErrorText = styled('div')({
  fontSize: 14,
  fontWeight: 400,
  color: red[500],
});

const Registration = () => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const email = searchParams.get('email');

  const headline = email ? 'Set password to finish your registration' : 'Register';

  return (
    <AuthPage>
      <Formik
        initialValues={{ ...initialValues, email: email ?? '' } as IRegistrationForm}
        validationSchema={RegistrationSchema}
        onSubmit={({ email, password }, actions) => {
          dispatch(submitRegistrationForm({ email, password, actions }));
        }}
      >
        {({ handleSubmit, handleReset, isSubmitting, errors }) => (
          <FormCard onSubmit={handleSubmit} onReset={handleReset}>
            <AuthInputsContainer>
              <Headline>{headline}</Headline>
              <EmailField name="email" placeholder="Email" disabled={!!email && !errors.email} />
              <PasswordField name="password" placeholder="Password" />
              <PasswordField name="confirmPassword" placeholder="Confirm password" />
              {!!errors.form && <ErrorText>{errors.form}</ErrorText>}
              <Button text="Sign up" isLoading={isSubmitting} />
              <AuthLink
                mainText="Do you have an account?"
                linkText="Sign in"
                link={ROUTES.LOGIN.path}
              />
            </AuthInputsContainer>
          </FormCard>
        )}
      </Formik>
    </AuthPage>
  );
};

export default Registration;
