import { createSelector } from '@reduxjs/toolkit';

import { NAME } from './constants';

import type { TRootState } from 'store';
import type { Chunk, Conversation, Entity, EntityRecord, IChat } from './types';

const getChat = (state: TRootState) => state[NAME];

const getConversationId = createSelector(getChat, ({ conversationId }) => conversationId);
const getSequenceNumber = createSelector(getChat, ({ sequenceNumber }) => sequenceNumber);

const getPushToTalkState = createSelector(getChat, ({ pushToTalkState }) => pushToTalkState);
const matchPushToTalkState = (matcher: IChat['pushToTalkState'] | IChat['pushToTalkState'][]) =>
  createSelector(getPushToTalkState, state =>
    Array.isArray(matcher) ? matcher.includes(state) : matcher === state
  );

const getHandsFreeState = createSelector(getChat, ({ handsFreeState }) => handsFreeState);
const matchHandsFreeState = (matcher: IChat['handsFreeState'] | IChat['handsFreeState'][]) =>
  createSelector(getHandsFreeState, state =>
    Array.isArray(matcher) ? matcher.includes(state) : matcher === state
  );

export const isConversationStarted = (state: TRootState) => state.chat.isConversationStarted;

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

type Message = { key: string; entity: Entity; text: string; id: string; sequenceNumber: number };

export const getSequenceChunks =
  ({ id, sequenceNumber, entity }: { id: string; sequenceNumber: number; entity: Entity }) =>
  (state: TRootState) => {
    const entityRecord = state.chat.conversations?.[id]?.[sequenceNumber]?.[entity] ?? null;

    if (!entityRecord) return [];

    const chunksEntries: Entries<EntityRecord> = Object.entries(entityRecord);

    return chunksEntries.sort(([a], [b]) => Number(a) - Number(b));
  };

export const getSequenceMessage =
  ({ id, sequenceNumber, entity }: { id: string; sequenceNumber: number; entity: Entity }) =>
  (state: TRootState) => {
    const entityRecord = state.chat.conversations?.[id]?.[sequenceNumber]?.[entity] ?? null;

    if (!entityRecord) return '';

    const chunksEntries: Entries<EntityRecord> = Object.entries(entityRecord);

    const sortedChunks = chunksEntries.sort(([a], [b]) => Number(a) - Number(b));

    return sortedChunks.reduce((message, [, { text }]) => [message, text].join(' ').trim(), '');
  };

export const checkSequenceIsMarked =
  ({ id, sequenceNumber, entity }: { id: string; sequenceNumber: number; entity: Entity }) =>
  (state: TRootState) => {
    const entityRecord = state.chat.conversations?.[id]?.[sequenceNumber]?.[entity] ?? null;

    if (!entityRecord) return false;

    const chunks: Chunk[] = Object.values(entityRecord);

    return chunks.some(({ isLast }) => isLast);
  };

export const getConversationMessages =
  ({ id, markedEntities }: { id: string; markedEntities: Entity[] }) =>
  (state: TRootState) => {
    const { conversations } = state.chat;
    if (!conversations?.[id]) return [];

    const conversation: Conversation = conversations[id];

    const sequencesEntries: Entries<Conversation> = Object.entries(conversation);
    const sortedSequencesEntries = sequencesEntries.sort(([a], [b]) => Number(a) - Number(b));

    return sortedSequencesEntries.reduce((acm, [sequenceNumber, sequence]) => {
      const entities: Entries<Record<string, EntityRecord>> = Object.entries({
        user: sequence?.user ?? {},
        bot: sequence?.bot ?? {},
      });

      entities.forEach(([entity]) => {
        const text = getSequenceMessage({
          id,
          sequenceNumber: Number(sequenceNumber),
          entity: entity as Entity,
        })(state);

        const conditionalMarkValidated =
          !markedEntities.includes(entity as Entity) ||
          checkSequenceIsMarked({
            id,
            sequenceNumber: Number(sequenceNumber),
            entity: entity as Entity,
          })(state);

        if (conditionalMarkValidated && text) {
          acm.push({
            entity: entity as Entity,
            key: `${id}-${sequenceNumber}-${entity}`,
            id,
            sequenceNumber: Number(sequenceNumber),
            text,
          });
        }
      });

      return acm;
    }, [] as Message[]);
  };

export const sttIsLoading = (state: TRootState) => state.chat.isLoading;

export default {
  getChat,
  getConversationId,
  getSequenceNumber,
  getPushToTalkState,
  matchPushToTalkState,
  getHandsFreeState,
  matchHandsFreeState,
  getSequenceChunks,
  getSequenceMessage,
};
