import { MODAL_CONTAINER_ID } from 'features/Task/components/TaskComplete/constants';

import TaskReviewTooltip from './TaskReviewTooltip';
import ImprovementTooltip from './ImprovementTooltip';

import type { JourneyConfig } from '../types';

const reviewJourney = [
  {
    name: 'taskReview',
    Element: TaskReviewTooltip,
    rootId: MODAL_CONTAINER_ID,
  },
  {
    name: 'improvement',
    Element: ImprovementTooltip,
    rootId: MODAL_CONTAINER_ID,
  },
] as const satisfies JourneyConfig;

export default reviewJourney;
