import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { GetActions, getActionTypes } from 'utils/redux';

import type { PlayState } from './types';

export interface IAudioOutput {
  playState: PlayState;
}

const initialState: IAudioOutput = {
  playState: 'stopped',
};

export const audioOutputSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setPlayState: (state, action: PayloadAction<IAudioOutput['playState']>) => {
      state.playState = action.payload;
    },
    resetState: () => ({
      ...initialState,
    }),
  },
});

export const { setPlayState, resetState } = audioOutputSlice.actions;

export const actionTypes = getActionTypes(audioOutputSlice);

export type Actions = GetActions<typeof audioOutputSlice.actions>;
