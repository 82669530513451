import { put, takeEvery } from 'typed-redux-saga/macro';

import { setCurrentRoute } from 'features/Auth/slice';

import actions from '../actions';
import { INITIAL_AUDIO_SESSION } from '../constants';

export function* initializeAndWatchAudioSession() {
  yield* put(actions.setAudioSession(INITIAL_AUDIO_SESSION));

  yield* takeEvery(actions.setAudioSession, function* ({ payload }) {
    if (navigator?.audioSession) {
      navigator.audioSession.type = payload ?? INITIAL_AUDIO_SESSION;
    }
  });

  yield* takeEvery([actions.resetState, setCurrentRoute], function* () {
    yield* put(actions.setAudioSession(null));
  });
}
