import { call, fork, put, select, take, takeEvery } from 'typed-redux-saga/macro';
import { channel } from 'redux-saga';
import TagManager from 'react-gtm-module';

import actions from './actions';

import type { BeforeInstallPromptEvent, IModalShownState } from './types';
import { setCurrentRoute } from 'features/Auth/slice';
import { getCurrentRoute } from 'features/Auth/selectors';
import { ROUTES } from 'navigation/constants';
import { isIOS } from 'utils/constants';
import { matchPath } from 'utils/helpers';

import { getTier, getId } from '../selectors';
import { UserSubscriptionTier } from '../types';
import { setSubscription } from '../slice';
import selectors from './selectors';

const STORAGE_KEY = 'progressiveWebAppShownState';

function* setShownStateToStorage() {
  const shownState = yield* select(selectors.getShownState);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(shownState));
}

function* getShownStateFromStorage() {
  const storedState = localStorage.getItem(STORAGE_KEY);
  const { timesShown, lastShown }: IModalShownState = storedState
    ? JSON.parse(storedState)
    : { timesShown: 0, lastShown: null };

  yield* put(actions.setTimesShown(timesShown));
  yield* put(actions.setLastShown(lastShown));
}

function* sendDataLayerEvent(event: string) {
  try {
    const userId = yield* select(getId);

    yield* call([TagManager, TagManager.dataLayer], {
      dataLayer: {
        event,
        userId,
      },
    });
  } catch {}
}

function* watchPromptOpen() {
  yield* takeEvery(actions.setModalStep, function* ({ payload: step }) {
    if (step === 'prompt') {
      yield* put(actions.setLastShown(Date.now()));
      yield* call(setShownStateToStorage);

      yield* call(sendDataLayerEvent, 'appInstallPromptShown');
    }
  });
}

function* incrementTimesShown() {
  const timesShown = yield* select(selectors.getTimesShown);
  yield* put(actions.setTimesShown(timesShown + 1));
}

function* triggerByRouteChange() {
  do {
    let tier = yield* select(getTier);

    if (!tier) {
      yield* take(setSubscription);
      tier = yield* select(getTier);
    }

    const currentRoute = yield* select(getCurrentRoute);

    const enabledRoutes = [ROUTES.HOME.path, ROUTES.PROFILE.path, ROUTES.LESSON.path];
    const enabledTiers: UserSubscriptionTier[] = ['subscriber', 'former_subscriber'];

    const installable =
      tier &&
      enabledTiers.includes(tier) &&
      enabledRoutes.some(path => matchPath(path, currentRoute));

    yield* put(actions.setInstallable(!!installable));

    const canPrompt = yield* select(selectors.canPrompt);

    if (installable && canPrompt) {
      yield* call(incrementTimesShown);
      yield* put(actions.setModalStep('prompt'));
    }

    yield* take(setCurrentRoute);
  } while (true);
}

function* interactivePrompt() {
  const installableChannel = yield* call(
    channel<{ type: 'installable'; payload: BeforeInstallPromptEvent }>
  );

  const handleBeforeInstallPrompt = (event: BeforeInstallPromptEvent) => {
    event.preventDefault();

    installableChannel.put({ type: 'installable', payload: event });
  };

  yield* call([window, window.addEventListener], 'beforeinstallprompt', handleBeforeInstallPrompt);

  while (true) {
    const { payload: installEvent } = yield* take(installableChannel);

    yield* fork(triggerByRouteChange);

    yield* take(actions.promptProgressiveWebAppInstallation);

    yield* call([installEvent, installEvent.prompt]);

    try {
      yield* call([installEvent, installEvent.userChoice]);
      yield* put(actions.setModalStep(null));
      yield* call(
        [window, window.removeEventListener],
        'beforeinstallprompt',
        handleBeforeInstallPrompt
      );

      yield* call(sendDataLayerEvent, 'appInstalled');

      break;
    } catch {}
  }
}

export function* watchProgressiveWebApp() {
  try {
    yield* call(getShownStateFromStorage);
    yield* fork(watchPromptOpen);

    if (isIOS) {
      const canInstall = 'standalone' in window.navigator;
      const isInstalled = (window.navigator as unknown as { standalone: boolean }).standalone;

      yield* put(actions.setInstallable(canInstall && !isInstalled));

      if (canInstall && !isInstalled) {
        yield* fork(triggerByRouteChange);
        while (true) {
          yield* take(actions.promptProgressiveWebAppInstallation);
          yield* put(actions.setModalStep('ios-instructions'));
        }
      }

      if (isInstalled) {
        yield* call(sendDataLayerEvent, 'appInstalled');
      }
    } else {
      yield* call(interactivePrompt);
    }
  } catch {}
}
