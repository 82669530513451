export const NAME = 'conversationControl';

export const CONVERSATION_METHOD = {
  LEGACY_PUSH_TO_TALK: 'push_to_talk',
  HANDS_FREE: 'hands_free',
  PUSH_TO_TALK: 'push_to_talk_v2',
} as const;

export enum CONVERSATION_SPEED {
  SLOW = 'slow',
  NORMAL = 'normal',
  FAST = 'fast',
}
