import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setUserId } from 'firebase/analytics';
import { css, Global, ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import TagManager from 'react-gtm-module';
import { ToastContainer, toast } from 'react-toastify';
import { version } from '../package.json';
import 'react-toastify/dist/ReactToastify.css';

import { ReactComponent as InfoSvg } from 'assets/info.svg';
import Navigation from 'navigation';
import emotionTheme, { FONT_FAMILIES } from 'theme/emotion';
import theme from 'theme';
import LoadingScreen from 'features/LoadingScreen/components';
import { initialAuth } from 'features/Auth/actions';
import { getUserId, getUserTier, getUserTotalConversationCount } from 'features/Auth/selectors';
import { Onboarding } from 'features/Onboarding';
import { setUser } from '@sentry/react';
import { analytics } from 'utils/firebase';
import { useScrollbarWidthSync } from 'utils/hooks/useScrollLock/useScrollLock';
import { PushNotifications } from 'features/PushNotifications';
import { Streaks } from 'features/Streaks';
import { ProgressiveWebAppPromptModal, BrandReviewPromptModal } from 'features/User';
import { startAudioInputContext } from 'features/AudioInput/actions';
import { useServiceWorker } from 'utils/hooks/useServiceWorker';
import styled from '@emotion/styled';
import colors from 'theme/emotion/colors';
import utils from 'theme/emotion/utils';
import durations from 'theme/emotion/durations';
import mediaQueries from 'theme/emotion/mediaQueries';

const StyledToastContainer = styled(ToastContainer)`
  z-index: 2147483647;

  & .Toastify__ {
    &toast--info {
      border-radius: 20px;
      overflow: hidden;
      cursor: pointer;
      user-select: none;

      &::after {
        content: '';
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${colors.neutral.white};
        transition: background-color ${durations.regular};
      }

      &:hover {
        &::after {
          background-color: ${utils.alphaHex(colors.primary.main, 3)};
        }
      }

      &:active {
        &::after {
          background-color: ${utils.alphaHex(colors.primary.main, 6)};
        }
      }

      & .Toastify__toast-body {
        display: grid;
        grid-template-columns: min-content 1fr;

        & > div:last-child {
          grid-area: 1 / 2 / span 2 / -1;
        }

        & > .Toastify__toast-icon {
          grid-area: 1 / 1 / span 1 / span 1;

          & svg {
            width: 24px;
            height: 24px;
            fill: ${colors.primary.light};
          }
        }
      }

      & button {
        display: none;
      }

      ${mediaQueries.sm.down} {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
`;

const ReloadToast = styled.div`
  display: grid;
  line-height: 1.35;
  letter-spacing: 0.02em;
  font-family: ${Object.keys(FONT_FAMILIES).join(',')}, sans-serif;
  font-feature-settings: 'pnum' on, 'lnum' on, 'kern' off, 'liga' off;
  font-size: 16px;
  font-weight: ${FONT_FAMILIES.Poppins.semiBold};
  color: ${colors.primary.main};

  & > span {
    font-weight: ${FONT_FAMILIES.Poppins.regular};
    color: ${utils.alphaHex(colors.primary.main, 55)};
    font-size: 12px;
  }
`;

const App = () => {
  const appHasBeenUpdated = useServiceWorker();

  useEffect(() => {
    if (appHasBeenUpdated) {
      toast.info(
        <ReloadToast>
          Update to use the latest features!<span>Click here to update</span>
        </ReloadToast>,
        {
          autoClose: false,
          icon: () => <InfoSvg />,
          closeOnClick: false,
          hideProgressBar: true,
          position: toast.POSITION.BOTTOM_RIGHT,
          onClick: () => {
            window.location.reload();
          },
        }
      );
    }
  }, [appHasBeenUpdated]);

  const dispatch = useDispatch();

  const userId = useSelector(getUserId);
  const userTier = useSelector(getUserTier);
  const userTotalConversationCount = useSelector(getUserTotalConversationCount);

  useEffect(() => {
    const documentClickHandler = () => dispatch(startAudioInputContext());

    document.addEventListener('click', documentClickHandler);

    dispatch(initialAuth());

    return () => {
      document.removeEventListener('click', documentClickHandler);
    };
  }, []);

  useScrollbarWidthSync();

  useEffect(() => {
    if (userId) {
      setUser({ id: userId });
    }

    if (userId && userTier && userTotalConversationCount) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'setUserData',
          userId,
          userTier,
          totalConversationCount: userTotalConversationCount,
          appVersion: version,
        },
      });
      setUserId(analytics, userId);
    }
  }, [userId, userTier, userTotalConversationCount]);

  return (
    <ThemeProvider theme={theme}>
      <EmotionThemeProvider theme={emotionTheme}>
        <Global
          styles={css`
        #root,
        html, 
        body {
          margin: 0;
          padding: 0;
          min-height: 100vh;
          background-color: ${theme.palette.background.paper};
          font-family: Poppins, sans-serif;

          @supports (height: 100dvh) {
            min-height: 100dvh;
          }
      `}
        />
        <StyledToastContainer />
        <ProgressiveWebAppPromptModal />
        <BrandReviewPromptModal />
        <PushNotifications />
        <Streaks />
        <Onboarding />
        <Navigation />
        <LoadingScreen />
      </EmotionThemeProvider>
    </ThemeProvider>
  );
};

export default App;
