import { useEffect, useRef } from 'react';
import callApi, { METHODS, PATHS } from 'api';

import { captureException } from '@sentry/react';

type FeedbackEvaluationProps =
  | { conversationId: string; userTaskProgressId?: never; type?: never }
  | { conversationId?: never; userTaskProgressId: string; type?: 'feedback_evaluation' };

export const useFeedbackEvaluation = ({
  conversationId,
  userTaskProgressId,
  type,
}: FeedbackEvaluationProps) => {
  const feedbackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (feedbackRef.current && (conversationId || userTaskProgressId)) {
      const feedbackContainer = feedbackRef.current;

      const watchChanges: MutationCallback = (_, mutationObserver) => {
        const buttonNodes = feedbackContainer.querySelectorAll(
          'button[class$=EmotionOption__isEmbedded]'
        );

        if (buttonNodes?.length === 5) {
          feedbackContainer
            .querySelectorAll('button[class$=EmotionOption__isEmbedded]')
            .forEach((buttonNode, index) => {
              buttonNode.addEventListener('click', async () => {
                try {
                  await callApi<{
                    evaluation_percentage: number;
                    conversation_id?: string;
                    user_task_progress_id?: string;
                  }>({
                    method: METHODS.POST,
                    mainPath: PATHS.USER_FEEDBACK,
                    data: {
                      ...(conversationId ? { conversation_id: conversationId } : {}),
                      ...(userTaskProgressId ? { user_task_progress_id: userTaskProgressId } : {}),
                      ...(type ? { type } : {}),
                      evaluation_percentage: index * 25,
                    },
                    authorized: true,
                  });
                } catch (error) {
                  captureException(error);
                }
              });
            });

          mutationObserver.disconnect();
        }
      };

      const observerOptions = {
        childList: true,
        subtree: true,
        attributes: true,
      };

      const observer = new MutationObserver(watchChanges);
      observer.observe(feedbackContainer, observerOptions);
    }
  }, [conversationId, userTaskProgressId]);

  return feedbackRef;
};
