import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import actions from '../../../actions';

export const usePromptStepService = () => {
  const dispatch = useDispatch();

  const handleReview = useCallback(() => {
    dispatch(actions.sendToBrandReviewPortal());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(actions.setModalStep(null));
  }, [dispatch]);

  return { handleReview, handleClose };
};
