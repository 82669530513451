import { configureStore, PreloadedState } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import { isDev } from 'utils/constants';

import rootReducer from './rootReducer';
import rootSaga from './rootSaga';

export const setupStore = (preloadedState?: PreloadedState<TRootState>) => {
  const sagaMiddleware = createSagaMiddleware();

  const store = configureStore({
    reducer: rootReducer,
    middleware: [sagaMiddleware],
    devTools: isDev,
    preloadedState,
  });

  sagaMiddleware.run(rootSaga);

  return store;
};

export type TRootState = ReturnType<typeof rootReducer>;
export type TAppStore = ReturnType<typeof setupStore>;
export type TAppDispatch = TAppStore['dispatch'];

const store = setupStore();

export default store;
