import React, { useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { FONT_FAMILIES, useMatchMedia } from 'theme/emotion';

import Tooltip, { AnchorAlignments } from '../../components/Tooltip';

import type { InterpolationWithTheme } from 'utils/types';
import type { FlatJourneyProps, JourneyProps } from '../types';

const Content = styled.div`
  ${({ theme: { mq, tp } }) => css`
    ${tp.p2}

    display: grid;
    row-gap: 0.5rem;

    ${mq.md.up} {
      width: 30rem;
    }

    ${mq.md.down} {
      min-width: 24rem;
    }
  `}
`;

const Title = styled.span`
  ${({ theme: { colors } }) => css`
    color: ${colors.neutral.white};
  `}
`;

const Subtitle = styled.span`
  ${({ theme: { colors } }) => css`
    margin-top: 0.5rem;
    color: ${colors.neutral.white};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
  `}
`;

const Description = styled.span`
  ${({ theme: { colors, utils } }) => css`
    color: ${utils.alphaHex(colors.neutral.white, 60)};
  `}
`;

const outlineStyle: InterpolationWithTheme = ({ colors }) =>
  css`
    background-color: ${colors.neutral.white};
    border-radius: 4rem;

    &::after {
      content: '';
      position: absolute;
      bottom: -1rem;
      transform: translateY(100%);
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: inherit;
    }
  `;

const positionStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.up} {
    right: -3rem;
  }

  ${mq.md.down} {
    bottom: calc(-5.5rem - 18px);
  }
`;

const arrowStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.up} {
    top: calc(9px + 0.5rem);
  }

  ${mq.md.down} {
    margin-left: 1rem;
  }
`;

const ConversationControlTooltip: React.FC<FlatJourneyProps> = props => {
  const { mq } = useTheme();

  const isMobile = useMatchMedia([mq.md.down]);

  const anchorAlignments = useMemo(() => {
    const breakpoint = isMobile ? 'mobile' : 'desktop';

    const anchorAlignmentsMap: Record<typeof breakpoint, AnchorAlignments> = {
      desktop: { anchor: 'right', alignment: 'top' },
      mobile: { anchor: 'bottom', alignment: 'left' },
    };

    return anchorAlignmentsMap[breakpoint];
  }, [isMobile]);

  return (
    <Tooltip
      {...(props as JourneyProps)}
      {...anchorAlignments}
      classNames={{
        outline: outlineStyle,
        position: positionStyle,
        arrow: arrowStyle,
      }}
    >
      <Content>
        <Title>Choose conversation control method:</Title>
        <Subtitle>1. Push to talk</Subtitle>
        <Description>Press and hold to speak. Reduces background noise effectively</Description>
        <Subtitle>2. Hands free</Subtitle>
        <Description>Automatically detects your voice. No need to press anything</Description>
      </Content>
    </Tooltip>
  );
};

export default ConversationControlTooltip;
