interface IWithConditionalComponent {
  isVisible: boolean;
}

export type TWithHideableProp<T> = T & IWithConditionalComponent;

const hideable = <T extends object>(Component: React.FC<T>) => {
  return function withConditionalComponent({
    isVisible,
    ...props
  }: IWithConditionalComponent & T) {
    if (!isVisible) {
      return null;
    }

    return <Component {...(props as T)} />;
  };
};

export default hideable;
