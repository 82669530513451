import { useEffect, useRef } from 'react';
import { useField } from 'formik';

import type { ITextArea } from './TextArea';

export const useTextAreaService = ({ name }: Pick<ITextArea, 'name'>) => {
  const [field, { error, touched }] = useField(name);

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaRef.current) {
      const element = textAreaRef.current;
      const autoSize = () => {
        setTimeout(function () {
          element.style.cssText = 'height:auto; padding:0';
          element.style.cssText = 'height:' + element.scrollHeight + 'px';
        }, 0);
      };

      element.addEventListener('keydown', autoSize);
    }
  }, []);

  return { textAreaRef, field, error, touched };
};
