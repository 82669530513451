import { useSelector } from 'react-redux';

import { getStreakCalendar } from '../../selectors';

export const useStreakCalendarService = () => {
  const streakCalendar = useSelector(getStreakCalendar);

  return {
    streakCalendar,
  };
};
