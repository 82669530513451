import { isInitialAuthDone } from 'features/Auth/selectors';
import { useSelector } from 'react-redux';
import LoadingScreen from './LoadingScreen';

const LoadingScreenService = () => {
  const isAuthDone = useSelector(isInitialAuthDone);

  return <LoadingScreen isVisible={!isAuthDone} />;
};

export default LoadingScreenService;
