import type { ITaskInfo } from './TaskInfo';
import {  useMemo } from 'react';
import { useLessonTasks } from 'features/TaskSelection/queries';
import WordWithDefinition from 'features/Task/components/TaskInfo/WordWithDefinition';

export const useTaskInfoService = ({ taskId, lessonId, currentPoints, totalPoints }: ITaskInfo) => {
  const progress = useMemo(() => (currentPoints / totalPoints) * 100, [currentPoints, totalPoints]);

  const tasks = useLessonTasks(lessonId);

  const task = useMemo(
    () =>
      (!tasks.isLoading &&
        tasks.data &&
        tasks.data.length &&
        tasks.data.find(({ id }) => id === taskId)) ||
      null,
    [tasks, taskId]
  );

  const { description = null, helperElement = null } = useMemo(() => {
    switch (task?.type) {
      case 'word_mentioning_game': {
        return {
          description: task.description,
          helperElement: <WordWithDefinition taskId={taskId} />,
        };
      }
      case 'association_game': {
        return { description: 'Find associations with the given word' };
      }
      case 'no_name_game': {
        return { description: `Describe the word's meaning without mentioning it` };
      }
      case 'quiz': {
        return {
          description: 'Choose the correct answer',
        };
      }
      case 'pronunciation_game': {
        return {
          description: 'Correctly pronounce given sentences',
        };
      }
      default: {
        return {};
      }
    }
  }, [task, taskId]);

  return { progress, description, helperElement };
};
