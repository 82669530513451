import { route } from 'react-router-typesafe-routes/dom';

import Profile, { profileRouter, profileRoutes } from './components/Profile';

import type { RouteObject } from 'react-router-dom';

export const userRoutes = {
  PROFILE: route('profile', void 0, profileRoutes),
};

export const userRouter: RouteObject[] = [
  {
    path: userRoutes.PROFILE.path,
    element: <Profile />,
    children: profileRouter,
  },
];
