import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

import actions from '../../actions';
import selectors from '../../selectors';

export const useProgressiveWebAppPromptModalService = () => {
  const { durations } = useTheme();

  const step = useSelector(selectors.getModalStep);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(actions.setModalStep(null));
  }, [dispatch]);

  const timeout = useMemo(() => Number(durations.regular.slice(0, -2)), [durations]);

  return { step, timeout, handleClose };
};
