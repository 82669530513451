import { route } from 'react-router-typesafe-routes/dom';

import ProfileInfo from '../ProfileInfo';

import ProfileEdit from './Edit';
import ProfileManageSubscription from './ManageSubscription';
import ProfileSendFeedback from './SendFeedback';

import type { RouteObject } from 'react-router-dom';

export const profileRoutes = {
  HOME: route(''),
  EDIT: route('edit'),
  SEND_FEEDBACK: route('send-feedback'),
  MANAGE_SUBSCRIPTION: route('manage-subscription'),
};

export const profileRouter: RouteObject[] = [
  {
    path: profileRoutes.EDIT.relativePath,
    element: <ProfileEdit />,
  },
  {
    path: profileRoutes.SEND_FEEDBACK.relativePath,
    element: <ProfileSendFeedback />,
  },
  {
    path: profileRoutes.MANAGE_SUBSCRIPTION.relativePath,
    element: <ProfileManageSubscription />,
  },
  {
    path: profileRoutes.HOME.relativePath,
    element: <ProfileInfo />,
    index: true,
  },
];
