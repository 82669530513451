import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { APP_ROOT_ID } from 'utils/constants';
import { useScrollLock } from 'utils/hooks';

import type { TModal } from './Modal';

export const useModalService = ({
  isOpen,
  showBackdropOverride = false,
  portalTarget = APP_ROOT_ID,
  scrollLock = true,
}: Pick<TModal, 'isOpen' | 'showBackdropOverride' | 'portalTarget' | 'scrollLock'>) => {
  const backdropRef = useRef<HTMLDivElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);

  const portalTargetNode = useMemo(
    () =>
      typeof portalTarget === 'string' ? document.querySelector(`#${portalTarget}`) : portalTarget,
    [portalTarget, isOpen]
  );

  const [lockScroll, unlockScroll, syncScroll] = useScrollLock();

  const showModal = useMemo(
    () => !!((isOpen || showBackdropOverride) && portalTargetNode),
    [isOpen, portalTargetNode, showBackdropOverride]
  );

  useEffect(() => {
    if (scrollLock) {
      if (showModal) {
        lockScroll();
      } else {
        unlockScroll();
      }
    } else {
      unlockScroll();
    }
  }, [showModal, scrollLock]);

  useEffect(
    () => () => {
      unlockScroll();
      syncScroll();
    },
    []
  );

  const handleModalContentClickPropagation = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.stopPropagation();
    },
    []
  );

  return { backdropRef, modalRef, showModal, portalTargetNode, handleModalContentClickPropagation };
};
