import { createAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { reviewSlice } from './slice';

const fetchReview = createAction(`${NAME}/fetchReview`);

const reviewMessageToSpeech = createAction<number>(`${NAME}/reviewMessageToSpeech`);

export default {
  ...reviewSlice.actions,
  fetchReview,
  reviewMessageToSpeech,
};
