import { createSelector } from '@reduxjs/toolkit';
import { TRootState } from 'store';
import { TGroup, TStatus } from 'features/Auth/types';

export const getAccessToken = (state: TRootState) => state.auth.accessToken;
export const getRefreshToken = (state: TRootState) => state.auth.refreshToken;
export const getUserId = (state: TRootState) => state.auth.userId;
export const getUserTier = (state: TRootState) => state.auth.userTier;
export const getUserTotalConversationCount = (state: TRootState) =>
  state.auth.userTotalConversationCount;
export const isInitialAuthDone = (state: TRootState) => state.auth.isInitialAuthDone;
export const isNotAuthorizedTier = (state: TRootState) => state.auth.isNotAuthorizedTier;

export const isAuthenticated = createSelector(
  getAccessToken,
  getRefreshToken,
  (accessToken, refreshToken) => !!accessToken && !!refreshToken
);

export const getEnabledUserExperimentByName = (
  state: TRootState,
  name: string,
  group: TGroup = 'test'
) => {
  const loading = !state.auth.isInitialAuthDone;
  const experiment = state.auth.userExperiments.find(
    ({ experiment_name }) => experiment_name === name
  );

  const isExperimentEnabled = (): boolean => {
    if (!experiment) return true;

    const { experiment_status, experiment_group } = experiment;

    switch (experiment_status as TStatus) {
      case 'active': {
        if (group === 'test' || group === 'test_b') {
          return experiment_group === 'test' || experiment_group === 'test_b';
        }

        return experiment_group === group;
      }
      case 'finished': {
        return true;
      }
      case 'pending':
      default: {
        return false;
      }
    }
  };

  return [isExperimentEnabled(), loading] as const;
};

export const getCurrentRoute = (state: TRootState) => state.auth.currentRoute;
