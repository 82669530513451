import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Tooltip from '../../components/Tooltip';

import type { FlatJourneyProps, JourneyProps } from '../types';

const Content = styled.div`
  ${({ theme: { colors, tp } }) => css`
    ${tp.p2}

    color: ${colors.neutral.white};
    max-width: 25.75rem;
  `}
`;

const arrowStyle = css`
  left: calc(25px - 1rem);
`;

const HandsFreeTooltip: React.FC<FlatJourneyProps> = props => {
  return (
    <Tooltip
      {...(props as JourneyProps)}
      anchor="bottom"
      classNames={{ arrow: arrowStyle }}
      proceedElement="Got it"
    >
      <Content>Speak to Leya when this indicator is active</Content>
    </Tooltip>
  );
};

export default HandsFreeTooltip;
