import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CONVERSATION_METHOD, CONVERSATION_SPEED, NAME } from './constants';

export interface IPressToTalk {
  isPressed: boolean;
  isButtonDisabled: boolean;
  conversationMethod: (typeof CONVERSATION_METHOD)[keyof typeof CONVERSATION_METHOD];
  conversationSpeed: CONVERSATION_SPEED;
}

const initialState: IPressToTalk = {
  conversationMethod: CONVERSATION_METHOD.PUSH_TO_TALK,
  conversationSpeed: CONVERSATION_SPEED.NORMAL,
  isPressed: false,
  isButtonDisabled: false,
};

export const pressToTalkSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setConversationMethod: (state, action: PayloadAction<IPressToTalk['conversationMethod']>) => {
      state.conversationMethod = action.payload;
    },
    setConversationSpeed: (state, action: PayloadAction<CONVERSATION_SPEED>) => {
      state.conversationSpeed = action.payload;
    },
    setPressed: (state, action: PayloadAction<boolean>) => {
      state.isPressed = action.payload;
    },
    setButtonDisabled: (state, action: PayloadAction<boolean>) => {
      state.isButtonDisabled = action.payload;
    },
    resetState: () => initialState,
  },
});

export const {
  setConversationMethod,
  setButtonDisabled,
  setConversationSpeed,
  setPressed,
  resetState,
} = pressToTalkSlice.actions;
