import { fork } from 'typed-redux-saga/macro';

import audioConfig from 'app/audio';
import analyticsConfig from 'app/Analytics';
import { watchAudioInput } from 'features/AudioInput/sagas';
import { watchAudioOutput } from 'features/AudioOutput/sagas';
import { watchLoginForm } from 'features/Login/sagas';
import { watchRegistrationForm } from 'features/Registration/sagas';
import { watchAuth } from 'features/Auth/sagas';
import { watchForgotPassword } from 'features/ForgotPassword/sagas';
import { watchConversationMethod } from 'features/ConversationControl/sagas';
import { watchChat } from 'features/Chat/sagas';
import { watchStreaks } from 'features/Streaks/sagas';
import { watchPronunciation } from 'features/Pronunciation/sagas';
import { watchUser } from 'features/User/sagas';

export default function* rootSaga() {
  yield* fork(audioConfig.saga);
  yield* fork(analyticsConfig.saga);
  yield* fork(watchAudioInput);
  yield* fork(watchAudioOutput);
  yield* fork(watchLoginForm);
  yield* fork(watchRegistrationForm);
  yield* fork(watchAuth);
  yield* fork(watchForgotPassword);
  yield* fork(watchConversationMethod);
  yield* fork(watchStreaks);
  yield* fork(watchChat);
  yield* fork(watchPronunciation);
  yield* fork(watchUser);
}
