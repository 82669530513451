import { atom, DefaultValue, selectorFamily } from 'recoil';

const scrollLockAtom = atom<Record<string, boolean>>({
  key: 'scrollLockState',
  default: {},
  effects: [
    ({ onSet }) => {
      const atLeastOneNeedsLock = (state: Record<string, boolean>) =>
        Object.values(state).some(lock => lock);

      onSet((value, previousValue) => {
        const shouldLock = atLeastOneNeedsLock(value);
        const wasLocked = atLeastOneNeedsLock(
          previousValue instanceof DefaultValue ? {} : previousValue
        );

        if (wasLocked !== shouldLock) {
          if (shouldLock) {
            document.body.dataset.scrollLock = 'true';
          } else {
            delete document.body.dataset.scrollLock;
          }
        }
      });
    },
  ],
});

export const scrollLockState = selectorFamily<boolean, string>({
  key: 'scrollLockSelector',
  get:
    id =>
    ({ get }) =>
      get(scrollLockAtom)?.[id] ?? false,
  set:
    id =>
    ({ set }, value) => {
      if (value instanceof DefaultValue) {
        console.log('somehow reset', id, value);
      }

      set(scrollLockAtom, state => ({
        ...state,
        [id]: value instanceof DefaultValue ? false : value,
      }));
    },
});
