import { createAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

export const initializePronunciationTask = createAction<string>(
  `${NAME}/initializePronunciationTask`
);
export const startPronunciation = createAction(`${NAME}/startPronunciation`);

export const startTextToSpeech = createAction(`${NAME}/startTextToSpeech`);

export const stopTextToSpeech = createAction(`${NAME}/stopTextToSpeech`);

