import React from 'react';
import { Formik } from 'formik';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import TextArea from 'molecules/TextArea';

import { ISuggestionForm, SUGGESTION_MAXIMUM_LENGTH, SuggestionSchema } from './form';
import ModalStep from './ModalStep';

interface IInputStep {
  title: string;
  description: string;
  onSubmit: (values: ISuggestionForm) => void | Promise<void>;
  placeholder?: string;
}

const InputStep: React.FC<IInputStep> = ({
  title,
  description,
  onSubmit,
  placeholder = 'I recommend to...',
}) => {
  return (
    <Formik
      initialValues={{ suggestion: '' } as ISuggestionForm}
      validationSchema={SuggestionSchema}
      onSubmit={onSubmit}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <ModalStep
          titleElement={
            <>
              <span>📨</span>
              {title}
            </>
          }
          description={description}
          buttonElement={
            <>
              Send
              <ArrowRightSvg />
            </>
          }
          buttonProps={{
            disabled: isSubmitting || !!Object.keys(errors).length,
            onClick: () => handleSubmit(),
            type: 'submit',
          }}
        >
          <TextArea
            name="suggestion"
            placeholder={placeholder}
            maxLength={SUGGESTION_MAXIMUM_LENGTH}
          />
        </ModalStep>
      )}
    </Formik>
  );
};

export default InputStep;
