import { isDev } from 'utils/constants';

export enum PATHS {
  START_CONVERSATION = '/v1/conversations/',
  TOPICS = '/v1/topic_situation/',
  LOGIN = '/v1/auth/login/',
  REGISTER = '/v1/auth/register/',
  REFRESH_TOKEN = '/v1/auth/refresh/',
  RESET_PASSWORD = '/v1/auth/reset-password/',
  WS_CONVERSATIONS = '/api/v1/conversations/',
  WS_TRANSCRIPTIONS = '/api/v1/transcriptions/',
  CONVERSATION_FEEDBACK = '/v1/conversations/',
  USER_INFO = '/v1/user/information/',
  USER_USAGE = '/v1/user/usage/',
  USER_PREFERENCES = '/v1/user/preferences/',
  USER_EXPERIMENTS = '/v1/user/experiments/',
  USER_STREAK = '/v1/user/streak/',
  USER_LEADERBOARD = '/v1/user/leaderboard/',
  USER_FEEDBACK = '/v1/user/feedback/',
  LESSONS = '/v1/lessons/',
  TASKS = '/v1/lessons/',
  WORDS = '/v1/lessons/',
  LESSON_EVALUATION = '/v1/lessons/',
  LESSON_TASKS_EVALUATION = '/v1/lessons/',
  GET_PRONUNCIATION_INFO = '/v1/pronunciation/',
  GET_PRONUNCIATION_EVALUATION = '/v1/pronunciation/evaluation/',
  TEXT_TO_SPEECH = '/v1/tts/',
  SUBSCRIPTION = '/v1/user/subscription/',
  CANCEL_SUBSCRIPTION = '/v1/payments/cancel-subscription/',
}

export enum STT_METHODS {
  PRONUNCIATION = '/api/v1/pronunciation/',
  TRANSCRIPTIONS = '/api/v1/transcriptions/',
}

export enum METHODS {
  POST = 'post',
  GET = 'get',
  PATCH = 'patch',
  PUT = 'put',
  DELETE = 'delete',
}

export const API_STATUS_CODES = {
  UNAUTHORIZED: 401,
};

export enum CUSTOM_STATUS_CODES {
  DEFAULT = 1000,
  NOT_AUTHENTICATED = 1001,
  NOT_AUTHORIZED_TIER = 1002,
}

export const API = isDev ? '/api' : `${process.env.VITE_BACKEND_HTTPS_DOMAIN}/api`;

export const CHAT_WSS_API =
  isDev && window.location.protocol !== 'https:'
    ? `ws://${window.location.host}/chat`
    : process.env.VITE_BACKEND_WSS_DOMAIN;

export const STT_WSS_API =
  isDev && window.location.protocol !== 'https:'
    ? `ws://${window.location.host}/stt`
    : process.env.VITE_STT_WSS_DOMAIN;
