import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Loader from 'atoms/Loader';

import { CLASSNAME_PREFIX } from './constants';

const Container = styled.div`
  ${({ theme: { durations } }) => css`
    display: grid;
    grid-area: 1 / 1 / 1 / 1;
    align-self: center;
    justify-self: stretch;
    justify-items: center;
    z-index: 1;

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }
  `}
`;

const StyledLoader = styled(Loader)`
  width: 100%;
  max-width: min(100%, 100rem);
  height: auto;
`;

const LoadingStep: React.FC = () => (
  <Container>
    <StyledLoader />
  </Container>
);

export default LoadingStep;
