import React, { forwardRef, PropsWithChildren } from 'react';

import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';
import { BASE_REM } from 'theme/emotion/constants';
import Layout from 'atoms/Layout';
import composeRawRemSize from './composeRawRemSize';
import { Classname } from 'utils/types';

export enum HEADER_VARIABLES {
  embeddedRem = '--embedded-rem',
}

const embeddedRemFromPx = (unitPx: number): string =>
  `calc(${unitPx / BASE_REM} * var(${HEADER_VARIABLES.embeddedRem}))`;

export const embeddedRem = (unitRem: number): string =>
  `calc(var(${HEADER_VARIABLES.embeddedRem}) * ${unitRem})`;

const StyledLayout = styled(Layout)`
  ${({ theme: { colors, depths, mq } }) => css`
    ${GLOBAL_STYLE_VARIABLES.margin}: 1fr;
    ${GLOBAL_STYLE_VARIABLES.container}: ${embeddedRemFromPx(mq.xl.container as number)};

    position: fixed;
    width: 100%;
    grid-template-rows: min-content;
    min-height: initial;
    ${HEADER_VARIABLES.embeddedRem}: ${BASE_REM}px;
    scroll-behavior: smooth;
    z-index: ${depths.menu};
    background-color: ${colors.neutral.grey};

    ${mq.xl.down} {
      ${HEADER_VARIABLES.embeddedRem}: ${composeRawRemSize({
        scaleMax: 100,
        scaleMin: 87.5,
        priorityWidth: 1280,
        precision: 4,
        widthMax: 1440,
        widthMin: 1024,
      })};
    }

    ${mq.lg.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: ${embeddedRemFromPx(mq.lg.margin as number)};
      ${GLOBAL_STYLE_VARIABLES.container}: 1fr;

      ${HEADER_VARIABLES.embeddedRem}: ${composeRawRemSize({
        scaleMax: 87.5,
        scaleMin: 75,
        priorityWidth: 960,
        precision: 4,
        widthMax: 1024,
        widthMin: 768,
      })};
    }

    ${mq.md.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: 1fr;
      ${GLOBAL_STYLE_VARIABLES.container}: ${embeddedRemFromPx(mq.md.container as number)};

      @media (orientation: landscape) {
        ${HEADER_VARIABLES.embeddedRem}: ${BASE_REM}px;
      }
      @media (orientation: portrait) {
        ${HEADER_VARIABLES.embeddedRem}: ${composeRawRemSize({
          scaleMax: 120,
          scaleMin: 100,
          priorityWidth: 576,
          widthMax: 768,
          widthMin: 480,
        })};
      }
    }

    ${mq.sm.down} {
      ${GLOBAL_STYLE_VARIABLES.margin}: ${embeddedRemFromPx(mq.sm.margin as number)};
      ${GLOBAL_STYLE_VARIABLES.container}: 1fr;

      ${HEADER_VARIABLES.embeddedRem}: ${BASE_REM}px;
    }

    ${mq.xs.down} {
      ${HEADER_VARIABLES.embeddedRem}: ${composeRawRemSize({
        scaleMax: 100,
        scaleMin: 80,
        priorityWidth: 320,
        widthMax: 375,
        widthMin: 240,
      })};
    }
  `}
`;

const Wrapper = forwardRef<HTMLDivElement, PropsWithChildren<Classname>>(
  ({ children, className }, ref) => (
    <StyledLayout className={className} ref={ref}>
      {children}
    </StyledLayout>
  )
);

export default Wrapper;
