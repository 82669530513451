import { useEffect, useMemo } from 'react';

import { useLessons } from 'features/Lessons/queries';
import { useLessonEvaluation, useTaskEvaluation } from 'features/Task/queries';
import { useLessonTasks } from 'features/TaskSelection/queries';
import { useScrollLock } from 'utils/hooks';

export const useTaskCompleteService = ({
  taskId,
  lessonId,
}: {
  taskId: string;
  lessonId: string;
}) => {
  const [lock, unlock] = useScrollLock();

  const lessonsData = useLessons();
  const lessonTasksData = useLessonTasks(lessonId);
  const lessonEvaluationData = useLessonEvaluation(lessonId);
  const taskEvaluationData = useTaskEvaluation(lessonId, taskId);

  useEffect(() => {
    lock();

    return () => {
      unlock();
    };
  }, []);

  const isLoading = useMemo(
    () =>
      lessonsData.isLoading ||
      lessonTasksData.isLoading ||
      lessonEvaluationData.isLoading ||
      taskEvaluationData.isLoading,
    [lessonsData, lessonTasksData, lessonEvaluationData, taskEvaluationData]
  );

  const lesson = useMemo(
    () =>
      (!lessonsData.isLoading &&
        lessonsData.data &&
        lessonsData.data.lessons.length &&
        lessonsData.data.lessons.find(({ id }) => lessonId === id)) ||
      null,
    [lessonsData, lessonId]
  );

  const task = useMemo(
    () =>
      (!lessonTasksData.isLoading &&
        lessonTasksData.data &&
        lessonTasksData.data.length &&
        lessonTasksData.data.find(({ id }) => taskId === id)) ||
      null,
    [lessonTasksData, taskId]
  );

  const lessonName = useMemo(() => lesson?.name ?? null, [lesson]);
  const taskName = useMemo(() => task?.name ?? null, [task]);

  const taskEvaluation = useMemo(
    () =>
      (!taskEvaluationData.isLoading && taskEvaluationData.data && taskEvaluationData.data) || null,
    [taskEvaluationData]
  );

  const lessonEvaluation = useMemo(
    () =>
      (!lessonEvaluationData.isLoading && lessonEvaluationData.data && lessonEvaluationData.data) ||
      null,
    [lessonEvaluationData]
  );

  const pointsEarned = useMemo(
    () => (!isLoading && (taskEvaluation?.points ?? lessonEvaluation?.points_earned ?? 0)) || 0,
    [isLoading, taskEvaluation, lessonEvaluation]
  );

  const isLessonFinished = useMemo(
    () =>
      !isLoading &&
      taskEvaluation?.points === taskEvaluation?.max_points &&
      lessonEvaluation?.points_earned === lessonEvaluation?.max_points,
    [isLoading, taskEvaluation, lessonEvaluation]
  );

  return {
    isLoading,
    isLessonFinished,
    lessonName,
    taskName,
    pointsEarned,
    taskEvaluationId: taskEvaluation?.id ?? '',
  };
};
