import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Highlight } from 'features/Onboarding';

import ScoreStars from '../ScoreStars';

import TextToSpeechButton from './TextToSpeechButton';

import type { HighlightProps } from 'features/Onboarding/components/Highlight/types';
import type { ReviewMessage, ScoreType } from '../../types';

export type TReviewableMessage = Omit<Extract<ReviewMessage, { entity: 'user' }>, 'entity'> & {
  handleTextToSpeech: () => void;
  highlightProps?: HighlightProps;
};

const Container = styled.div`
  display: grid;
  grid-auto-rows: min-content;
`;

const Message = styled.div<{ flattenBorder: boolean }>`
  ${({ theme: { colors, mq, tp }, flattenBorder }) => css`
    ${tp.p1};

    display: grid;
    row-gap: 1rem;
    border: 1px solid ${colors.primary.dark};
    padding: 2rem 2.5rem;
    border-radius: 1.5rem 1.5rem 1.5rem 0.25rem;

    ${flattenBorder
      ? css`
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        `
      : ''}

    ${mq.md.down} {
      padding: 2rem;
    }
  `}
`;

const Bar = styled.div<{ type: ScoreType }>`
  ${({ theme: { colors, mq, tp, utils }, type }) => css`
    ${tp.p1};

    display: grid;
    position: relative;
    grid-auto-flow: column;
    justify-self: flex-start;
    align-items: center;
    column-gap: 1.25rem;
    padding: 0.75rem 1rem;
    color: ${{ good: colors.secondary.main, basic: colors.warning.main, bad: colors.error.main }[
      type
    ]};
    background-color: ${utils.alphaHex(
      { good: colors.secondary.main, basic: colors.warning.main, bad: colors.error.main }[type],
      8
    )};
    border-radius: 2rem;

    ${mq.md.down} {
      column-gap: 1rem;
    }
  `}
`;

const Improvement = styled.div`
  ${({ theme: { colors, mq, utils, tp } }) => css`
    ${tp.p1};

    display: grid;
    grid-template-columns: 1fr min-content;
    row-gap: 1rem;
    background-color: ${utils.alphaHex(colors.primary.light, 6)};
    padding: 2rem 2.5rem;
    border-radius: 0 0 1.5rem 1.5rem;

    & > span {
      color: ${colors.primary.light};
    }

    ${mq.md.down} {
      padding: 2rem;
    }
  `}
`;

const ReviewableMessage: React.FC<TReviewableMessage> = ({
  text,
  score,
  improvement,
  textToSpeechState,
  handleTextToSpeech,
  highlightProps,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const scrolledRef = useRef(false);

  useEffect(() => {
    if (highlightProps && !scrolledRef.current) {
      containerRef.current?.scrollIntoView();
      scrolledRef.current = true;
    }
  }, [highlightProps]);

  return (
    <Container ref={containerRef}>
      <Message flattenBorder={!!improvement}>
        <span>{text}</span>
        <Bar type={score}>
          <ScoreStars type={score} />
          {{ good: 'Perfect!', basic: 'Nice job!', bad: `Let's improve!` }[score]}
        </Bar>
      </Message>
      {improvement ? (
        highlightProps ? (
          <Highlight<HTMLDivElement> {...(highlightProps as any)}>
            {({ ref }) => (
              <Improvement ref={ref}>
                <span>{improvement}</span>
                <TextToSpeechButton handleClick={handleTextToSpeech} state={textToSpeechState!} />
              </Improvement>
            )}
          </Highlight>
        ) : (
          <Improvement>
            <span>{improvement}</span>
            <TextToSpeechButton handleClick={handleTextToSpeech} state={textToSpeechState!} />
          </Improvement>
        )
      ) : null}
    </Container>
  );
};

export default ReviewableMessage;
