import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { filterProps } from 'utils/helpers';
import { FONT_FAMILIES } from 'theme/emotion/typography';
import CircularProgress from 'atoms/CircularProgress';
import { useMatchMedia } from 'theme/emotion';

interface ILessonCard {
  label: string;
  emoji: string;
  title: string;
  description: string;
  hasBeenStarted: boolean;
  progress: number;
  onClick: () => void;
}

enum LESSON_CARD_AREAS {
  chip = 'chip',
  title = 'title',
  progress = 'progress',
  description = 'description',
  button = 'button',
}

const Container = styled.div`
  ${({ theme: { colors, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [LESSON_CARD_AREAS.chip, LESSON_CARD_AREAS.progress],
      [LESSON_CARD_AREAS.title, LESSON_CARD_AREAS.progress],
      [LESSON_CARD_AREAS.description, LESSON_CARD_AREAS.description],
      [LESSON_CARD_AREAS.button, LESSON_CARD_AREAS.button],
    ])};
    grid-template-columns: 1fr min-content;
    grid-template-rows: repeat(3, min-content) 1fr;
    row-gap: 1rem;
    column-gap: 2rem;
    background-color: ${colors.neutral.white};
    border-radius: 3rem;
    padding: 4rem;

    ${mq.md.up} {
      min-height: 32.5rem;
    }

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        [LESSON_CARD_AREAS.progress, LESSON_CARD_AREAS.chip],
        [LESSON_CARD_AREAS.progress, LESSON_CARD_AREAS.title],
        [LESSON_CARD_AREAS.progress, LESSON_CARD_AREAS.description],
      ])};
      grid-template-columns: min-content 1fr;
      column-gap: 2.5rem;
      row-gap: 0.5rem;
      padding: 2.5rem;
      border-radius: 2.5rem;
      cursor: pointer;
    }
  `}
`;

const Chip = styled.span`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p3};

    grid-area: ${LESSON_CARD_AREAS.chip};
    display: grid;
    align-items: center;
    justify-self: flex-start;
    border: 1px solid ${colors.primary.dark};
    border-radius: 3rem;
    padding: 0 1.25rem;
    margin-bottom: 1rem;
    height: 3rem;

    ${mq.md.down} {
      padding: 0 1rem;
      height: 2.5rem;
      margin-bottom: 0.5rem;
    }
  `}
`;

const Progress = styled.div`
  ${({ theme: { mq } }) => css`
    grid-area: ${LESSON_CARD_AREAS.progress};
    display: grid;
    height: 8rem;
    width: 8rem;
    align-items: center;
    justify-items: center;

    ${mq.md.down} {
      align-self: center;
      height: 7rem;
      width: 7rem;
    }
  `}
`;

const StyledCircularProgress = styled(CircularProgress)`
  grid-row: 1 / 1;
  grid-column: 1 / 1;
`;

const Emoji = styled.span`
  ${({ theme: { tp } }) => css`
    ${tp.h1}

    grid-row: 1 / 1;
    grid-column: 1 / 1;
  `}
`;

const Title = styled.h2`
  ${({ theme: { tp } }) => css`
    ${tp.h2};

    grid-area: ${LESSON_CARD_AREAS.title};
    margin: 0;
  `}
`;

const Description = styled.h5`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p2};

    grid-area: ${LESSON_CARD_AREAS.description};
    margin: 0;
    color: ${utils.alphaHex(colors.primary.dark, 55)};
  `}
`;

const Button = styled('button', filterProps('hasBeenStarted'))<{ hasBeenStarted: boolean }>`
  ${({ theme: { colors, durations, mq }, hasBeenStarted }) => css`
    grid-area: ${LESSON_CARD_AREAS.button};
    display: grid;
    grid-auto-flow: column;
    justify-self: flex-start;
    column-gap: 1rem;
    align-self: flex-end;
    align-items: center;
    background-color: ${hasBeenStarted ? colors.primary.light : colors.neutral.white};
    border: 0.25rem solid ${colors.primary.light};
    border-radius: 4.5rem;
    text-align: center;
    text-decoration: initial;
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 1.75rem;
    padding: 0 5rem;
    height: 6rem;
    transition: border-width ${durations.regular};
    cursor: pointer;

    ${hasBeenStarted
      ? css`
          background-color: ${colors.primary.light};
          color: ${colors.neutral.white};

          & > svg {
            fill: ${colors.neutral.white};
            width: 2.5rem;
            height: 2.5rem;
          }
        `
      : css`
          background-color: ${colors.neutral.white};
          color: ${colors.primary.light};
        `}

    &:active {
      opacity: 0.9;
    }

    &:disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    ${mq.hover} {
      &:hover:not(:disabled) {
        border-width: 0.375rem;
      }
    }

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const LessonCard = forwardRef<HTMLDivElement, ILessonCard>(function LessonCardWithRef(
  { label, emoji, title, description, hasBeenStarted, progress, onClick },
  ref
) {
  const { mq } = useTheme();

  const isMobile = useMatchMedia([mq.md.down]);

  return (
    <Container onClick={isMobile ? onClick : void 0} ref={ref}>
      <Chip>{label}</Chip>
      <Progress>
        <StyledCircularProgress progress={progress} strokeWidth={6} />
        <Emoji>{emoji}</Emoji>
      </Progress>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button hasBeenStarted={hasBeenStarted} onClick={onClick}>
        {hasBeenStarted ? (
          <>
            Continue practice <ArrowRightSvg />
          </>
        ) : (
          'Start practice'
        )}
      </Button>
    </Container>
  );
});

export default LessonCard;
