import { useCallback, useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';

import { getUserId } from 'features/Auth/selectors';
import { COOKIE_KEY, getCookie, setCookie } from 'utils/cookies';

import {
  getRetriggerCount,
  isInitialized,
  isModalOpen,
  isPushNotificationsEnabled,
} from '../selectors';
import { setIsModalOpen, setRetriggerCount } from '../slice';
import { useInitializePushNotificationsService } from '../hooks';

export const usePushNotificationsModalService = () => {
  const initializePushNotificationsService = useInitializePushNotificationsService();

  useEffect(() => {
    initializePushNotificationsService();
  }, []);

  const userId = useSelector(getUserId);
  const initialized = useSelector(isInitialized);
  const pushNotificationsEnabled = useSelector(isPushNotificationsEnabled);
  const retriggerCount = useSelector(getRetriggerCount);
  const modalShouldBeOpen = useSelector(isModalOpen);
  const [showModal, setShowModal] = useState(false);
  const [promptLoading, setPromptLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const hasPushNotificationsBeenPrompted = getCookie(COOKIE_KEY.pushNotificationsHasBeenPrompted);
    const pushNotificationsPromptCount =
      Number(getCookie(COOKIE_KEY.pushNotificationsPromptCount)) || 0;

    if (
      pushNotificationsEnabled !== null &&
      (pushNotificationsPromptCount < 5 || !!retriggerCount)
    ) {
      const shouldShowModal =
        modalShouldBeOpen &&
        initialized &&
        !pushNotificationsEnabled &&
        (hasPushNotificationsBeenPrompted !== 'true' || !!retriggerCount);

      if (shouldShowModal) {
        (async () => {
          const permission = await OneSignal.getNotificationPermission();

          if (permission !== 'default') return;

          setShowModal(true);
          dispatch(setRetriggerCount(0));
          const time = new Date().getTime();
          const oneHourExpiry = new Date(time + 3600 * 100);

          setCookie(COOKIE_KEY.pushNotificationsHasBeenPrompted, 'true', {
            expires: oneHourExpiry,
          });

          if (!retriggerCount) {
            setCookie(
              COOKIE_KEY.pushNotificationsPromptCount,
              String(pushNotificationsPromptCount + 1)
            );
          }

          TagManager.dataLayer({
            dataLayer: {
              event: 'pushNotificationsModalPromptShown',
              userId,
            },
          });
        })();
      }
    }
  }, [modalShouldBeOpen, initialized, pushNotificationsEnabled, retriggerCount, userId]);

  const handleClose = useCallback(() => {
    dispatch(setIsModalOpen(false));
    setShowModal(false);
  }, []);

  const handlePrompt = useCallback(async () => {
    if (initialized && !pushNotificationsEnabled) {
      setPromptLoading(true);
      OneSignal.registerForPushNotifications();

      OneSignal.on('notificationPermissionChange', () => {
        setPromptLoading(false);
        handleClose();
      });
    }
  }, [initialized, pushNotificationsEnabled]);

  const benefits = [
    {
      emoji: '🎈',
      title: "Don't rush it!",
      description:
        "Learning a language takes time. With daily reminders from Leya, you'll keep steady and learn better",
    },
    {
      emoji: '🕰️',
      title: 'Remember, little by little becomes a lot!',
      description: 'Consistent learning with Leya beats any intense study session',
    },
  ];

  const intenseStreakData = [
    { scale: 1.6, text: 'Week 1' },
    { scale: 1, text: 'Week 2' },
    { scale: 0.7, text: 'Week 3' },
    { scale: 0, text: 'Week 4' },
    { scale: 0, text: 'Week 5' },
  ];

  const consistentStreakData = [
    { scale: 1, text: 'Week 1' },
    { scale: 1, text: 'Week 2' },
    { scale: 1, text: 'Week 3' },
    { scale: 1, text: 'Week 4' },
    { scale: 1, text: 'Week 5' },
  ];

  return {
    showModal,
    handlePrompt,
    handleClose,
    benefits,
    promptLoading,
    intenseStreakData,
    consistentStreakData,
  };
};
