import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import { ReactComponent as RefreshSvg } from 'assets/refresh.svg';
import { ReactComponent as MicSvg } from 'assets/mic.svg';
import Button from 'atoms/Button';

import { usePushToTalkButtonService } from './hooks';
import { filterProps } from 'utils/helpers';
import { Highlight } from 'features/Onboarding';

const rotateAnimation = keyframes`
  ${css`
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  `}
`;

const pulseAnimation = keyframes`
  ${css`
    from {
      width: calc(100% + 1.5rem);
      height: calc(100% + 1.5rem);
    }
    to {
      width: calc(100% + 3rem);
      height: calc(100% + 3rem);
    }
  `}
`;

const StyledButton = styled(Button, filterProps('animation'))<{
  animation: 'rotate' | 'pulse' | null;
}>`
  ${({ theme: { colors, durations, mq, utils }, animation }) => css`
    position: relative;
    justify-self: center;

    && {
      border-color: ${colors.primary.light};
    }

    ${animation === 'rotate'
      ? css`
          animation: ${rotateAnimation} ${durations.long} linear infinite;
        `
      : ''}

    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
      z-index: -1;
      content: '';
      width: 100%;
      height: 100%;
      background-color: ${utils.alphaHex(colors.primary.light, 25)};
      transition: all ${durations.long} ease-in-out;
    }

    ${animation === 'pulse'
      ? css`
          &::before {
            animation: ${pulseAnimation} ${Number(durations.long.slice(0, -2)) * 2}ms ease-in-out
              infinite alternate;
          }
        `
      : ''}

    && {
      padding: 2rem;
      margin-bottom: 3rem;
    }
  `}
`;

const PushToTalkButton = () => {
  const { state, handlePush, handleRelease } = usePushToTalkButtonService();

  return (
    <Highlight<HTMLButtonElement>
      journey="pronunciation"
      step="pushToTalk"
      proceedStep={null}
      directStep={null}
    >
      {({ ref }) => (
        <StyledButton
          ref={ref}
          onMouseDown={handlePush}
          onMouseUp={handleRelease}
          onTouchStart={handlePush}
          onTouchEnd={handleRelease}
          disabled={state === 'disabled' || state === 'transcribing' || state === null}
          animation={
            state === 'transcribing'
              ? 'rotate'
              : state === 'listening' || state === 'pending'
              ? 'pulse'
              : null
          }
        >
          {state === 'transcribing' ? <RefreshSvg /> : <MicSvg />}
        </StyledButton>
      )}
    </Highlight>
  );
};

export default PushToTalkButton;
