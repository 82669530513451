import React, { useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { layoutStyles } from 'atoms/Layout';
import { GLOBAL_STYLE_VARIABLES, useMatchMedia } from 'theme/emotion';

import Tooltip, { AnchorAlignments } from '../../components/Tooltip';

import type { InterpolationWithTheme } from 'utils/types';
import type { FlatJourneyProps, JourneyProps } from '../types';

const Content = styled.div`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p2}

    color: ${colors.neutral.white};
    max-width: 20rem;

    ${mq.lg.down} {
      ${mq.md.up} {
        max-width: 26rem;
      }
    }
  `}
`;

const positionStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.down} {
    ${layoutStyles};

    width: calc(100vw - var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth}));
    left: calc(((100% - 100vw + var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth})) / 2));
  }
`;

const containerStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.lg.up} {
    margin-top: 4.5rem;
  }

  ${mq.md.down} {
    justify-self: flex-start;
  }
`;

const arrowStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.lg.down} {
    ${mq.md.up} {
      right: 7rem;
    }
  }

  ${mq.md.down} {
    left: 4.75rem;
  }
`;

const LessonTooltip: React.FC<FlatJourneyProps> = props => {
  const { mq } = useTheme();

  const isTablet = useMatchMedia([mq.lg.down, mq.md.up]);
  const isMobile = useMatchMedia([mq.md.down]);

  const anchorAlignments = useMemo(() => {
    const breakpoint = isTablet ? 'tablet' : isMobile ? 'mobile' : 'desktop';

    const anchorAlignmentsMap: Record<typeof breakpoint, AnchorAlignments> = {
      desktop: { anchor: 'right', alignment: 'top' },
      tablet: { anchor: 'bottom', alignment: 'right' },
      mobile: { anchor: 'bottom', alignment: 'left' },
    };

    return anchorAlignmentsMap[breakpoint];
  }, [isTablet, isMobile]);

  return (
    <Tooltip
      {...(props as JourneyProps)}
      {...anchorAlignments}
      classNames={{
        position: positionStyle,
        container: containerStyle,
        arrow: arrowStyle,
      }}
    >
      <Content>Pick a lesson topic that you're interested in learning more about</Content>
    </Tooltip>
  );
};

export default LessonTooltip;
