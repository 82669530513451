import React, {useEffect, useMemo} from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { layoutStyles } from 'atoms/Layout';
import { TOOLTIP_VARIABLES } from 'features/Onboarding/components/Tooltip/Tooltip';
import { GLOBAL_STYLE_VARIABLES, useMatchMedia } from 'theme/emotion';

import Tooltip, { AnchorAlignments } from '../../components/Tooltip';

import ReviewableMessageImage from './ReviewableMessage.png';

import type { InterpolationWithTheme } from 'utils/types';
import type { FlatJourneyProps, JourneyProps } from '../types';

const Content = styled.div`
  ${({ theme: { colors, mq, tp } }) => css`
    display: grid;
    row-gap: 1rem;
    margin-bottom: -1rem;

    & > span {
      ${tp.p2}

      color: ${colors.neutral.white};
      padding-right: 3rem;
    }

    ${mq.md.up} {
      min-width: 21rem;
    }
  `}
`;

const outlineStyle: InterpolationWithTheme = ({ colors }) => css`
  background-color: ${colors.neutral.white};
  border-radius: 4rem;
`;

const positionStyle: InterpolationWithTheme = ({ mq }) => css`
  top: -1rem;

  ${mq.md.down} {
    top: -2rem;
  }
`;

const arrowStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.up} {
    top: calc(var(${TOOLTIP_VARIABLES.arrowSpacing}) + 0.4rem);
  }
`;

const progressStyle = css`
  display: none;
`;

const contentStyle = css`
  grid-column: 1 / -1;
`;

const buttonStyle = css`
  display: none;
`;

const ReviewContainer = styled.img`
  height: 12.5rem;
  width: 23.75rem;
`;

const TaskReviewTooltip: React.FC<FlatJourneyProps> = props => {
  const { mq } = useTheme();

  const isMobile = useMatchMedia([mq.md.down]);

  const anchorAlignments = useMemo(() => {
    const breakpoint = isMobile ? 'mobile' : 'desktop';

    const anchorAlignmentsMap: Record<typeof breakpoint, AnchorAlignments> = {
      desktop: { anchor: 'right', alignment: 'top' },
      mobile: { anchor: 'top', alignment: 'left' },
    };

    return anchorAlignmentsMap[breakpoint];
  }, [isMobile]);

  useEffect(() => {

  }, [])

  return (
    <Tooltip
      {...(props as JourneyProps)}
      {...anchorAlignments}
      classNames={{
        outline: outlineStyle,
        position: positionStyle,
        arrow: arrowStyle,
        progress: progressStyle,
        button: buttonStyle,
        content: contentStyle,
      }}
      proceedElement="Got it"
    >
      <Content>
        <span>Study task review with improvement suggestions</span>
        <ReviewContainer src={ReviewableMessageImage} />
      </Content>
    </Tooltip>
  );
};

export default TaskReviewTooltip;
