import React from 'react';
import { css, keyframes } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as LoaderSvg } from 'assets/loader.svg';

export enum LOADER_VARIABLES {
  animationDuration = '--animation-duration',
}

const makeDashOffsetAnimation = ({ stroke, delayStep }: { stroke: number; delayStep: number }) => {
  const animation = keyframes`
    ${css`
      0% {
        stroke-dashoffset: ${stroke}px;
      }

      49.9% {
        stroke-dashoffset: 0;
      }

      50% {
        stroke-dashoffset: ${stroke * 2}px;
      }

      85%,
      100% {
        stroke-dashoffset: ${stroke}px;
      }
    `}
  `;

  return css`
    stroke-dasharray: ${stroke}px;
    animation: ${animation} calc(var(${LOADER_VARIABLES.animationDuration}) * 2)
      cubic-bezier(0.47, 0, 0.745, 0.715)
      calc(var(${LOADER_VARIABLES.animationDuration}) / 10 * ${delayStep}) infinite backwards;
  `;
};

const StyledLoaderSvg = styled(LoaderSvg)`
  ${LOADER_VARIABLES.animationDuration}: 1200ms;

  .line-1 {
    ${makeDashOffsetAnimation({
      stroke: 284,
      delayStep: 1,
    })};
  }

  .line-2 {
    ${makeDashOffsetAnimation({
      stroke: 124.5,
      delayStep: 2,
    })};
  }

  .line-3 {
    ${makeDashOffsetAnimation({
      stroke: 110,
      delayStep: 3,
    })};
  }

  .line-4 {
    ${makeDashOffsetAnimation({
      stroke: 167,
      delayStep: 4,
    })};
  }

  .line-5 {
    ${makeDashOffsetAnimation({
      stroke: 174,
      delayStep: 5,
    })};
  }

  .line-6 {
    ${makeDashOffsetAnimation({
      stroke: 33,
      delayStep: 6,
    })};
  }
`;

const Loader: React.FC<JSX.IntrinsicElements['svg']> = props => <StyledLoaderSvg {...props} />;
export default Loader;
