import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as UserSvg } from 'assets/user.svg';
import { ReactComponent as EditSvg } from 'assets/edit.svg';
import { ReactComponent as AddUserSvg } from 'assets/addUser.svg';
import { ReactComponent as ChatBubblesSvg } from 'assets/chatBubbles.svg';
import { ReactComponent as QuestionMarkSvg } from 'assets/questionMark.svg';
import { ReactComponent as LogoutSvg } from 'assets/logout.svg';
import { ROUTES } from 'navigation/constants';

import { useProfileInfoService } from './hooks';
import ProfileLink from '../ProfileLink';
import Button from 'atoms/Button';
import { StreakIndicator, StreakCalendar } from 'features/Streaks';
import { Link } from 'react-router-dom';
import { useHandleSupport } from 'utils/hooks/useHandleSupport';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-template-rows: repeat(3, min-content) 1fr;

    ${mq.md.down} {
      row-gap: 2rem;
      margin-bottom: 2.5rem;
    }
  `}
`;

const areas = {
  avatar: 'avatar',
  username: 'username',
  email: 'email',
  button: 'button',
};

const InfoContainer = styled.div`
  ${({ theme: { mq, utils } }) => css`
    display: grid;
    padding-bottom: 1rem;

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        [areas.avatar],
        [areas.username],
        [areas.email],
        [areas.button],
      ])};
      row-gap: 1rem;
      padding-bottom: 2rem;
    }
  `}
`;

const EditProfileLink = styled(Link)`
  display: contents;
`;

const Avatar = styled.button`
  ${({ theme: { colors, durations, mq, utils } }) => css`
    display: grid;
    grid-area: ${areas.avatar};
    justify-self: center;
    align-items: center;
    justify-items: center;
    background-color: transparent;
    border-radius: 50%;
    border: 0.25rem solid ${utils.alphaHex(colors.primary.main, 12)};
    transition: all ${durations.regular};
    cursor: pointer;

    &:active {
      border-color: ${utils.alphaHex(colors.primary.main, 25)};
      background-color: ${utils.alphaHex(colors.primary.main, 1)};
    }

    ${mq.hover} {
      &:hover {
        border-color: ${utils.alphaHex(colors.primary.main, 25)};
      }
    }

    ${mq.md.down} {
      width: 7rem;
      height: 7rem;

      & > svg {
        width: 3rem;
        height: 3rem;
        fill: ${utils.alphaHex(colors.primary.main, 12)};
      }
    }
  `}
`;

const Username = styled.h2`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h2};

    grid-area: ${areas.username};
    margin: 0;
    color: ${colors.primary.main};
    text-align: center;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const Email = styled.span`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p2};

    grid-area: ${areas.email};
    color: ${utils.alphaHex(colors.primary.main, 55)};
    text-align: center;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const EditProfileButton = styled(Button)`
  grid-area: ${areas.button};
  justify-self: center;
  padding: 0.5rem 1rem;
  margin: -0.5rem 0;
  background-color: transparent;
`;

const StreaksContainer = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    background-color: ${colors.primary.main};
    border-radius: 1.5rem;
    padding: 1rem 1.5rem 2rem;
    row-gap: 1rem;

    ${mq.md.down} {
      padding: 1rem 3rem 2.5rem;
      border-radius: 2.5rem;
      row-gap: 1.5rem;
    }
  `}
`;

const StreakIndicatorContainer = styled.div`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h3};

    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    color: ${colors.neutral.white};
    padding-top: 1rem;

    ${mq.md.down} {
      padding-top: 1.25rem;
    }
  `}
`;

const StyledStreakIndicator = styled(StreakIndicator)`
  ${({ theme: { colors, mq } }) => css`
    background: ${colors.neutral.white};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${mq.md.up} {
      height: 4rem;
    }
  `}
`;

const StyledStreakCalendar = styled(StreakCalendar)`
  ${({ theme: { mq } }) => css`
    padding: 0;

    ${mq.md.down} {
    }
  `}
`;

const LinksContainer = styled.div`
  ${({ theme: { colors, mq, utils } }) => css`
    display: grid;
    align-content: flex-start;

    ${mq.md.down} {
      & > *:not(:first-of-type) {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: 0;
          left: 2rem;
          width: calc(100% - 4rem);
          height: 1px;
          background-color: ${utils.alphaHex(colors.primary.main, 5)};
        }
      }
    }
  `}
`;

const SignoutButton = styled(ProfileLink)`
  ${({ theme: { colors, mq } }) => css`
    ${mq.md.down} {
      grid-template-columns: min-content max-content;
      justify-content: center;
      align-self: flex-end;

      & > span {
        color: ${colors.error.main};
      }

      & > svg {
        fill: ${colors.error.main};
      }

      & > svg:nth-last-of-type(1) {
        display: none;
      }
    }
  `}
`;

const ProfileInfo: React.FC = () => {
  const handleSupport = useHandleSupport();
  const { username, email, nextBillingDate, handleLogout } = useProfileInfoService();

  return (
    <Container>
      <InfoContainer>
        <Avatar>
          <UserSvg />
        </Avatar>
        <Username>{username}</Username>
        <Email>{email}</Email>
      </InfoContainer>
      <StreaksContainer>
        <StreakIndicatorContainer>
          <StyledStreakIndicator />
          day streak
        </StreakIndicatorContainer>
        <StyledStreakCalendar />
      </StreaksContainer>
      <LinksContainer>
        <ProfileLink
          label="Manage subscription"
          Icon={AddUserSvg}
          url={ROUTES.PROFILE.MANAGE_SUBSCRIPTION.path}
        >
          {nextBillingDate}
        </ProfileLink>
        <ProfileLink
          label="Send feedback"
          Icon={ChatBubblesSvg}
          url={ROUTES.PROFILE.SEND_FEEDBACK.path}
        />
        <ProfileLink label="Support center" Icon={QuestionMarkSvg} onClick={handleSupport} />
      </LinksContainer>
      <SignoutButton label="Sign out" Icon={LogoutSvg} onClick={handleLogout} />
    </Container>
  );
};

export default ProfileInfo;
