import { useQuery } from 'react-query';
import { getBoolean, fetchAndActivate } from 'firebase/remote-config';

import { remoteConfig } from 'utils/firebase';

export interface IRemoteConfigValues {
  isCreateMetricEnabled: boolean;
  isVocabularyBuilderEnabled: boolean;
  isWordCloudEnabled: boolean;
  filterFromDaysAgoValue: number;
}

export const getRemoteConfigValues = async () => {
  await fetchAndActivate(remoteConfig);

  const isPushToTalkEnabled = getBoolean(remoteConfig, 'isPushToTalkEnabled');
  const isAppUpdateNotificationEnabled = getBoolean(remoteConfig, 'isAppUpdateNotificationEnabled');
  const isConversationSpeedEnabled = getBoolean(remoteConfig, 'isConversationSpeedEnabled');

  return {
    isPushToTalkEnabled,
    isAppUpdateNotificationEnabled,
    isConversationSpeedEnabled,
  };
};

export const useRemoteConfig = () =>
  useQuery({
    queryKey: ['useRemoteConfig'],
    queryFn: getRemoteConfigValues,
  });
