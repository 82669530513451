import axios, { AxiosError, AxiosResponse, ResponseType } from 'axios';

import { logout, setAuthData } from 'features/Auth/actions';
import refreshTokenRequest from 'features/Auth/refreshTokenRequest';
import { getAccessToken, getRefreshToken } from 'features/Auth/selectors';
import { IAuthResponse } from 'features/Auth/types';
import store from 'store';
import { ERROR_MESSAGES } from 'utils/constants';

import { PATHS, METHODS, API, CUSTOM_STATUS_CODES } from './constants';
import { setNotAuthorizedTier } from 'features/Auth/slice';

interface IAxiosErrorBody {
  detail?: string;
  custom_status_code?: CUSTOM_STATUS_CODES;
}

interface IQueryParameter {
  key: string;
  value: string;
}

interface ICallApiProps<T> {
  method: METHODS;
  mainPath: PATHS;
  data?: T;
  queryParams?: IQueryParameter[];
  extraPath?: string;
  authorized?: boolean;
  responseType?: string;
}

axios.interceptors.response.use(
  (value: AxiosResponse) => value,
  async (error: AxiosError<IAxiosErrorBody>) => {
    if (error?.response?.data?.custom_status_code === CUSTOM_STATUS_CODES.NOT_AUTHORIZED_TIER) {
      store.dispatch(setNotAuthorizedTier(true));

      return Promise.reject(error);
    }

    if (
      error?.config?.url === `${API}${PATHS.REFRESH_TOKEN}` ||
      error?.response?.data?.custom_status_code !== CUSTOM_STATUS_CODES.NOT_AUTHENTICATED
    ) {
      return Promise.reject(error);
    }

    try {
      const refreshToken = getRefreshToken(store.getState());

      if (!refreshToken) {
        throw new Error(ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      }

      const response: AxiosResponse<IAuthResponse> = await refreshTokenRequest({
        refreshToken,
      });

      const newAccessToken = response.data.access_token;
      const newRefreshToken = response.data.refresh_token;

      error.config!.headers.Authorization = `Bearer ${newAccessToken}`;

      store.dispatch(
        setAuthData({
          accessToken: newAccessToken,
          refreshToken: newRefreshToken,
        })
      );

      return await axios.request(error.config || {});
    } catch {
      store.dispatch(logout());
    }
  }
);

const callApi = <T, R extends any = any>({
  method,
  mainPath,
  data,
  queryParams = [],
  extraPath = '',
  authorized,
  responseType = 'json',
}: ICallApiProps<T>): Promise<AxiosResponse<R>> => {
  const accessToken = getAccessToken(store.getState());

  const params = queryParams
    .map(
      (parameter, index) =>
        `${
          parameter.value
            ? `${index === 0 ? '?' : '&'}${parameter.key}=${encodeURIComponent(parameter.value)}`
            : ''
        }`
    )
    .join('');

  return axios({
    method,
    url: `${API}${mainPath}${extraPath}${params?.length ? params : ''}`,
    data,
    headers: {
      'X-App-Version': process.env.npm_package_version,
      ...(authorized
        ? {
            Authorization: `Bearer ${accessToken}`,
          }
        : {}),
    },
    responseType: responseType as ResponseType,
  });
};

export default callApi;
