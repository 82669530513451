import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { TranscribedText } from './TranscribedText';

const Container = styled.div`
  ${({ theme: { colors, mq } }) => css`
    position: relative;
    display: grid;
    background-color: ${colors.neutral.white};
    border-radius: 1.5rem 1.5rem 0.25rem 1.5rem;
    padding: 2rem 2.5rem;
    max-width: 45rem;
    min-width: 30rem;
    z-index: -1;
    overflow: hidden;

    ${mq.md.down} {
      padding: 2rem;
      max-width: 70%;
      min-width: 50%;
    }
  `}
`;

interface IBotMessage {
  id: string;
  sequenceNumber: number;
}

const BotMessage: React.FC<IBotMessage> = ({ id, sequenceNumber }) => {
  return (
    <Container>
      <TranscribedText id={id} sequenceNumber={sequenceNumber} />
    </Container>
  );
};

export default BotMessage;
