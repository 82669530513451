import { useSelector } from 'react-redux';
import selectors from 'features/Chat/selectors';
import { useMemo } from 'react';
import onboardingSelectors from 'features/Onboarding/selectors';

export const usePushToTalkWorkspaceService = () => {
  const pushToTalkActive = !useSelector(selectors.matchPushToTalkState(null));
  const handsFreeActive = !useSelector(selectors.matchHandsFreeState(null));
  const currentOnboardingJourney = useSelector(onboardingSelectors.getCurrentJourney);
  const currentOnboardingStep = useSelector(onboardingSelectors.getCurrentStep);

  const shouldRender = useMemo(
    () => pushToTalkActive || handsFreeActive,
    [pushToTalkActive, handsFreeActive]
  );

  const isOnboarding = useMemo(
    () => currentOnboardingJourney === 'lessons' && currentOnboardingStep === 'pushToTalk',
    [currentOnboardingJourney, currentOnboardingStep]
  );

  return { shouldRender, pushToTalkActive, isOnboarding };
};
