import { ChangeEvent } from 'react';
import { TextField, styled } from '@mui/material';

import AuthInputsContainer from 'components/AuthInputsContainer';
import AuthLink from 'components/AuthLink';
import AuthPage from 'components/AuthPage';
import FormCard from 'components/FormCard';
import Button from 'components/Button';
import { ROUTES } from 'navigation/constants';

interface ILogin {
  email: string;
  password: string;
  error: string;
  isLoading: boolean;
  onEmailChange: (value: string) => void;
  onPasswordChange: (value: string) => void;
  onSubmit: React.FormEventHandler;
}

const StyledInput = styled(TextField)({
  marginBottom: 12,
});

const StyledForgotPasswordLink = styled(AuthLink)({
  marginBottom: 12,
});

const ErrorText = styled('div')(props => ({
  fontSize: 14,
  fontWeight: 400,
  color: props.theme.palette.error.light,
}));

const Login = ({
  onEmailChange,
  onPasswordChange,
  onSubmit,
  email,
  password,
  error,
  isLoading,
}: ILogin) => {
  const handleEmailChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onEmailChange(e.target.value);
  };

  const handlePasswordChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onPasswordChange(e.target.value);
  };

  return (
    <AuthPage>
      <FormCard onSubmit={onSubmit}>
        <AuthInputsContainer>
          <StyledInput
            onChange={handleEmailChange}
            value={email}
            placeholder="Email"
            type="email"
          />
          <StyledInput
            onChange={handlePasswordChange}
            value={password}
            placeholder="Password"
            type="password"
          />
          {!!error && <ErrorText>{error}</ErrorText>}
          <Button text="Sign in" isLoading={isLoading} />
          <StyledForgotPasswordLink
            mainText="Forgot your password?"
            linkText="Reset it here"
            link={ROUTES.FORGOT_PASSWORD.path}
          />
          <AuthLink mainText="No account?" linkText="Sign up" link={ROUTES.REGISTRATION.path} />
        </AuthInputsContainer>
      </FormCard>
    </AuthPage>
  );
};

export default Login;
