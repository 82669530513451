import { EventChannel, eventChannel, Task } from 'redux-saga';
import { captureException } from '@sentry/react';
import Statsig from 'statsig-js';
import { call, cancel, delay, fork, race, select, take } from 'typed-redux-saga/macro';

import { logout, setAuthData } from 'features/Auth/actions';

import { IDLE_TIMEOUT_SECONDS, MAINTAIN_ACTIVE_SESSION_INTERVAL_SECONDS } from '../constants';

import type { CaptureContext } from '@sentry/types';
import selectors from 'app/Analytics/selectors';
import actions from 'app/Analytics/actions';

function* watchIdleInactivity({
  activate,
  deactivate,
  sessionTimerEventChannel,
}: {
  activate: () => void;
  deactivate: () => void;
  sessionTimerEventChannel: SessionTimerEventChannel;
}) {
  while (true) {
    const { active, inactive } = yield* race({
      active: take('*'),
      inactive: delay(IDLE_TIMEOUT_SECONDS * 1000),
    });

    if (active) activate();
    else if (inactive) {
      deactivate();
      yield* race([
        call(function* reactivateOnAnyAction() {
          yield* take('*');
          activate();
        }),
        take(sessionTimerEventChannel),
      ]);
    }
  }
}

function* watchActiveSession() {
  let userIsActive = true;

  const activate = () => (userIsActive = true);
  const deactivate = () => (userIsActive = false);

  const sessionTimerEventChannel: SessionTimerEventChannel = eventChannel(emitter => {
    let timerSeconds = MAINTAIN_ACTIVE_SESSION_INTERVAL_SECONDS;

    const timerInterval = setInterval(() => {
      if (userIsActive) {
        timerSeconds -= 1;
        emitter('change');
      }

      if (timerSeconds <= 0) {
        emitter('active');
        timerSeconds = MAINTAIN_ACTIVE_SESSION_INTERVAL_SECONDS;
      }
    }, 1000);

    return () => {
      clearInterval(timerInterval);
    };
  });

  try {
    window.addEventListener('focus', activate);
    window.addEventListener('blur', deactivate);

    yield* fork(watchIdleInactivity, {
      activate,
      deactivate,
      sessionTimerEventChannel,
    });

    while (true) {
      const sessionEvent = yield* take(sessionTimerEventChannel);

      if (sessionEvent === 'active') {
        yield* call([Statsig, Statsig.logEvent], 'maintain_active_session');
      }
    }
  } catch (error) {
    yield* call(captureException, error, {
      level: 'warning',
      tags: {
        feature: 'Analytics',
      },
    } as CaptureContext);
  } finally {
    sessionTimerEventChannel.close();
    window.removeEventListener('focus', activate);
    window.removeEventListener('blur', deactivate);
  }
}

export function* initializeActiveSessionAnalytics() {
  let activeSessionTask: Task;

  try {
    while (true) {
      try {
        yield* take(setAuthData);

        let isStatsigInitialized = yield* select(selectors.isStatsigInitialized);
        while (!isStatsigInitialized) {
          const { payload } = yield* take(actions.setStatsigInitialized);
          isStatsigInitialized = payload;
        }

        activeSessionTask = yield* fork(watchActiveSession);

        yield* take(logout);
      } finally {
        // Executed on logout & or when activeSessionTask prematurely exits
        yield* cancel(activeSessionTask!);
      }
    }
  } finally {
    // Executed when calling parent saga terminates
    yield* cancel(activeSessionTask!);
  }
}

type SessionTimerEventChannel = EventChannel<'active' | 'change'>;
