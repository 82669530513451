import { all, takeEvery, put, select } from 'redux-saga/effects';

import { signIn } from 'features/Auth/actions';
import { TRootState } from 'store';

import { SUBMIT_LOGIN_FORM } from './actionTypes';
import { getEmail, getPassword } from './selectors';
import { setLoading, setError } from './slice';

function* loginFormSubmitSaga() {
  const state: TRootState = yield select();

  const email = getEmail(state);
  const password = getPassword(state);

  if (!email) {
    return;
  }

  if (!password) {
    return;
  }

  yield put(setLoading(true));
  yield put(setError(''));
  yield put(signIn({ email, password, flow: 'login' }));
}

export function* watchLoginForm() {
  yield all([takeEvery(SUBMIT_LOGIN_FORM, loginFormSubmitSaga)]);
}
