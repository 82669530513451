import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createPortal } from 'react-dom';

import { useAwaitNode } from 'utils/hooks/useAwaitElement';

import { useDeferredOnboardingCallback } from '../../hooks';
import { useGetJourneyStepId } from '../../journeys';
import { getCurrentJourney, getCurrentStep } from '../../selectors';

import type { Directions, AnchorAlignments, TooltipProps } from './types';
import { TOOLTIP_VARIABLES } from 'features/Onboarding/components/Tooltip/Tooltip';
import { resetState } from 'features/Onboarding/slice';
import { useGetCurrentSteps } from 'features/Onboarding/journeys/hooks';
import { APP_ROOT_ID } from 'utils/constants';

const useAnchorAlignmentProps = ({ anchor, alignment: baseAlignment }: AnchorAlignments) =>
  useMemo(() => {
    const opposites: Record<Directions, Directions> = {
      top: 'bottom',
      bottom: 'top',
      left: 'right',
      right: 'left',
    };

    const alignment =
      baseAlignment ||
      (
        {
          top: 'left',
          bottom: 'left',
          left: 'top',
          right: 'top',
        } as Record<Directions, Directions>
      )[anchor];

    const oppositeAnchor = opposites[anchor];
    const oppositeAlignment = opposites[alignment];

    const positionCardinals = {
      [anchor]: `var(${TOOLTIP_VARIABLES.inset})`,
      [oppositeAnchor]: 'initial',
      [alignment]: '0',
      [oppositeAlignment]: 'initial',
    } as Record<Directions, string>;

    const arrowCardinals = {
      [anchor]: 'initial',
      [oppositeAnchor]: `var(${TOOLTIP_VARIABLES.arrowDistance})`,
      [alignment]: `var(${TOOLTIP_VARIABLES.arrowSpacing})`,
      [oppositeAlignment]: 'initial',
    } as Record<Directions, string>;

    const horizontalTranslatesMap: Record<Directions, number> = {
      right: 100,
      left: -100,
      top: 0,
      bottom: 0,
    };

    const verticalTranslatesMap: Record<Directions, number> = {
      right: 0,
      left: 0,
      top: -100,
      bottom: 100,
    };

    const positionTransforms = {
      x: horizontalTranslatesMap[anchor],
      y: verticalTranslatesMap[anchor],
    };

    const arrowTransforms = {
      x: horizontalTranslatesMap[oppositeAnchor],
      y: verticalTranslatesMap[oppositeAnchor],
      rotate: ({ right: 45, left: -45, top: -135, bottom: 135 } as Record<Directions, number>)[
        anchor
      ],
    };

    return { positionCardinals, arrowCardinals, positionTransforms, arrowTransforms };
  }, [anchor, baseAlignment]);

export const useTooltipService = ({
  journey,
  step,
  anchor,
  alignment,
  onProceed = () => void 0,
  localTrigger,
  remoteTrigger = 'highlight',
}: TooltipProps) => {
  const currentJourney = useSelector(getCurrentJourney);
  const currentStep = useSelector(getCurrentStep);
  const dispatch = useDispatch();

  const getJourneyStepId = useGetJourneyStepId();

  const id = useMemo(() => getJourneyStepId(journey, step), [journey, step]);

  const isStepActive = useMemo(
    () => journey === currentJourney && step === currentStep,
    [journey, step, currentJourney, currentStep]
  );

  const [outlineHasLoaded, setOutlineHasLoaded] = useState(
    !!document.querySelector(`#${APP_ROOT_ID} #${id}`)
  );

  const element = useAwaitNode(`#${APP_ROOT_ID} #${id}`, isStepActive && outlineHasLoaded);

  useDeferredOnboardingCallback({ journey, step, localTrigger: 'tooltip' }, () => {
    setOutlineHasLoaded(true);
  });

  const tooltipPortal = useCallback(
    (Tooltip: ReactNode) => {
      if (isStepActive && element) {
        return createPortal(Tooltip, element);
      }

      return null;
    },
    [isStepActive, element]
  );

  const handleProceed = useDeferredOnboardingCallback(
    {
      journey,
      step,
      ...(localTrigger ? { localTrigger } : { remoteTrigger }),
    },
    onProceed
  );

  const handleCancel = useCallback(() => {
    dispatch(resetState());
  }, [dispatch]);

  const { current, total } = useGetCurrentSteps();

  return {
    tooltipPortal,
    handleProceed,
    handleCancel,
    current,
    total,
    ...useAnchorAlignmentProps({ anchor, alignment } as AnchorAlignments),
  };
};
