import { styled } from '@mui/material';
import FieldInput from 'components/FieldInput';

const EmailField = styled(FieldInput)({
  '& .MuiInputBase-root.Mui-disabled': {
    background: '#87878747',
    color: '#000000de',
  },
  '& .MuiInputBase-input.Mui-disabled': {
    WebkitTextFillColor: 'initial',
  },
});

export default EmailField;
