import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Modal from 'molecules/Modal';

import Button from 'atoms/Button';
import { FONT_FAMILIES } from 'theme/emotion';

import AnimatedCalendar from './AnimatedCalendar';
import ReminderButton from './ReminderButton';
import StreakSection from './StreakSection';
import { useStreaksModalService } from './hooks';

import type { InterpolationWithTheme } from 'utils/types';
import { LAYOUT_TEMPLATE_COLUMNS } from 'atoms/Layout';

const StyledModal = styled(Modal)`
  ${({ theme: { colors, depths, mq } }) => css`
    overflow-y: auto;
    z-index: ${depths.priority * 3 + depths.modal};

    ${mq.md.down} {
      background-color: ${colors.neutral.white};
    }
  `}
`;

const styledModalModal: InterpolationWithTheme = ({ colors, mq }) => css`
  position: relative;
  grid-auto-rows: initial;

  ${mq.md.up} {
    justify-self: center;
    align-self: center;
    border-radius: 4rem;
    background-color: ${colors.neutral.grey};
  }

  ${mq.md.down} {
    && {
      grid-column: ${LAYOUT_TEMPLATE_COLUMNS.contentStart} / ${LAYOUT_TEMPLATE_COLUMNS.contentEnd};
    }
  }
`;

const Container = styled.div`
  ${({ theme: { durations, mq } }) => css`
    display: grid;
    row-gap: 4rem;
    justify-items: center;
    padding: 4rem 4rem 8rem;
    width: 58rem;
    grid-row: 1/ 1;
    grid-column: 1/ 1;
    transition: opacity ${durations.regular};
    grid-template-rows: 1fr repeat(3, min-content) 1fr;

    &::before {
      content: '';
      height: 0;
    }

    ${mq.md.down} {
      padding: 2.5rem 0 8rem;
      width: 100%;
    }
  `}
`;

const Caption = styled.span`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p1};

    display: grid;
    color: ${utils.alphaHex(colors.primary.main, 55)};
    text-align: center;
    font-weight: ${FONT_FAMILIES.Poppins.lightBold};
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const ReminderSection = styled.div`
  display: grid;
  row-gap: 2rem;
  margin-top: 2rem;
`;

const ContinueButton = styled(Button)`
  align-self: flex-end;
`;

const StreaksModal: React.FC = () => {
  const { showModal, handleClose, streakCount, streakCountAnimationDelay } =
    useStreaksModalService();

  return (
    <StyledModal isOpen={showModal} onClick={handleClose} classNames={{ modal: styledModalModal }}>
      <Container>
        <StreakSection streakCount={streakCount} animationDelayMs={streakCountAnimationDelay} />
        <AnimatedCalendar />
        <ReminderSection>
          <Caption>Build streaks by practicing every day</Caption>
          <ReminderButton />
        </ReminderSection>
        <ContinueButton onClick={handleClose}>Continue</ContinueButton>
      </Container>
    </StyledModal>
  );
};

export default StreaksModal;
