import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import StreakIndicator from '../StreakIndicator';

import { animateZoomOutIn } from './animations';

import type { Classname, InterpolationWithTheme } from 'utils/types';

interface IStreakSection extends Classname {
  streakCount: number;
  animationDelayMs: number;
}

const Container = styled.div`
  display: grid;
  justify-items: center;
`;

const StyledStreakIndicator = styled(StreakIndicator)`
  ${({ theme: { colors, utils } }) => css`
    color: ${utils.alphaHex(colors.primary.dark, 25)};
    fill: ${utils.alphaHex(colors.primary.dark, 25)};

    & {
      height: 13.75rem;
      font-size: 4rem;
    }
  `}
`;

const styledStreakIndicatorIcon: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.up} {
    && {
      top: 34%;
    }
  }
`;

const styledStreakIndicatorStreak: (interpolationProps: {
  animationDelayMs: number;
}) => InterpolationWithTheme =
  ({ animationDelayMs }) =>
  ({ durations }) =>
    css`
      animation: ${animateZoomOutIn} ${durations.fade};
      animation-delay: ${animationDelayMs}ms;
    `;

const Label = styled.h2`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h1};
    display: grid;
    background: linear-gradient(
      90deg,
      ${colors.gradients.single.red} 30%,
      ${colors.gradients.single.orange} 70%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin: -1rem 0 0;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const StreakSection: React.FC<IStreakSection> = ({ className, streakCount, animationDelayMs }) => (
  <Container className={className}>
    <StyledStreakIndicator
      classNames={{
        icon: styledStreakIndicatorIcon,
        streak: styledStreakIndicatorStreak({ animationDelayMs }),
      }}
      overrideCount={streakCount}
    />
    <Label>day streak</Label>
  </Container>
);

export default StreakSection;
