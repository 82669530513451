import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as FireSvg } from 'assets/fire.svg';
import { ReactComponent as FireWideSvg } from 'assets/fireWide.svg';
import { ClassNames, FONT_FAMILIES } from 'theme/emotion';
import { filterProps } from 'utils/helpers';

import type { Classname, Classnames } from 'utils/types';
import { useStreakIndicatorService } from 'features/Streaks/components/StreakIndicator/hooks';

export interface IStreakIndicator extends Classname, Classnames<'streak' | 'icon'> {
  overrideCount?: number;
}

const FireIcon = styled(FireSvg)`
  //padding-bottom: 0.75em;
`;

const WideFireIcon = styled(FireWideSvg)`
  //padding-bottom: 0.85em;
`;

const Streak = styled.span`
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
`;

const Container = styled('div', filterProps('active', 'hide'))<{ active: boolean; hide: boolean }>`
  ${({ theme: { colors, durations, mq, tp, utils }, active, hide }) => css`
    ${tp.h3};

    position: relative;
    display: grid;
    align-items: center;
    justify-items: center;
    font-weight: ${FONT_FAMILIES.Poppins.bold};
    height: 5.5rem;
    color: ${utils.alphaHex(colors.neutral.white, 60)};
    fill: ${utils.alphaHex(colors.neutral.white, 12)};
    opacity: ${hide ? 0 : 1};
    transition: opacity ${durations.long} ease-in-out;

    & > * {
      grid-area: 1 / 1 / -1 / -1;
    }

    & > svg {
      transform: translateY(-51%);
      height: inherit;
      width: auto;
      box-sizing: content-box;
      margin-bottom: -100%;
    }

    & > svg > path {
      transition: fill 2000ms;
    }

    ${active
      ? css`
          background: linear-gradient(
            45deg,
            ${colors.gradients.single.red} 13%,
            ${colors.gradients.single.orange} 67%
          );
          & > svg > path {
            fill: ${'auto'};
          }
        `
      : css`
          & {
            background: ${`linear-gradient(40deg, currentColor 13.88%, currentColor 67.2%)`};
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            & > svg {
              fill: ${'inherit'};

              & > path {
                fill: ${'inherit'};
              }
            }
          }
        `}

    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    ${mq.md.down} {
      height: 4.75rem;
    }
  `}
`;

const StreakIndicator: React.FC<IStreakIndicator> = ({ className, classNames, overrideCount }) => {
  const { dailyStreakCompleted, streak, isLoading } = useStreakIndicatorService({ overrideCount });

  return (
    <Container active={!!dailyStreakCompleted} hide={isLoading} className={className}>
      <ClassNames classNames={classNames}>
        {c => (
          <>
            {streak >= 100 ? <WideFireIcon className={c.icon} /> : <FireIcon className={c.icon} />}
            <Streak className={c.streak}>{streak}</Streak>
          </>
        )}
      </ClassNames>
    </Container>
  );
};

export default StreakIndicator;
