import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Modal from 'molecules/Modal';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { ReactComponent as FireSvg } from 'assets/fire.svg';
import Button from 'atoms/Button';
import CancelButton from 'atoms/CancelButton';
import Loader from 'components/Loader';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';
import { filterProps } from 'utils/helpers';

import { usePushNotificationsModalService } from './hooks';

import type { InterpolationWithTheme } from 'utils/types';

const StyledModal = styled(Modal)`
  ${({ theme: { depths } }) => css`
    z-index: ${depths.priority + depths.priority + depths.modal};
  `}
`;

const StyledCancelButton = styled(CancelButton)`
  ${({ theme: { mq } }) => css`
    position: absolute;
    top: 6rem;
    right: 6rem;

    ${mq.md.up} {
      transform: translate(100%, -100%);
    }

    ${mq.md.down} {
      position: fixed;
      top: 3rem;
      right: 3rem;
    }
  `}
`;

const Container = styled.div`
  ${({ theme: { durations, mq } }) => css`
    display: grid;
    row-gap: 3rem;
    justify-items: center;
    padding: 4rem;
    width: 58rem;
    grid-row: 1/ 1;
    grid-column: 1/ 1;
    transition: opacity ${durations.regular};

    ${mq.md.down} {
      grid-auto-rows: min-content;
      align-self: flex-end;
      align-items: flex-end;
      justify-items: center;
      padding: 2.5rem 0;
      width: 100%;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h1};
    display: grid;
    row-gap: 1rem;
    color: ${colors.neutral.white};
    text-align: center;
    margin: 0;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const Caption = styled.h2`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.h3};
    display: grid;
    color: ${utils.alphaHex(colors.neutral.white, 60)};
    text-align: center;
    margin: 0 0 2rem;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const Card = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 3rem;
    border-radius: 3rem;
    background-color: ${colors.neutral.grey};
    padding: 5rem;

    ${mq.md.down} {
      row-gap: 2rem;
      padding: 3rem;
      margin: 0 1.5rem;
    }
  `}
`;

const Benefit = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-template-columns: min-content 1fr;
    row-gap: 1rem;
    column-gap: 1rem;
    justify-content: flex-start;

    ${mq.md.down} {
      row-gap: 0.75rem;

      & > svg {
        width: 2rem;
        height: 2rem;
      }
    }
  `}
`;

const BenefitEmoji = styled.span`
  ${({ theme: { tp } }) => css`
    ${tp.h2}

    grid-area: 1 / 1 / span 1 / span 1;
  `}
`;

const BenefitHeader = styled.h3`
  ${({ theme: { tp } }) => css`
    ${tp.h3}

    margin: 0;
    grid-area: 1 / 2 / span 1 / span 1;
  `}
`;

const BenefitDescription = styled.h4`
  ${({ theme: { tp } }) => css`
    ${tp.p2}

    margin: 0;
    grid-area: 2 / 2 / span 1 / span 1;
  `}
`;

const StyledButton = styled(Button)`
  ${({ theme: { colors, mq } }) => css`
    && circle {
      stroke: ${colors.neutral.white};
    }

    ${mq.hover} {
      border-color: ${colors.primary.dark};
    }
  `}
`;

const StreakContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 2rem;
  align-items: flex-end;
  margin-top: 2rem;
`;

const StreakTitle = styled.h4`
  ${({ theme: { tp } }) => css`
    ${tp.h2};

    margin: 0;
    grid-column: 1 / -1;
    text-align: center;
  `}
`;

const StreakItem = styled.span`
  ${({ theme: { tp } }) => css`
    ${tp.p2};

    display: grid;
    justify-items: center;
    row-gap: 2rem;
  `}
`;

const StreakIcon = styled('span', filterProps('scale'))<{ scale: number }>`
  ${({ scale }) => css`
    & > svg {
      height: calc(5rem * ${scale});
      width: auto;

      & > path {
        fill-rule: nonzero;
      }
    }
    line-height: 1;
  `}
`;

const modalStyle: InterpolationWithTheme = ({ colors, mq }) => css`
  position: relative;
  grid-auto-rows: initial;
  background-color: ${colors.primary.dark};

  ${mq.md.up} {
    justify-self: center;
    align-self: center;
    border-radius: 4rem;
  }

  ${mq.md.down} {
    padding: 3rem 0 7rem;
    max-height: 100vh;
    overflow-y: scroll;
  }
`;

const PushNotificationsModal: React.FC = () => {
  const {
    showModal,
    handlePrompt,
    handleClose,
    benefits,
    promptLoading,
    intenseStreakData,
    consistentStreakData,
  } = usePushNotificationsModalService();

  return (
    <StyledModal isOpen={showModal} onClick={handleClose} classNames={{ modal: modalStyle }}>
      <StyledCancelButton onClick={handleClose} />
      <Container>
        <Title>
          <span>📆</span>Mastering Daily Practice
        </Title>
        <Caption>Enable push notifications!</Caption>
        <Card>
          {benefits.map(benefit => (
            <Benefit key={JSON.stringify(benefit)}>
              <BenefitEmoji>{benefit.emoji}</BenefitEmoji>
              <BenefitHeader>{benefit.title}</BenefitHeader>
              <BenefitDescription>{benefit.description}</BenefitDescription>
            </Benefit>
          ))}
          <StreakContainer>
            <StreakTitle>Intensity</StreakTitle>
            {intenseStreakData.map(({ scale, text }) => (
              <StreakItem key={text}>
                <StreakIcon scale={scale}>
                  <FireSvg />
                </StreakIcon>
                {text}
              </StreakItem>
            ))}
          </StreakContainer>
          <StreakContainer>
            <StreakTitle>Consistency</StreakTitle>
            {consistentStreakData.map(({ scale, text }) => (
              <StreakItem key={text}>
                <StreakIcon scale={scale}>
                  <FireSvg />
                </StreakIcon>
                {text}
              </StreakItem>
            ))}
          </StreakContainer>
        </Card>

        <StyledButton onClick={handlePrompt}>
          {promptLoading ? (
            <Loader isVisible size={20} />
          ) : (
            <>
              Get Started
              <ArrowRightSvg />
            </>
          )}
        </StyledButton>
      </Container>
    </StyledModal>
  );
};

export default PushNotificationsModal;
