import { createSelector } from '@reduxjs/toolkit';
import * as rdd from 'react-device-detect';

import { getUser } from '../selectors';

import { MAX_PROMPTS, PROMPT_TIMEOUT } from './constants';

const getProgressiveWebApp = createSelector(getUser, ({ progressiveWebApp }) => progressiveWebApp);

const isInstallable = createSelector(getProgressiveWebApp, ({ installable }) => installable);

const getModalStep = createSelector(
  getProgressiveWebApp,
  progressiveWebApp => progressiveWebApp.modalStep
);

const getShownState = createSelector(getProgressiveWebApp, ({ timesShown, lastShown }) => ({
  timesShown,
  lastShown,
}));

const getTimesShown = createSelector(getShownState, ({ timesShown }) => timesShown ?? 0);

const canPrompt = createSelector(getShownState, ({ timesShown, lastShown }) => {
  const selectors = rdd.getSelectorsByUserAgent(window.navigator.userAgent);

  if (selectors?.isSamsungBrowser) return false;

  if (!lastShown || !timesShown) return true;
  return Date.now() - lastShown > PROMPT_TIMEOUT && timesShown < MAX_PROMPTS;
});

export default {
  isInstallable,
  getModalStep,
  getShownState,
  getTimesShown,
  canPrompt,
};
