import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import { useLessonsJourneyTrigger } from 'features/Onboarding';
import { ROUTES } from 'navigation/constants';

import { useLessons } from '../queries';
import { useMatchMedia } from 'theme/emotion';

export const useLessonsService = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { mq } = useTheme();
  const isTablet = useMatchMedia([mq.md.up, mq.lg.down]);

  const { data, isLoading } = useLessons();

  useLessonsJourneyTrigger(!isLoading && !!data?.lessons.length);

  const lessons = useMemo(
    () =>
      data?.lessons.map(
        ({
          id,
          order,
          name,
          description,
          emoji,
          tasks_total,
          tasks_completed,
          points_earned,
          max_points,
        }) => {
          const hasBeenStarted = !!tasks_completed && tasks_total !== tasks_completed;

          const onClick = () => {
            navigate(ROUTES.LESSON.buildPath({ id }));
          };

          return {
            id,
            label: `Lesson ${order}`,
            title: name,
            description,
            emoji,
            hasBeenStarted,
            progress: (points_earned / max_points) * 100,
            onClick,
          };
        }
      ) ?? [],
    [data, navigate]
  );

  const placeholders = useMemo(
    () =>
      [
        { title: 'Culture', emoji: '🖼️' },
        { title: 'Entertainment', emoji: '🎧' },
      ].map((placeholderData, index) => ({
        ...placeholderData,
        label: `Lesson ${lessons.length + index + 1}`,
      })),
    [lessons]
  );

  const tabs = useMemo(
    () =>
      [
        { label: 'Roleplays', to: ROUTES.ROLEPLAYS.path },
        { label: 'Lessons', to: ROUTES.HOME.path },
      ].map(tab => ({ ...tab, active: tab.to === pathname })),
    [pathname]
  );

  const suggestionsCardIndex = useMemo(() => (isTablet ? 1 : 2), [isTablet]);

  return {
    isLoading,
    tabs,
    lessonsCompleted: data?.lessons_completed ?? 0,
    lessonsTotal: data?.lessons_total ?? 0,
    pointsEarned: data?.points_earned ?? 0,
    lessons,
    placeholders,
    suggestionsCardIndex,
  };
};
