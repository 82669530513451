import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as LockSvg } from 'assets/lock.svg';

interface IPlaceholderCard {
  label: string;
  title: string;
}

enum PLACEHOLDER_CARD_AREAS {
  chip = 'chip',
  title = 'title',
  disclaimer = 'disclaimer',
}

const Container = styled.div`
  ${({ theme: { colors, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [PLACEHOLDER_CARD_AREAS.chip, PLACEHOLDER_CARD_AREAS.disclaimer],
      [PLACEHOLDER_CARD_AREAS.title, PLACEHOLDER_CARD_AREAS.title],
    ])};
    grid-template-columns: 1fr max-content;
    grid-template-rows: repeat(2, min-content);
    row-gap: 3rem;
    column-gap: 2rem;
    background-color: ${utils.alphaHex(colors.neutral.white, 60)};
    border-radius: 3rem;
    padding: 4rem;

    ${mq.md.down} {
      column-gap: 2.5rem;
      row-gap: 1.5rem;
      padding: 2.5rem;
      border-radius: 2.5rem;
    }
  `}
`;

const Chip = styled.span`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p3};

    grid-area: ${PLACEHOLDER_CARD_AREAS.chip};
    display: grid;
    align-items: center;
    justify-self: flex-start;
    border: 1px solid ${colors.primary.dark};
    border-radius: 3rem;
    padding: 0 1.25rem;
    height: 3rem;

    ${mq.md.down} {
      align-self: flex-end;
      padding: 0 1rem;
      height: 2.5rem;
    }
  `}
`;

const Title = styled.h2`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h2};

    grid-area: ${PLACEHOLDER_CARD_AREAS.title};
    margin: 0;
    opacity: 0.6;

    ${mq.md.down} {
    }
  `}
`;

const Disclaimer = styled.span`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p2}

    display: inline-flex;
    align-self: flex-start;
    column-gap: 0.5rem;
    color: ${colors.primary.light};

    & > svg {
      fill: ${colors.primary.light};
      width: 2rem;
      height: 2rem;
    }

    ${mq.md.down} {
      align-self: flex-start;

      & > svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  `}
`;

const PlaceholderCard: React.FC<IPlaceholderCard> = ({ label, title }) => (
  <Container>
    <Chip>{label}</Chip>
    <Disclaimer>
      <LockSvg />
      Coming soon
    </Disclaimer>
    <Title>{title}</Title>
  </Container>
);

export default PlaceholderCard;
