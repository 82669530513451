import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { InterpolationWithTheme } from 'utils/types';
import { layoutStyles } from 'atoms/Layout';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';

import Tooltip from '../../components/Tooltip';

import type { FlatJourneyProps, JourneyProps } from '../types';

const Content = styled.div`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p2}

    color: ${colors.neutral.white};

    ${mq.md.up} {
      min-width: 21rem;
    }
  `}
`;
const positionStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.down} {
    ${layoutStyles};

    width: calc(100vw - var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth}));
    left: calc(((100% - 100vw + var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth})) / 2));
    margin-top: -0.5rem;
  }
`;

const containerStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.down} {
    justify-self: flex-start;
    width: calc(50% + 25px);
  }
`;

const arrowStyle = css`
  right: calc(25px - 1rem);
`;

const PushToTalkTooltip: React.FC<FlatJourneyProps> = props => {
  return (
    <Tooltip
      {...(props as JourneyProps)}
      anchor="top"
      alignment="right"
      classNames={{ position: positionStyle, container: containerStyle, arrow: arrowStyle }}
      proceedElement="Got it"
    >
      <Content>Speak to Leya while holding the button pressed</Content>
    </Tooltip>
  );
};

export default PushToTalkTooltip;
