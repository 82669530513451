import callApi, { METHODS, PATHS } from 'api';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

export interface ITopic {
  id: string;
  type: string;
  name: string;
}

export const useRoleplays = () => {
  return useQuery({
    queryKey: ['useRoleplays'],
    queryFn: async () => {
      const response: AxiosResponse<ITopic[]> = await callApi({
        method: METHODS.GET,
        mainPath: PATHS.TOPICS,
        authorized: true,
        queryParams: [
          {
            key: 'limit',
            value: '21',
          },
        ],
      });

      return response.data;
    },
  });
};
