import { createSlice } from 'utils/redux';

import { NAME } from './constants';

import type { IProgressiveWebApp } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';

export const progressiveWebAppSlice = createSlice({
  name: NAME,
  initialState: {
    installable: false,
    modalStep: null,
    timesShown: 0,
    lastShown: null,
  } as IProgressiveWebApp,
  reducers: {
    setInstallable: (state, action: PayloadAction<IProgressiveWebApp['installable']>) => {
      state.installable = action.payload;
    },
    setModalStep: (state, action: PayloadAction<IProgressiveWebApp['modalStep']>) => {
      if (state.installable) {
        state.modalStep = action.payload;
      }
    },
    setTimesShown: (state, action: PayloadAction<IProgressiveWebApp['timesShown']>) => {
      state.timesShown = action.payload;
    },
    setLastShown: (state, action: PayloadAction<IProgressiveWebApp['lastShown']>) => {
      state.lastShown = action.payload;
    },
  },
});
