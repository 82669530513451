import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Loader from 'atoms/Loader';

import { CLASSNAME_PREFIX } from './constants';

const areas = {
  title: 'title',
  description: 'description',
  buttons: 'buttons',
};

const Container = styled.div`
  ${({ theme: { durations, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [areas.title],
      [areas.description],
      [areas.buttons],
    ])};
    row-gap: 4.5rem;
    justify-items: center;
    padding: 6rem;
    width: 68rem;
    min-height: 34rem;
    grid-area: 1 / 1 / 1 / 1;

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        ['.'],
        [areas.title],
        [areas.description],
        ['.'],
        [areas.buttons],
      ])};
      grid-template-rows: 1fr repeat(2, min-content) 1fr min-content;
      align-items: center;
      justify-items: center;
      padding: 2.5rem 0 0;
      width: 100%;
    }

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }
  `}
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LoadingStep: React.FC = () => {
  return (
    <Container>
      <StyledLoader />
    </Container>
  );
};

export default LoadingStep;
