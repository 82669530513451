import { styled } from '@mui/material';
import { PlayArrow } from '@mui/icons-material';

import { forwardRef } from 'react';

interface IPlayButton {
  onClick: () => void;
  isVisible: boolean;
}

const PlayButtonContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  alignSelf: 'center',
  fontSize: 14,
  fontWeight: 600,
  cursor: 'pointer',
});

const StyledPlayButton = styled('div')(props => ({
  backgroundColor: props.theme.palette.primary.main,
  height: 50,
  width: 50,
  border: 'none',
  outline: 'none',
  borderRadius: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
  marginRight: 12,
  '& svg': {
    fontSize: '3rem',
    fill: 'white',
  },
}));

const PlayButton = forwardRef<HTMLDivElement, IPlayButton>(function PlayButtonWithRef(
  { isVisible, onClick },
  ref
) {
  return isVisible ? (
    <PlayButtonContainer
      onClick={event => {
        event.preventDefault();

        onClick();
      }}
      ref={ref}
    >
      <StyledPlayButton>
        <PlayArrow />
      </StyledPlayButton>
      <div>Start the conversation</div>
    </PlayButtonContainer>
  ) : null;
});

export default PlayButton;
