import { useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/material';
import styled from '@emotion/styled';
import { useTypedParams } from 'react-router-typesafe-routes/dom';
import { Helmet } from 'react-helmet';
import { captureException, captureMessage } from '@sentry/react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';

import { ERROR_MESSAGES, isChrome, isSafari } from 'utils/constants';
import PageTitle from 'components/PageTitle';
import AnimatedDots from 'components/AnimatedDots';
import Loader from 'components/atoms/Loader';
import { PATHS } from 'api';
import hideable from 'utils/hideable';
import silence from 'assets/silence.mp3';
import { getAccessToken, getUserId, getUserTotalConversationCount } from 'features/Auth/selectors';
import {
  getConversationMethod,
  isPressToTalkEnabled as isPressToTalkEnabledSelector,
  getConversationSpeed,
} from 'features/ConversationControl/selectors';

import ChatBackButton from './ChatBackButton';
import { useStartConversation } from '../queries';
import { CONVERSATION_TEXTS } from '../constants';
import PlayButton from './PlayButton';
import MicrophoneProblem from './MicrophoneProblem';
import ChromeInstructions from './ChromeInstructions';
import SafariInstructions from './SafariInstructions';
import ChatHeader from './ChatHeader';
import {
  addConversationChunk,
  markConversationChunk,
  resetState,
  setChunkPlayedState,
  setConversationIsStarted,
  setPlayedChunkDuration,
} from '../slice';
import { isConversationStarted as isConversationStartedSelector, sttIsLoading } from '../selectors';
import ChatSettings from './ChatSettings';
import { ROUTES } from 'navigation/constants';
import IOSInstructions from 'features/Chat/components/IOSInstructions';
import ChatMessages from 'features/Chat/components/ChatMessages';
import { getBrowserNotSupported } from 'features/AudioInput/selectors';
import { CONVERSATION_METHOD, CONVERSATION_SPEED } from 'features/ConversationControl/constants';
import { setMicrophoneAccess } from 'features/AudioInput/slice';
import { CHAT_WSS_API } from 'api/constants';
import { startUncontrolledListening } from 'features/Chat/actions';
import audioInputActions from 'app/audio/Input/actions';
import { checkStreak } from 'features/Streaks/actions';
import selectors from 'features/Chat/selectors';
import { filterProps } from 'utils/helpers';
import { analyticsSelectors } from 'app/Analytics';

import type { OggVorbisDecoder } from '@wasm-audio-decoders/ogg-vorbis';

let decoder: OggVorbisDecoder;

type AudioData = {
  id: string;
  sequenceNumber: number;
  chunkNumber: number;
  data: string;
};

interface IDisplayMessages {
  [key: number]: {
    user?: string[];
    bot?: string[];
  };
}

interface ISendMessage {
  type: 'start' | 'end' | 'media';
  payload: {
    conversation_id: string;
    sequence_number?: string;
    chunk_number?: string;
    text?: string;
    audio?: string;
  };
}

interface IReceiveMessageData {
  type: 'started' | 'ended' | 'media' | 'mark';
  payload: {
    conversation_id: string;
    sequence_number?: string;
    chunk_number?: string;
    last_chunk_number?: string;
    text?: string;
    audio?: string;
    conversation_initiator?: 'human' | 'bot';
  };
}

const ChatContainer = styled('main', filterProps('extraPaddingBottom'))<{
  extraPaddingBottom: boolean;
}>`
  ${({ theme: { mq }, extraPaddingBottom }) => css`
    display: grid;
    width: 68rem;
    padding: 6rem 0 ${extraPaddingBottom ? 30 : 20}rem; //20
    justify-items: center;

    ${mq.md.down} {
      padding: 4rem 0 ${extraPaddingBottom ? 24 : 16}rem; //16
      width: 100%;
    }
  `}
`;

const MessageBox = styled.div`
  ${({ theme: { mq, tp } }) => css`
    ${tp.p1}

    max-width: 45rem;
    padding: 2rem 2.5rem;
    border-radius: 1.5rem;
    margin: 1rem 0;

    ${mq.md.down} {
      max-width: 75%;
      margin: 0.75rem 0;
      padding: 2rem;
    }
  `}
`;

const UserMessageBox = styled(MessageBox)`
  ${({ theme: { colors } }) => css`
    border: 1px solid ${colors.primary.dark};
    border-bottom-left-radius: 0.25rem;
  `}
`;

const UserMessageContainer = styled('div', filterProps('hide'))<{ hide?: boolean }>`
  ${({ hide = false }) => css`
    display: ${hide ? 'none' : 'flex'};
    justify-self: flex-start;
  `}
`;

const StyledLoader = styled(Loader, filterProps('visible'))<{ visible: boolean }>`
  ${({ theme: { durations }, visible }) => css`
    position: absolute;
    transition: opacity ${durations.regular};
    opacity: ${visible ? 1 : 0};
    pointer-events: none;
  `}
`;

const UserAnimationBox = styled(UserMessageBox)`
  ${({ theme: { mq } }) => css`
    padding: 2rem 4rem;

    ${mq.md.down} {
      padding: 2rem 3rem;
    }
  `}
`;

const Chat = () => {
  const webSocketRef = useRef<WebSocket>();
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const audioContextRef = useRef<AudioContext | null>();
  const [audioObject] = useState(new Audio());

  const { id: topicId } = useTypedParams(ROUTES.CHAT);
  const theme = useTheme();
  const dispatch = useDispatch();
  const userId = useSelector(getUserId);
  const accessToken = useSelector(getAccessToken);
  const userTotalConversationCount = useSelector(getUserTotalConversationCount);
  const isPressToTalkEnabled = useSelector(isPressToTalkEnabledSelector);
  const isButtonPressed = useSelector(selectors.matchPushToTalkState(['pending', 'listening']));
  const conversationSpeed = useSelector(getConversationSpeed);
  const isConversationStarted = useSelector(isConversationStartedSelector);

  const [listeningStartDate, setListeningStartDate] = useState<Date | null>(null);
  const [conversationId, setConversationId] = useState('');
  const [isPermissionGranted, setIsPermissionGranted] = useState(false);
  const [isPermissionRejected, setIsPermissionRejected] = useState(false);
  const [isConnectionLoading, setConnectionLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [serviceNotAllowedError, setServiceNotAllowedError] = useState(false);
  const [isConversationOver, setConversationIsOver] = useState(false);
  const [isWebsocketConnectionReady, setWebsocketConnectionReady] = useState(false);
  const [isMessageMarkDetected, setMessageMarkDetected] = useState(false);
  const [audioToPlay, setAudioToPlay] = useState<AudioData | undefined>();
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [isNormalizedAudioPlaying, setIsNormalizedAudioPlaying] = useState(isAudioPlaying);
  const [sequenceNumber, setSequenceNumber] = useState(1);
  const [displayMessages, setDisplayMessages] = useState<IDisplayMessages>({});
  const [audioFiles, setAudioFiles] = useState<AudioData[]>([]);
  const [isBotLoading, setIsBotLoading] = useState(false);
  const [initiator, setInitiator] = useState<null | 'human' | 'bot'>(null);
  const sttIsListening = useSelector(sttIsLoading);
  const conversationMethod = useSelector(getConversationMethod);
  const isSpeechToTextEnabled = conversationMethod === CONVERSATION_METHOD.PUSH_TO_TALK;
  const browserNotSupported = useSelector(getBrowserNotSupported);

  useEffect(() => {
    if (browserNotSupported) setServiceNotAllowedError(true);
  }, [browserNotSupported]);

  const askMicrophonePermission = async () => {
    try {
      if (navigator?.audioSession) {
        navigator.audioSession.type = 'play-and-record';
      }

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      setIsPermissionGranted(true);
      stream.getTracks().forEach(track => track.stop());
      dispatch(setMicrophoneAccess('granted'));
    } catch (err) {
      setIsPermissionRejected(isSafari() || isChrome());
      captureException(err);

      TagManager.dataLayer({
        dataLayer: {
          event: 'microphonePermissionDenied',
          userId,
          topicId,
        },
      });
    } finally {
      if (navigator?.audioSession) {
        navigator.audioSession.type = 'playback';
      }
    }
  };

  const sendWsMessage = (message: ISendMessage) => {
    if (!webSocketRef.current || webSocketRef.current.readyState === WebSocket.CLOSED) {
      return;
    }

    webSocketRef.current.send(JSON.stringify(message));
  };

  const closeWebsocket = () => {
    if (!!webSocketRef.current && webSocketRef.current.readyState !== WebSocket.CLOSED) {
      webSocketRef.current.close();
    }
  };

  const startListening = () => {
    if (!isSpeechToTextEnabled) {
      dispatch(startUncontrolledListening());
    }

    setListeningStartDate(new Date());
  };

  useEffect(() => {
    audioContextRef.current = new AudioContext();

    return () => {
      dispatch(checkStreak());
      audioContextRef.current?.close();
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsNormalizedAudioPlaying(isAudioPlaying);
    }, 250);

    return () => {
      clearTimeout(timeout);
    };
  }, [isAudioPlaying]);

  useEffect(() => {
    if (
      !isPressToTalkEnabled &&
      isWebsocketConnectionReady &&
      !Object.keys(displayMessages).length &&
      !audioToPlay &&
      !audioFiles.length &&
      !isNormalizedAudioPlaying &&
      !sttIsListening &&
      initiator === 'human'
    ) {
      startListening();
    }
  }, [
    isPressToTalkEnabled,
    isWebsocketConnectionReady,
    displayMessages,
    audioToPlay,
    audioFiles,
    isNormalizedAudioPlaying,
    sttIsListening,
    initiator,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsBotLoading(
        !isMessageMarkDetected &&
          !sttIsListening &&
          !!initiator &&
          (initiator === 'bot' || !!Object.keys(displayMessages).length)
      );
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [sttIsListening, isMessageMarkDetected, displayMessages, initiator]);

  useEffect(() => {
    if ((isPressToTalkEnabled || isSpeechToTextEnabled) && isAudioPlaying) {
      const audioContext = audioContextRef.current;
      if (isButtonPressed) {
        if (audioContext?.state === 'running') {
          audioContextRef.current?.suspend();

          setAudioToPlay(void 0);
          setAudioFiles([]);
          setIsAudioPlaying(false);
        }
      }
    }
  }, [isPressToTalkEnabled, isSpeechToTextEnabled, isButtonPressed, isAudioPlaying]);

  const nextPlayableTimeRef = useRef(new Date().getTime());
  const processedAudioRef = useRef<string[]>([]);
  useEffect(() => {
    if (audioToPlay && !isAudioPlaying) {
      (async () => {
        const context = audioContextRef.current;

        const { data, ...metadata } = audioToPlay;

        const checkSum = JSON.stringify(metadata);

        if (context && !processedAudioRef.current.includes(checkSum)) {
          processedAudioRef.current.push(checkSum);
          const source = context.createBufferSource();

          const binaryData = window.atob(data);
          const buffer = new ArrayBuffer(binaryData.length);
          let view = new Uint8Array(buffer);

          for (let i = 0; i < binaryData.length; i++) {
            view[i] = binaryData.charCodeAt(i);
          }

          try {
            source.buffer = await context.decodeAudioData(buffer);
          } catch {
            captureMessage('WebAudioAPI decoding failed');

            if (!decoder) {
              const WasmDecoders = await import('@wasm-audio-decoders/ogg-vorbis');
              decoder = new WasmDecoders.OggVorbisDecoder();
            }

            await decoder.ready;
            const { errors, sampleRate, channelData } = await decoder.decodeFile(view);
            await decoder.reset();

            if (!errors.length && channelData.length === 1) {
              const audioBuffer = context.createBuffer(1, channelData[0].length, sampleRate);

              const audioBufferChannelData = audioBuffer.getChannelData(0);
              audioBufferChannelData.set(channelData[0]);

              source.buffer = audioBuffer;
            }
          }

          source.connect(context.destination);
          source.addEventListener('ended', () => {
            setAudioToPlay(undefined);
            setIsAudioPlaying(false);
            dispatch(setChunkPlayedState({ ...metadata, playedState: 'finished' }));
            source.disconnect();
          });

          context.addEventListener('statechange', () => {
            if (context.state === 'suspended') {
              source.stop();
              source.disconnect();
              setAudioToPlay(undefined);
              setIsAudioPlaying(false);
            }
          });

          if (context.state === 'suspended') {
            await context.resume();
          }
          if (!isSpeechToTextEnabled) {
            if (navigator?.audioSession) {
              navigator.audioSession.type = 'playback';
            }
          }

          const currentTime = new Date().getTime();
          const durationMs = (source.buffer ? source.buffer.duration : 0) * 1000;

          if (currentTime < nextPlayableTimeRef.current) {
            const waitTimeMs = Math.round(nextPlayableTimeRef.current - currentTime);

            setTimeout(() => {
              nextPlayableTimeRef.current = new Date().getTime() + durationMs;

              source.start(0);

              setIsAudioPlaying(true);
              dispatch(setPlayedChunkDuration({ ...metadata, duration: durationMs }));
            }, waitTimeMs);
          } else {
            nextPlayableTimeRef.current = new Date().getTime() + durationMs;

            source.start(0);
            setIsAudioPlaying(true);
            dispatch(setPlayedChunkDuration({ ...metadata, duration: durationMs }));
          }
        }
      })();
    }

    if (!audioToPlay && !!audioFiles.length) {
      setAudioToPlay(audioFiles[0]);
      setAudioFiles(prevAudioFiles => prevAudioFiles.slice(1));
    }

    if (
      !audioToPlay &&
      !audioFiles.length &&
      !isAudioPlaying &&
      !isConversationOver &&
      !isPressToTalkEnabled &&
      isMessageMarkDetected
    ) {
      startListening();
    }
  }, [
    audioToPlay,
    audioFiles,
    isAudioPlaying,
    isMessageMarkDetected,
    isConversationOver,
    isPressToTalkEnabled,
    isSpeechToTextEnabled,
  ]);

  const handleConversationEnded = () => {
    setConversationIsOver(true);

    TagManager.dataLayer({
      dataLayer: {
        event: 'conversationEnded',
        userId,
        topicId,
        conversationId,
      },
    });

    setDisplayMessages(prevMessages => {
      const key = Math.max(Number(Object.keys(prevMessages)));
      const prevSequenceMessages = prevMessages[key];

      return {
        ...prevMessages,
        [key]: {
          ...prevSequenceMessages,
          bot: [
            ...(prevSequenceMessages?.bot ? prevSequenceMessages.bot : []),
            ...(!prevSequenceMessages?.bot?.includes(CONVERSATION_TEXTS.HAS_ENDED)
              ? [CONVERSATION_TEXTS.HAS_ENDED]
              : []),
          ],
        },
      };
    });
  };

  const onStartConversationError = () => {
    setError(true);
    setConnectionLoading(false);
  };

  const onStartConversationSuccess = (id: string) => {
    webSocketRef.current = new WebSocket(
      `${CHAT_WSS_API}${PATHS.WS_CONVERSATIONS}${id}/?auth_token=${accessToken}`
    );

    setConversationId(id);

    TagManager.dataLayer({
      dataLayer: {
        event: 'conversationStarted',
        conversationId: id,
        category: 'experiment',
        experimentValue: theme.palette.primary.main,
        topicId,
        userId,
        totalConversationCount: userTotalConversationCount,
        conversationMethod,
      },
    });

    if (!webSocketRef.current) {
      return;
    }

    webSocketRef.current.onopen = () => {
      sendWsMessage({ type: 'start', payload: { conversation_id: id } });
    };

    webSocketRef.current.onclose = () => {
      dispatch(audioInputActions.stopListening());

      handleConversationEnded();
    };

    webSocketRef.current.onmessage = (event: MessageEvent<string>) => {
      const dataObject = JSON.parse(event.data) as IReceiveMessageData;

      if (dataObject.type === 'ended') {
        dispatch(audioInputActions.stopListening());

        handleConversationEnded();

        return;
      }

      if (dataObject.type === 'started') {
        setConnectionLoading(false);
        setWebsocketConnectionReady(true);
        setInitiator(dataObject?.payload.conversation_initiator ?? null);

        return;
      }

      if (dataObject.type === 'mark') {
        dispatch(
          markConversationChunk({
            id: dataObject.payload.conversation_id,
            sequenceNumber: Number(dataObject.payload.sequence_number),
            chunkNumber: Number(dataObject.payload.last_chunk_number),
            entity: 'bot',
          })
        );

        setMessageMarkDetected(true);

        return;
      }

      if (dataObject.type === 'media') {
        dispatch(
          addConversationChunk({
            id: dataObject.payload.conversation_id,
            sequenceNumber: Number(dataObject.payload.sequence_number),
            chunkNumber: Number(dataObject.payload.chunk_number),
            text: String(dataObject.payload.text),
            entity: 'bot',
            isLast: false,
          })
        );

        if (dataObject.payload.audio) {
          setAudioFiles(prevFiles => [
            ...prevFiles,
            {
              id: dataObject.payload.conversation_id,
              sequenceNumber: Number(dataObject.payload.sequence_number),
              chunkNumber: Number(dataObject.payload.chunk_number),
              data: dataObject.payload.audio!,
            },
          ]);
        }

        setDisplayMessages(prevMessages => {
          const previousKeys = Object.keys(prevMessages);
          const key = previousKeys.length
            ? Math.max(...previousKeys?.map(messageKey => Number(messageKey)))
            : 0;
          const prevSequenceMessages = prevMessages[key];

          return {
            ...prevMessages,
            [key]: {
              ...prevSequenceMessages,
              bot: [
                ...(prevSequenceMessages?.bot ? prevSequenceMessages.bot : []),
                dataObject.payload.text || '',
              ],
            },
          };
        });
      }
    };
  };

  const sendMessage = (message: string) => {
    if (!conversationId) {
      return;
    }

    if (listeningStartDate) {
      const now = new Date();
      const listeningDurationSeconds = (now.getTime() - listeningStartDate.getTime()) / 1000;

      const wordCount = message.split(' ').length;

      TagManager.dataLayer({
        dataLayer: {
          event: 'conversationInput',
          durationSeconds: listeningDurationSeconds,
          transcriptWordCount: wordCount,
          userId,
          conversationId,
          topicId,
        },
      });

      setListeningStartDate(null);
    }

    sendWsMessage({
      type: 'media',
      payload: {
        conversation_id: conversationId,
        sequence_number: `${sequenceNumber}`,
        chunk_number: '1',
        text: message,
      },
    });

    const previousSequenceNumberKey = sequenceNumber - 1;
    const previousMessageSet = displayMessages[previousSequenceNumberKey];

    if (sequenceNumber > 1 && !previousMessageSet?.bot) {
      setDisplayMessages(previousMessages => ({
        ...previousMessages,
        [previousSequenceNumberKey]: {
          ...previousMessageSet,
          user: [...(previousMessageSet?.user ? previousMessageSet.user : []), message],
        },
      }));
    } else {
      setDisplayMessages(prevMessages => ({
        ...prevMessages,
        [sequenceNumber]: { user: [message] },
      }));

      setSequenceNumber(prevNumber => prevNumber + 1);
    }

    setMessageMarkDetected(false);

    if (audioContextRef.current?.state === 'suspended') {
      audioContextRef.current?.resume();
    }
  };

  const { mutate: startConversation } = useStartConversation({
    onSuccess: onStartConversationSuccess,
    onError: onStartConversationError,
  });

  useEffect(() => {
    if (
      !isConversationOver &&
      !isPressToTalkEnabled &&
      isMessageMarkDetected &&
      !audioFiles.length &&
      !audioToPlay &&
      isWebsocketConnectionReady
    ) {
      startListening();
    }
  }, [
    isWebsocketConnectionReady,
    isMessageMarkDetected,
    audioFiles,
    audioToPlay,
    isPressToTalkEnabled,
  ]);

  const markTriggerRef = useRef<boolean | null>(null);
  useEffect(() => {
    if (
      !isConversationOver &&
      !isPressToTalkEnabled &&
      isMessageMarkDetected &&
      !audioFiles.length &&
      !audioToPlay &&
      isWebsocketConnectionReady &&
      !!markTriggerRef.current
    ) {
      markTriggerRef.current = true;
      startListening();
    }
  }, [
    isWebsocketConnectionReady,
    isMessageMarkDetected,
    audioFiles,
    audioToPlay,
    isPressToTalkEnabled,
  ]);

  useEffect(() => {
    askMicrophonePermission();

    return () => {
      dispatch(resetState());

      if (!conversationId) {
        closeWebsocket();
        return;
      }

      TagManager.dataLayer({
        dataLayer: {
          event: 'conversationEnded',
          userId,
          topicId,
          conversationId,
        },
      });

      sendWsMessage({
        type: 'end',
        payload: { conversation_id: conversationId },
      });

      dispatch(audioInputActions.stopListening());
      closeWebsocket();
    };
  }, []);

  const handlePlayClick = () => {
    if (topicId) {
      startConversation({ topicSituationId: topicId });
    }

    audioObject.src = silence;
    audioObject.play();

    dispatch(setConversationIsStarted(true));
    setConnectionLoading(true);
  };

  const [fullConversationTracked, setFullConversationTracked] = useState(false);

  useEffect(() => {
    if (!fullConversationTracked) {
      const totalMessages = Object.values(displayMessages).reduce(
        (totalDisplayMessages, messages) => {
          return totalDisplayMessages + Object.keys(messages).length;
        },
        0
      );

      if (totalMessages >= 7) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'hadFullConversation',
            userId,
          },
        });
        setFullConversationTracked(true);
      }
    }
  }, [displayMessages, fullConversationTracked, userId]);

  return (
    <>
      <Helmet>
        <meta name="googlebot" content="notranslate" />
      </Helmet>
      <ChatHeader backText="Roleplays selection" backTo={ROUTES.ROLEPLAYS.path} />
      <ChatContainer extraPaddingBottom={isPressToTalkEnabled}>
        <PlayButton
          isVisible={!isConversationStarted && isPermissionGranted && !serviceNotAllowedError}
          onClick={handlePlayClick}
        />
        <ChatSettings
          isVisible={!isConversationStarted && isPermissionGranted && !serviceNotAllowedError}
        />
        <MicrophoneProblem isVisible={!isPermissionGranted && !isPermissionRejected} />
        <ChromeInstructions isVisible={isPermissionRejected && isChrome()} />
        <SafariInstructions isVisible={isPermissionRejected && isSafari()} />
        <IOSInstructions
          isVisible={isPermissionGranted && !isPermissionRejected && serviceNotAllowedError}
        />
        <StyledLoader
          visible={isConnectionLoading || (isConversationStarted && initiator === null)}
        />
        {isConversationStarted && isPermissionGranted && !serviceNotAllowedError ? (
          <>
            {isError && <PageTitle>{ERROR_MESSAGES.SOMETHING_WENT_WRONG}</PageTitle>}
            <ChatMessages
              id={conversationId}
              currentSequenceNumber={sequenceNumber}
              sendMessage={sendMessage}
              isBotLoading={isBotLoading && !isConversationOver}
              evaluationIsReady={false}
              openEvaluation={() => {}}
            />
            {sttIsListening && (
              <UserMessageContainer>
                <UserAnimationBox>
                  <AnimatedDots />
                </UserAnimationBox>
              </UserMessageContainer>
            )}
            <ChatBackButton
              isVisible={isConversationOver}
              feedbackId="finished-conversation"
              conversationId={conversationId}
            >
              Start a new conversation choosing the topic
            </ChatBackButton>
          </>
        ) : null}
        <audio ref={audioRef} />
      </ChatContainer>
    </>
  );
};

export default hideable(Chat);
