import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { layoutStyles } from 'atoms/Layout';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';

import Tooltip from '../../components/Tooltip';

import type{ InterpolationWithTheme } from 'utils/types';
import type { FlatJourneyProps, JourneyProps } from '../types';

const Content = styled.div`
  ${({ theme: { colors, tp } }) => css`
    ${tp.p2}

    color: ${colors.neutral.white};
    max-width: 25.75rem;
  `}
`;

const positionStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.down} {
    ${layoutStyles};

    width: calc(100vw - var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth}));
    left: calc(((100% - 100vw + var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth})) / 2));
  }
`;

const containerStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.up} {
    margin-right: 6.5rem;
  }

  ${mq.md.down} {
    left: 0;
    justify-self: flex-end;
    margin-right: 3rem;

    && {
      grid-column: content-start / grid-end;
    }
  }
`;

const arrowStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.down} {
    right: 4.5rem;
  }
`;

const TaskTooltip: React.FC<FlatJourneyProps> = props => {
  return (
    <Tooltip
      {...(props as JourneyProps)}
      anchor="bottom"
      alignment="right"
      classNames={{ position: positionStyle, container: containerStyle, arrow: arrowStyle }}
    >
      <Content>Choose a task or game and start your journey</Content>
    </Tooltip>
  );
};

export default TaskTooltip;
