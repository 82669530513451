import { channel } from 'redux-saga';
import { call, cancel, fork, put, take } from 'typed-redux-saga/macro';

import {
  transcriptionSocket,
  onCallback,
  onCallbackParameters,
  TranscriptionSocketInstanceInfo,
} from '../transcriptionSocket';

function* controlledTranscriptionSocket({ id, sequenceNumber }: TranscriptionSocketInstanceInfo) {
  const { on, send, sendStart, ...restTranscriptionSocket } = yield* call(
    transcriptionSocket<never>,
    {
      id,
      sequenceNumber,
    } as const
  );

  const startChannel = yield* call(channel<'ready'>);

  const startTask = yield* fork<onCallbackParameters<'open'>, onCallback<'open'>>(
    on,
    'open',
    function* () {
      sendStart();
      yield* put(startChannel, 'ready');
    }
  );

  yield* take(startChannel);
  yield* cancel(startTask);

  return restTranscriptionSocket;
}

export default controlledTranscriptionSocket;
