import React from 'react';

import TutorialVideoModal from './TutorialVideoModal';

import type { FlatJourneyProps, JourneyProps } from '../types';

export const FirstTimeUsingTutorial: React.FC<FlatJourneyProps> = props => (
  <TutorialVideoModal
    {...(props as JourneyProps)}
    title="👋 First time using? Check this out"
    vidyardId="nrMrUUfv6KSE6YuWb6LeGs"
    buttonElement="Explore the app"
  />
);
