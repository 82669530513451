import { createAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { inputSlice } from './slice';

const startListening = createAction(`${NAME}/startListening`);

const stopListening = createAction(`${NAME}/stopListening`);

export default {
  ...inputSlice.actions,
  startListening,
  stopListening,
};
