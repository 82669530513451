import callApi, { METHODS, PATHS } from 'api';
import { AxiosError, AxiosResponse } from 'axios';
import { useMutation, useQuery } from 'react-query';
import { captureException } from '@sentry/react';

export interface IWord {
  id: string;
  name: string;
  definition: string;
  created_at: string;
  updated_at: string;
  lesson_id: string;
  word_use_count: number;
  points_per_word_mention: number;
  order: number;
}

export const useTaskWords = (taskId: string) =>
  useQuery<unknown, unknown, IWord[]>({
    queryKey: ['useTaskWords', taskId],
    queryFn: async () => {
      try {
        const response: AxiosResponse<IWord[]> = await callApi({
          method: METHODS.GET,
          mainPath: PATHS.WORDS,
          extraPath: `${taskId}/words/`,
          authorized: true,
        });

        return response.data;
      } catch (error) {
        captureException(error);
      }
    },
  });

interface IUseStartTask {
  onSuccess: (conversationId: string) => void;
  onError: () => void;
}

interface IStartTaskParams {
  taskId: string;
}

interface IStartTaskResponse {
  expires_at: string;
  id: string;
}

export const useStartTask = ({ onSuccess, onError }: IUseStartTask) =>
  useMutation<AxiosResponse<IStartTaskResponse>, AxiosError, IStartTaskParams>(
    ({ taskId }) =>
      callApi({
        method: METHODS.POST,
        mainPath: PATHS.START_CONVERSATION,
        authorized: true,
        queryParams: [
          {
            key: 'type',
            value: 'task',
          },
          {
            key: 'task_id',
            value: taskId,
          },
        ],
      }),
    {
      onSuccess: response => {
        onSuccess(response.data.id);
      },
      onError,
    }
  );

interface ILessonEvaluation {
  id: string;
  lesson_id: string;
  user_id: string;
  max_points: number;
  points_earned: number;
  created_at: string;
  updated_at: string;
}

export const useLessonEvaluation = (lessonId: string) =>
  useQuery<unknown, unknown, ILessonEvaluation>({
    queryKey: ['useLessonEvaluation', lessonId],
    queryFn: async () => {
      try {
        const response: AxiosResponse<ILessonEvaluation> = await callApi({
          method: METHODS.GET,
          mainPath: PATHS.LESSON_EVALUATION,
          extraPath: `${lessonId}/evaluation/`,
          authorized: true,
        });

        return response.data;
      } catch (error) {
        captureException(error);
      }
    },
  });

interface ITaskEvaluation {
  id: string;
  task_id: string;
  user_id: string;
  status: string;
  points: number;
  max_points: number;
  created_at: string;
  updated_at: string;
}

export const useTaskEvaluation = (lessonId: string, taskId: string) =>
  useQuery<unknown, unknown, ITaskEvaluation>({
    queryKey: ['useTaskEvaluation', lessonId, taskId],
    queryFn: async () => {
      try {
        const response: AxiosResponse<ITaskEvaluation> = await callApi({
          method: METHODS.GET,
          mainPath: PATHS.LESSON_EVALUATION,
          extraPath: `${lessonId}/tasks/${taskId}/evaluation/`,
          authorized: true,
        });

        return response.data;
      } catch (error) {
        captureException(error);
      }
    },
  });
