import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { ReactComponent as PlaySvg } from 'assets/play.svg';
import CancelButton from 'atoms/CancelButton';
import Button from 'atoms/Button';
import ModalBackdrop from 'molecules/Modal';
import { filterProps } from 'utils/helpers';

import { ONBOARDING_NODE_ID, STACK_LEVEL } from '../../constants';

import { usePromptModalService } from './hooks';

import type { InterpolationWithTheme } from 'utils/types';
import { FlatJourneyProps, JourneyProps } from 'features/Onboarding/journeys/types';

const Backdrop = styled(ModalBackdrop, filterProps('hasLightBackdrop'))<{
  lightBackdrop: boolean;
}>`
  ${({ theme: { colors, depths, utils }, lightBackdrop }) => css`
    z-index: ${depths.priority + STACK_LEVEL.background};

    &&& {
      background-color: ${utils.alphaHex(colors.neutral.black, lightBackdrop ? 30 : 60)};
    }
  `}
`;

enum MODAL_AREAS {
  title = 'title',
  description = 'description',
  primary = 'primary',
  or = 'or',
  secondary = 'secondary',
}

const modalStyle: InterpolationWithTheme = ({ colors, mq, utils }) => css`
  grid-template-areas: ${utils.composeTemplateAreas([
    [MODAL_AREAS.title],
    [MODAL_AREAS.description],
    [MODAL_AREAS.primary],
    [MODAL_AREAS.or],
    [MODAL_AREAS.secondary],
  ])};
  background-color: ${colors.neutral.white};

  ${mq.md.up} {
    min-width: 68rem;
    padding: 6rem;
    justify-self: center;
    align-self: center;
    border-radius: 4rem;
  }

  ${mq.md.down} {
    grid-template-areas: ${utils.composeTemplateAreas([
      ['.'],
      [MODAL_AREAS.title],
      [MODAL_AREAS.description],
      ['.'],
      [MODAL_AREAS.primary],
      [MODAL_AREAS.or],
      [MODAL_AREAS.secondary],
    ])};
    grid-template-rows: 1fr min-content min-content 1fr min-content min-content min-content;
    padding-bottom: 7rem;
  }
`;

const StyledCancelButton = styled(CancelButton)`
  ${({ theme: { colors, mq, utils } }) => css`
    & > svg {
      fill: ${utils.alphaHex(colors.primary.dark, 55)};
    }

    ${mq.md.up} {
      grid-area: ${MODAL_AREAS.title};
      justify-self: flex-end;
      align-self: flex-start;
      transform: translate(100%, -100%);
    }

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1};

    grid-area: ${MODAL_AREAS.title};
    margin: 0;
    padding: 0 4rem;
    text-align: center;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const Description = styled.p`
  ${({ theme: { mq, tp } }) => css`
    ${tp.p1};

    grid-area: ${MODAL_AREAS.description};
    margin: 1rem 0 4.5rem;
    padding: 0 4rem;
    text-align: center;

    ${mq.md.down} {
      padding: 0 3rem;
      margin-bottom: 0;
    }
  `}
`;

const PrimaryButton = styled(Button)`
  grid-area: ${MODAL_AREAS.primary};
`;

const OrText = styled.span`
  ${({ theme: { tp } }) => css`
    ${tp.p2}

    grid-area: ${MODAL_AREAS.or};
    text-align: center;
    margin: 2rem 0 1.5rem;
  `}
`;

const SecondaryButton = styled(Button)`
  grid-area: ${MODAL_AREAS.secondary};

  && > svg {
    width: 2.75rem;
    height: 2.75rem;
  }
`;

const PromptModal: React.FC<FlatJourneyProps> = props => {
  const {
    showBackdrop,
    showModal,
    lessonsJourneyIsActive,
    handleClose,
    handleBackdropClick,
    handleStart,
    handleVideoStart,
  } = usePromptModalService(props as JourneyProps);

  return (
    <Backdrop
      id={ONBOARDING_NODE_ID}
      isOpen={showModal}
      onClick={handleBackdropClick}
      showBackdropOverride={showBackdrop}
      lightBackdrop={!showModal}
      classNames={{
        modal: modalStyle,
      }}
      scrollLock={!(!showModal && lessonsJourneyIsActive)}
    >
      <StyledCancelButton onClick={handleClose} />
      <Title>👋 Welcome!</Title>
      <Description>
        Start with a Lessons intro walkthrough.
        <br />
        We'll have you up and running in no time
      </Description>
      <PrimaryButton onClick={handleStart}>
        <>
          Explore the app
          <ArrowRightSvg />
        </>
      </PrimaryButton>
      <OrText>or</OrText>
      <SecondaryButton inverted bare transitionText onClick={handleVideoStart}>
        Check out video tutorials
        <PlaySvg />
      </SecondaryButton>
    </Backdrop>
  );
};

export default PromptModal;
