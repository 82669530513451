import { actionChannel, call, fork, put, select, take, takeEvery } from 'typed-redux-saga/macro';

import actions from './actions';

import type { IModalShownState } from './types';
import {
  setCurrentRoute,
  setUserExperiments,
  setUserTotalConversationCount,
} from 'features/Auth/slice';
import { matchPath } from 'utils/helpers';

import { getTier } from '../selectors';
import { UserSubscriptionTier } from '../types';
import { setSubscription } from '../slice';
import selectors from './selectors';
import { getCurrentRoute } from 'features/Auth/selectors';
import { ROUTES } from 'navigation/constants';
import { checkStreak } from 'features/Streaks/actions';

const STORAGE_KEY = 'brandReviewShownState';

function* setShownStateToStorage() {
  const shownState = yield* select(selectors.getShownState);
  localStorage.setItem(STORAGE_KEY, JSON.stringify(shownState));
}

function* getShownStateFromStorage() {
  const storedState = localStorage.getItem(STORAGE_KEY);
  const { timesShown, lastShown, hasReviewed }: IModalShownState = storedState
    ? JSON.parse(storedState)
    : { timesShown: 0, lastShown: null };

  yield* put(actions.setTimesShown(timesShown));
  yield* put(actions.setLastShown(lastShown));
  yield* put(actions.setHasReviewed(hasReviewed));
}

function* watchPromptOpen() {
  yield* takeEvery(actions.setModalStep, function* ({ payload: step }) {
    if (step === 'prompt') {
      yield* put(actions.setLastShown(Date.now()));
      yield* call(setShownStateToStorage);
    }
  });
}

function* incrementTimesShown() {
  const timesShown = yield* select(selectors.getTimesShown);
  yield* put(actions.setTimesShown(timesShown + 1));
}

function* triggerByRouteChange() {
  do {
    let tier = yield* select(getTier);

    if (!tier) {
      yield* take(setSubscription);
      tier = yield* select(getTier);
    }

    const currentRoute = yield* select(getCurrentRoute);
    const hasReviewedAlready = yield* select(selectors.hasReviewedAlready);

    const enabledRoutes = [
      ROUTES.HOME.path,
      ROUTES.LESSON.path,
      ROUTES.PROFILE.path,
      ROUTES.TASK.path,
    ];
    const enabledTiers: UserSubscriptionTier[] = ['subscriber', 'former_subscriber'];

    const canReview =
      tier &&
      enabledTiers.includes(tier) &&
      !hasReviewedAlready &&
      enabledRoutes.some(path => matchPath(path, currentRoute));

    yield* put(actions.setCanReview(!!canReview));

    yield* take(setCurrentRoute);
  } while (true);
}

function* sendBrandReview() {
  yield* take(actions.sendToBrandReviewPortal);
  yield* put(actions.setHasReviewed(true));
  yield* call(setShownStateToStorage);
  yield* put(actions.setModalStep(null));
  yield* put(actions.setCanReview(false));
}

export function* watchBrandReview() {
  try {
    const conversationCountActionChannel = yield* actionChannel(setUserTotalConversationCount);
    const experimentsActionChannel = yield* actionChannel(setUserExperiments);

    yield* call(getShownStateFromStorage);
    yield* fork(watchPromptOpen);

    yield* take(conversationCountActionChannel);
    yield* take(experimentsActionChannel);
    yield* fork(triggerByRouteChange);

    yield* fork(sendBrandReview);

    while (true) {
      yield* take(checkStreak);

      const canPrompt = yield* select(selectors.canPrompt);

      if (canPrompt) {
        yield* call(incrementTimesShown);
        yield* put(actions.setModalStep('prompt'));
      }
    }
  } catch {}
}
