import { STORAGE_KEYS } from './constants';

export const storageSetItem = (key: STORAGE_KEYS, value: string) => {
  localStorage.setItem(key, value);
};

export const storageRemoveItem = (key: STORAGE_KEYS) => {
  localStorage.removeItem(key);
};

export const storageGetItem = (key: STORAGE_KEYS) => {
  return localStorage.getItem(key);
};
