import { createSelector } from '@reduxjs/toolkit';

import chatSelectors from '../selectors';

import { NAME_FRAGMENT } from './constants';

const getReview = createSelector(chatSelectors.getChat, chat => chat[NAME_FRAGMENT]);

const getStatus = createSelector(getReview, ({ status }) => status);

const getScores = createSelector(getReview, ({ scores }) => scores);

const getMessages = createSelector(getReview, ({ messages }) => messages);

export default {
  getReview,
  getStatus,
  getScores,
  getMessages,
};
