import { useCallback, useEffect, useRef, useState } from 'react';

export const useVidyardPlayerService = (id: string) => {
  const playerRef = useRef<HTMLDivElement>(null);
  const [renderKey, setRenderKey] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const [svgSize, setSvgSize] = useState<{
    width: number | undefined;
    height: number | undefined;
  }>({ width: void 0, height: void 0 });

  const videoUrl = `https://play.vidyard.com/${id}`;
  const imageUrl = `${videoUrl}.jpg`;

  useEffect(() => {
    if (isReady && playerRef.current) {
      const element = playerRef.current?.querySelector('.vidyard-player-container');

      if (element) {
        const playerMaxHeight = playerRef.current.clientHeight - 2;
        const width = element.clientWidth;
        const height = element.clientHeight;

        const scaleRatio = height / playerMaxHeight;

        setSvgSize({ width: width / scaleRatio, height: height / scaleRatio });
        setIsPlaying(true);
      }
    }
  }, [isReady]);

  const handleReady = useCallback(() => {
    setIsReady(true);
  }, [setIsReady]);

  const handleEnded = useCallback(() => {
    setRenderKey(key => key + 1);
  }, [setRenderKey]);

  const handlePause = useCallback(() => {
    setIsPlaying(false);
  }, [setIsPlaying]);

  const handlePlay = useCallback(() => {
    setIsPlaying(true);
  }, [setIsPlaying]);

  return {
    playerRef,
    svgSize,
    renderKey,
    videoUrl,
    imageUrl,
    isReady,
    isPlaying,
    handleReady,
    handleEnded,
    handlePause,
    handlePlay,
  };
};
