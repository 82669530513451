import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as CancelSvg } from 'assets/cancel.svg';

const Button = styled.button`
  ${({ theme: { colors, durations, mq, utils } }) => css`
    padding: 0;
    background: initial;
    border: initial;
    cursor: pointer;

    & > svg {
      width: 3rem;
      height: 3rem;
      fill: ${utils.alphaHex(colors.neutral.white, 60)};

      transition: all ${durations.regular};
      opacity: 1;

      ${mq.hover} {
        transform: scale(1);

        &:hover {
          transform: scale(1.1);
        }
      }

      &:active {
        opacity: 0.8;
      }
    }
  `}
`;

const CancelButton: React.FC<JSX.IntrinsicElements['button']> = props => (
  <Button {...props}>
    <CancelSvg />
  </Button>
);

export default CancelButton;
