import { useCallback, useState } from 'react';
import { captureException } from '@sentry/react';

import callApi, { METHODS, PATHS } from 'api';

import { IFeedbackForm } from './form';

export const useProfileSendFeedbackService = () => {
  const [submitted, setSubmitted] = useState(false);

  const handleFeedback = useCallback(
    async (values: IFeedbackForm) => {
      try {
        if (!submitted)
          await callApi<{ feedback: string; type: string }>({
            method: METHODS.POST,
            mainPath: PATHS.USER_FEEDBACK,
            data: { feedback: values.feedback, type: 'user_feedback' },
            authorized: true,
          });

        setSubmitted(true);
      } catch (error) {
        captureException(error);
      }
    },
    [submitted]
  );

  return { handleFeedback, submitted };
};
