import hideable from 'utils/hideable';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FONT_FAMILIES } from 'theme/emotion';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    position: relative;
    display: grid;
    grid-auto-rows: min-content;
    justify-items: center;
    row-gap: 2rem;
    width: 68rem;
    padding: 6rem;
    overflow-y: auto;

    ${mq.md.down} {
      padding: 0 3rem;
      width: 100%;
      height: 100%;

      &::before {
        content: '';
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1};
    display: grid;
    text-align: center;
    margin: 0 0 2rem;

    ${mq.md.up} {
      padding: 0 4rem;
    }
  `}
`;

const Description = styled.p`
  ${({ theme: { mq, tp } }) => css`
    ${tp.p1};
    display: grid;
    text-align: center;
    margin: 0;

    ${mq.md.up} {
      padding: 0 4rem;
    }
  `}
`;

const List = styled.div`
  ${({ theme: { tp } }) => css`
    display: grid;
    row-gap: 1rem;
    list-style: none;
    padding: 1rem 0;

    & > span {
      ${tp.h2};

      font-weight: ${FONT_FAMILIES.Poppins.lightBold};
    }
  `}
`;

const IOSInstructions = () => (
  <Container>
    <Title>Oops! Leya's Ears Need a Little Help 🙉</Title>
    <Description>
      Uh-oh! It seems we've hit a bit of a snag and Leya can't hear you right now 🙁. There may be a
      cheeky workaround that you can do 🤫💻.
    </Description>
    <Description>
      Here's how to fix it 🪛 We suggest you hang out with Leya using either:
    </Description>
    <List>
      <span>📱 Mobile Safari on iOS</span>
      <span>💻 Google Chrome</span>
    </List>
    <Description>
      These fellas are superstar browsers 🌟🌟 and can help you and Leya chit chat seamlessly. So,
      if you've had some hiccups while using Leya in another app like Facebook or Instagram, this
      should do the trick!
    </Description>
    <Description>
      And just in case you've taken these steps and something is still not working well, don't
      hesitate to message our customer support 📞. We're here to help!
    </Description>
    <Description>
      Plus, if we can't fix the issue, you'll definitely get a refund. So rest easy, we've got your
      back! 🤗 Thank you for your patience and understanding! 💖
    </Description>
  </Container>
);

export default hideable(IOSInstructions);
