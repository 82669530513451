import { styled } from '@mui/material';

export default styled('form')({
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: 24,
  maxWidth: 400,
  width: '100%',
  boxShadow: '0px 1px 40px rgba(24, 24, 24, 0.08)',
});
