import { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { css, keyframes } from '@emotion/react';
import { animateScroll } from 'react-scroll';

import actions from '../actions';
import { resetConversation } from '../slice';
import { checkSequenceIsMarked, getConversationMessages, getSequenceMessage } from '../selectors';

import BotMessage from './BotMessage/BotMessage';
import { PushToTalkWorkspace } from './PushToTalkWorkspace';
import AnimatedDots from 'components/AnimatedDots';

import Button from 'atoms/Button';
import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';


const Container = styled.div`
  width: 100%;
`;

const MessageBox = styled.div`
  ${({ theme: { mq, tp } }) => css`
    ${tp.p1}

    max-width: 45rem;
    padding: 2rem 2.5rem;
    border-radius: 1.5rem;
    margin: 1rem 0;

    ${mq.md.down} {
      max-width: 75%;
      margin: 0.75rem 0;
      padding: 2rem;
    }
  `}
`;

const UserMessageBox = styled(MessageBox)`
  ${({ theme: { colors } }) => css`
    border: 1px solid ${colors.primary.dark};
    border-bottom-left-radius: 0.25rem;
  `}
`;

const UserMessageContainer = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const BotMessageContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const BotAnimatedDotsContainer = styled.div`
  ${({ theme: { colors, mq } }) => css`
    position: relative;
    display: grid;
    background-color: ${colors.neutral.white};
    border-radius: 1.5rem 1.5rem 0.25rem 1.5rem;
    padding: 2rem 4rem;

    ${mq.md.down} {
      padding: 2rem 3rem;
    }
  `}
`;

const dotAnimation = (initialColor: string, endColor: string) => keyframes`
	0% {
		background-color: ${initialColor};
	}
	50%, 100% {
    background-color: ${endColor};
	}
`;

const BotAnimatedDots = styled(AnimatedDots)`
  ${({ theme: { colors, utils } }) => css`
    position: relative;
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 50%;
    background-color: ${colors.primary.light};
    animation: ${dotAnimation(colors.primary.light, utils.alphaHex(colors.primary.light, 10))} 1s
      infinite linear alternate;
    animation-delay: 0.6s;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      background-color: inherit;
      animation: inherit;
    }

    &::before {
      left: -1.875rem;
      animation-delay: 0.3s;
    }
    &::after {
      left: 1.875rem;
      animation-delay: 0.9s;
    }
  `}
`;

const ViewReviewContainer = styled.div`
  ${({ theme: { colors, mq } }) => css`
    position: relative;
    display: grid;
    background-color: ${colors.neutral.white};
    border-radius: 1.5rem 1.5rem 1.5rem 1.5rem;
    padding: 2rem 4rem;
    margin-top: 1rem; 

    ${mq.md.down} {
      padding: 2rem 3rem;
    }
  `}
`;

 const ViewReviewButton = styled(Button)`
   ${({ theme: { colors, mq } }) => css`
     ${mq.hover} {
       border-color: ${colors.primary.dark};
     }
   `}
 `;


interface IChatMessages {
  id: string;
  currentSequenceNumber: number;
  sendMessage: (message: string) => void;
  isBotLoading: boolean;
  evaluationIsReady: boolean;
  openEvaluation: () => void;
}

const ChatMessages: React.FC<IChatMessages> = ({
  id,
  currentSequenceNumber,
  sendMessage,
  isBotLoading,
  evaluationIsReady,
  openEvaluation,
}) => {
  const dispatch = useDispatch();
  const [evaluationSequence, setEvaluationSequence] = useState<number | null>(null);

  useEffect(
    function syncConversationWithRedux() {
      dispatch(actions.startConversation());
      dispatch(actions.setConversationId(id));

      return () => {
        dispatch(actions.setConversationId(null));
      };
    },
    [id]
  );

  useEffect(
    function syncSequenceNumberWithRedux() {
      dispatch(actions.setSequenceNumber(currentSequenceNumber));
    },
    [currentSequenceNumber]
  );

  useEffect(() => {
    if ((evaluationIsReady) && evaluationSequence === null) {
      setEvaluationSequence(currentSequenceNumber - 1);
    }
  },
  [evaluationIsReady, evaluationSequence]
  );

  useEffect(() => {
    return () => {
      dispatch(resetConversation(id));
    };
  }, []);

  const messages = useSelector(getConversationMessages({ id, markedEntities: ['user'] }));

  const isLatestUserSequenceMarked = useSelector(
    checkSequenceIsMarked({ id, sequenceNumber: currentSequenceNumber, entity: 'user' })
  );

  const currentUserMessage = useSelector(
    getSequenceMessage({ id, sequenceNumber: currentSequenceNumber, entity: 'user' })
  );

  useEffect(() => {
    if (isLatestUserSequenceMarked && !!currentUserMessage) {
      sendMessage(currentUserMessage);
      animateScroll.scrollToBottom();
    }
  }, [isLatestUserSequenceMarked, currentUserMessage]);


  return (
    <>
      <Container>
        {messages.map(({ key, text, entity, id: messageId, sequenceNumber }) =>
          entity === 'user' ? (
            <UserMessageContainer key={key}>
              <UserMessageBox>{text}</UserMessageBox>
            </UserMessageContainer>
          ) : (
            <>
              <BotMessageContainer key={key}>
                <BotMessage id={messageId} sequenceNumber={sequenceNumber} />
              </BotMessageContainer>
              {evaluationIsReady && sequenceNumber === evaluationSequence && (
                <BotMessageContainer key={key + 'reviewButton'}>
                  <ViewReviewContainer>
                    <ViewReviewButton onClick={openEvaluation} variant="outlined" bare transitionText>
                       View Review
                      <ArrowRightSvg />
                    </ViewReviewButton>
                  </ViewReviewContainer>
                </BotMessageContainer>
              )}
            </>
          )
        )}
        {isBotLoading && messages?.[messages.length - 1]?.entity !== 'bot' ? (
          <BotMessageContainer>
            <BotAnimatedDotsContainer>
              <BotAnimatedDots />
            </BotAnimatedDotsContainer>
          </BotMessageContainer>
        ) : null}
      </Container>
      <PushToTalkWorkspace />
    </>
  );
};

export default ChatMessages;
