import { useCallback, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getPaymentFrequencyMonths } from 'features/User/selectors';
import { setCancellationStep } from 'features/User/slice';

export const usePromptStepService = () => {
  const paymentFrequencyMonths = useSelector(getPaymentFrequencyMonths);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(setCancellationStep(null));
  }, [dispatch]);

  const handleConfirm = useCallback(() => dispatch(setCancellationStep('feedback')), [dispatch]);

  const keepPlanText = useMemo(
    () => `Keep my ${paymentFrequencyMonths} month subscription`,
    [paymentFrequencyMonths]
  );

  return { keepPlanText, handleClose, handleConfirm };
};
