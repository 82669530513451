import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import { ReactComponent as RefreshSvg } from 'assets/refresh.svg';
import Button from 'atoms/Button';
import { Review } from 'features/Chat';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';
import { useFeedbackEvaluation } from 'utils/hooks/useFeedbackEvaluation';

import { CLASSNAME_PREFIX } from './constants';

import type { InterpolationWithTheme } from 'utils/types';

interface IResultsStep {
  taskName: string;
  taskEvaluationId: string;
  goToResults: () => void;
  goToTaskSelection: () => void;
}

const Container = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 6rem;
    grid-area: 1 / 1 / 1 / 1;
    justify-self: center;
    width: 100%;
    padding-bottom: 5.5rem;

    & > * {
      z-index: 1;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      top: var(${GLOBAL_STYLE_VARIABLES.headerHeightInverse});
      left: 0;
      width: 100%;
      height: calc(100% - var(${GLOBAL_STYLE_VARIABLES.headerHeightInverse}));
      background-color: ${colors.neutral.grey};
      z-index: -1;
    }

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${0} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${0} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }

    ${mq.md.down} {
      row-gap: 4rem;
      padding: 4rem 0 3.25rem;
    }
  `}
`;

const Content = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    row-gap: 2rem;
    max-width: 68rem;
    width: 100%;
    justify-self: center;

    ${mq.md.down} {
      row-gap: 1.5rem;
    }
  `}
`;

const Header = styled.div`
  display: grid;
  grid-auto-flow: column;
  align-items: center;

  & > * {
    grid-area: 1 / 1 / span 1 / span 1;
  }
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1};

    display: grid;
    text-align: center;
    padding: 0 4rem;
    justify-self: center;

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const ResultsButton = styled(Button)`
  background-color: transparent;
  justify-self: flex-start;
`;

const PracticeButton = styled(Button)`
  background-color: transparent;
  justify-self: flex-end;
`;

const EvaluationPanel = styled.div`
  justify-self: center;
  max-width: 100%;
  border-radius: 2rem;
  overflow: hidden;

  && [class^='_hj'] {
    & [class$='__Feedback__isEmbedded'] {
      width: 100%;
    }

    & [class$='__ExpandedWidget__isEmbedded'] {
      margin: 0;
    }

    & [class$='__EmotionStep__isEmbedded'] {
      min-height: initial;
    }

    & [class$='__EmotionStep__noBranding'] {
      height: initial;
    }

    & [class$='__FinalStep__isEmbedded'] {
      min-height: initial;
    }

    & [class$='__EmotionOption__isEmbedded'] > :last-child {
      padding-top: 0.5rem;
    }
  }
`;

const highlightOutline: InterpolationWithTheme = ({ colors, utils }) => css`
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: ${utils.alphaHex(colors.neutral.black, 30)};
  }
`;

const ReviewStep: React.FC<IResultsStep> = ({
  taskName,
  taskEvaluationId,
  goToResults,
  goToTaskSelection,
}) => {
  const feedbackRef = useFeedbackEvaluation({
    userTaskProgressId: taskEvaluationId,
    type: 'feedback_evaluation',
  });

  return (
    <Container>
      <Header>
        <ResultsButton onClick={goToResults} variant="outlined" bare transitionText>
          <ArrowLeftSvg />
          Task results
        </ResultsButton>
        <PracticeButton onClick={goToTaskSelection} variant="outlined" bare transitionText>
          Practice again
          <RefreshSvg />
        </PracticeButton>
        <Title>{`${taskName} task review`}</Title>
      </Header>
      <Content>
        <Review
          reviewableMessageHighlightProps={{
            journey: 'review',
            step: 'improvement',
            classNames: { outline: highlightOutline },
          }}
        />
        <EvaluationPanel data-feedback-id="task-review" ref={feedbackRef} />
      </Content>
    </Container>
  );
};

export default ReviewStep;
