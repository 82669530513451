import { END, MulticastChannel, multicastChannel } from 'redux-saga';

export interface BroadcastDataEvent {
  type: 'data';
  payload: Uint8Array;
}
export interface BroadcastEndEvent {
  type: 'end';
}

type BroadcastEvents = BroadcastDataEvent | BroadcastEndEvent;

const channel = multicastChannel<BroadcastEvents>();

export const inputBroadcastChannel: MulticastChannel<BroadcastEvents> & {
  takeAsync: () => Promise<BroadcastEvents>;
} = {
  ...channel,
  takeAsync: () =>
    new Promise<BroadcastEvents>((resolve, reject) => {
      inputBroadcastChannel.take(event => {
        if (event === END) return reject(event);
        return resolve(event as BroadcastEvents);
      });
    }),
};
