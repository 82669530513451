import React, { ReactElement, RefObject } from 'react';

import { useHighlightService } from './hooks';

import type { HighlightProps } from './types';

type THighlight<
  RefType extends HTMLElement = HTMLElement,
  Callback extends () => void = () => void
> = HighlightProps<Callback> & {
  children: (content: { ref: RefObject<RefType>; callback: Callback }) => ReactElement;
};

const Highlight = <RefType extends HTMLElement>({ children, ...props }: THighlight<RefType>) => {
  const { ref, outlinePortal, callback } = useHighlightService<RefType>(props);

  return (
    <>
      {children({ ref, callback: callback })}
      {outlinePortal}
    </>
  );
};

export default Highlight;
