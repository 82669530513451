import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Header from 'organisms/Header';
import {
  isAuthenticated as isAuthenticatedSelector,
  isNotAuthorizedTier,
} from 'features/Auth/selectors';

import { ROUTES } from 'navigation/constants';

const PurchaseRoute = () => {
  const location = useLocation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userHasSubscription = !useSelector(isNotAuthorizedTier);

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN.path} state={{ from: location }} replace />;
  }

  if (isAuthenticated && userHasSubscription) {
    return <Navigate to={ROUTES.HOME.path} state={{ from: location }} replace />;
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default PurchaseRoute;
