import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { ReactComponent as InfoSvg } from 'assets/info.svg';

import Button from 'atoms/Button';
import TaskInfo from 'features/Task/components/TaskInfo';
import { filterProps } from 'utils/helpers';

import Prose from './Prose';
import PushToTalkButton from './PushToTalkButton';
import TextToSpeechButton from './TextToSpeechButton';
import { usePronunciationService } from './hooks';
import Loader from 'atoms/Loader';
import TaskComplete from 'features/Task/components/LegacyTaskComplete';

export interface IPronunciation {
  taskId: string;
  lessonId: string;
}

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-template-rows: min-content 1fr min-content;
    row-gap: 3rem;
    justify-self: center;
    width: 100%;
    height: 100%;

    ${mq.md.up} {
      max-width: 68rem;
    }

    ${mq.md.down} {
      row-gap: 2rem;
    }
  `}
`;

const StyledTaskInfo = styled(TaskInfo)`
  ${({ theme: { mq } }) => css`
    margin-top: 2rem;
    ${mq.md.down} {
      margin-top: 0;
    }
  `}
`;

const ProseContainer = styled('div', filterProps('disabled'))<{ disabled: boolean }>`
  ${({ theme: { colors, durations, mq }, disabled }) => css`
    display: grid;
    position: relative;
    grid-template-rows: 1fr min-content min-content;
    row-gap: 1rem;
    background: ${colors.neutral.white};
    border-radius: 2rem;
    padding: 5rem 2rem 1.5rem;
    height: clamp(20rem, 35rem, 100%);
    pointer-events: ${disabled ? 'none' : 'initial'};

    & > *:not(svg) {
      transition: opacity ${durations.long} ease-in-out;
      opacity: ${disabled ? 0.15 : 1};
    }

    ${mq.md.down} {
      height: clamp(18rem, 45rem, 100%);
    }
  `}
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledProse = styled(Prose)``;

const Progress = styled('div', filterProps('percentageFilled'))<{ percentageFilled: number }>`
  ${({ theme: { colors, durations, mq }, percentageFilled }) => css`
    width: 100%;
    background-color: ${colors.neutral.grey};
    height: 0.5rem;
    border-radius: 0.25rem;
    align-self: flex-end;
    overflow: hidden;

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      transform: scaleX(${percentageFilled / 100});
      transform-origin: left;
      transition: ${durations.long} transform ease-in;
      border-radius: 0.25rem;
      background-color: ${colors.primary.light};
    }

    ${mq.md.down} {
    }
  `}
`;

const ButtonContainer = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    align-self: flex-end;
    padding: 6rem 0;
    row-gap: 4rem;

    ${mq.md.down} {
      padding: 5rem 0;
      row-gap: 3rem;
    }
  `}
`;

const StyledNextButton = styled(Button, filterProps('hide'))<{ hide: boolean }>`
  ${({ hide }) => css`
    justify-self: center;
    background-color: transparent;
    opacity: ${hide ? 0 : 1};
    pointer-events: ${hide ? 'none' : 'initial'};
  `}
`;

const InfoButton = styled(Button, filterProps('hide'))<{ hide: boolean }>`
  ${({ hide }) => css`
    position: absolute;
    bottom: 3.5rem;
    right: 2rem;

    &&& {
      opacity: ${hide ? 0 : 1};
      pointer-events: ${hide ? 'none' : 'initial'};
    }

    && {
      padding: 1.5rem;
    }
  `}
`;

const Pronunciation: React.FC<IPronunciation> = ({ taskId, lessonId }) => {
  const {
    isLoading,
    words,
    currentScore,
    nextButtonState,
    handleNext,
    currentPoints,
    totalPoints,
    isEvaluationOpen,
    handleCloseEvaluation,
    hideInfoButton,
    handleInfo,
  } = usePronunciationService({
    taskId,
    lessonId,
  });

  return (
    <>
      <Container>
        <StyledTaskInfo
          isVisible={true}
          taskId={taskId}
          lessonId={lessonId}
          currentPoints={currentPoints}
          totalPoints={totalPoints}
        />
        <ProseContainer disabled={isLoading}>
          {isLoading && <StyledLoader />}
          <StyledProse words={words} />
          <TextToSpeechButton />
          <Progress percentageFilled={currentScore} />
          <InfoButton variant="outlined" bare hide={hideInfoButton} onClick={handleInfo}>
            <InfoSvg />
          </InfoButton>
        </ProseContainer>
        <ButtonContainer>
          <StyledNextButton
            variant="outlined"
            bare
            hide={nextButtonState === 'hide'}
            disabled={isLoading}
            onClick={handleNext}
          >
            {nextButtonState === 'next' && (
              <>
                Next sentence <ArrowRightSvg />
              </>
            )}
            {nextButtonState === 'finish' && 'Finish'}
            {nextButtonState === 'hide' && ' '}
          </StyledNextButton>
          <PushToTalkButton />
        </ButtonContainer>
      </Container>
      <TaskComplete
        isVisible={isEvaluationOpen}
        handleClose={handleCloseEvaluation}
        taskId={taskId}
        lessonId={lessonId}
      />
    </>
  );
};

export default Pronunciation;
