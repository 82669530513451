import { createAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { chatSlice } from './slice';

export const startControlledListening = createAction(`${NAME}/startControlledListening`);

export const startUncontrolledListening = createAction(`${NAME}/startUncontrolledListening`);

export const stopListening = createAction(`${NAME}/stopListening`);

export const startConversation = createAction(`${NAME}/startConversation`);

export default {
  ...chatSlice.actions,
  startControlledListening,
  startUncontrolledListening,
  stopListening,
  startConversation,
};
