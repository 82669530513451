import { useCallback, useMemo, useState } from 'react';
import { useTheme } from '@emotion/react';
import { captureException } from '@sentry/react';

import callApi, { METHODS, PATHS } from 'api';

import type { ISuggestionForm } from './form';

export const useSuggestionService = () => {
  const { durations } = useTheme();
  const [step, setStep] = useState<null | 'input' | 'complete'>(null);

  const handleClose = useCallback(() => setStep(null), []);

  const openModal = useCallback(() => setStep('input'), []);

  const handleSubmit = useCallback(
    async (values: ISuggestionForm) => {
      try {
        await callApi<{ feedback: string }>({
          method: METHODS.POST,
          mainPath: PATHS.USER_FEEDBACK,
          data: { feedback: values.suggestion },
          authorized: true,
        });
      } catch (error) {
        captureException(error);
      } finally {
        setStep('complete');
      }
    },
    [setStep]
  );
  const timeout = useMemo(() => Number(durations.regular.slice(0, -2)), [durations]);

  return { step, openModal, handleClose, handleSubmit, timeout };
};
