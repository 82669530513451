import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { LAYOUT_TEMPLATE_COLUMNS } from 'atoms/Layout';
import Modal from 'molecules/Modal';

import { CLASSNAME_PREFIX } from './constants';
import { useProgressiveWebAppPromptModalService } from './hooks';
import PromptStep from './PromptStep';
import IosInstructionsStep from './IosInstructionsStep';

import type { InterpolationWithTheme } from 'utils/types';

const StyledModal = styled(Modal)`
  ${({ theme: { depths } }) => css`
    z-index: ${depths.priority + depths.modal};
    overflow-y: auto;
  `}
`;

const modalStyle: InterpolationWithTheme = ({ colors, mq }) => css`
  position: relative;
  background-color: ${colors.primary.dark};
  grid-auto-rows: initial;

  ${mq.md.up} {
    justify-self: center;
    align-self: center;
    border-radius: 4rem;
  }

  ${mq.md.down} {
    && {
      grid-column: ${LAYOUT_TEMPLATE_COLUMNS.contentStart} / ${LAYOUT_TEMPLATE_COLUMNS.contentEnd};
    }

    &::before {
      position: fixed;
      height: 100%;
      width: 100%;
      background-color: ${colors.primary.dark};
      top: 0;
      left: 0;
      content: '';
      z-index: -1;
    }

    padding: 3rem 0 7rem;
    max-height: 100vh;
  }
`;

const ModalContent = styled.section`
  ${({ theme: { durations } }) => css`
    display: grid;
    transition: all ${durations.regular};
  `}
`;

const ProgressiveWebAppPromptModal: React.FC = () => {
  const { step, timeout, handleClose } = useProgressiveWebAppPromptModalService();

  return (
    <StyledModal isOpen={step !== null} onClick={handleClose} classNames={{ modal: modalStyle }}>
      <TransitionGroup component={ModalContent}>
        <CSSTransition key={step} timeout={timeout} classNames={CLASSNAME_PREFIX}>
          {() => {
            switch (step) {
              case 'ios-instructions': {
                return <IosInstructionsStep />;
              }
              case 'prompt':
              default: {
                return <PromptStep />;
              }
            }
          }}
        </CSSTransition>
      </TransitionGroup>
    </StyledModal>
  );
};

export default ProgressiveWebAppPromptModal;
