import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as CancelSvg } from 'assets/cancel.svg';
import { ReactComponent as MenuSvg } from 'assets/menu.svg';
import { filterProps } from 'utils/helpers';

import { embeddedRem } from './Wrapper';

import type { Classname } from 'utils/types';

interface IMenuButton extends Classname {
  onClick: JSX.IntrinsicElements['button']['onClick'];
  isOpen: boolean;
}

enum MENU_BUTTON_VARIABLES {
  menuOpacity = '--menu-opacity',
  cancelOpacity = '--cancel-opacity',
}

const Container = styled('button', filterProps('isOpen'))<{ isOpen: boolean }>`
  ${({ theme: { depths, durations, mq }, isOpen }) => css`
    ${MENU_BUTTON_VARIABLES.menuOpacity}: ${isOpen ? 0 : 1};
    ${MENU_BUTTON_VARIABLES.cancelOpacity}: ${isOpen ? 1 : 0};

    box-sizing: content-box;
    display: grid;
    position: relative;
    z-index: ${depths.menu + 1};
    border: initial;
    background: initial;
    cursor: pointer;
    padding: ${embeddedRem(1)};

    & > svg {
      grid-row: 1/ 1;
      grid-column: 1/ 1;
      transition: opacity ${durations.regular};
      width: ${embeddedRem(3)};
      height: ${embeddedRem(3)};
    }

    ${mq.md.up} {
      //display: none;
    }
  `}
`;

const MenuIcon = styled(MenuSvg)`
  opacity: var(${MENU_BUTTON_VARIABLES.menuOpacity});
`;

const CancelIcon = styled(CancelSvg)`
  opacity: var(${MENU_BUTTON_VARIABLES.cancelOpacity});
`;

const MenuButton: React.FC<IMenuButton> = ({ className, onClick, isOpen }) => (
  <Container onClick={onClick} isOpen={isOpen} className={className}>
    <MenuIcon />
    <CancelIcon />
  </Container>
);

export default MenuButton;
