import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import OneSignal from 'react-onesignal';

import { useScrollLock } from 'utils/hooks';
import useOnClickOutside from 'utils/useOnClickOutside';
import { ROUTES } from 'navigation/constants';
import { GLOBAL_STYLE_VARIABLES, useMatchMedia } from 'theme/emotion';
import { useDispatch, useSelector } from 'react-redux';
import { isAuthenticated, isNotAuthorizedTier } from 'features/Auth/selectors';
import { logout } from 'features/Auth/actions';
import { isIOS } from 'utils/constants';
import { useShowPushNotificationsModal } from 'features/PushNotifications';
import { isInitialized, isPushNotificationsEnabled } from 'features/PushNotifications/selectors';
import { COOKIE_KEY, removeCookie } from 'utils/cookies';
import { setRetriggerCount } from 'features/PushNotifications/slice';
import { getUsername } from 'features/User/selectors';
import { useBrandReviewPrompt, useProgressiveWebAppPrompt } from 'features/User';

const useHeaderMenu = () => {
  const { pathname } = useLocation();
  const { mq } = useTheme();

  const popupRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false);
  const [lockScroll, unlockScroll] = useScrollLock();

  const isMobile = useMatchMedia([mq.md.down]);

  const handleClickOutside = useCallback(() => {
    if (!isMobile && isPopupOpen) {
      setIsPopupOpen(false);
    }
  }, [isMobile, isPopupOpen]);

  useOnClickOutside(popupRef, handleClickOutside);

  const handleMenu = useCallback(() => {
    if (isMobile) {
      setIsOpen(currentIsOpen => !currentIsOpen);
    } else {
      setIsPopupOpen(currentIsPopupOpen => !currentIsPopupOpen);
    }
  }, [isMobile]);

  useEffect(() => {
    if (isOpen) {
      lockScroll();
    } else {
      unlockScroll();
    }
  }, [isOpen]);

  useEffect(() => {
    if (isMobile) setIsPopupOpen(false);
  }, [isMobile]);

  useEffect(() => {
    return () => {
      unlockScroll();
    };
  }, []);

  useEffect(() => {
    setIsOpen(currentIsOpen => {
      if (currentIsOpen) return false;
      else return currentIsOpen;
    });

    setIsPopupOpen(currentIsPopupOpen => {
      if (currentIsPopupOpen) return false;
      else return currentIsPopupOpen;
    });
  }, [pathname]);

  return { isOpen, handleMenu, isPopupOpen, popupRef };
};

export const useHeaderHeight = () => {
  const [headerIsSticky, setHeaderIsSticky] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const scrollMarginRef = useRef<number>(0);
  const scrollTriggerHeightRef = useRef<number>(0);

  useEffect(() => {
    const setScrollHeaderSticky = () => {
      const scrollNumber = window.scrollY;

      if (!!scrollTriggerHeightRef.current && scrollNumber > 40) {
        setHeaderIsSticky(true);
      } else {
        setHeaderIsSticky(false);
      }
    };

    const setScrollMargin = () => {
      const headerHeight = containerRef?.current?.offsetHeight ?? 0;
      if (headerHeight > 0) {
        const header = containerRef.current;

        if (header && header.nextSibling) {
          const headerPaddingBottom = window.getComputedStyle(header).paddingBottom;

          const siblingPaddingTop = window.getComputedStyle(
            header.nextSibling as Element
          ).paddingTop;

          if (headerPaddingBottom && siblingPaddingTop) {
            const headerPadding = Number(headerPaddingBottom.slice(0, -2));
            const siblingPadding = Number(siblingPaddingTop.slice(0, -2));

            const scrollTriggerHeight = siblingPadding - headerPadding;

            if (scrollTriggerHeight > scrollTriggerHeightRef.current) {
              scrollTriggerHeightRef.current = scrollTriggerHeight;
              setScrollHeaderSticky();
            }
          }
        }

        if (scrollMarginRef.current < headerHeight || scrollMarginRef.current === 0) {
          scrollMarginRef.current = headerHeight;
          document.body.style.setProperty(GLOBAL_STYLE_VARIABLES.headerHeight, `${headerHeight}px`);
        }

        document.body.style.setProperty(
          GLOBAL_STYLE_VARIABLES.headerHeightInverse,
          `${headerHeight}px`
        );
      }
    };

    document.addEventListener('scroll', setScrollHeaderSticky);
    document.addEventListener('resize', setScrollMargin);
    containerRef.current?.addEventListener('transitionend', setScrollMargin);

    setScrollMargin();
    setScrollHeaderSticky();

    return () => {
      document.removeEventListener('scroll', setScrollHeaderSticky);
      document.removeEventListener('resize', setScrollMargin);
      containerRef.current?.removeEventListener('transitionend', setScrollMargin);
    };
  }, []);

  return {
    containerRef,
    headerIsSticky,
  };
};

export const useHeaderService = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const areLinksVisible = useSelector(isAuthenticated);

  const { handleMenu, ...menuProps } = useHeaderMenu();

  const headerHeightProps = useHeaderHeight();

  const { mq } = useTheme();
  const isMobile = useMatchMedia([mq.md.down]);

  const isAuthorizedUser = !useSelector(isNotAuthorizedTier);

  const homeUrl = ROUTES.HOME.path;

  const links: { label: string; url: string; active: boolean; onClick?: () => void }[] = useMemo(
    () =>
      [
        ...(isAuthorizedUser && isMobile ? [{ label: 'Profile', url: ROUTES.PROFILE.path }] : []),
        { label: 'Roleplays', url: ROUTES.ROLEPLAYS.path },
        { label: 'Lessons', url: ROUTES.HOME.path },
        { label: 'Leaderboard', url: ROUTES.LEADERBOARD.path },
      ].map(link => ({
        ...link,
        active: link.url === pathname,
      })),
    [pathname, isAuthorizedUser, isMobile]
  );

  const pushNotificationsInitialized = useSelector(isInitialized);
  const pushNotificationsEnabled = useSelector(isPushNotificationsEnabled);
  const showPushNotificationsModal = useShowPushNotificationsModal();

  const [canPromptForNotifications, setCanPromptForNotifications] = useState(false);
  const progressiveWebAppPrompt = useProgressiveWebAppPrompt();
  const brandReviewPrompt = useBrandReviewPrompt();
  const isPwaInstalled = !!(window.navigator as unknown as { standalone: boolean })?.standalone;

  useEffect(() => {
    if (pushNotificationsInitialized) {
      (async () => {
        const permission = await OneSignal.getNotificationPermission();

        setCanPromptForNotifications(permission === 'default');
      })();
    }
  }, [pushNotificationsInitialized]);

  const popupLinks: typeof links = useMemo(
    () => [
      ...(progressiveWebAppPrompt.installable && progressiveWebAppPrompt.canPrompt
        ? [
            {
              label: 'Download App',
              url: ROUTES.HOME.path,
              active: false,
              onClick: progressiveWebAppPrompt.open,
            },
          ]
        : []),
      ...(brandReviewPrompt.canReview
        ? [
            {
              label: 'Review us',
              url: ROUTES.HOME.path,
              active: false,
              onClick: brandReviewPrompt.open,
            },
          ]
        : []),

      ...((!isIOS || isPwaInstalled) &&
      canPromptForNotifications &&
      pushNotificationsInitialized &&
      pushNotificationsEnabled !== null &&
      !pushNotificationsEnabled
        ? [
            {
              label: 'Get Daily Reminders',
              url: ROUTES.HOME.path,
              active: false,
              onClick: () => {
                removeCookie(COOKIE_KEY.pushNotificationsHasBeenPrompted);
                dispatch(setRetriggerCount(1));
                showPushNotificationsModal();
              },
            },
          ]
        : []),
    ],
    [
      progressiveWebAppPrompt,
      brandReviewPrompt,
      canPromptForNotifications,
      pushNotificationsInitialized,
      pushNotificationsEnabled,
      isPwaInstalled,
      showPushNotificationsModal,
    ]
  );

  const handleLogout = () => {
    dispatch(logout());
    handleMenu();
  };

  const username = useSelector(getUsername);

  return {
    areLinksVisible,
    homeUrl,
    links,
    popupLinks,
    handleLogout,
    handleMenu,
    isAuthorizedUser,
    isMobile,
    username,
    ...menuProps,
    ...headerHeightProps,
  };
};
