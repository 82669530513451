import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';

import type { Classname } from 'utils/types';

interface ICircularProgress extends Classname {
  progress: number;
  strokeWidth?: number;
}

export enum CIRCULAR_PROGRESS_CLASSNAMES {
  path = 'CircularProgressbar-path',
  trail = 'CircularProgressbar-trail',
  text = 'CircularProgressbar-text',
  background = 'CircularProgressbar-background',
}

const StyledCircularProgressbar = styled(CircularProgressbar)`
  ${({ theme: { colors } }) => css`
    .CircularProgressbar-path {
      stroke: ${colors.primary.light};
    }
    .CircularProgressbar-trail {
      stroke: ${colors.neutral.grey};
    }
  `}
`;

const CircularProgress: React.FC<ICircularProgress> = ({
  className,
  progress,
  strokeWidth = 8,
}) => (
  <StyledCircularProgressbar
    className={className}
    value={progress}
    strokeWidth={strokeWidth}
    styles={buildStyles({
      strokeLinecap: 'round',
    })}
  />
);

export default CircularProgress;
