import * as Yup from 'yup';

export const FEEDBACK_MAXIMUM_LENGTH = 1024;

export interface IFeedbackForm {
  feedback: string;
}

export const FeedbackSchema = Yup.object().shape({
  feedback: Yup.string()
    .max(
      FEEDBACK_MAXIMUM_LENGTH - 1,
      `Feedback cannot be longer than ${FEEDBACK_MAXIMUM_LENGTH - 1} characters`
    )
    .required('Please provide some feedback'),
});
