import { css, keyframes } from '@emotion/react';

import { embeddedRem } from './Wrapper';

export const menuIn = keyframes`
  ${css`
    0% {
      opacity: 0;
      padding: ${embeddedRem(10)} 0 0;
      height: 0;
    }

    40% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      padding: ${embeddedRem(21.5)} 0 0;
      height: 100vh;
    }
  `}
`;

export const menuOut = keyframes`
  ${css`
    0% {
      opacity: 1;
      padding: ${embeddedRem(21.5)} 0 0;
      height: 100vh;
    }

    60% {
      opacity: 0;
    }

    100% {
      opacity: 0;
      padding: ${embeddedRem(10)} 0 0;
      height: 0;
    }
  `}
`;
