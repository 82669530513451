import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Statsig from 'statsig-js';

import { chatSelectors } from 'features/Chat';
import { useDeferredOnboardingCallback } from 'features/Onboarding';
import { setCurrentJourney, setCurrentStep } from 'features/Onboarding/slice';
import { useFeedbackEvaluation } from 'utils/hooks/useFeedbackEvaluation';

import type { StepByJourney } from 'features/Onboarding/journeys';
import type { IResultsStep } from './ResultsStep';

export const useResultsStepService = ({
  taskEvaluationId,
  goToReview: baseGoToReview,
  canReview,
}: Pick<IResultsStep, 'taskEvaluationId' | 'canReview' | 'goToReview'>) => {
  const feedbackRef = useFeedbackEvaluation({ userTaskProgressId: taskEvaluationId });

  const reviewStatus = useSelector(chatSelectors.review.getStatus);
  const reviewScores = useSelector(chatSelectors.review.getScores);
  const dispatch = useDispatch();

  const normalizedReviewStatus = useMemo(
    () => (canReview ? reviewStatus : null),
    [reviewStatus, canReview]
  );

  useEffect(
    function startOnboardingTaskReviewOnSuccessfulLoad() {
      if (normalizedReviewStatus === 'success') {
        dispatch(setCurrentJourney('review'));
        dispatch(setCurrentStep('taskReview'));
      }
    },
    [normalizedReviewStatus]
  );

  const reviewOnboardingProceedStep: StepByJourney<'review'> | null = useMemo(
    () => (reviewScores.good < 100 ? 'improvement' : null),
    [reviewScores]
  );

  const reviewOnboardingCallback = useDeferredOnboardingCallback({
    journey: 'review',
    step: 'taskReview',
    remoteTrigger: 'highlight',
  });

  const goToReview = useCallback(() => {
    baseGoToReview();
    Statsig.logEvent('view_task_review');
    reviewOnboardingCallback();
  }, [baseGoToReview, reviewOnboardingCallback]);

  return {
    feedbackRef,
    goToReview,
    reviewOnboardingProceedStep,
    reviewStatus: normalizedReviewStatus,
  };
};
