import React, { useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { InterpolationWithTheme } from 'utils/types';
import { useMatchMedia } from 'theme/emotion';

import Tooltip, { AnchorAlignments } from '../../components/Tooltip';

import type { FlatJourneyProps, JourneyProps } from '../types';

const Content = styled.div`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p2}

    color: ${colors.neutral.white};
    max-width: 20rem;

    ${mq.md.down} {
      max-width: 21rem;
    }
  `}
`;

const outlineStyle: InterpolationWithTheme = ({ colors }) => css`
  background-color: ${colors.neutral.grey};

  border-radius: 0 0 1.5rem 1.5rem;
`;

const positionStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.down} {
    top: 0.5rem;
  }
`;

const containerStyle: InterpolationWithTheme = ({ mq }) => css``;

const arrowStyle = css`
  right: calc(25px - 1rem);
`;

const progressStyle = css`
  display: none;
`;

const ImprovementTooltip: React.FC<FlatJourneyProps> = props => {
  const { mq } = useTheme();

  const isMobile = useMatchMedia([mq.md.down]);

  const anchorAlignments = useMemo(() => {
    const breakpoint = isMobile ? 'mobile' : 'desktop';

    const anchorAlignmentsMap: Record<typeof breakpoint, AnchorAlignments> = {
      desktop: { anchor: 'right', alignment: 'top' },
      mobile: { anchor: 'bottom', alignment: 'left' },
    };

    return anchorAlignmentsMap[breakpoint];
  }, [isMobile]);

  return (
    <Tooltip
      {...(props as JourneyProps)}
      {...anchorAlignments}
      classNames={{
        outline: outlineStyle,
        position: positionStyle,
        container: containerStyle,
        arrow: arrowStyle,
        progress: progressStyle,
      }}
      proceedElement="Got it"
    >
      <Content>Suggested improved answer to sound like a native speaker</Content>
    </Tooltip>
  );
};

export default ImprovementTooltip;
