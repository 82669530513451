import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { FONT_FAMILIES } from 'theme/emotion/typography';
import Modal from 'molecules/Modal';
import CancelButton from 'atoms/CancelButton';
import { InterpolationWithTheme } from 'utils/types';

interface IWordDefinition {
  isOpen: boolean;
  handleClose: () => void;
  word: string;
  definition: string;
}

const StyledModal = styled(Modal)`
  align-items: center;
  justify-items: center;
`;

const modalContent: InterpolationWithTheme = ({ colors, mq }) => css`
  position: relative;
  display: grid;
  grid-auto-rows: min-content;
  justify-items: flex-start;
  row-gap: 2rem;
  width: 68rem;
  background-color: ${colors.neutral.white};
  padding: 6rem 5rem;
  overflow-y: auto;
  border-radius: 4rem;

  ${mq.md.down} {
    align-self: flex-end;
    grid-template-rows: 1fr repeat(5, min-content);
    padding: 4rem 3rem 6rem;
    width: 100%;
    border-radius: 3rem 3rem 0 0;

    &::before {
      content: '';
    }
  }
`;

const StyledCancelButton = styled(CancelButton)`
  ${({ theme: { colors, utils } }) => css`
    position: absolute;
    top: 3rem;
    right: 3rem;
    background: initial;
    border: initial;
    cursor: pointer;

    & > svg {
      fill: ${utils.alphaHex(colors.primary.dark, 55)};
      width: 3rem;
      height: 3rem;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { tp } }) => css`
    ${tp.h1};

    display: grid;
    text-align: center;
    margin: 0 0 3rem;
  `}
`;

const Subtitle = styled.h3`
  ${({ theme: { colors, utils } }) => css`
    text-align: center;
    color: ${utils.alphaHex(colors.primary.dark, 55)};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 1.5rem;
    margin: 0;
  `}
`;

const Definition = styled.h6`
  ${({ theme: { tp } }) => css`
    ${tp.p1};

    margin: 0;
  `}
`;

const WordDefinition: React.FC<IWordDefinition> = ({ isOpen, handleClose, word, definition }) => {
  return (
    <StyledModal isOpen={isOpen} onClick={handleClose} classNames={{ modal: modalContent }}>
      <StyledCancelButton onClick={handleClose} />
      <Title>{word}</Title>
      <Subtitle>Definition</Subtitle>
      <Definition>{definition}</Definition>
    </StyledModal>
  );
};

export default WordDefinition;
