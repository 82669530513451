import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getNextPaymentDate,
  getPaymentAmount,
  getPaymentFrequencyMonths,
  getStatus,
} from 'features/User/selectors';
import { setCancellationStep } from 'features/User/slice';

export const useProfileManageSubscriptionService = () => {
  const paymentFrequencyMonths = useSelector(getPaymentFrequencyMonths);
  const paymentAmount = useSelector(getPaymentAmount);
  const nextPaymentDate = useSelector(getNextPaymentDate);
  const status = useSelector(getStatus);

  const dispatch = useDispatch();

  const plan = useMemo(() => `${paymentFrequencyMonths} month plan`, [paymentFrequencyMonths]);

  const paymentAmountPerFrequencyMonth = useMemo(() => {
    switch (nextPaymentDate) {
      case 'Invalid date':
      case '': {
        return ' ';
      }
      default: {
        return `${paymentAmount}/${paymentFrequencyMonths} month`;
      }
    }
  }, [paymentFrequencyMonths, paymentAmount, nextPaymentDate]);

  const nextBillingDate = useMemo(() => {
    switch (nextPaymentDate) {
      case '':
      case 'Invalid date': {
        return '';
      }
      default: {
        return `Next billing ${nextPaymentDate}`;
      }
    }
  }, [nextPaymentDate]);

  const canCancel = useMemo(
    () => status !== 'non_renewing' && !!nextBillingDate,
    [nextBillingDate, status]
  );

  const handleCancelPlan = useCallback(() => dispatch(setCancellationStep('prompt')), [dispatch]);

  return {
    canCancel,
    plan,
    nextBillingDate,
    paymentAmountPerFrequencyMonth,
    handleCancelPlan,
  };
};
