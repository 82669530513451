import { css, keyframes } from '@emotion/react';

export const ANIMATION_DELAY_MS = 500;

export const animateZoomOutIn = keyframes`
    ${css`
      0% {
        font-size: inherit;
      }
      50% {
        font-size: 6rem;
      }
      100% {
        font-size: inherit;
      }
    `}
`;

export const composeAnimateFadeInTextColor = ({
  inactiveColor,
  activeColor,
}: {
  inactiveColor: string;
  activeColor: string;
}) => keyframes`
  ${css`
    0% {
      color: ${inactiveColor};
    }
    50% {
      color: ${activeColor};
    }
    100% {
      color: ${activeColor};
    }
  `}
`;

export const composeAnimatePulseScaleWithBackground = ({
  backgroundColor,
}: {
  backgroundColor: string;
}) => keyframes`
  ${css`
    0% {
      transform: scale(1);
      background-color: transparent;
    }
    50% {
      transform: scale(1.5);
      background-color: ${backgroundColor};
      border-color: transparent;
    }
    100% {
      transform: scale(1);
      background-color: ${backgroundColor};
      border-color: transparent;
    }
  `}
`;

export const animateIconFadeIn = keyframes`
  ${css`
    0% {
      fill: transparent;
    }
    50% {
      fill: white;
    }
    100% {
      fill: white;
    }
  `}
`;
