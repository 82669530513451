import { progressiveWebAppSlice } from './slice';
import { createAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

const promptProgressiveWebAppInstallation = createAction(
  `${NAME}/promptProgressiveWebAppInstallation`
);

export default { ...progressiveWebAppSlice.actions, promptProgressiveWebAppInstallation };
