import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedParams, useTypedState } from 'react-router-typesafe-routes/dom';

import { useLessonTasks } from 'features/TaskSelection/queries';
import { ROUTES } from 'navigation/constants';

export const useTaskService = () => {
  const { id: taskId } = useTypedParams(ROUTES.TASK);
  const { lessonId } = useTypedState(ROUTES.TASK);

  const navigate = useNavigate();

  useEffect(() => {
    if (!lessonId) {
      navigate(ROUTES.HOME.path);
    }
  }, [lessonId]);

  const lessonTasksData = useLessonTasks(lessonId);


  const task = useMemo(
    () =>
      (!lessonTasksData.isLoading &&
        lessonTasksData.data &&
        lessonTasksData.data.length &&
        lessonTasksData.data.find(({ id }) => taskId === id)) ||
      null,
    [lessonTasksData, taskId]
  );

  useEffect(() => {
    if (!lessonTasksData.isLoading) {
      if (lessonTasksData.data?.length) {
        const currentTaskIndex = lessonTasksData.data.findIndex(({ id }) => id === taskId);

        if (currentTaskIndex >= 0) {
        } else {
          if (lessonId) {
            navigate(ROUTES.LESSON.buildPath({ id: lessonId }));
          } else {
            navigate(ROUTES.HOME.path);
          }
        }
      } else {
        navigate(ROUTES.HOME.path);
      }
    }
  }, [lessonTasksData]);

  return {
    isLoading: lessonTasksData.isLoading,
    taskId,
    lessonId: lessonId as string,
    title: task?.name ?? 'New word',
    maxPoints: task?.max_points ?? 0,
    taskType: task?.type ?? '',
  };
};
