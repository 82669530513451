import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import themeColors from 'theme/emotion/colors';
import { FONT_FAMILIES } from 'theme/emotion/typography';
import { filterProps } from 'utils/helpers';

export type TButton = Omit<JSX.IntrinsicElements['button'], 'color'> &
  ButtonInterpolationProps & {
    loading?: boolean;
  };

type ButtonInterpolationProps = {
  color?: keyof Pick<typeof themeColors, 'primary' | 'secondary' | 'tertiary'>;
  variant?: 'contained' | 'outlined';
  inverted?: boolean;
  transitionText?: boolean;
  bare?: boolean;
} & (
  | ({ variant?: 'contained' } & (
      | { inverted: true; bare?: true }
      | { inverted?: false; bare?: never }
    ))
  | { variant: 'outlined'; inverted?: never }
);

const StyledButton = styled(
  'button',
  filterProps('color', 'variant', 'inverted', 'transitionText', 'bare')
)<ButtonInterpolationProps>`
  ${({
    theme: { colors, durations, mq, utils },
    color = 'primary',
    variant = 'contained',
    inverted = false,
    transitionText = false,
    bare = false,
  }) => {
    const isContained = variant === 'contained';
    const baseColor = colors[color].light;
    const neutralColor = colors.neutral.white;

    const backgroundColor = inverted || !isContained ? neutralColor : baseColor;
    const foregroundColor = inverted || !isContained ? baseColor : neutralColor;

    return css`
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 1rem;
      border-style: solid;
      border-color: ${utils.alphaHex(
        isContained ? backgroundColor : foregroundColor,
        isContained ? 0 : 100
      )};
      border-radius: 4.5rem;
      color: ${foregroundColor};
      background-color: ${backgroundColor};
      text-align: center;
      font-size: 1.75rem;
      line-height: 1.25;
      font-weight: ${FONT_FAMILIES.Poppins.semiBold};
      cursor: pointer;
      transition: all ${durations.regular};
      user-select: none;

      ${mq.hover} {
        ${isContained
          ? css`
              border-color: ${foregroundColor};
            `
          : ''}

        &:hover {
          ${isContained
            ? css`
                border-color: ${backgroundColor};
              `
            : css`
                background-color: ${utils.alphaHex(foregroundColor, 5)};
              `}

          ${transitionText
            ? css`
                color: ${utils.alphaHex(foregroundColor, 70)};

                & > svg {
                  fill: ${utils.alphaHex(foregroundColor, 70)};
                }
              `
            : ''}
        }
      }

      &:disabled {
        opacity: 0.25;
        pointer-events: none;
      }

      &:active {
        background-color: ${utils.alphaHex(
          isContained ? backgroundColor : foregroundColor,
          isContained ? 90 : 15
        )};

        ${isContained
          ? css`
              border-color: ${utils.alphaHex(backgroundColor, 90)};
            `
          : ''}

        ${transitionText
          ? css`
              color: ${utils.alphaHex(foregroundColor, 90)};

              & > svg {
                fill: ${utils.alphaHex(foregroundColor, 90)};
              }
            `
          : ''}
      }

      & > svg {
        width: 2.5rem;
        height: 2.5rem;
        fill: ${foregroundColor};
        transition: all ${durations.regular};
      }

      ${mq.md.down} {
        & > svg {
          width: 2.25rem;
          height: 2.25rem;
        }
      }

      ${!bare
        ? css`
            border-width: ${isContained ? 0.375 : 0.25}rem;
            padding: 0 5rem;
            min-height: 6rem;

            ${mq.md.down} {
              border-width: 0.25rem;
              padding: 0 3rem;
              min-height: 5.5rem;
            }
          `
        : css`
            padding: 0;
            border-width: 0;
          `}
    `;
  }}
`;

const Button = forwardRef<HTMLButtonElement, TButton>((props, ref) => (
  <StyledButton ref={ref} {...props} />
));

export default Button;
