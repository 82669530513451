import { createSlice } from 'utils/redux';

import { NAME } from './constants';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { IAnalytics } from './types';

const initialState: IAnalytics = {
  statsigInitialized: false,
};

export const analyticsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setStatsigInitialized: (state, action: PayloadAction<IAnalytics['statsigInitialized']>) => {
      state.statsigInitialized = action.payload;
    },
  },
});
