import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { animateScroll } from 'react-scroll';

import selectors from 'features/Chat/selectors';
import { getConversationMethod } from 'features/ConversationControl/selectors';
import { getCurrentRoute } from 'features/Auth/selectors';
import { ROUTES } from 'navigation/constants';
import { matchPath } from 'utils/helpers';
import { analyticsSelectors } from 'app/Analytics';
import Statsig from 'statsig-js';

const useTranscribingState = () => {
  const pushToTalkState = useSelector(selectors.getPushToTalkState);
  const handsFreeState = useSelector(selectors.getHandsFreeState);

  const conversationMethod = useSelector(getConversationMethod);

  if (conversationMethod === 'hands_free') {
    return handsFreeState;
  } else {
    return pushToTalkState;
  }
};

export const useLegacyTranscribedTextService = ({
  id,
  sequenceNumber,
}: {
  id: string;
  sequenceNumber: number;
}) => {
  const currentChunks = useSelector(
    selectors.getSequenceChunks({
      id: id,
      sequenceNumber: sequenceNumber,
      entity: 'bot',
    })
  );

  const currentMessage = useSelector(
    selectors.getSequenceMessage({
      id,
      sequenceNumber,
      entity: 'bot',
    })
  );

  const fullMessageRef = useRef<null | string>(null);
  useEffect(
    function syncFullMessageWhenMarked() {
      if (fullMessageRef.current === null) {
        const hasMark = currentChunks.some(([, { isLast }]) => isLast);
        if (hasMark) {
          fullMessageRef.current = currentMessage;
        }
      }
    },
    [currentChunks, currentMessage]
  );

  const state = useTranscribingState();

  const currentRoute = useSelector(getCurrentRoute);
  const enabledRoutes = [ROUTES.CHAT.path, ROUTES.TASK.path];

  const shouldScrollRef = useRef(true);
  useEffect(
    function syncScrollTriggerWithRoute() {
      shouldScrollRef.current = enabledRoutes.some(path => matchPath(path, currentRoute));
    },
    [currentRoute, enabledRoutes]
  );

  const textRenderedRef = useRef(true);
  const [renderKey, setRenderKey] = useState(0);
  const [effectKey, setEffectKey] = useState(0);
  const [shouldRenderIndicator, setShouldRenderIndicator] = useState<boolean>(true);
  const [sequence, setSequence] = useState<(number | string | (() => void))[]>([
    '',
    0,
    '',
    () => {
      textRenderedRef.current = true;
    },
  ]);

  useEffect(
    function updateSequenceBasedOnCurrentMessageChanges() {
      if (textRenderedRef.current) {
        textRenderedRef.current = false;

        setSequence(currentSequence => {
          const current = currentSequence[2] as string;

          return [
            current,
            0,
            currentMessage,
            () => {
              textRenderedRef.current = true;

              if (shouldScrollRef.current) animateScroll.scrollToBottom();

              if (currentMessage && currentMessage !== current) {
                setEffectKey(key => key + 1);
              }
              if (currentMessage && currentMessage === fullMessageRef.current) {
                setShouldRenderIndicator(false);
              }
            },
          ];
        });
        setRenderKey(key => key + 1);
      }
    },
    [currentMessage, effectKey]
  );

  return { currentMessage, shouldRenderIndicator, state, renderKey, sequence };
};

export const useTranscribedTextService = ({
  id,
  sequenceNumber,
}: {
  id: string;
  sequenceNumber: number;
}) => {
  const currentChunks = useSelector(
    selectors.getSequenceChunks({
      id: id,
      sequenceNumber: sequenceNumber,
      entity: 'bot',
    })
  );

  const [speed, setSpeed] = useState(30);

  const currentChunksRef = useRef(JSON.stringify(currentChunks));

  const [currentMessage, setCurrentMessage] = useState('');
  useEffect(
    function updateCurrentMessageAfterCurrentPlayingChunkAudioFinishes() {
      const checkSum = JSON.stringify(currentChunks);
      if (checkSum !== currentChunksRef.current) {
        currentChunksRef.current = checkSum;

        const lastFinishedChunkIndex = currentChunks.reduce(
          (acm, [, { playedState }], currentIndex) => {
            if (playedState === 'finished') {
              return currentIndex;
            }

            return acm;
          },
          0
        );

        const messageWithNextChunk = currentChunks.reduce((acm, [, { text }], currentIndex) => {
          if (currentIndex <= lastFinishedChunkIndex + 1) {
            return acm + text;
          }

          return acm;
        }, '');

        setCurrentMessage(messageWithNextChunk);
      }
    },
    [currentChunks]
  );

  const fullMessage = useMemo(
    () => currentChunks.reduce((acm, [, { text }]) => acm + text, ''),
    [currentChunks]
  );
  const fullMessageRef = useRef<null | string>(null);
  useEffect(
    function syncFullMessageWhenMarked() {
      if (fullMessageRef.current === null) {
        const hasMark = currentChunks.some(([, { isLast }]) => isLast);
        if (hasMark) {
          fullMessageRef.current = fullMessage;
        }
      }
    },
    [currentChunks, fullMessage]
  );

  const state = useTranscribingState();

  const currentRoute = useSelector(getCurrentRoute);
  const enabledRoutes = [ROUTES.CHAT.path, ROUTES.TASK.path];

  const shouldScrollRef = useRef(true);
  useEffect(
    function syncScrollTriggerWithRoute() {
      shouldScrollRef.current = enabledRoutes.some(path => matchPath(path, currentRoute));
    },
    [currentRoute, enabledRoutes]
  );

  const interruptedRef = useRef(false);
  const [interrupted, setInterrupted] = useState(false);

  useEffect(
    function setInterruptedWhenStateChanges() {
      if (
        !interruptedRef.current &&
        ['pending', 'listening', 'transcribing'].includes(state as string)
      ) {
        interruptedRef.current = true;
        setInterrupted(true);
      }
    },
    [state]
  );

  const textRenderedRef = useRef(true);
  const [renderKey, setRenderKey] = useState(0);
  const [effectKey, setEffectKey] = useState(0);
  const [shouldRenderIndicator, setShouldRenderIndicator] = useState<boolean>(true);
  const [sequence, setSequence] = useState<(number | string | (() => void))[]>([
    '',
    0,
    '',
    () => {
      textRenderedRef.current = true;
    },
  ]);

  useEffect(
    function updateSequenceBasedOnCurrentMessageChanges() {
      if (textRenderedRef.current && !interrupted) {
        textRenderedRef.current = false;

        setSequence(currentSequence => {
          const current = currentSequence[2] as string;

          return [
            current,
            0,
            currentMessage,
            () => {
              textRenderedRef.current = true;

              if (shouldScrollRef.current) animateScroll.scrollToBottom();

              if (currentMessage && currentMessage !== current) {
                setEffectKey(key => key + 1);
              }
              if (currentMessage && currentMessage === fullMessageRef.current) {
                setShouldRenderIndicator(false);
              }
            },
          ];
        });
        setRenderKey(key => key + 1);
      }
    },
    [currentMessage, effectKey, interrupted]
  );

  const usedShortCircuitRef = useRef(false);
  useEffect(
    function shortCircuitTextAnimation() {
      if (interrupted && !usedShortCircuitRef.current && fullMessage) {
        setShouldRenderIndicator(false);

        if (shouldScrollRef.current) animateScroll.scrollToBottom();

        setSequence([
          currentMessage,
          () => {
            if (shouldScrollRef.current) animateScroll.scrollToBottom();
          },
          fullMessage,
          () => {
            textRenderedRef.current = true;
            if (shouldScrollRef.current) animateScroll.scrollToBottom();
          },
        ]);

        setSpeed(1);
        setRenderKey(key => key + 1);
        usedShortCircuitRef.current = !!fullMessageRef.current;
      }
    },
    [interrupted, currentChunks, currentMessage, fullMessage]
  );

  const [shouldAddDelay, setShouldAddDelay] = useState(false);
  const isStatsigInitialized = useSelector(analyticsSelectors.isStatsigInitialized);

  useEffect(() => {
    if (isStatsigInitialized) {
      const experimentName = 'exp22_tts_improvement';
      const gate = Statsig.checkGate(`${experimentName}_gate`);

      if (gate) {
        const groupName = Statsig.getExperiment(experimentName).getGroupName();

        if (groupName === 'test_b') {
          setShouldAddDelay(true);
        }
      }
    }
  }, [isStatsigInitialized]);

  const legacyState = useLegacyTranscribedTextService({ id, sequenceNumber });

  if (shouldAddDelay) {
    return { fullMessage, shouldRenderIndicator, state, renderKey, sequence, speed };
  } else {
    return {
      ...legacyState,
      fullMessage: legacyState.currentMessage,
      speed: 30,
    };
  }
};

export const useTranscribedTextServiceAudio = ({
  id,
  sequenceNumber,
}: {
  id: string;
  sequenceNumber: number;
}) => {
  const currentChunks = useSelector(
    selectors.getSequenceChunks({
      id: id,
      sequenceNumber: sequenceNumber,
      entity: 'bot',
    })
  );

  const [currentMessages, setCurrentMessages] = useState<string[]>([]);
  const [currentDurations, setCurrentDurations] = useState<number[]>([]);

  const currentChunksRef = useRef(JSON.stringify(currentChunks));

  useEffect(() => {
    const checkSum = JSON.stringify(currentChunks);
    if (checkSum !== currentChunksRef.current) {
      currentChunksRef.current = checkSum;
      setCurrentMessages(currentChunks.map(([, { text }]) => text));
      setCurrentDurations(
        currentChunks.reduce((acm, [, { duration }]) => {
          if (duration) return [...acm, duration];
          return acm;
        }, [] as number[])
      );
    }
  }, [currentChunks]);

  const state = useTranscribingState();
  const textRenderedRef = useRef(false);
  const [renderKey, setRenderKey] = useState(0);
  const [effectKey, setEffectKey] = useState(0);
  const [showIndicator, setShowIndicator] = useState(true);
  const [shouldRender, setShouldRender] = useState<boolean>(true);
  const [sequence, setSequence] = useState<(number | string | (() => void))[]>([
    '',
    '',
    () => {
      textRenderedRef.current = true;
    },
  ]);
  const [speed, setSpeed] = useState(180);

  const indexRef = useRef<number>(-1);

  useEffect(
    function setShouldRenderWorker() {
      setShouldRender(true);
    },
    [effectKey]
  );

  useEffect(
    function triggerSequenceUpdateWhenMessagesComeInAsync() {
      setEffectKey(key => key + 1);
    },
    [currentDurations.length]
  );

  const interruptedRef = useRef(false);
  const [interrupted, setInterrupted] = useState(false);

  useEffect(
    function setInterruptedWhenStateChanges() {
      if (
        !interruptedRef.current &&
        ['pending', 'listening', 'transcribing'].includes(state as string)
      ) {
        interruptedRef.current = true;
        setInterrupted(true);
      }
    },
    [state]
  );

  useEffect(
    function updateSequenceBasedOnCurrentMessageChanges() {
      if (textRenderedRef.current && !interrupted) {
        const nextIndex = indexRef.current + 1;

        if (currentMessages.length > nextIndex && currentDurations.length > nextIndex) {
          textRenderedRef.current = false;
          indexRef.current = nextIndex;

          const previousMessage = currentChunks.reduce((acm, [, { text }], index) => {
            if (index < nextIndex) {
              return acm + text;
            }

            return acm;
          }, '');

          const nextMessage = currentChunks.reduce((acm, [, { text }], index) => {
            if (index <= nextIndex) {
              return acm + text;
            }

            return acm;
          }, '');

          setSequence(currentSequence => {
            const current = currentSequence[1] as string;

            return [
              previousMessage,
              nextMessage,
              () => {
                textRenderedRef.current = true;
                if (nextMessage !== current) {
                  setEffectKey(key => key + 1);
                }
                if (currentChunks?.[nextIndex]?.[1]?.isLast) {
                  setShowIndicator(false);
                }
              },
            ];
          });

          const [, { text, duration }] = currentChunks[nextIndex];

          const newSpeed = (duration ?? 0) / text.length;
          setSpeed(newSpeed);
          setRenderKey(key => {
            return key + 1;
          });
        }
      }
    },
    [currentMessages, currentDurations, interrupted, effectKey, currentChunks]
  );

  const usedShortCircuitRef = useRef(false);
  useEffect(
    function shortCircuitTextAnimation() {
      if (interrupted && !usedShortCircuitRef.current) {
        const previousMessage = currentChunks.reduce((acm, [, { text }], index) => {
          if (index <= indexRef.current) {
            return acm + text;
          }

          return acm;
        }, '');

        const fullMessage = currentChunks.reduce((acm, [, { text }]) => acm + text, '');

        setSequence([
          previousMessage,
          fullMessage,
          () => {
            textRenderedRef.current = true;
            setShowIndicator(false);
          },
        ]);
        setSpeed(2);
        setRenderKey(key => key + 1);
        usedShortCircuitRef.current = true;
      }
    },
    [interrupted, currentChunks]
  );

  return { speed, shouldRender, state, renderKey, sequence, showIndicator };
};
