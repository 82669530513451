const colors = {
  neutral: {
    black: '#000000',
    white: '#ffffff',
    grey: '#f3f6f8',
  },
  primary: {
    light: '#7400f6',
    lightDark: '#905cff',
    main: '#391f4b',
    dark: '#2b143b',
  },
  secondary: {
    light: '#71c0e2',
    lightDark: '#71c0e2',
    main: '#41B8DE',
    dark: '#bcfdda',
  },
  tertiary: {
    light: '#bcfdda',
    lightDark: '#bcfdda',
    main: '#bcfdda',
    dark: '#71c0e2',
  },
  error: {
    main: '#E93C51',
  },
  warning: {
    main: '#f39911',
  },
  gradients: {
    orangePink: 'linear-gradient(#f4c6b9, #d770c6)',
    greenBlue: 'linear-gradient(#bcfdda, #71c0e2)',
    blueGreen: 'linear-gradient(#71c0e2, #bcfdda)',
    single: {
      red: '#ea2400',
      orange: '#ff8623',
      pink: '#d770c6',
      green: '#bcfdda',
      blue: '#71c0e2',
    },
  },
};

export default colors;
