import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import type { Classname } from 'utils/types';
import type { PositionProps } from './types';

interface IOutline extends Classname {
  id: string;
  position: PositionProps;
}

const Container = styled.div`
  ${({ theme: { durations, depths } }) => css`
    position: absolute;
    transition: all ${durations.regular};
    z-index: ${depths.priority};

    && {
      padding: 0;
    }
  `}
`;

const Outline: React.FC<IOutline> = ({ id, position, className }) => (
  <Container id={id} {...position} style={position} className={className} />
);

export default Outline;
