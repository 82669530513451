import { call, take } from 'typed-redux-saga/macro';

import { camelObjectToSnake } from 'utils/camelToSnake';
import { snakeObjectToCamel } from 'utils/snakeToCamel';

import initializeServerEventsChannel from './initializeServerEventsChannel';

import type { getPayloadFromTypeOrTypes, mergeEvents, ServerEvents, SocketEvent } from './types';

function* startSocket<
  CustomClientEvents extends SocketEvent = never,
  CustomServerEvents extends SocketEvent = never
>(url: string) {
  type ExtendedServerEvents = mergeEvents<ServerEvents, CustomServerEvents>;
  type ClientEvents = Utils.snakeObjectToCamel<CustomClientEvents>;

  const socket = new WebSocket(url);
socket.readyState
  const broadcastChannel = yield* call(initializeServerEventsChannel<ExtendedServerEvents>, socket);

  function* on<
    eventType extends ExtendedServerEvents['type'] | ExtendedServerEvents['type'][] =
      | ExtendedServerEvents['type']
      | ExtendedServerEvents['type'][],
    payload = getPayloadFromTypeOrTypes<ExtendedServerEvents, eventType>,
    typeUnion = eventType extends ExtendedServerEvents['type']
      ? eventType
      : eventType extends ExtendedServerEvents['type'][]
      ? eventType extends (infer eventUnion)[]
        ? eventUnion
        : never
      : never,
    transformedPayload = typeUnion extends 'raw' ? payload : Utils.snakeObjectToCamel<payload>,
    callbackWithoutPayload = (type: typeUnion) => void | Generator<unknown | void>,
    callbackWithPayload = (
      type: typeUnion,
      payload: transformedPayload
    ) => void | Generator<unknown | void>,
    callback = payload extends void
      ? callbackWithoutPayload
      : payload extends never
      ? callbackWithoutPayload
      : callbackWithPayload
  >(type: eventType, callback?: callback) {
    try {
      while (true) {
        const event = yield* take(broadcastChannel, type);
        const eventType = event.type as eventType;

        if (callback) {
          if ('payload' in event) {
            const eventPayload = event.payload as payload;

            const transformedPayload = (
              eventType === 'raw'
                ? eventPayload
                : snakeObjectToCamel(eventPayload as Record<string, unknown>)
            ) as transformedPayload;

            yield* call(
              callback as (
                type: eventType,
                payload: transformedPayload
              ) => void | Generator<unknown | void>,
              eventType,
              transformedPayload
            );
          } else {
            yield* call(
              callback as (type: eventType) => void | Generator<unknown | void>,
              eventType
            );
          }
        }
      }
    } finally {
    }
  }

  const send = (frame: ClientEvents | ArrayBufferLike | Blob | ArrayBufferView) => {
    const isBlob = frame instanceof Blob;
    const isArrayBuffer = frame instanceof ArrayBuffer;
    const isArrayBufferView =
      frame instanceof Object &&
      'buffer' in frame &&
      'byteLength' in frame &&
      'byteOffset' in frame &&
      !('type' in frame);

    const isBinary = isBlob || isArrayBuffer || isArrayBufferView;

    if (isBinary) {
      return socket.send(frame);
    } else if ('type' in frame) {
      const snakeCaseFrame = camelObjectToSnake(frame) as CustomClientEvents;
      return socket.send(JSON.stringify(snakeCaseFrame));
    }
  };

  const close = () => socket.close();

  return { on, send, close };
}

export default startSocket;
