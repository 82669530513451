import React from 'react';
import { useField } from 'formik';
import { styled } from '@mui/material';
import TextField, { TextFieldProps } from '@mui/material/TextField';

const StyledInput = styled(TextField)({
  marginBottom: 12,
});

export type TFieldInput = TextFieldProps & {
  name: string;
};

const FieldInput: React.FC<TFieldInput> = ({ name, ...props }) => {
  const [field, { error, touched }] = useField(name);

  return (
    <StyledInput {...field} error={touched && !!error} helperText={touched && error} {...props} />
  );
};

export default FieldInput;
