import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

export interface ILogin {
  email: string;
  password: string;
  error: string;
  isLoading: boolean;
}

const initialState: ILogin = {
  email: '',
  password: '',
  error: '',
  isLoading: false,
};

export const loginSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setEmail, setPassword, setError, setLoading, resetState } =
  loginSlice.actions;
