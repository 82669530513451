import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import Button from 'atoms/Button';
import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import { ROUTES } from 'navigation/constants';

import Feedback, { IFeedback } from './Feedback';

interface ITaskEvaluation extends IFeedback {
  lessonId: string;
  handleClose: () => void;
}

const Container = styled.div`
  ${({ theme: { colors, mq } }) => css`
    position: relative;
    display: grid;
    grid-auto-rows: min-content;
    justify-items: center;
    row-gap: 3rem;
    width: 68rem;
    padding: 6rem;
    overflow-y: auto;
    background-color: ${colors.neutral.grey};

    ${mq.md.down} {
      grid-template-rows: 1fr min-content 1fr min-content min-content;
      padding: 15rem 3rem 10.5rem;
      width: 100%;
      height: 100%;

      &::before {
        content: '';
      }
    }
  `}
`;

const Content = styled.div`
  display: grid;
  justify-self: stretch;
  justify-items: center;
  row-gap: 1rem;
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1};

    display: grid;
    row-gap: 1rem;
    text-align: center;
    margin: 0;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const TaskButton = styled(Button)`
  ${({ theme: { mq } }) => css`
    ${mq.md.down} {
      grid-row: 4 / span 1;
    }
  `}
`;

const ContinueButton = styled(Button)`
  ${({ theme: { mq } }) => css`
    background-color: transparent;
    margin-top: -0.5rem;

    ${mq.md.down} {
      grid-row: 5 / span 1;
    }
  `}
`;

const TaskEvaluation: React.FC<ITaskEvaluation> = ({
  taskName,
  lessonId,
  pointsEarned,
  handleClose,
  taskEvaluationId,
}) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <Title>
          <span>🎉</span>
          Congratulations
        </Title>
        <Feedback
          pointsEarned={pointsEarned}
          taskName={taskName}
          taskEvaluationId={taskEvaluationId}
        />
      </Content>
      <TaskButton
        onClick={() => {
          handleClose();
          navigate(ROUTES.LESSON.buildPath({ id: lessonId }));
        }}
      >
        Proceed to the next task
        <ArrowRightSvg />
      </TaskButton>
      <ContinueButton onClick={handleClose} variant="outlined" bare transitionText>
        <ArrowLeftSvg />
        Continue conversation
      </ContinueButton>
    </Container>
  );
};

export default TaskEvaluation;
