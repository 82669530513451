import { useSelector } from 'react-redux';
import { getStreakCount, isDailyStreakComplete } from 'features/Streaks/selectors';
import { useMemo } from 'react';
import { IStreakIndicator } from 'features/Streaks/components/StreakIndicator/StreakIndicator';

export const useStreakIndicatorService = ({
  overrideCount,
}: Pick<IStreakIndicator, 'overrideCount'>) => {
  const dailyStreakCompleted = useSelector(isDailyStreakComplete);
  const streak = useSelector(getStreakCount);

  const isLoading = useMemo(() => dailyStreakCompleted === null, [dailyStreakCompleted]);

  return {
    isLoading,
    dailyStreakCompleted,
    streak: overrideCount ? overrideCount : streak,
  };
};
