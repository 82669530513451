import { channel } from 'redux-saga';
import { call, cancel, fork, put, select, take } from 'typed-redux-saga/macro';

import { getUserId } from 'features/Auth/selectors';
import { camelObjectToSnake } from 'utils/camelToSnake';

import { sttSocket } from '../sttSocket';

import { PRONUNCIATION_ERRORS } from './constants';

import type {
  ClientEvents,
  onCallback,
  onCallbackParameters,
  OnResultCallback,
  PronunciationSocketInstanceInfo,
  ServerEvents,
} from './types';

function* pronunciationSocket({ id, sequenceNumber, sentence }: PronunciationSocketInstanceInfo) {
  const userId = yield* select(getUserId);

  if (!userId) throw new Error(PRONUNCIATION_ERRORS.NO_USER_ID);

  const { on, send, sendStart, onTranscript, onMark, ...restSttSocket } = yield* call(
    sttSocket<ClientEvents, ServerEvents>,
    'pronunciation' as const
  );

  function* onResult(callback: OnResultCallback) {
    yield* call<onCallbackParameters<'pronunciation_result'>, onCallback<'pronunciation_result'>>(
      on,
      'pronunciation_result',
      function* (type, { audioFilename, evaluationJson }) {
        const normalizedEvaluationJson = {
          ...evaluationJson,
          result: camelObjectToSnake(evaluationJson.result),
        };

        yield* call(callback, {
          audioFilename,
          evaluationJson: normalizedEvaluationJson,
        });
      }
    );
  }

  const startChannel = yield* call(channel<'ready'>);

  const startTask = yield* fork<onCallbackParameters<'open'>, onCallback<'open'>>(
    on,
    'open',
    function* () {
      sendStart({
        id,
        sequenceNumber,
      });
      send({ type: 'pronunciation_request', payload: { userId, sentence } });
      yield* put(startChannel, 'ready');
    }
  );

  yield* take(startChannel);
  yield* cancel(startTask);

  return {
    onResult,
    ...restSttSocket,
  };
}

export default pronunciationSocket;
