import { useEffect, useLayoutEffect, useRef } from 'react';

import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentRoute } from 'features/Auth/slice';
import Chat from 'features/Chat/components';
import Login from 'features/Login/components';
import Registration from 'features/Registration/components';
import Leaderboard from 'features/Leaderboard/components';
import NotAuthorizedTier from 'features/Auth/components/NotAuthorizedTier';
import ForgotPassword from 'features/ForgotPassword/components';
import Lessons from 'features/Lessons/components';
import TaskSelection from 'features/TaskSelection/components';
import Task from 'features/Task/components';

import ProtectedRoute from 'layout/ProtectedRoute';
import PublicRoute from 'layout/PublicRoute';

import PurchaseRoute from 'layout/PurchaseRoute';
import { getCurrentRoute, isInitialAuthDone } from 'features/Auth/selectors';
import { userRouter } from 'features/User';
import { roleplaysRouter } from 'features/Roleplays';

import { ROUTES } from './constants';

const router = createBrowserRouter([
  {
    element: <PurchaseRoute />,
    children: [
      {
        path: ROUTES.NO_SUBSCRIPTION.path,
        element: <NotAuthorizedTier />,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: ROUTES.HOME.path,
        element: <Lessons />,
      },
      {
        path: ROUTES.LESSON.path,
        element: <TaskSelection />,
      },
      {
        path: ROUTES.TASK.path,
        element: <Task />,
        errorElement: <Navigate to={ROUTES.HOME.path} replace />,
      },
      {
        path: ROUTES.CHAT.path,
        element: <Chat />,
      },
      {
        path: ROUTES.LEADERBOARD.path,
        element: <Leaderboard />,
      },
      ...roleplaysRouter,
      ...userRouter,
    ],
  },
  {
    element: <PublicRoute />,
    children: [
      {
        path: ROUTES.LOGIN.path,
        element: <Login />,
      },
      {
        path: ROUTES.REGISTRATION.path,
        element: <Registration />,
      },
      {
        path: ROUTES.FORGOT_PASSWORD.path,
        element: <ForgotPassword />,
      },
    ],
  },
]);

const NavigationWrapper = () => {
  const isAuthDone = useSelector(isInitialAuthDone);
  const currentRoute = useSelector(getCurrentRoute);
  const dispatch = useDispatch();
  const currentRouteRef = useRef<string>(currentRoute);

  useEffect(() => {
    currentRouteRef.current = currentRoute;
  }, [currentRoute]);

  useLayoutEffect(() => {
    if (router) {
      dispatch(setCurrentRoute(router.state.location.pathname));

      router.subscribe(({ location }) => {
        if (currentRouteRef.current !== location.pathname)
          dispatch(setCurrentRoute(location.pathname));
      });
    }
  }, [router]);

  if (!isAuthDone) return null;

  return <RouterProvider router={router} />;
};

export default NavigationWrapper;
