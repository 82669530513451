import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import Button from 'atoms/Button';
import { LAYOUT_TEMPLATE_COLUMNS } from 'atoms/Layout';
import Modal from 'molecules/Modal';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';
import type { Classname, InterpolationWithTheme } from 'utils/types';

import { useSuggestionService } from './hooks';
import InputStep from './InputStep';
import ModalStep, { CLASSNAME_PREFIX } from './ModalStep';

interface ISuggestion extends Classname {
  prompt: string;
  caption: string;
  completionDescription: string;
  backButtonText: string;
  placeholder?: string;
}

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    row-gap: 1rem;
    justify-items: flex-start;
    align-content: center;
    padding: 4rem;

    ${mq.md.down} {
      grid-auto-flow: column;
      grid-template-columns: min-content max-content;
      column-gap: 1rem;
      row-gap: 0.25rem;
      align-items: center;
      justify-items: center;
      justify-content: center;
      padding: 2.5rem;
    }
  `}
`;

const Title = styled.span`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1}

    ${mq.md.down} {
      ${tp.h2}
    }
  `}
`;

const Caption = styled.span`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p2}

    color: ${utils.alphaHex(colors.primary.dark, 55)};

    ${mq.md.down} {
    }
  `}
`;

const StyledButton = styled(Button)`
  ${({ theme: { mq } }) => css`
    background-color: transparent;

    ${mq.md.up} {
      margin-left: -1rem;
      padding: 1rem;
    }

    ${mq.md.down} {
      grid-column: 1 / -1;
      grid-row: 2 / span 1;
      padding: 0.25rem 0.75rem;
    }
  `}
`;

const StyledModal = styled(Modal)`
  ${({ theme: { colors, mq } }) => css`
    ${mq.md.down} {
      &::before,
      &::after {
        content: '';
        background-color: ${colors.neutral.white};
        width: 100%;
        height: 100%;
      }
    }
  `}
`;

const ModalContent = styled.section`
  ${({ theme: { durations } }) => css`
    display: grid;
    transition: all ${durations.regular};
  `}
`;

const modalStyle: InterpolationWithTheme = ({ mq }) => css`
  position: relative;
  grid-auto-rows: initial;

  ${mq.md.up} {
    justify-self: center;
    align-self: center;
    border-radius: 4rem;
  }

  ${mq.md.down} {
    padding: var(${GLOBAL_STYLE_VARIABLES.headerHeight}) 0 7rem;

    && {
      grid-column: ${LAYOUT_TEMPLATE_COLUMNS.contentStart} / ${LAYOUT_TEMPLATE_COLUMNS.contentEnd};
    }
  }
`;

const MobileBackButton = styled(Button)`
  ${({ theme: { mq } }) => css`
    position: absolute;
    top: calc(var(${GLOBAL_STYLE_VARIABLES.headerHeight}) + 2rem);
    justify-self: flex-start;

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const Suggestion: React.FC<ISuggestion> = ({
  className,
  prompt,
  caption,
  completionDescription,
  backButtonText,
  placeholder,
}) => {
  const { step, openModal, handleClose, handleSubmit, timeout } = useSuggestionService();

  return (
    <Container className={className}>
      <Title>📨</Title>
      <Caption>{caption}</Caption>
      <StyledButton variant="outlined" bare transitionText onClick={openModal}>
        {prompt}
        <ArrowRightSvg />
      </StyledButton>
      <StyledModal isOpen={step !== null} onClick={handleClose} classNames={{ modal: modalStyle }}>
        <MobileBackButton inverted bare transitionText onClick={handleClose}>
          <ArrowLeftSvg />
          {backButtonText}
        </MobileBackButton>
        <TransitionGroup component={ModalContent}>
          <CSSTransition key={step} timeout={timeout} classNames={CLASSNAME_PREFIX}>
            {step === 'input' ? (
              <InputStep
                title={prompt}
                description={caption}
                placeholder={placeholder}
                onSubmit={handleSubmit}
              />
            ) : (
              <ModalStep
                titleElement={
                  <>
                    <span>👍</span>
                    Thanks for helping us improve your experience with Leya
                  </>
                }
                description={completionDescription}
                buttonElement="Got it"
                buttonProps={{ onClick: handleClose }}
              />
            )}
          </CSSTransition>
        </TransitionGroup>
      </StyledModal>
    </Container>
  );
};

export default Suggestion;
