import { put } from 'typed-redux-saga/macro';

import actions from '../actions';

import { inputBroadcastChannel } from './inputBroadcastChannel';

export function* startListeningWithStream() {
  yield* put(actions.startListening());

  return new ReadableStream<Uint8Array>({
    start: async controller => {
      try {
        while (true) {
          const event = await inputBroadcastChannel.takeAsync();
          if (event.type === 'data') {
            controller.enqueue(event.payload);
          } else if (event.type === 'end') {
            break;
          }
        }
      } finally {
        controller.close();
      }
    },
  });
}
