import React from 'react';
import { GlobalStyle } from 'theme/emotion';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import { Highlight } from 'features/Onboarding';
import ContainerLoader from 'molecules/ContainerLoader';
import Suggestion from 'organisms/Suggestion';
import { filterProps } from 'utils/helpers';

import { useLessonsService } from './hooks';
import LessonCard from './LessonCard';
import ProgressCard from './ProgressCard';
import PlaceholderCard from './PlaceholderCard';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    padding-bottom: 21rem;

    ${mq.md.down} {
      padding-bottom: 6rem;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1};

    text-align: center;
    margin: 0 0 4rem;

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const Tabs = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    background-color: ${colors.neutral.white};
    border-radius: 4rem;
    padding: 0.5rem;
    margin-bottom: 2.5rem;

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const Tab = styled(Link, filterProps('active'))<{ active: boolean }>`
  ${({ theme: { colors, tp }, active }) => css`
    ${tp.h3}

    text-align: center;
    border-radius: 3rem;
    text-decoration: initial;
    min-height: 5rem;
    padding: 1.25rem;

    ${active
      ? css`
          color: ${colors.primary.light};
          background-color: ${colors.neutral.grey};
        `
      : ''}
  `}
`;

const Cards = styled.section`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(44.5rem, 1fr));
    gap: 2rem;

    ${mq.md.down} {
      grid-template-columns: 1fr;
    }
  `}
`;

const SuggestionCard = styled(Suggestion)``;

const Lessons = () => {
  const {
    isLoading,
    tabs,
    lessonsCompleted,
    lessonsTotal,
    pointsEarned,
    lessons,
    placeholders,
    suggestionsCardIndex,
  } = useLessonsService();

  return (
    <>
      <GlobalStyle />
      <ContainerLoader showLoader={isLoading}>
        <Container>
          <Title>Lessons</Title>
          <Tabs>
            {tabs.map(({ label, to, active }, index) => (
              <Tab key={`${label}${to}${index}`} active={active} to={to}>
                {label}
              </Tab>
            ))}
          </Tabs>
          <Cards>
            <ProgressCard
              lessonsCompleted={lessonsCompleted}
              lessonsTotal={lessonsTotal}
              pointsEarned={pointsEarned}
            />
            {lessons.slice(0, suggestionsCardIndex).map(({ onClick, ...lessonData }, index) =>
              !index ? (
                <Highlight<HTMLDivElement>
                  key={lessonData.id}
                  journey="lessons"
                  step="lesson"
                  forwardedCallback={onClick}
                >
                  {({ ref, callback }) => (
                    <LessonCard {...lessonData} ref={ref} onClick={callback} />
                  )}
                </Highlight>
              ) : (
                <LessonCard key={lessonData.id} {...lessonData} onClick={onClick} />
              )
            )}
            <SuggestionCard
              prompt="Suggest new lessons"
              caption="Share your opinion on what lessons to add"
              completionDescription="New lessons are coming soon, so stay tuned"
              backButtonText="Lesson selection"
            />
            {lessons.slice(suggestionsCardIndex).map(lessonData => (
              <LessonCard key={lessonData.id} {...lessonData} />
            ))}
            {placeholders.map((placeholderData, index) => (
              <PlaceholderCard key={`${placeholderData.title}${index}`} {...placeholderData} />
            ))}
          </Cards>
        </Container>
      </ContainerLoader>
    </>
  );
};

export default Lessons;
