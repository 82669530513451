import { keyframes } from '@emotion/react';
import { styled } from '@mui/material';

const animation = keyframes`
	0% {
		background-color: #2B143B;
	}
	50%, 100% {
		background-color: rgba(255, 255, 255, 0.2);
	}
`;

export default styled('div')({
  position: 'relative',
  width: 10,
  height: 10,
  borderRadius: 5,
  backgroundColor: '#2B143B',
  color: '#2B143B',
  animation: `${animation} 1s infinite linear alternate`,
  animationDelay: '0.6s',
  '::before': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: -15,
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '#2B143B',
    color: '#2B143B',
    animation: `${animation} 1s infinite alternate`,
    animationDelay: '0.3s',
  },
  '::after': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    top: 0,
    left: 15,
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: '#2B143B',
    color: '#2B143B',
    animation: `${animation} 1s infinite alternate`,
    animationDelay: '0.9s',
  },
});
