import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import ReactPlayer from 'react-player/vidyard';

import { ReactComponent as PlaySvg } from 'assets/play.svg';
import Loader from 'atoms/Loader';
import { filterProps } from 'utils/helpers';

import { useVidyardPlayerService } from './hooks';

const PlayerWrapper = styled.div`
  ${({ theme: { colors, mq, utils } }) => css`
    position: relative;
    border-radius: 1.5rem;
    box-shadow: ${utils.alphaHex(colors.neutral.black, 20)} 0 0.25rem 0.5rem -0.125rem,
      ${utils.alphaHex(colors.neutral.black, 14)} 0 0.5rem 0.625rem 0,
      ${utils.alphaHex(colors.neutral.black, 12)} 0 -0.125rem 1.25rem 0;
    background-color: ${colors.neutral.grey};
    overflow: hidden;
    display: grid;
    align-self: center;
    justify-items: center;
    width: 100%;
    height: 100%;

    & > svg {
      width: 100%;
    }

    ${mq.md.up} {
      min-height: 70rem;
    }
  `}
`;

const StyledPlayer = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;

  & #backlink {
    display: none;
  }
`;

const SizingSvg = styled.svg`
  max-width: 100%;
  height: 100%;
`;

const PausedOverlay = styled('div', filterProps('isPlaying'))<{
  isPlaying: boolean;
}>`
  ${({ theme: { colors }, isPlaying }) => css`
    display: grid;
    position: absolute;
    top: 0;
    left: 0;
    z-index: ${isPlaying ? -1 : 1};
    background-color: ${colors.primary.dark};
    width: 100%;
    height: 100%;

    & > * {
      grid-area: 1 / 1 / 1 / 1;
    }
  `}
`;

const Thumbnail = styled.img`
  width: 100%;
  height: 100%;
  filter: blur(1px);
`;

const PlayButton = styled.button`
  ${({ theme: { colors, durations, mq } }) => css`
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    justify-self: center;
    transition: all ${durations.regular};
    z-index: 1;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: ${colors.primary.light};
    cursor: pointer;
    width: 7.5rem;
    height: 7.5rem;

    &:active {
      opacity: 0.9;
    }

    & > svg {
      fill: ${colors.neutral.white};
      width: 3rem;
      height: 3rem;
    }

    ${mq.hover} {
      &:hover {
        border-color: ${colors.primary.light};
      }
    }
  `}
`;

const StyledLoader = styled(Loader, filterProps('isReady'))<{
  isReady: boolean;
}>`
  ${({ theme: { colors, durations }, isReady }) => css`
    position: absolute;
    top: 0;
    left: 0;
    transition: all ${durations.regular};
    opacity: ${isReady ? 0 : 1};
    z-index: ${isReady ? -1 : 2};
    background-color: ${colors.primary.dark};
    height: 100%;

    && {
      width: 100%;
    }
  `}
`;

interface IVidyardPlayer {
  id: string;
}

const VidyardPlayer: React.FC<IVidyardPlayer> = ({ id }) => {
  const {
    playerRef,
    svgSize,
    renderKey,
    videoUrl,
    imageUrl,
    isReady,
    isPlaying,
    handleReady,
    handleEnded,
    handlePause,
    handlePlay,
  } = useVidyardPlayerService(id);

  return (
    <PlayerWrapper ref={playerRef}>
      <SizingSvg {...svgSize} />
      <StyledPlayer
        key={renderKey}
        height="100%"
        width="100%"
        url={videoUrl}
        onReady={handleReady}
        playing={isPlaying}
        onEnded={handleEnded}
        onPause={handlePause}
        onPlay={handlePlay}
      />
      <StyledLoader isReady={isReady} />
      <PausedOverlay isPlaying={isPlaying} onClick={handlePlay}>
        <Thumbnail src={imageUrl} />
        <PlayButton>
          <PlaySvg />
        </PlayButton>
      </PausedOverlay>
    </PlayerWrapper>
  );
};

export default VidyardPlayer;
