import { AxiosError, isAxiosError } from 'axios';

import {
  AUTH_CUSTOM_STATUS_CODES,
  BASE_AUTH_ERROR_MESSAGES,
  ERROR_MESSAGES,
} from 'utils/constants';

export const getAuthErrorMessage = (
  error: unknown | AxiosError,
  errorMessages: Partial<Record<AUTH_CUSTOM_STATUS_CODES, string>>
) => {
  if (isAxiosError(error)) {
    const { response } = error as AxiosError<IAuthError>;

    const { custom_status_code: customStatusCode = '', detail = '' } = response?.data ?? {};

    const errorMessageExists = Object.keys(errorMessages).includes(customStatusCode.toString());

    if (errorMessageExists) {
      return {
        customStatusCode,
        message: errorMessages[customStatusCode as keyof typeof errorMessages],
      };
    }

    if (customStatusCode && detail) {
      return {
        customStatusCode,
        message: detail,
      };
    }

    return {
      customStatusCode: AUTH_CUSTOM_STATUS_CODES.UNEXPECTED_ERROR,
      message: BASE_AUTH_ERROR_MESSAGES[AUTH_CUSTOM_STATUS_CODES.UNEXPECTED_ERROR],
    };
  }

  return {
    customStatusCode: ERROR_MESSAGES.SOMETHING_WENT_WRONG,
    message: ERROR_MESSAGES.SOMETHING_WENT_WRONG,
  };
};

interface IAuthError {
  detail: string;
  custom_status_code: AUTH_CUSTOM_STATUS_CODES;
}
