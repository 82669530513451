import { all, takeEvery, put, select, call } from 'redux-saga/effects';

import { TRootState } from 'store';

import { SUBMIT_FORGOT_PASSWORD_FORM } from './actionTypes';
import { getEmail } from './selectors';
import { setLoading, setError, setSuccess } from './slice';
import callApi, { METHODS, PATHS } from 'api';
import { captureException } from '@sentry/react';
import { ERROR_MESSAGES } from 'utils/constants';

function* forgotPasswordSubmitSaga() {
  const state: TRootState = yield select();

  const email = getEmail(state);

  if (!email) {
    return;
  }

  yield put(setLoading(true));
  yield put(setError(''));

  try {
    yield call(callApi, {
      method: METHODS.POST,
      mainPath: PATHS.RESET_PASSWORD,
      data: {
        email,
      },
    });

    yield put(
      setSuccess(
        'Success! Password reset link sent to the provided email, if it exists in our database as a client.'
      )
    );
  } catch (error) {
    captureException(error);

    yield put(setError(ERROR_MESSAGES.SOMETHING_WENT_WRONG));
  } finally {
    yield put(setLoading(false));
  }
}

export function* watchForgotPassword() {
  yield all([takeEvery(SUBMIT_FORGOT_PASSWORD_FORM, forgotPasswordSubmitSaga)]);
}
