import { call, put, select, take } from 'typed-redux-saga/macro';
import Statsig from 'statsig-js';
import { captureException } from '@sentry/react';

import { logout, setAuthData } from 'features/Auth/actions';
import { getUserId } from 'features/Auth/selectors';
import { setUserId } from 'features/Auth/slice';
import { isDev } from 'utils/constants';

import actions from '../actions';
import { STATSIG_LOGGING_BUFFER_MAX_SIZE, STATSIG_LOGGING_INTERVAL_MS } from '../constants';

import type { CaptureContext } from '@sentry/types';

export function* initializeStatsig() {
  while (true) {
    try {
      yield* take(setAuthData);
      let userID = yield* select(getUserId);

      while (!userID) {
        const { payload } = yield* take(setUserId);
        userID = payload;
      }

      yield* call(
        [Statsig, Statsig.initialize],
        process.env.VITE_STATSIG_SDK_KEY,
        { userID, appVersion: process.env.npm_package_version },
        {
          environment: { tier: process.env.VITE_ENVIRONMENT },
          loggingBufferMaxSize: STATSIG_LOGGING_BUFFER_MAX_SIZE,
          loggingIntervalMillis: STATSIG_LOGGING_INTERVAL_MS,
        }
      );
      yield* put(actions.setStatsigInitialized(true));

      yield* take(logout);
      yield* call([Statsig, Statsig.shutdown]);
    } catch (error) {
      yield* call(captureException, error, {
        level: 'error',
        tags: {
          feature: 'Analytics',
        },
      } as CaptureContext);
    } finally {
      yield* put(actions.setStatsigInitialized(false));
    }
  }
}
