import React from 'react';
import styled from '@emotion/styled';
import { Global, css } from '@emotion/react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import ContainerLoader from 'molecules/ContainerLoader';
import CircularProgress from 'atoms/CircularProgress';
import { Highlight, ONBOARDING_NODE_ID } from 'features/Onboarding';
import { FONT_FAMILIES } from 'theme/emotion/typography';
import themeColors from 'theme/emotion/colors';
import { GLOBAL_STYLE_VARIABLES, GlobalStyle } from 'theme/emotion';
import { ROUTES } from 'navigation/constants';

import { useTaskSelectionService } from './hooks';
import TaskCard from './TaskCard';
import PlaceholderCard from './PlaceholderCard';

enum TASK_SELECTION_AREAS {
  link = 'link',
  title = 'title',
  description = 'description',
  progress = 'progress',
  cards = 'cards',
}

const globalBackground = css`
  #root {
    background-color: ${themeColors.neutral.white};

    & > :first-child:not(#${ONBOARDING_NODE_ID}) {
      background-color: inherit;
    }
  }
`;

const Container = styled.div`
  ${({ theme: { mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [TASK_SELECTION_AREAS.link, TASK_SELECTION_AREAS.title, TASK_SELECTION_AREAS.progress],
      ['.', TASK_SELECTION_AREAS.description, TASK_SELECTION_AREAS.progress],
      [TASK_SELECTION_AREAS.cards, TASK_SELECTION_AREAS.cards, TASK_SELECTION_AREAS.cards],
    ])};
    grid-template-columns: 1fr 3fr 1fr;
    grid-auto-rows: min-content;
    row-gap: 1rem;
    padding-bottom: 21.5rem;

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        [TASK_SELECTION_AREAS.link],
        [TASK_SELECTION_AREAS.title],
        [TASK_SELECTION_AREAS.description],
        [TASK_SELECTION_AREAS.progress],
        [TASK_SELECTION_AREAS.cards],
      ])};
      grid-template-columns: 1fr;
      padding-bottom: 8.25rem;
    }
  `}
`;

const BackLink = styled(Link)`
  ${({ theme: { colors, mq } }) => css`
    display: flex;
    align-items: center;
    column-gap: 1rem;
    align-self: flex-start;
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    color: ${colors.primary.light};
    text-decoration: initial;
    line-height: 1.25;
    font-size: 1.75rem;

    & > svg {
      width: 2.75rem;
      height: 2.75rem;
      fill: ${colors.primary.light};
    }

    ${mq.md.down} {
      line-height: 1.35;
      margin-bottom: 3rem;

      & > svg {
        width: 2.25rem;
        height: 2.25rem;
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { tp, mq } }) => css`
    ${tp.h1};

    grid-area: ${TASK_SELECTION_AREAS.title};
    align-self: flex-end;
    text-align: center;
    margin: 0;

    ${mq.md.down} {
      padding: 0 2rem;
    }
  `}
`;

const Description = styled.h4`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p2};

    grid-area: ${TASK_SELECTION_AREAS.description};
    margin: 0;
    text-align: center;
    color: ${utils.alphaHex(colors.primary.dark, 55)};

    ${mq.md.down} {
      padding: 0 2rem;
      margin-bottom: 2rem;
    }
  `}
`;

const ProgressContainer = styled.div`
  ${({ theme: { mq } }) => css`
    grid-area: ${TASK_SELECTION_AREAS.progress};
    display: grid;
    justify-items: center;
    row-gap: 1rem;

    ${mq.md.up} {
      justify-self: flex-end;
    }
  `}
`;

const Progress = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    height: 8rem;
    width: 8rem;
    align-items: center;
    justify-items: center;

    ${mq.md.down} {
      align-self: center;
      height: 7rem;
      width: 7rem;
    }
  `}
`;

const StyledCircularProgress = styled(CircularProgress)`
  grid-row: 1 / 1;
  grid-column: 1 / 1;
`;

const Emoji = styled.span`
  ${({ theme: { tp } }) => css`
    ${tp.h1}

    grid-row: 1 / 1;
    grid-column: 1 / 1;
  `}
`;

const ProgressText = styled.h4`
  ${({ theme: { colors, utils } }) => css`
    margin: 0;
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    color: ${utils.alphaHex(colors.primary.dark, 55)};
    text-decoration: initial;
    line-height: 1.25;
    font-size: 1.75rem;

    & > span {
      color: ${colors.primary.light};
    }
  `}
`;

const Cards = styled.div`
  ${({ theme: { colors, mq } }) => css`
    grid-area: ${TASK_SELECTION_AREAS.cards};
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 2rem;
    margin-top: 3rem;
    background-color: ${colors.neutral.grey};
    border-radius: 4rem;
    padding: 4rem;

    ${mq.md.down} {
      row-gap: 1rem;
      padding: 3rem;

      ${mq.notIos} {
        margin-left: calc((100% - 100vw + var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth})) / 2);
        width: calc(100vw - var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth}));
      }

      ${mq.ios} {
        padding: 3rem 0;

        &::after {
          content: '';
          border-radius: inherit;
          position: absolute;
          top: 0;
          left: calc((100% - 100vw + var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth})) / 2);
          width: 100vw;
          height: 100%;
          background-color: inherit;
          z-index: -1;
        }
      }
    }
  `}
`;

const TaskSelection = () => {
  const {
    isLoading,
    title,
    description,
    emoji,
    pointsEarned,
    maxPoints,
    lessonProgress,
    tasks,
    placeholders,
  } = useTaskSelectionService();

  return (
    <>
      <GlobalStyle />
      <Global styles={globalBackground} />
      <ContainerLoader showLoader={isLoading}>
        <Container>
          <BackLink to={ROUTES.HOME.path}>
            <ArrowLeftSvg />
            Lesson selection
          </BackLink>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <ProgressContainer>
            <Progress>
              <StyledCircularProgress progress={lessonProgress} strokeWidth={6} />
              <Emoji>{emoji}</Emoji>
            </Progress>
            <ProgressText>
              <span>{pointsEarned}</span>/{maxPoints}
            </ProgressText>
          </ProgressContainer>
          <Cards>
            {tasks.map(({ onClick, ...task }, index) =>
              !index ? (
                <Highlight<HTMLDivElement>
                  key={task.id}
                  journey="lessons"
                  step="task"
                  forwardedCallback={onClick}
                >
                  {({ ref, callback }) => <TaskCard {...task} ref={ref} onClick={callback} />}
                </Highlight>
              ) : (
                <TaskCard key={task.id} {...task} onClick={onClick} />
              )
            )}
            {placeholders.map((placeholderData, index) => (
              <PlaceholderCard key={`${placeholderData.title}${index}`} {...placeholderData} />
            ))}
          </Cards>
        </Container>
      </ContainerLoader>
    </>
  );
};

export default TaskSelection;
