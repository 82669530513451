import { useDispatch, useSelector } from 'react-redux';
import { getPushToTalkButtonState } from 'features/Pronunciation/selectors';
import { useCallback, useEffect } from 'react';
import { resetSentence } from 'features/Pronunciation/slice';
import { startPronunciation } from 'features/Pronunciation/actions';
import { useDeferredOnboardingCallback } from 'features/Onboarding';
import actions from 'app/audio/Input/actions';

export const usePushToTalkButtonService = () => {
  const state = useSelector(getPushToTalkButtonState);
  const dispatch = useDispatch();

  const onboardingCallback = useDeferredOnboardingCallback({
    journey: 'pronunciation',
    step: 'pushToTalk',
    remoteTrigger: 'highlight',
  });

  const handleClick = useCallback(() => {
    if (state === 'retry') {
      dispatch(resetSentence());
    }
  }, [state]);

  const handlePush = useCallback(() => {
    if (state === 'idle') {
      onboardingCallback();
      dispatch(startPronunciation());
    }
  }, [state, onboardingCallback]);

  const handleRelease = useCallback(() => {
    if (state === 'active') {
      dispatch(actions.stopListening());
    }
  }, [state]);

  useEffect(() => {
    const composeSpaceKeyEvent = (callback: () => void) => (event: KeyboardEvent) => {
      if (event.key == ' ' || event.code == 'Space' || event.keyCode == 32) {
        return callback();
      }
    };

    const onSpaceDown = composeSpaceKeyEvent(state === 'retry' ? handleClick : handlePush);
    const onSpaceUp = composeSpaceKeyEvent(handleRelease);

    document.addEventListener('keydown', onSpaceDown);
    document.addEventListener('keyup', onSpaceUp);

    return () => {
      document.removeEventListener('keydown', onSpaceDown);
      document.removeEventListener('keyup', onSpaceUp);
    };
  }, [state, handleClick, handlePush, handleRelease]);

  return { state, handleClick, handlePush, handleRelease };
};
