import { route } from 'react-router-typesafe-routes/dom';

import Roleplays from './components/Roleplays';

import type { RouteObject } from 'react-router-dom';

export const roleplaysRoutes = {
  ROLEPLAYS: route('roleplays'),
};

export const roleplaysRouter: RouteObject[] = [
  {
    path: roleplaysRoutes.ROLEPLAYS.path,
    element: <Roleplays />,
  },
];
