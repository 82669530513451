import { createSlice } from 'utils/redux';

import { NAME } from './constants';

import type { IBrandReview } from './types';
import type { PayloadAction } from '@reduxjs/toolkit';

export const brandReviewSlice = createSlice({
  name: NAME,
  initialState: {
    canReview: false,
    modalStep: null,
    timesShown: 0,
    lastShown: null,
    hasReviewed: false,
  } as IBrandReview,
  reducers: {
    setCanReview: (state, action: PayloadAction<IBrandReview['canReview']>) => {
      state.canReview = action.payload;
    },
    setModalStep: (state, action: PayloadAction<IBrandReview['modalStep']>) => {
      if (state.canReview) {
        state.modalStep = action.payload;
      }
    },
    setTimesShown: (state, action: PayloadAction<IBrandReview['timesShown']>) => {
      state.timesShown = action.payload;
    },
    setLastShown: (state, action: PayloadAction<IBrandReview['lastShown']>) => {
      state.lastShown = action.payload;
    },
    setHasReviewed: (state, action: PayloadAction<IBrandReview['hasReviewed']>) => {
      state.hasReviewed = action.payload;
    },
  },
});
