import callApi, { METHODS, PATHS } from 'api';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { captureException } from '@sentry/react';

export interface ITask {
  id: string;
  lesson_id: string;
  prompt_id: string;
  name: string;
  description: string;
  type:
    | 'quiz'
    | 'word_mentioning_game'
    | 'association_game'
    | 'no_name_game'
    | 'pronunciation_game';
  max_points: number;
  order: number;
  created_at: string;
  updated_at: string;
  status: 'not started' | 'started' | 'finished';
  points_earned: number;
}

export const useLessonTasks = (lessonId: string | undefined) =>
  useQuery<unknown, unknown, ITask[]>({
    enabled: !!lessonId,
    queryKey: ['useLessonTasks', lessonId],
    queryFn: async () => {
      try {
        const response: AxiosResponse<ITask[]> = await callApi({
          method: METHODS.GET,
          mainPath: PATHS.TASKS,
          extraPath: `${lessonId}/tasks/`,
          authorized: true,
        });

        return response.data;
      } catch (error) {
        captureException(error);
      }
    },
  });
