import { all, call, takeEvery } from 'redux-saga/effects';
import { captureException } from '@sentry/react';
import TagManager from 'react-gtm-module';

import { AxiosError } from 'axios';

import callApi, { METHODS, PATHS } from 'api';
import { AUTH_CUSTOM_STATUS_CODES, ERROR_MESSAGES } from 'utils/constants';

import { SUBMIT_REGISTRATION_FORM } from './actionTypes';
import { REGISTRATION_ERROR_MESSAGES } from './constants';
import { IRegistrationSubmitProps } from './types';
import { signInSaga } from 'features/Auth/sagas';
import { SIGN_IN } from 'features/Auth/actionTypes';
import { getAuthErrorMessage } from 'utils/getAuthErrorMessages';

interface IRegistrationFormSubmitSaga {
  type: typeof SUBMIT_REGISTRATION_FORM;
  payload: IRegistrationSubmitProps;
}

function* registrationFormSubmitSaga({
  payload: { email, password, actions },
}: IRegistrationFormSubmitSaga) {
  try {
    actions.setSubmitting(true);

    yield call(callApi, {
      method: METHODS.POST,
      mainPath: PATHS.REGISTER,
      data: {
        email,
        password,
      },
    });

    TagManager.dataLayer({
      dataLayer: {
        event: 'registrationSuccessful',
      },
    });

    yield signInSaga({ type: SIGN_IN, payload: { email, password, flow: 'registration' } });
  } catch (error: unknown | AxiosError) {
    const { customStatusCode, message } = getAuthErrorMessage(error, REGISTRATION_ERROR_MESSAGES);

    switch (customStatusCode) {
      case AUTH_CUSTOM_STATUS_CODES.UNEXPECTED_ERROR:
      case ERROR_MESSAGES.SOMETHING_WENT_WRONG: {
        captureException({ error, email });
      }
      default: {
        actions.setFieldError('form', message);
      }
    }
  } finally {
    actions.setSubmitting(false);
  }
}

export function* watchRegistrationForm() {
  yield all([takeEvery(SUBMIT_REGISTRATION_FORM, registrationFormSubmitSaga)]);
}
