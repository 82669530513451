export const isDev = process.env.NODE_ENV === 'development';
export const isChrome = () => navigator.userAgent.indexOf('Chrome') !== -1;
export const isSafari = () =>
  navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1;

export const APP_ROOT_ID = 'root';

export enum STORAGE_KEYS {
  REFRESH_TOKEN = 'refresh_token',
  CONVERSATION_METHOD = 'conversation_method',
}

export const ERROR_MESSAGES = {
  SOMETHING_WENT_WRONG: 'Something went wrong.',
  WRONG_EMAIL_PASS: 'Wrong email or password.',
  NO_SUBSCRIPTION:
    "It seems like the email you're using to log in doesn't have an active subscription. Please verify the email address or click on the link below to purchase a subscription and continue using our services.",
};

export enum AUTH_CUSTOM_STATUS_CODES {
  UNEXPECTED_ERROR = 1000,
  NOT_AUTHENTICATED = 1001,
  NOT_AUTHORIZED_TIER = 1002,
  EMAIL_ALREADY_EXISTS = 1003,
  INVALID_CREDENTIALS = 1004,
  INVALID_REFRESH_TOKEN = 1005,
  INVALID_EMAIL = 1006,
}

export const BASE_AUTH_ERROR_MESSAGES = {
  [AUTH_CUSTOM_STATUS_CODES.UNEXPECTED_ERROR]:
    'Unexpected error. Please try again later or contact support.',
};

export const isIOS =
  navigator.userAgent.match(/(iPod|iPhone|iPad)/) && navigator.userAgent.match(/AppleWebKit/);

export const LINKS = {
  FUNNEL: 'https://leyaai.com/',
  FUNNEL_CHECKOUT_PLANS: 'https://leyaai.com/checkout/#plans',
  TRUST_PILOT_REVIEW:
    'https://www.trustpilot.com/evaluate/leyaai.com?utm_medium=trustbox&utm_source=TrustBoxReviewCollector',
};

export const SEARCH_PARAMS = {
  CONVERSATION_ID: 'conversation_id',
};

export const LEYA_AI_DOMAIN = '.leyaai.com';
