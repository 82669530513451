import React, { useMemo } from 'react';
import { styled } from '@mui/material';

import { filterProps } from 'utils/materialUI';

interface IUserQuantile {
  quantile?: number;
}

const Container = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'grid',
  rowGap: 48,
  justifyContents: 'center',
  justifyItems: 'center',
  backgroundColor: `#9580FFb8`,
  boxShadow: '4px 4px 8px 4px #00000042',
  borderRadius: 24,
  padding: '48px 32px',
  marginTop: 'calc(3rem + 24px)',
  overflow: 'hidden',
  '&::before': {
    position: 'absolute',
    display: 'block',
    top: '0',
    left: '0',
    background: `linear-gradient(transparent 80%, #ebf1ffb8)`,
    width: '100%',
    height: '100%',
    content: '""',
  },
  [theme.breakpoints.down('md')]: {
    marginTop: 24,
  },
}));

const Quantile = styled('span')(({ theme }) => ({
  ...theme.typography.h3,
  fontWeight: '500',
  color: 'white',
  textShadow: '2px 2px 8px black',
  zIndex: 2,
}));

const Pie = styled(
  'span',
  filterProps('quantile')
)<{ quantile: number }>(({ theme, quantile }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '50%',
  backgroundImage: `conic-gradient(#a5eaac 0%, #5da55e ${quantile}%, #000000 ${quantile}%, #92afff)`,
  boxShadow: '0 0 8px 4px #00000042',
  height: 200,
  width: 200,
  transition: 'all 300ms ease-in-out',
  cursor: 'pointer',
  ...theme.typography.h4,
  '&::before': {
    position: 'absolute',
    display: 'block',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    backgroundColor: 'white',
    boxShadow: '0 0 8px 5px inset #00000042',
    width: '80%',
    height: '80%',
    content: '""',
  },
  '&::after': {
    position: 'absolute',
    display: 'block',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: '50%',
    background: 'radial-gradient(#7b879d, #45504d00)',
    width: '80%',
    height: '80%',
    content: '""',
    zIndex: 1,
  },
  ':hover': {
    transform: 'scale(1.05)',
  },
}));

const Title = styled('span')(({ theme }) => ({
  ...theme.typography.h5,
  fontSize: '2rem',
  fontWeight: '500',

  textShadow: '1px 1px 4px black',
  color: 'white',
  textAlign: 'center',
}));

const UserQuantile: React.FC<IUserQuantile> = ({ quantile }) => {
  const [normalizedQuantile, invertedQuantile] = useMemo(() => {
    const normalizedQuantile = quantile ?? 50;
    const invertedQuantile = 100 - normalizedQuantile;
    const invertedNormalizedQuantile = invertedQuantile ? invertedQuantile : 1;
    return [normalizedQuantile, invertedNormalizedQuantile];
  }, [quantile]);

  return (
    <Container>
      <Title>You are in the top</Title>
      <Pie quantile={normalizedQuantile}>
        <Quantile>{invertedQuantile}%</Quantile>
      </Pie>
    </Container>
  );
};

export default UserQuantile;
