import React from 'react';
import { GLOBAL_STYLE_VARIABLES, GlobalStyle } from 'theme/emotion';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from 'react-router-dom';

import ContainerLoader from 'molecules/ContainerLoader';
import { filterProps } from 'utils/helpers';

import { useRoleplaysService } from './hooks';
import RoleplayCard from './RoleplayCard';
import Suggestion from 'organisms/Suggestion';

const Container = styled.div`
  ${({ theme: { colors, mq } }) => css`
    background-color: ${colors.neutral.grey};
    padding-bottom: 21rem;

    ${mq.md.down} {
      padding-bottom: 6rem;

      ${mq.notIos} {
        margin-left: calc((100% - 100vw + var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth})) / 2);
        width: calc(100vw - var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth}));
      }

      ${mq.ios} {
        &::after {
          content: '';
          border-radius: inherit;
          position: absolute;
          top: 0;
          left: calc((100% - 100vw + var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth})) / 2);
          width: 100vw;
          height: 100%;
          background-color: inherit;
          z-index: -2;
        }
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1};

    text-align: center;
    margin: 0 0 4rem;

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const Tabs = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    background-color: ${colors.neutral.white};
    border-radius: 4rem;
    padding: 0.5rem;
    margin-bottom: 2.5rem;

    ${mq.notIos} {
      margin-left: 2rem;
      margin-right: 2rem;
    }

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const Tab = styled(Link, filterProps('active'))<{ active: boolean }>`
  ${({ theme: { colors, tp }, active }) => css`
    ${tp.h3}

    text-align: center;
    border-radius: 3rem;
    text-decoration: initial;
    min-height: 5rem;
    padding: 1.25rem;

    ${active
      ? css`
          color: ${colors.primary.light};
          background-color: ${colors.neutral.grey};
        `
      : ''}
  `}
`;

const Cards = styled.section`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(40.5rem, 1fr));
    background-color: ${colors.neutral.white};
    gap: 2rem;
    padding: 4rem;
    border-radius: 4rem;
    position: relative;

    ${mq.md.down} {
      row-gap: 1rem;
      padding: 3rem;

      ${mq.ios} {
        padding: 3rem 0;
        border-radius: 0;

        &::after {
          content: '';
          border-radius: 4rem;
          position: absolute;
          top: 0;
          left: calc((100% - 100vw + var(${GLOBAL_STYLE_VARIABLES.scrollbarWidth})) / 2);
          width: 100vw;
          height: 100%;
          background-color: inherit;
          z-index: -1;
        }
      }
    }
  `}
`;

const Roleplays = () => {
  const { isLoading, tabs, lessons } = useRoleplaysService();

  return (
    <>
      <GlobalStyle />
      <ContainerLoader showLoader={isLoading}>
        <Container>
          <Title>Roleplays</Title>
          <Tabs>
            {tabs.map(({ label, to, active }, index) => (
              <Tab key={`${label}${to}${index}`} active={active} to={to}>
                {label}
              </Tab>
            ))}
          </Tabs>
          <Cards>
            {lessons.map(({ onClick, ...lessonData }) => (
              <RoleplayCard key={lessonData.id} {...lessonData} onClick={onClick} />
            ))}

            <Suggestion
              prompt="Suggest new roleplays"
              caption="Share your opinion on what roleplays to add"
              completionDescription="New roleplays are coming soon, so stay tuned"
              backButtonText="Roleplay selection"
            />
          </Cards>
        </Container>
      </ContainerLoader>
    </>
  );
};

export default Roleplays;
