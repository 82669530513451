import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { TTier, IUserExperiment } from './types';
import { GetActions, getActionTypes } from 'utils/redux';

export interface IAuth {
  refreshToken: string;
  accessToken: string;
  userId: string;
  isInitialAuthDone: boolean;
  isNotAuthorizedTier: boolean;
  userTier: TTier | null;
  userTotalConversationCount: number | null;
  userExperiments: Array<IUserExperiment>;
  currentRoute: string;
}

const initialState: IAuth = {
  refreshToken: '',
  accessToken: '',
  userId: '',
  userTier: null,
  userTotalConversationCount: null,
  userExperiments: [],
  isInitialAuthDone: false,
  isNotAuthorizedTier: false,
  currentRoute: '/',
};

export const authSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string>) => {
      state.accessToken = action.payload;
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload;
    },
    setInitialAuthDone: (state, action: PayloadAction<boolean>) => {
      state.isInitialAuthDone = action.payload;
    },
    setNotAuthorizedTier: (state, action: PayloadAction<boolean>) => {
      state.isNotAuthorizedTier = action.payload;
    },
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload;
    },
    setUserTier: (state, action: PayloadAction<TTier>) => {
      state.userTier = action.payload;
    },
    setUserTotalConversationCount: (state, action: PayloadAction<number>) => {
      state.userTotalConversationCount = action.payload;
    },
    setUserExperiments: (state, action: PayloadAction<Array<IUserExperiment>>) => {
      state.userExperiments = action.payload;
    },
    setCurrentRoute: (state, action: PayloadAction<IAuth['currentRoute']>) => {
      state.currentRoute = action.payload;
    },
    resetState: () => ({
      ...initialState,
      isInitialAuthDone: true,
    }),
  },
});

export const {
  setAccessToken,
  setRefreshToken,
  setInitialAuthDone,
  setNotAuthorizedTier,
  setUserId,
  setUserTier,
  setUserTotalConversationCount,
  setUserExperiments,
  setCurrentRoute,
  resetState,
} = authSlice.actions;

export const actionTypes = getActionTypes(authSlice);

export type Actions = GetActions<typeof authSlice.actions>;
