import PromptModal from './PromptModal';
import LessonTooltip from './LessonTooltip';
import TaskTooltip from './TaskTooltip';
import ConversationControlTooltip from './ConversationControlTooltip';
import ConversationSpeedTooltip from './ConversationSpeedTooltip';
import StartConversationTooltip from './StartConversationTooltip';
import PushToTalkTooltip from './PushToTalkTooltip';
import HandsFreeTooltip from './HandsFreeTooltip';
import { FirstTimeUsingTutorial } from './OnboardingVideos';

import type { JourneyConfig } from '../types';

const lessonsJourney = [
  {
    name: 'prompt',
    Element: PromptModal,
    indexType: 'skip',
  },
  {
    name: 'lesson',
    Element: LessonTooltip,
  },
  {
    name: 'task',
    Element: TaskTooltip,
  },
  {
    name: 'conversationControl',
    Element: ConversationControlTooltip,
  },
  {
    name: 'conversationSpeed',
    Element: ConversationSpeedTooltip,
  },
  {
    name: 'startConversation',
    Element: StartConversationTooltip,
  },
  {
    name: 'pushToTalk',
    Element: PushToTalkTooltip,
    indexType: 'chat',
  },
  {
    name: 'handsFree',
    Element: HandsFreeTooltip,
    indexType: 'chat',
  },
  {
    name: 'firstTimeUsing',
    Element: FirstTimeUsingTutorial,
    indexType: 'skip',
  },
] as const satisfies JourneyConfig;

export default lessonsJourney;
