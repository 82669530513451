import hideable from 'utils/hideable';
import styled from '@emotion/styled';

const List = styled.ul`
  font-size: 2rem;
`;

const ListItem = styled.li`
  font-size: 2rem;
`;

const SafariInstructions = () => (
  <List>
    <ListItem>
      Open Safari and click on the "Safari" menu in the top left corner of the screen.
    </ListItem>
    <ListItem>Select "Preferences" from the drop-down menu.</ListItem>
    <ListItem>Click on the "Privacy" tab at the top of the Preferences window.</ListItem>
    <ListItem>
      Click on the "Microphone" section to view the list of websites that have requested microphone
      access.
    </ListItem>
    <ListItem>Find "app.leyaai.com" in the list and click on it.</ListItem>
    <ListItem>Click the "Remove" button to delete the website's permission settings.</ListItem>
    <ListItem>Close the Preferences window and reload the website.</ListItem>
  </List>
);

export default hideable(SafariInstructions);
