import { useTaskWords } from 'features/Task/queries';
import { useCallback, useMemo, useState } from 'react';

export const useWordWithDefinitionService = (taskId: string) => {
  const wordsData = useTaskWords(taskId);

  const [isDefinitionOpen, setIsDefinitionOpen] = useState(false);

  const wordData = useMemo(
    () =>
      (!wordsData.isLoading &&
        wordsData.data &&
        wordsData.data.length &&
        (wordsData.data?.[0] ?? null)) ||
      null,
    [wordsData]
  );

  const word = useMemo(() => (wordData && wordData?.name) ?? null, [wordData]);
  const definition = useMemo(() => (wordData && wordData?.definition) ?? null, [wordData]);

  const openDefinition = useCallback(() => {
    if (definition) setIsDefinitionOpen(true);
  }, [definition]);

  const closeDefinition = useCallback(() => {
    setIsDefinitionOpen(false);
  }, []);

  return { openDefinition, closeDefinition, isDefinitionOpen, word, definition };
};
