import { AUTH_CUSTOM_STATUS_CODES } from 'utils/constants';

export const NAME = 'registration';

export const PASSWORD_MINIMUM_LENGTH = 8;
export const PASSWORD_MAXIMUM_LENGTH = 255;

export const REGISTRATION_ERROR_MESSAGES = {
  [AUTH_CUSTOM_STATUS_CODES.EMAIL_ALREADY_EXISTS]:
    'Email address already registered. Please log in or use a different email.',
};
