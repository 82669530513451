import React, { useCallback, useEffect, useRef, useState } from 'react';

import type { ArrayElement } from 'utils/types';

type QuerySelectorReturnType = ReturnType<ParentNode['querySelector']>;

export const useAwaitNode = (
  selector: ArrayElement<Parameters<ParentNode['querySelector']>>,
  shouldStartObserving = true,
  timeoutDuration = 10
): QuerySelectorReturnType => {
  const [element, setElement] = useState<QuerySelectorReturnType>(null);

  const awaitElement = useCallback(
    async (currentAttempt = 0): Promise<Element | null> => {
      if (!currentAttempt) {
        const node = document.querySelector(selector);
        if (node) return node;
      }

      const awaitedElement = await new Promise<QuerySelectorReturnType>(resolve => {
        setTimeout(() => resolve(document.querySelector(selector)), timeoutDuration);
      });

      return (await awaitedElement) ?? (await awaitElement(currentAttempt + 1));
    },
    [selector]
  );

  useEffect(() => {
    if (shouldStartObserving) {
      awaitElement().then(setElement);
    }
  }, [shouldStartObserving]);

  return element;
};

export const useAwaitElement = <ElementType extends HTMLElement = HTMLElement>(
  shouldStartObserving = true,
  timeoutDuration = 10
): [React.RefObject<ElementType>, ElementType | null] => {
  const [element, setElement] = useState<ElementType | null>(null);

  const elementRef = useRef<ElementType>(null);

  const awaitElement = useCallback(async (currentAttempt = 0): Promise<ElementType | null> => {
    if (!currentAttempt && elementRef.current) return elementRef.current;

    const awaitedElement = await new Promise<ElementType | null>(resolve => {
      setTimeout(() => resolve(elementRef.current ?? null), timeoutDuration);
    });

    return awaitedElement ?? (await awaitElement(currentAttempt + 1));
  }, []);

  useEffect(() => {
    if (shouldStartObserving) {
      awaitElement().then(setElement);
    }
  }, [shouldStartObserving]);

  return [elementRef, element];
};
