import { useCallback } from 'react';

import { useDispatch } from 'react-redux';
import { cancelPlan } from 'features/User/actions';

import { ICancellationFeedbackForm } from './form';

export const useFeedbackStepService = () => {
  const dispatch = useDispatch();

  const handleFeedback = useCallback((values: ICancellationFeedbackForm) => {
    dispatch(cancelPlan(values));
  }, []);

  return { handleFeedback };
};
