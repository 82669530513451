import { all, takeEvery, put, call } from 'redux-saga/effects';

import { setConversationMethod, setConversationSpeed, setPressed } from './slice';
import { HANDLE_SPEED_SELECT, INIT_CONVERSATION_CONTROL } from './actionTypes';
import { storageGetItem, storageSetItem } from 'utils/localStorage';
import { STORAGE_KEYS } from 'utils/constants';
import callApi, { METHODS, PATHS } from 'api';
import { CONVERSATION_SPEED } from './constants';
import { captureException } from '@sentry/react';
import { fetchUserInfo } from 'features/Auth/actions';
import { resetState } from 'features/Chat/slice';

interface ISetConversationMethodSaga {
  type: typeof setConversationMethod.type;
  payload: string;
}

interface IHandleSpeedSelectSaga {
  type: typeof setConversationMethod.type;
  payload: CONVERSATION_SPEED;
}

function* setConversationMethodSaga(action: ISetConversationMethodSaga) {
  yield call(storageSetItem, STORAGE_KEYS.CONVERSATION_METHOD, `${action.payload}`);

  try {
    yield call(callApi, {
      method: METHODS.POST,
      mainPath: PATHS.USER_PREFERENCES,
      authorized: true,
      data: {
        conversation_method: action.payload === 'push_to_talk_v2' ? 'push_to_talk' : action.payload,
      },
    });

    yield put(fetchUserInfo());
  } catch (error) {
    captureException(error);
  }
}

function* handleSpeedSelectSaga(action: IHandleSpeedSelectSaga) {
  yield put(setConversationSpeed(action.payload));

  try {
    yield call(callApi, {
      method: METHODS.POST,
      mainPath: PATHS.USER_PREFERENCES,
      authorized: true,
      data: {
        bot_speaking_speed: action.payload,
      },
    });

    yield put(fetchUserInfo());
  } catch (error) {
    captureException(error);
  }
}

function* initConversationControlSaga() {
  const savedState: string | null = yield call(storageGetItem, STORAGE_KEYS.CONVERSATION_METHOD);

  if (savedState) {
    yield put(setConversationMethod(savedState as any));
  }
}

export function* watchConversationMethod() {
  yield all([
    takeEvery(setConversationMethod.type, setConversationMethodSaga),
    takeEvery(INIT_CONVERSATION_CONTROL, initConversationControlSaga),
    takeEvery(HANDLE_SPEED_SELECT, handleSpeedSelectSaga),
    takeEvery(resetState.type, function* () {
      yield put(setPressed(false));
    }),
  ]);
}
