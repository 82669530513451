import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { initializePronunciationTask, startTextToSpeech } from 'features/Pronunciation/actions';
import {
  getCurrentScore,
  getCurrentSentenceIndex,
  getCurrentSentencePronunciationAttempts,
  getCurrentWords,
  getSentences,
  isPronunciationLoading,
  shouldEvaluate,
} from 'features/Pronunciation/selectors';
import { resetState, setCurrentSentenceIndex, setEvaluate } from 'features/Pronunciation/slice';

import type { IPronunciation } from './Pronunciation';
import { useDeferredOnboardingCallback } from 'features/Onboarding';
import { setCurrentJourney, setCurrentStep } from 'features/Onboarding/slice';
import { COOKIE_KEY, setCookie } from 'utils/cookies';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';

export const usePronunciationService = ({ taskId }: IPronunciation) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializePronunciationTask(taskId));

    return () => {
      dispatch(resetState());
    };
  }, [taskId]);

  const isLoading = useSelector(isPronunciationLoading);
  const words = useSelector(getCurrentWords);
  const currentScore = useSelector(getCurrentScore);
  const currentSentencePronunciationAttempts = useSelector(getCurrentSentencePronunciationAttempts);
  const sentenceCount = useSelector(getSentences).length;
  const currentSentenceIndex = useSelector(getCurrentSentenceIndex);
  const isEvaluationOpen = useSelector(shouldEvaluate);

  const [nextButtonState, setNextButtonState] = useState<'hide' | 'next' | 'finish'>('hide');

  useEffect(() => {
    if (currentSentencePronunciationAttempts === 0) {
      setNextButtonState('hide');
    } else {
      setNextButtonState(currentSentenceIndex < sentenceCount - 1 ? 'next' : 'finish');
    }
  }, [currentSentencePronunciationAttempts, currentSentenceIndex, sentenceCount]);

  const handleNext = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const isKeyboardEvent = !event.clientX && !event.clientY;
      if (isKeyboardEvent) {
        return;
      }

      if (currentSentenceIndex < sentenceCount - 1) {
        dispatch(setCurrentSentenceIndex(currentSentenceIndex + 1));
        dispatch(startTextToSpeech());
      } else {
        dispatch(setEvaluate(true));
        setNextButtonState('hide');
      }
    },
    [currentSentenceIndex, sentenceCount, nextButtonState]
  );

  const currentPoints = useMemo(
    () => (sentenceCount ? currentSentenceIndex + 1 : 0),
    [currentSentenceIndex, sentenceCount]
  );

  const totalPoints = sentenceCount;

  const handleCloseEvaluation = useCallback(() => {
    dispatch(setEvaluate(false));
  }, []);

  useDeferredOnboardingCallback(
    {
      journey: 'pronunciation',
      step: 'wordEvaluation',
      localTrigger: 'highlight',
    },
    () => {
      dispatch(setCurrentStep(null));
      setCookie(COOKIE_KEY.wordEvaluationSeen, 'true');
    }
  );

  const hideInfoButton = !currentScore;

  const handleInfo = () => {
    dispatch(setCurrentJourney('pronunciation'));
    dispatch(setCurrentStep('wordEvaluation'));
  };

  return {
    isLoading,
    words,
    currentScore,
    nextButtonState,
    handleNext,
    currentPoints,
    totalPoints,
    isEvaluationOpen,
    handleCloseEvaluation,
    hideInfoButton,
    handleInfo,
  };
};
