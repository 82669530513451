import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTypedParams } from 'react-router-typesafe-routes/dom';

import { useLessons, ILesson } from 'features/Lessons/queries';
import { ROUTES } from 'navigation/constants';

import { useLessonTasks } from '../queries';

export const useTaskSelectionService = () => {
  const { id: lessonId } = useTypedParams(ROUTES.LESSON);
  const navigate = useNavigate();

  const lessons = useLessons();
  const lessonTasks = useLessonTasks(lessonId as string);

  const [lesson, setLesson] = useState<ILesson | null>(null);

  useEffect(() => {
    if (!lessons.isLoading) {
      if (lessons.data?.lessons) {
        const currentLesson = lessons.data.lessons.find(({ id }) => id === lessonId);

        if (currentLesson) setLesson(currentLesson);
        else navigate(ROUTES.HOME.path);
      } else {
        navigate(ROUTES.HOME.path);
      }
    }
  }, [lessons]);

  const pointsEarned = useMemo(() => lesson?.points_earned ?? 0, [lesson]);
  const maxPoints = useMemo(() => lesson?.max_points ?? 0, [lesson]);

  const lessonProgress = useMemo(
    () => (maxPoints ? (pointsEarned / maxPoints) * 100 : 0),
    [pointsEarned, maxPoints]
  );

  const tasks = useMemo(
    () =>
      lessonTasks.data?.map(({ id, lesson_id, name, description, status, type }, index) => {
        const onClick = () => {
          navigate(ROUTES.TASK.buildPath({ id }), {
            state: ROUTES.TASK.buildState({ lessonId: lesson_id }),
          });
        };

        return {
          id,
          label: `Task ${index + 1}`,
          title: name,
          description,
          isCompleted: status === 'finished',
          onClick,
          ...(status !== 'finished' && type === 'pronunciation_game' ? { chip: 'New' } : {}),
        };
      }) ?? [],
    [lessonTasks, navigate]
  );

  const placeholders = useMemo(
    () =>
      [{ title: 'Discussion' }].map((data, index) => ({
        ...data,
        label: `Task ${tasks.length + index + 1}`,
      })),
    [tasks]
  );

  return {
    isLoading: lessonTasks.isLoading || lessons.isLoading,
    title: lesson?.name,
    description: lesson?.description,
    emoji: lesson?.emoji,
    pointsEarned,
    maxPoints,
    lessonProgress,
    tasks,
    placeholders,
  };
};
