import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ROUTES } from 'navigation/constants';

import { useRoleplays } from '../../queries';

export const useRoleplaysService = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data, isLoading } = useRoleplays();

  const lessons = useMemo(
    () =>
      data?.map(({ id, name }) => {
        const onClick = () => {
          navigate(ROUTES.CHAT.buildPath({ id }));
        };

        const [label] = name.split(`:`);
        const normalizedLabel = ['Situation', 'Topic'].includes(label) ? label : 'Roleplay';
        const [emoji] = name.split(' ').reverse();

        const getTitle = () => {
          let nameWithEmoji = name;

          if (name.includes(':')) {
            nameWithEmoji = name.split(':').slice(1).join('');
          }

          return nameWithEmoji.split(' ').slice(0, -1).join(' ');
        };

        return {
          id,
          label: normalizedLabel,
          title: getTitle(),
          description: '',
          emoji,
          hasBeenStarted: false,
          progress: 0,
          onClick,
        };
      }) ?? [],
    [data, navigate]
  );

  const tabs = useMemo(
    () =>
      [
        { label: 'Roleplays', to: ROUTES.ROLEPLAYS.path },
        { label: 'Lessons', to: ROUTES.HOME.path },
      ].map(tab => ({ ...tab, active: tab.to === pathname })),
    [pathname]
  );

  return {
    isLoading,
    tabs,
    lessons,
  };
};
