import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { LAYOUT_TEMPLATE_COLUMNS } from 'atoms/Layout';
import Modal from 'molecules/Modal';

import { useProgressiveWebAppPromptModalService } from './hooks';
import PromptStep from './PromptStep';

import type { InterpolationWithTheme } from 'utils/types';

const StyledModal = styled(Modal)`
  ${({ theme: { depths, durations } }) => css`
    transition-delay: ${durations.regular};
    z-index: ${depths.menu + 2};
    overflow-y: auto;
  `}
`;

const modalStyle: InterpolationWithTheme = ({ colors, mq }) => css`
  position: relative;
  background-color: ${colors.neutral.white};
  grid-auto-rows: initial;

  ${mq.md.up} {
    justify-self: center;
    align-self: center;
    border-radius: 4rem;
  }

  ${mq.md.down} {
    && {
      grid-column: ${LAYOUT_TEMPLATE_COLUMNS.contentStart} / ${LAYOUT_TEMPLATE_COLUMNS.contentEnd};
    }

    &::before {
      position: fixed;
      height: 100%;
      width: 100%;
      background-color: ${colors.neutral.white};
      top: 0;
      left: 0;
      content: '';
      z-index: -1;
    }

    padding: 3rem 0 7rem;
    max-height: 100vh;
  }
`;

const ProgressiveWebAppPromptModal: React.FC = () => {
  const { step, handleClose } = useProgressiveWebAppPromptModalService();

  return (
    <StyledModal isOpen={step !== null} onClick={handleClose} classNames={{ modal: modalStyle }}>
      <PromptStep />
    </StyledModal>
  );
};

export default ProgressiveWebAppPromptModal;
