import { useDispatch, useSelector } from 'react-redux';

import Login from './Login';
import { setEmail, setPassword } from '../slice';
import { submitLoginForm } from '../actions';
import {
  getEmail,
  getPassword,
  getError,
  isLoading as isLoginLoading,
} from '../selectors';

const LoginService = () => {
  const dispatch = useDispatch();

  const email = useSelector(getEmail);
  const password = useSelector(getPassword);
  const isLoading = useSelector(isLoginLoading);
  const error = useSelector(getError);

  const onEmailChange = (value: string) => {
    dispatch(setEmail(value));
  };

  const onPasswordChange = (value: string) => {
    dispatch(setPassword(value));
  };

  const onSubmit: React.FormEventHandler = (event) => {
    event.preventDefault();

    dispatch(submitLoginForm());
  };

  return (
    <Login
      error={error}
      onEmailChange={onEmailChange}
      onPasswordChange={onPasswordChange}
      onSubmit={onSubmit}
      email={email}
      password={password}
      isLoading={isLoading}
    />
  );
};

export default LoginService;
