import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { isConversationStarted as isConversationStartedSelector } from 'features/Chat/selectors';

import PressToTalkSwitch from './ConversationControlRadio';
import { getConversationMethod } from '../selectors';
import { setConversationMethod } from '../slice';
import { initConversationControl } from '../actions';
import { useRemoteConfig } from 'features/RemoteConfig/queries';
import { useDeferredOnboardingCallback } from 'features/Onboarding';

const ConversationControlRadioService = () => {
  const dispatch = useDispatch();

  const { data } = useRemoteConfig();

  const value = useSelector(getConversationMethod);

  const isVisible = !useSelector(isConversationStartedSelector) && !!data?.isPushToTalkEnabled;

  useEffect(() => {
    if (data?.isPushToTalkEnabled) {
      dispatch(initConversationControl());
    }
  }, [data?.isPushToTalkEnabled]);

  const onboardingCallback = useDeferredOnboardingCallback({
    journey: 'lessons',
    step: 'conversationControl',
    remoteTrigger: 'highlight',
  });

  const onSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setConversationMethod(event.target.value as any));
    onboardingCallback();
  };

  return <PressToTalkSwitch isVisible={isVisible} value={value} onChange={onSwitchChange} />;
};

export default ConversationControlRadioService;
