import { captureMessage } from '@sentry/react';
import { call, put, select, take } from 'typed-redux-saga/macro';

import audioInputWorklets from 'utils/audioWorklets/inputWorklets?worker&url';

import audioActions from '../../actions';
import audioSelectors from '../../selectors';

import actions from '../actions';
import { SAMPLE_RATE } from '../constants';

export function* checkIfBrowserMicrophoneIsSupported() {
  try {
    const webAudioAccessIsGranted = yield* select(audioSelectors.isWebAudioAccessGranted);

    if (!webAudioAccessIsGranted) {
      yield* take(audioActions.setWebAudioAccessGranted);
    }

    const audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });

    yield* call(
      [audioContext.audioWorklet, audioContext.audioWorklet.addModule],
      audioInputWorklets
    );

    yield* call([audioContext, audioContext.close]);

    yield* put(actions.setBrowserIsSupported(true));
  } catch {
    yield* put(actions.setBrowserIsSupported(false));
    yield* call(captureMessage, 'Browser not supported');
  }
}
