import React from 'react';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { filterProps } from 'utils/helpers';

import { getStreakCalendar } from '../../selectors';

import StreakCalendar from '../StreakCalendar';

import {
  animateIconFadeIn,
  ANIMATION_DELAY_MS,
  composeAnimateFadeInTextColor,
  composeAnimatePulseScaleWithBackground,
} from './animations';

const StyledStreakCalendar = styled(StreakCalendar, filterProps('activeDays'))<{
  activeDays: { active: boolean }[];
}>`
  ${({ theme: { colors, durations, mq, utils }, activeDays }) => css`
    max-width: 60rem;

    ${mq.md.up} {
      width: 100%;
    }

    ${mq.md.down} {
      min-width: 40rem;
    }

    ${activeDays.map(({ active }, index) =>
      active
        ? css`
            & > *:nth-child(${index + 1}) {
              animation: ${composeAnimateFadeInTextColor({
                  inactiveColor: utils.alphaHex(colors.neutral.white, 60),
                  activeColor: colors.neutral.white,
                })}
                1s forwards;
              animation-delay: ${index * ANIMATION_DELAY_MS}ms;

              & > div {
                border-color: ${utils.alphaHex(colors.neutral.white, 12)};
                animation: ${composeAnimatePulseScaleWithBackground({
                    backgroundColor: colors.primary.light,
                  })}
                  1s both;
                animation-delay: ${index * ANIMATION_DELAY_MS}ms;

                & > svg {
                  animation: ${animateIconFadeIn} ${durations.regular} both;
                  animation-delay: ${index * ANIMATION_DELAY_MS}ms;
                }
              }
            }
          `
        : ``
    )}
  `}
`;

const AnimatedCalendar: React.FC = () => {
  const activeDays = useSelector(getStreakCalendar);

  return <StyledStreakCalendar activeDays={activeDays} />;
};

export default AnimatedCalendar;
