import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TypeAnimation } from 'react-type-animation';

import { useTranscribedTextService, useTranscribedTextServiceAudio } from './hooks';
import { filterProps } from 'utils/helpers';
import { FONT_FAMILIES } from 'theme/emotion';
import { captureException } from '@sentry/react';

interface ITranscribedText {
  id: string;
  sequenceNumber: number;
}

const Text = styled.span`
  ${({ theme: { durations, tp } }) => css`
    ${tp.p1};

    position: relative;
    grid-area: 1 / 1 / span 1 / span 1;
    width: 100%;
    min-height: 100%;
    bottom: 0;
    transition: all ${durations.regular};
    font-weight: ${FONT_FAMILIES.Poppins.lightBold};

    & > span {
      color: inherit;
      transition: inherit;

      &::after {
        content: none;
      }
    }
  `}
`;

const DisplayText = styled(Text, filterProps('visible'))<{ visible: boolean }>`
  ${({ theme: { colors }, visible }) => css`
    color: ${visible ? colors.primary.light : 'transparent'};
  `}
`;

const ProgressText = styled(Text)`
  user-select: none;
  pointer-events: none;
  z-index: 2;
`;

const OverlayText = styled(Text)`
  ${({ theme: { colors } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${colors.neutral.white};
    mix-blend-mode: screen;
    z-index: 3;
    color: ${colors.primary.dark};
    transition: initial;
    user-select: none;
    pointer-events: none;
  `}
`;

const Cursor = styled('span', filterProps('showIndicator'))<{ showIndicator: boolean }>`
  ${({ theme: { colors, durations }, showIndicator }) => css`
    position: relative;
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: ${colors.primary.light};
    margin-left: 0.5ch;
    border-radius: 50%;
    opacity: ${showIndicator ? 1 : 0};
    z-index: 4;
    user-select: none;
    pointer-events: none;

    && {
      transition: all ${durations.fade};
    }
  `}
`;

class ErrorBoundary extends React.Component<
  PropsWithChildren<{ fallback: React.ReactNode }>,
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren<{ fallback: React.ReactNode }>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    captureException({ error, stack: info.componentStack });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

const TranscribedText: React.FC<ITranscribedText> = ({ id, sequenceNumber }) => {
  const { renderKey, sequence, fullMessage, shouldRenderIndicator, speed } =
    useTranscribedTextService({
      id,
      sequenceNumber,
    });
  const audio = useTranscribedTextServiceAudio({ id, sequenceNumber });

  return (
    <ErrorBoundary fallback={<Text>{fullMessage}</Text>}>
      <DisplayText visible={audio.showIndicator}>
        <TypeAnimation
          key={renderKey}
          sequence={sequence}
          preRenderFirstString
          speed={{ type: 'keyStrokeDelayInMs', value: speed }}
        />
        <Cursor showIndicator={shouldRenderIndicator} />
      </DisplayText>
      <ProgressText>
        <TypeAnimation
          key={audio.renderKey}
          sequence={audio.sequence}
          preRenderFirstString
          speed={{ type: 'keyStrokeDelayInMs', value: audio.speed }}
        />
      </ProgressText>
      <OverlayText>{fullMessage}</OverlayText>
    </ErrorBoundary>
  );
};

export default TranscribedText;
