import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import Button from 'atoms/Button';

import { useReminderButtonService } from './hooks';

import type { Classname } from 'utils/types';

const StyledButton = styled(Button)`
  && {
    background-color: transparent;
  }
`;

const ReminderButton: React.FC<Classname> = ({ className }) => {
  const { shouldRender, handleClick } = useReminderButtonService();

  return shouldRender ? (
    <StyledButton
      variant="outlined"
      bare
      transitionText
      onClick={handleClick}
      className={className}
    >
      Set reminder <ArrowRightSvg />
    </StyledButton>
  ) : null;
};

export default ReminderButton;
