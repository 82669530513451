import { useSelector } from 'react-redux';

import { getEmail, getNextPaymentDate, getUsername } from 'features/User/selectors';

export const useProfileEditService = () => {
  const username = useSelector(getUsername);
  const email = useSelector(getEmail);
  const nextBillingDate = useSelector(getNextPaymentDate);

  return {
    username,
    email,
    nextBillingDate,
  };
};
