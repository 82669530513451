import * as Yup from 'yup';

export const SUGGESTION_MAXIMUM_LENGTH = 1024;

export interface ISuggestionForm {
  suggestion: string;
}

export const SuggestionSchema = Yup.object().shape({
  suggestion: Yup.string()
    .max(
      SUGGESTION_MAXIMUM_LENGTH - 1,
      `Suggestion cannot be longer than ${SUGGESTION_MAXIMUM_LENGTH - 1} characters`
    )
    .required('Please provide a suggestion'),
});
