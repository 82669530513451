import { all, fork, takeLeading } from 'typed-redux-saga/macro';

import actions from '../actions';

import { checkIfBrowserMicrophoneIsSupported } from './checkIfBrowserMicrophoneIsSupported';
import { startListening } from './startListening';

export function* watchInput() {
  yield* all([
    fork(checkIfBrowserMicrophoneIsSupported),
    takeLeading(actions.startListening, startListening),
  ]);
}

export { startListeningWithStream } from './startListeningWithStream';
