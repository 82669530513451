import { createSelector } from '@reduxjs/toolkit';

import { NAME } from './constants';

import type { TRootState } from 'store';

const getAnalytics = (state: TRootState) => state[NAME];

const isStatsigInitialized = createSelector(
  getAnalytics,
  ({ statsigInitialized }) => statsigInitialized
);

export default {
  getAnalytics,
  isStatsigInitialized,
};
