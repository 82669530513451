import { useSelector } from 'react-redux';

import selectors from '../../selectors';

export const useScoreBarChartService = () => {
  const scores = useSelector(selectors.getScores);

  const goodScore = scores?.good ?? 0;
  const basicScore = scores?.basic ?? 0;
  const badScore = scores?.bad ?? 0;

  const maxScore = Math.max(goodScore, basicScore, badScore);
  const goodProgress = Math.round((goodScore / maxScore) * 100);
  const basicProgress = Math.round((basicScore / maxScore) * 100);
  const badProgress = Math.round((badScore / maxScore) * 100);

  return {
    goodScore,
    basicScore,
    badScore,
    goodProgress,
    basicProgress,
    badProgress,
  };
};
