import { keysOf } from 'utils/helpers';

import * as types from './types';

export const camelToSnake = <
  camelString extends string,
  snakeString = types.camelToSnake<camelString>
>(
  camelString: camelString
) => camelString.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`) as snakeString;

export const camelObjectToSnake = <
  camelObject extends Record<string, unknown>,
  snakeObject = types.camelObjectToSnake<camelObject>
>(
  obj: camelObject
): snakeObject => {
  const keys = keysOf(obj);

  return keys.reduce((acm, key) => {
    const snakeKey = camelToSnake(key);

    const value = obj[key];

    const shouldRecurse =
      typeof value === 'object' && !Array.isArray(value) && value !== null && value !== undefined;

    return {
      ...acm,
      [snakeKey]: shouldRecurse ? camelObjectToSnake(value as Record<string, unknown>) : value,
    };
  }, {} as snakeObject);
};
