import { createSelector } from '@reduxjs/toolkit';

import audioSelectors from '../selectors';

import { NAME_FRAGMENT } from './constants';

import type { IInput } from './types';

const getInput = createSelector(audioSelectors.getAudio, audio => audio[NAME_FRAGMENT]);

const matchState = (matcher: IInput['state'] | IInput['state'][]) =>
  createSelector(getInput, ({ state }) =>
    Array.isArray(matcher) ? matcher.includes(state) : matcher === state
  );

const isBrowserSupported = createSelector(getInput, ({ browserIsSupported }) => browserIsSupported);

const matchMicrophonePermission = (
  matcher: IInput['microphonePermission'] | IInput['microphonePermission'][]
) =>
  createSelector(getInput, ({ microphonePermission }) =>
    Array.isArray(matcher)
      ? matcher.includes(microphonePermission)
      : matcher === microphonePermission
  );

export default {
  getInput,
  matchState,
  isBrowserSupported,
  matchMicrophonePermission,
};
