import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserTotalConversationCount } from 'features/Auth/selectors';

import { useGotoJourneyStep } from '../hooks';
import { getCurrentJourney, getCurrentStep } from '../../selectors';
import { resetState, setCurrentJourney, setCurrentStep } from '../../slice';

import type { JourneyProps, StepByJourney } from '../types';
import { useIsOnboardingStepActive } from 'features/Onboarding/hooks';

export const useSetLessonsJourneyStep = () => {
  const dispatch = useDispatch();

  return useCallback(
    (step: StepByJourney<'lessons'>) => dispatch(setCurrentStep(step)),
    [dispatch]
  );
};

export const usePromptModalService = ({ journey, step }: JourneyProps) => {
  const currentJourney = useSelector(getCurrentJourney);
  const currentStep = useSelector(getCurrentStep);

  const dispatch = useDispatch();

  const lessonsJourneyIsActive = useMemo(
    () => currentJourney === journey,
    [currentJourney, journey]
  );

  const showModal = useMemo(
    () => lessonsJourneyIsActive && currentStep === step,
    [lessonsJourneyIsActive, currentStep, step]
  );

  const handleClose = useCallback(() => {
    dispatch(resetState());
  }, [dispatch]);

  const handleBackdropClick = useCallback(() => {
    if (showModal) {
      handleClose();
    }
  }, [handleClose, lessonsJourneyIsActive, showModal]);

  const handleGotoStep = useGotoJourneyStep();

  const showBackdrop =
    lessonsJourneyIsActive && !(currentStep === 'pushToTalk' || currentStep === 'handsFree');

  return {
    showModal,
    showBackdrop,
    lessonsJourneyIsActive,
    handleClose,
    handleBackdropClick,
    handleStart: () => handleGotoStep(),
    handleVideoStart: () => handleGotoStep('firstTimeUsing'),
  };
};

export const useLessonsJourneyTrigger = (shouldCheck: boolean) => {
  const userTotalConversationCount = useSelector(getUserTotalConversationCount);
  const currentJourney = useSelector(getCurrentJourney);
  const setLessonsJourneyStep = useSetLessonsJourneyStep();

  const dispatch = useDispatch();

  const triggeredRef = useRef(false);

  const currentlyNotOnboarding = useMemo(() => currentJourney === null, [currentJourney]);

  const shouldBeOnboarded = useMemo(
    () => userTotalConversationCount === 0,
    [userTotalConversationCount]
  );

  useEffect(() => {
    if (!triggeredRef.current && shouldCheck && shouldBeOnboarded && currentlyNotOnboarding) {
      dispatch(setCurrentJourney('lessons'));
      setLessonsJourneyStep('prompt');

      triggeredRef.current = true;
    }
  }, [shouldCheck, shouldBeOnboarded, currentlyNotOnboarding, dispatch, setLessonsJourneyStep]);
};

export const useVideosModal = ({ journey, step }: JourneyProps) => {
  const isActive = useIsOnboardingStepActive(journey, step);

  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetState());
  };

  const handleNext = useGotoJourneyStep();

  return { handleNext, handleClose, isActive };
};
