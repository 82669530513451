import { call, fork, put, select, take } from 'typed-redux-saga/macro';

import audioInputWorklets from 'utils/audioWorklets/inputWorklets?worker&url';
import { COOKIE_KEY, getCookie, setCookie } from 'utils/cookies';

import { promptForMicrophoneAccess, startAudioInputContext } from './actions';
import { INPUT_SAMPLE_RATE } from './constants';
import { getMicrophoneAccess, isWebAudioAccessGranted } from './selectors';
import {
  audioInputSlice,
  setBrowserNotSupported,
  setMicrophoneAccess,
  setWebAudioAccessGranted,
} from './slice';

import { captureException } from '@sentry/react';

import { ArrayElement, IOSNavigator } from 'utils/types';

function* initializeMicrophoneAccess() {

  try {
    const microphonePermissionStatus: PermissionStatus = yield* call(
      [navigator.permissions, navigator.permissions.query],
      {
        name: 'microphone' as PermissionName,
      }
    );

    const accessStateFromNavigator = microphonePermissionStatus.state;

    yield* put(setMicrophoneAccess(accessStateFromNavigator));
  } catch (error) {
    // Fallback for if `navigator.permissions.query` is not supported
    const accessStateFromCookie = (getCookie(COOKIE_KEY.microphoneAccess) ||
      audioInputSlice.getInitialState().microphoneAccess) as PermissionState;

    yield* put(setMicrophoneAccess(accessStateFromCookie));
  }

  const microphoneAccess: PermissionState = yield* select(getMicrophoneAccess);

  return microphoneAccess;
}

function* watchOncePromptForMicrophoneAccess() {
  try {
    yield* take(promptForMicrophoneAccess);

    if (navigator?.audioSession) {
      navigator.audioSession.type = 'play-and-record';
    }

    const inputStream: MediaStream = yield* call(
      [navigator.mediaDevices, navigator.mediaDevices.getUserMedia],
      {
        audio: { echoCancellation: true },
      }
    );

    yield* put(setMicrophoneAccess('granted'));

    const tracks = yield* call([inputStream, inputStream.getTracks]);

    yield* call([tracks, tracks.forEach], (track: ArrayElement<typeof tracks>) => track.stop());
  } catch {
    yield* put(setMicrophoneAccess('denied'));
  } finally {
    if (navigator?.audioSession) {
      navigator.audioSession.type = 'playback';
    }
  }
}

function* watchSetMicrophoneAccessSaga() {
  while (true) {
    const { payload } = yield* take(setMicrophoneAccess);

    yield* call(setCookie, COOKIE_KEY.microphoneAccess, payload);
  }
}

function* initializeAudioInputContext() {
  while (true) {
    try {
      const webAudioAccessGranted = yield* select(isWebAudioAccessGranted);
      if (!webAudioAccessGranted) {
        while (true) {
          const { payload } = yield* take(setWebAudioAccessGranted);
          if (payload) break;
        }
      }

      const audioInputContext = new AudioContext({ sampleRate: INPUT_SAMPLE_RATE });

      if (audioInputContext) {
        yield* call(
          [audioInputContext.audioWorklet, audioInputContext.audioWorklet.addModule],
          audioInputWorklets
        );

        return audioInputContext;
      }
    } catch (error) {
      console.log('error', error);
    }
  }
}

function* initializeWebAudioAccess() {
  while (true) {
    try {
      yield* take(startAudioInputContext);
      const audioInputContext = new AudioContext({ sampleRate: INPUT_SAMPLE_RATE });
      yield* call([audioInputContext, audioInputContext.close]);

      yield* put(setWebAudioAccessGranted(true));
      return;
    } catch (error) {
      console.log('error', error);
    }
  }
}

export function* watchAudioInput() {
  yield* fork(watchSetMicrophoneAccessSaga);
  yield* fork(initializeWebAudioAccess);

  let microphoneAccess = yield* call(initializeMicrophoneAccess);

  if (microphoneAccess === 'prompt') {
    yield* fork(watchOncePromptForMicrophoneAccess);

    const { payload } = yield* take(setMicrophoneAccess);

    microphoneAccess = payload;
  }

  if (microphoneAccess === 'denied') {
    return;
  }

  try {
    const audioInputContext = yield* call(initializeAudioInputContext);

    yield* call([audioInputContext, audioInputContext.close]);
  } catch (error) {
    yield* put(setBrowserNotSupported(true));
    yield* fork(captureException, error);
  }
}
