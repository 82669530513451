import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { captureException } from '@sentry/react';

import callApi, { METHODS, PATHS } from 'api';
import { actionTypes as authActionTypes, Actions } from 'features/Auth/slice';

import { setDailyStreakComplete, setIsModalOpen, setStreakCount } from './slice';

import type { AxiosResponse } from 'axios';
import { checkStreak } from 'features/Streaks/actions';
import { isDailyStreakComplete } from 'features/Streaks/selectors';

function* fetchUserStreaksSaga() {
  try {
    const {
      data: { streak_completed_for_today, current_streak_days },
    }: AxiosResponse<{
      streak_completed_for_today: boolean;
      current_streak_days: number;
    }> = yield call(callApi, {
      method: METHODS.GET,
      mainPath: PATHS.USER_STREAK,
      queryParams: [{ key: 'timezone', value: Intl.DateTimeFormat().resolvedOptions().timeZone }],
      authorized: true,
    });

    yield put(setDailyStreakComplete(streak_completed_for_today));
    yield put(setStreakCount(current_streak_days));
  } catch (error) {
    captureException(error, {
      extra: { request: PATHS.USER_STREAK, function: 'fetchUserStreaksSaga' },
    });
  }
}

function* initializeStreaksSaga({ payload }: Actions['setInitialAuthDone']) {
  if (payload) {
    yield call(fetchUserStreaksSaga);
  }
}
function* checkStreakSaga() {
  const dailyStreakComplete: ReturnType<typeof isDailyStreakComplete> = yield select(
    isDailyStreakComplete
  );

  if (!dailyStreakComplete) {
    yield call(fetchUserStreaksSaga);

    const newDailyStreakComplete: ReturnType<typeof isDailyStreakComplete> = yield select(
      isDailyStreakComplete
    );
    if (newDailyStreakComplete) {
      yield put(setIsModalOpen(true));
    }
  }
}

export function* watchStreaks() {
  yield all([takeEvery(authActionTypes.setInitialAuthDone, initializeStreaksSaga)]);
  yield all([takeEvery(checkStreak.type, checkStreakSaga)]);
}
