import { TRootState } from 'store';
import { CONVERSATION_METHOD } from './constants';

export const isPressToTalkEnabled = (state: TRootState) =>
  state.conversationControl.conversationMethod === CONVERSATION_METHOD.PUSH_TO_TALK;

export const isPressed = (state: TRootState) => state.conversationControl.isPressed;

export const isButtonDisabled = (state: TRootState) => state.conversationControl.isButtonDisabled;

export const getConversationMethod = (state: TRootState) =>
  state.conversationControl.conversationMethod;

export const getConversationSpeed = (state: TRootState) =>
  state.conversationControl.conversationSpeed;
