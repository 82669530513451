import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ClassNames } from 'theme/emotion';

import { DEFAULT_MAX_LENGTH_TEXT_AREA } from './constants';
import { useTextAreaService } from './hooks';

import type { Classname, Classnames } from 'utils/types';

export interface ITextArea extends Classname, Classnames<'wrapper' | 'textArea' | 'error'> {
  name: string;
  placeholder?: string;
  maxLength?: number;
  disabled?: boolean;
}

const Container = styled.div`
  display: grid;
  row-gap: 0.5rem;
  width: 100%;
`;

const Wrapper = styled.div`
  ${({ theme: { colors, mq } }) => css`
    padding: 2.25rem 3rem;
    background-color: ${colors.neutral.grey};
    border-radius: 3.75rem;
    width: 100%;

    ${mq.md.down} {
      border-radius: 3.5rem;
    }
  `}
`;

const StyledTextArea = styled.textarea`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p1}

    padding: 0;
    background-color: inherit;
    border: initial;
    outline: initial;
    resize: none;
    overflow: hidden;
    width: 100%;
    min-height: 8rem;

    &:disabled {
      color: ${utils.alphaHex(colors.primary.dark, 55)};
    }

    &::placeholder {
      color: ${utils.alphaHex(colors.primary.dark, 55)};
    }
  `}
`;

const ErrorText = styled.span`
  ${({ theme: { colors, tp } }) => css`
    ${tp.p2}

    margin-left: 3rem;
    color: ${colors.error};
  `}
`;

const TextArea: React.FC<ITextArea> = ({
  className,
  classNames,
  name,
  placeholder,
  maxLength = DEFAULT_MAX_LENGTH_TEXT_AREA,
  disabled,
}) => {
  const { textAreaRef, field, error, touched } = useTextAreaService({ name });

  return (
    <Container className={className}>
      <ClassNames classNames={classNames}>
        {c => (
          <>
            <Wrapper className={c.wrapper}>
              <StyledTextArea
                className={c.textArea}
                ref={textAreaRef}
                placeholder={placeholder}
                maxLength={maxLength}
                disabled={disabled}
                {...field}
              />
            </Wrapper>
            {touched && !!error ? <ErrorText className={c.error}>{error}</ErrorText> : null}
          </>
        )}
      </ClassNames>
    </Container>
  );
};

export default TextArea;
