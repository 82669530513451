import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { GetActions, getActionTypes } from 'utils/redux';

export interface IStreaks {
  dailyStreakComplete: boolean | null;
  streakCount: number;
  isModalOpen: boolean;
}

const initialState: IStreaks = {
  dailyStreakComplete: null,
  streakCount: 0,
  isModalOpen: false,
};

export const streaksSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setDailyStreakComplete: (state, action: PayloadAction<IStreaks['dailyStreakComplete']>) => {
      state.dailyStreakComplete = action.payload;
    },
    setStreakCount: (state, action: PayloadAction<IStreaks['streakCount']>) => {
      state.streakCount = action.payload;
    },
    setIsModalOpen: (state, action: PayloadAction<IStreaks['isModalOpen']>) => {
      state.isModalOpen = action.payload;
    },
    resetState: () => ({
      ...initialState,
    }),
  },
});

export const { setDailyStreakComplete, setStreakCount, setIsModalOpen, resetState } =
  streaksSlice.actions;

export const actionTypes = getActionTypes(streaksSlice);

export type Actions = GetActions<typeof streaksSlice.actions>;
