import { useCallback } from 'react';
import { WindowWithIntercom } from '../types';

export const useHandleSupport = () => {
  const handleSupport = useCallback(() => {
    const { Intercom } = window as WindowWithIntercom;

    if (Intercom) Intercom('show');
  }, []);

  return handleSupport;
};
