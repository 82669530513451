import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import shareButton from './assets/shareButton.png';
import addToHomeScreenButton from './assets/addToHomeScreenButton.png';
import homeScreenAppButton from './assets/homeScreenAppButton.png';
import CancelButton from 'atoms/CancelButton';

import { CLASSNAME_PREFIX } from '../constants';

import { useIosInstructionsStepService } from './hooks';

const Container = styled.div`
  ${({ theme: { durations, mq } }) => css`
    display: grid;
    row-gap: 3rem;
    justify-items: center;
    padding: 4rem;
    width: 58rem;
    grid-row: 1/ 1;
    grid-column: 1/ 1;
    transition: opacity ${durations.regular};

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
          transition-delay: ${durations.regular};
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }

    ${mq.md.down} {
      grid-auto-rows: min-content;
      row-gap: 1rem;
      align-self: center;
      justify-items: stretch;
      align-items: flex-end;
      padding: 2.5rem 0;
      width: 100%;
    }
  `}
`;

const StyledCancelButton = styled(CancelButton)`
  ${({ theme: { mq } }) => css`
    position: absolute;
    top: 6rem;
    right: 6rem;

    ${mq.md.up} {
      transform: translate(100%, -100%);
    }

    ${mq.md.down} {
      position: fixed;
      top: 3rem;
      right: 3rem;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h1};

    display: grid;
    row-gap: 1rem;
    color: ${colors.neutral.white};
    text-align: center;
    margin: 0;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0;
      margin-bottom: 2rem;
    }
  `}
`;

const Benefit = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-template-columns: min-content 1fr;
    row-gap: 1rem;
    column-gap: 1.5rem;
    justify-content: flex-start;
    background-color: ${colors.primary.main};
    padding: 2rem;
    border-radius: 2.5rem;

    ${mq.md.down} {
      row-gap: 1.5rem;
    }
  `}
`;

const Counter = styled.span`
  ${({ theme: { colors, tp } }) => css`
    ${tp.p3}

    display: grid;
    align-items: center;
    justify-items: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    border: 1px solid ${colors.neutral.white};
    color: ${colors.neutral.white};
    grid-area: 1 / 1 / span 1 / span 1;
  `}
`;

const Label = styled.h3`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p1}

    margin: 0;
    color: ${utils.alphaHex(colors.neutral.white, 60)};
    grid-area: 1 / 2 / span 1 / span 1;

    & > span {
      color: ${colors.neutral.white};
    }
  `}
`;

const Image = styled.img`
  width: 100%;
  grid-area: 2 / 1 / span 1 / span 2;
`;

const IosInstructionsStep: React.FC = () => {
  const { handleClose } = useIosInstructionsStepService();

  return (
    <Container>
      <StyledCancelButton onClick={handleClose} />
      <Title>
        <span>📑</span>How to install the app in IOS
      </Title>
      <Benefit>
        <Counter>1</Counter>
        <Label>
          Click on the <span>Share button</span>
        </Label>
        <Image src={shareButton} />
      </Benefit>
      <Benefit>
        <Counter>2</Counter>
        <Label>
          Click on the <span>Add to Home screen button</span>
        </Label>
        <Image src={addToHomeScreenButton} />
      </Benefit>
      <Benefit>
        <Counter>3</Counter>
        <Label>
          Find the app on your <span>Home screen</span>
        </Label>
        <Image src={homeScreenAppButton} />
      </Benefit>
    </Container>
  );
};

export default IosInstructionsStep;
