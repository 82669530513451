import { NAME } from './constants';
import { watchAudio } from './sagas';
import { audioSlice } from './slice';

const config = {
  reducer: {
    [NAME]: audioSlice.reducer,
  },
  saga: watchAudio,
};

export * from './Input';

export default config;
