import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSTransition } from 'react-transition-group';

import Layout from 'components/atoms/Layout';
import Loader from 'components/atoms/Loader';

import { useContainerLoaderService } from './hooks';

import type { Classname } from 'utils/types';

interface IContainerLoader extends PropsWithChildren, Classname {
  showLoader: boolean;
}

const CLASSNAME_PREFIX = 'fade';

const Container = styled(Layout)`
  @supports (height: 100dvh) {
    min-height: 100dvh;
  }

  & > main {
    grid-row: 1 / 3;
  }
`;

const LoaderContainer = styled(Layout)`
  ${({ theme: { durations } }) => css`
    position: fixed;
    top: 0;
    left: 0;
    padding-top: inherit;
    transition: opacity ${durations.regular};
    width: 100%;

    & > main {
      grid-row: 1 / 3;
    }

    @supports (height: 100dvh) {
      min-height: 100dvh;
    }

    &.${CLASSNAME_PREFIX} {
      &-exit,
      &-enter-active,
      &-enter-done {
        opacity: 1;
      }

      &-enter-active {
        transition-delay: ${durations.regular};
        transition-timing-function: ease-in;
      }

      &-enter,
      &-exit-active,
      &-exit-done {
        opacity: 0;
      }

      &-exit-active {
        transition-timing-function: ease-out;
      }
    }
  `}
`;

const LoaderWrapper = styled.main`
  ${({ theme: { colors, depths } }) => css`
    display: grid;
    align-items: center;
    justify-items: center;

    &::before {
      position: absolute;
      top: 0;
      left: 0;
      z-index: ${depths.deepdive};
      background-color: ${colors.primary.dark};
      width: 100vw;
      height: 100%;
      content: '';
    }
  `}
`;

const ContentWrapper = styled.main`
  display: grid;
`;

const StyledLoader = styled(Loader)`
  width: 100%;
  max-width: min(100%, 100rem);
  height: auto;
`;

const ContainerLoader: React.FC<IContainerLoader> = ({ children, className, showLoader }) => {
  const {
    renderLoader,
    renderContent,
    handleLoaderExited,
    handleContentEnter,
    contentRef,
    loaderRef,
  } = useContainerLoaderService(showLoader);

  return (
    <Container className={className}>
      <CSSTransition
        in={renderContent}
        timeout={0}
        mountOnEnter={showLoader}
        onEnter={handleContentEnter}
        nodeRef={contentRef}
      >
        <ContentWrapper ref={contentRef}>{children}</ContentWrapper>
      </CSSTransition>
      <CSSTransition
        in={renderLoader}
        classNames={CLASSNAME_PREFIX}
        mountOnEnter={!showLoader}
        timeout={300}
        unmountOnExit
        onExited={handleLoaderExited}
        nodeRef={loaderRef}
      >
        <LoaderContainer ref={loaderRef}>
          <LoaderWrapper>
            <StyledLoader />
          </LoaderWrapper>
        </LoaderContainer>
      </CSSTransition>
    </Container>
  );
};

export default ContainerLoader;
