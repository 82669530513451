import React from 'react';
import styled from '@emotion/styled';
import { Helmet } from 'react-helmet';

import ContainerLoader from 'molecules/ContainerLoader';
import { GlobalStyle } from 'theme/emotion';
import BrowserSupportProblem from 'features/Chat/components/BrowserSupportProblem';
import { Pronunciation } from 'features/Pronunciation';

import { useTaskService } from './hooks';
import TaskChat from './TaskChat';

const Container = styled.div`
  display: grid;
  justify-items: center;
  grid-auto-rows: min-content;
`;

const Task = () => {
  const { isLoading, taskId, lessonId, title, maxPoints, taskType } = useTaskService();

  return (
    <>
      <Helmet>
        <meta name="googlebot" content="notranslate" />
      </Helmet>
      <GlobalStyle />
      <ContainerLoader showLoader={isLoading}>
        {taskType === 'pronunciation_game' ? (
          <Pronunciation taskId={taskId} lessonId={lessonId} title={title} />
        ) : (
          <Container>
            <TaskChat
              isVisible
              taskId={taskId}
              lessonId={lessonId}
              title={title}
              totalPoints={maxPoints}
            />
            <BrowserSupportProblem isVisible={false} />
          </Container>
        )}
      </ContainerLoader>
    </>
  );
};

export default Task;
