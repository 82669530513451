import { call, take } from 'typed-redux-saga/macro';

import { startAudioInputContext } from 'features/AudioInput/actions';
import { playAudio } from 'features/AudioOutput/actions';

function* initializeAudioOutputContext() {
  while (true) {
    try {
      yield* take(startAudioInputContext.type);

      const audioInputContext = new AudioContext();
      if (audioInputContext) {
        return audioInputContext;
      }
    } catch (error) {
      console.log('error', error);
    }
  }
}

export function* watchAudioOutput() {
  try {
    const audioOutputContext = yield* call(initializeAudioOutputContext);

    if (navigator?.audioSession) {
      navigator.audioSession.type = 'playback';
    }

    while (true) {
      yield* take(playAudio.type);
      break;
    }
    yield* call([audioOutputContext, audioOutputContext.close]);
  } catch {}
}
