import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import Header from 'organisms/Header';
import {
  isAuthenticated as isAuthenticatedSelector,
  isNotAuthorizedTier,
} from 'features/Auth/selectors';

import { ROUTES } from 'navigation/constants';

const ProtectedRoute = () => {
  const location = useLocation();
  const isAuthenticated = useSelector(isAuthenticatedSelector);
  const userDoesNotHaveSubscription = useSelector(isNotAuthorizedTier);

  if (!isAuthenticated) {
    return <Navigate to={ROUTES.LOGIN.path} state={{ from: location }} replace />;
  }

  if (isAuthenticated && userDoesNotHaveSubscription) {
    return <Navigate to={ROUTES.NO_SUBSCRIPTION.path} state={{ from: location }} replace />;
  }

  return (
    <>
      <Header />
      <Outlet />
    </>
  );
};

export default ProtectedRoute;
