import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { logout } from 'features/Auth/actions';
import { ROUTES } from 'navigation/constants';

export const useProfileService = () => {
  const { pathname: currentRouteName } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    navigate(ROUTES.HOME.path);
    dispatch(logout());
  }, [dispatch]);

  return { handleLogout, currentRouteName };
};
