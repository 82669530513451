import { styled } from '@mui/material';
import { CircularProgress } from '@mui/material';

import hideable from 'utils/hideable';

const Container = styled('div')({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  minHeight: '100vh',
  alignItems: 'center',
});

const LoadingScreen = () => (
  <Container>
    <CircularProgress color="primary" size={50} />
  </Container>
);

export default hideable(LoadingScreen);
