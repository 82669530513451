import React, { useLayoutEffect, useState } from 'react';
import styled from '@emotion/styled';
import { css, useTheme } from '@emotion/react';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import Button from 'atoms/Button';
import { ScoreBarChart } from 'features/Chat';
import { Highlight } from 'features/Onboarding';
import { isIOS } from 'utils/constants';

import Loader from 'atoms/Loader';
import { filterProps } from 'utils/helpers';

import type { IReview } from 'features/Chat/Review/types';
import type { StepByJourney } from 'features/Onboarding/journeys';
import type { InterpolationWithTheme } from 'utils/types';

interface IReviewPanel {
  status: IReview['status'] | null;
  onboardingProceedStep: StepByJourney<'review'> | null;
  goToReview: () => void;
}

interface VisibleInterpolationProps {
  isVisible: boolean;
}

const Container = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    position: relative;
    background-color: ${colors.neutral.white};
    padding: 2rem 2.5rem 2.5rem;
    margin-bottom: 2rem;
    border-radius: 1.5rem;
    width: 100%;

    & > * {
      grid-area: 1 / 1 / span 1 / span 1;
    }

    ${mq.md.down} {
      padding: 2rem;
    }
  `}
`;

const Info = styled('div', filterProps('isVisible'))<VisibleInterpolationProps>`
  ${({ theme: { colors, durations, mq, tp, utils }, isVisible }) => css`
    display: grid;
    row-gap: 1.5rem;
    width: 100%;
    transition: opacity ${durations.regular};
    opacity: ${isVisible ? 1 : 0};

    & > span {
      ${tp.h4};

      color: ${utils.alphaHex(colors.primary.dark, 55)};
    }

    ${mq.md.down} {
      & > span {
        line-height: 1.25;
      }
    }
  `}
`;

const Loading = styled('div', filterProps('isVisible'))<VisibleInterpolationProps>`
  ${({ theme: { colors, durations, tp, utils }, isVisible }) => css`
    display: grid;
    width: 100%;
    justify-items: center;
    align-items: flex-end;
    transition: opacity ${durations.regular};
    opacity: ${isVisible ? 1 : 0};

    & > span {
      ${tp.h3};

      color: ${utils.alphaHex(colors.primary.dark, 55)};
      text-align: center;
    }
  `}
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: calc(50% - 2rem);
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  height: 100%;
`;

const ErrorMessage = styled('h2', filterProps('isVisible'))<VisibleInterpolationProps>`
  ${({ theme: { colors, durations, tp }, isVisible }) => css`
    ${tp.h2}

    display: grid;
    justify-self: center;
    align-items: center;
    text-align: center;
    margin: 0;
    color: ${colors.error.main};
    transition: opacity ${durations.regular};
    opacity: ${isVisible ? 1 : 0};
  `}
`;

const StyledButton = styled(Button)`
  ${({ theme: { mq } }) => css`
    background-color: transparent;
    padding: 1.5rem 5rem;
    margin: -1.5rem -5rem;

    ${mq.md.down} {
      padding: 1.5rem 3rem;
      margin: -1.5rem -3rem;
    }
  `}
`;

const highlightOutline: InterpolationWithTheme = ({ colors, utils }) => css`
  &::before {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: ${utils.alphaHex(colors.neutral.black, 30)};
  }
`;

const ReviewPanel: React.FC<IReviewPanel> = ({ goToReview, onboardingProceedStep, status }) => {
  const { durations } = useTheme();

  const [safeLoader, setSafeLoader] = useState(
    (window.navigator as unknown as { standalone: boolean }).standalone ? null : <StyledLoader />
  );

  useLayoutEffect(() => {
    if (safeLoader === null && status === 'loading') {
      setTimeout(() => {
        setSafeLoader(<StyledLoader />);
      }, Number(durations.regular.slice(0, -2)));
    }
  }, [status, safeLoader]);

  return status ? (
    <>
      <Container>
        <Info isVisible={status === 'success'}>
          <span>Your answers</span>
          <ScoreBarChart />
        </Info>
        <Loading isVisible={status === 'loading'}>
          {safeLoader}
          <span>Generating your task review</span>
        </Loading>
        <ErrorMessage isVisible={!status || status === 'failed'}>
          Oops, something went wrong!
        </ErrorMessage>
      </Container>
      <Highlight<HTMLButtonElement>
        journey="review"
        step="taskReview"
        proceedStep={onboardingProceedStep}
        directStep={onboardingProceedStep}
        classNames={{ outline: highlightOutline }}
      >
        {({ ref }) => (
          <StyledButton
            onClick={goToReview}
            variant="outlined"
            bare
            transitionText
            disabled={status !== 'success'}
            ref={ref}
          >
            Task review
            <ArrowRightSvg />
          </StyledButton>
        )}
      </Highlight>
    </>
  ) : null;
};

export default ReviewPanel;
