import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Button from 'atoms/Button';

import { CLASSNAME_PREFIX } from '../constants';

import { useHandleSupport } from 'utils/hooks/useHandleSupport';

const areas = {
  title: 'title',
  description: 'description',
  buttons: 'buttons',
};

const Container = styled.div`
  ${({ theme: { durations, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [areas.title],
      [areas.description],
      [areas.buttons],
    ])};
    row-gap: 4.5rem;
    justify-items: center;
    padding: 6rem;
    width: 68rem;
    min-height: 34rem;
    grid-area: 1 / 1 / 1 / 1;

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        ['.'],
        [areas.title],
        [areas.description],
        ['.'],
        [areas.buttons],
      ])};
      grid-template-rows: 1fr repeat(2, min-content) 1fr min-content;
      align-items: center;
      justify-items: center;
      padding: 2.5rem 0 0;
      width: 100%;
    }

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1}

    display: grid;
    grid-area: ${areas.title};
    row-gap: 1rem;
    text-align: center;
    margin: 0;

    ${mq.md.down} {
      ${tp.h2}

      padding: 0 3rem;
    }
  `}
`;

const Description = styled.span`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p1}

    grid-area: ${areas.description};
    color: ${utils.alphaHex(colors.primary.dark, 55)};
    text-align: center;
    margin-top: -3.5rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const ButtonContainer = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-area: ${areas.buttons};
    grid-auto-columns: max-content;
    grid-auto-rows: min-content;
    justify-self: center;
    align-self: flex-end;
    row-gap: 2rem;

    ${mq.md.down} {
    }
  `}
`;

const ErrorStep: React.FC = () => {
  const handleSupport = useHandleSupport();
  return (
    <Container>
      <Title>Oops there was a hiccup</Title>
      <Description>
        We were unable to automatically cancel your subscription plan
        <br />
        Please contact support to get this all sorted out
      </Description>
      <ButtonContainer>
        <Button onClick={handleSupport}>Contact support</Button>
      </ButtonContainer>
    </Container>
  );
};

export default ErrorStep;
