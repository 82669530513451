import { all, call, fork, put, select, take, takeEvery } from 'typed-redux-saga/macro';
import { captureException } from '@sentry/react';

import { resetState as authResetState, setUserId } from 'features/Auth/slice';

import { setId, setSubscription, resetState, setUsername, setCancellationStep } from './slice';
import callApi, { METHODS, PATHS } from 'api';

import type {
  UserSubscriptionCurrency,
  UserSubscriptionStatus,
  UserSubscriptionTier,
} from 'features/User/types';
import type { ILeaderboard } from 'features/Leaderboard/queries';
import { getEmail, getSubscription } from 'features/User/selectors';
import { cancelPlan } from 'features/User/actions';

import { watchProgressiveWebApp } from './ProgressiveWebApp/sagas';
import { watchBrandReview } from './BrandReview/sagas';

function* fetchSubscriptionInfo() {
  try {
    const { data } = yield* call(
      callApi<
        object,
        {
          id: string;
          tier: UserSubscriptionTier;
          subscription_status: UserSubscriptionStatus;
          payment_frequency_months: number;
          next_payment_date: string;
          payment_amount: number;
          currency: UserSubscriptionCurrency;
        }
      >,
      {
        method: METHODS.GET,
        mainPath: PATHS.SUBSCRIPTION,
        authorized: true,
      }
    );

    return data;
  } catch (error) {
    yield* call(captureException, error);
    throw new Error('Failed to fetch subscription data');
  }
}

function* fetchUsername() {
  try {
    const { data } = yield* call(callApi<object, ILeaderboard>, {
      method: METHODS.GET,
      mainPath: PATHS.USER_LEADERBOARD,
      authorized: true,
      queryParams: [
        {
          key: 'leaderboard_metric',
          value: 'active_days_count',
        },
      ],
    });

    return data.username;
  } catch (error) {
    yield* call(captureException, error);
    throw new Error('Failed to fetch username');
  }
}

function* getSubscriptionStatus() {
  const {
    tier,
    subscription_status,
    payment_amount,
    next_payment_date,
    payment_frequency_months,
    currency,
  } = yield* call(fetchSubscriptionInfo);

  yield* put(
    setSubscription({
      tier,
      status: subscription_status,
      paymentAmount: payment_amount,
      nextPaymentDate: next_payment_date,
      paymentFrequencyMonths: payment_frequency_months,
      currency,
    })
  );
}

function* sendCancellationFeedback(feedback: string) {
  try {
    yield* fork(callApi<{ feedback: string }>, {
      method: METHODS.POST,
      mainPath: PATHS.USER_FEEDBACK,
      data: { feedback, type: 'cancel_reason' },
      authorized: true,
    });
  } catch (error) {
    yield* call(captureException, error);
  }
}

function* cancelUserSubscriptionWorker() {
  while (true) {
    const {
      payload: { feedback },
    } = yield* take(cancelPlan);

    yield* put(setCancellationStep('loading'));

    yield* fork(sendCancellationFeedback, feedback);

    try {
      yield* call(callApi, {
        method: METHODS.POST,
        mainPath: PATHS.CANCEL_SUBSCRIPTION,
        authorized: true,
      });

      yield* put(setCancellationStep('success'));

      yield* call(getSubscriptionStatus);

      const subscription = yield* select(getSubscription);
      if (subscription) yield* put(setSubscription({ ...subscription, status: 'non_renewing' }));
    } catch (error) {
      yield* put(setCancellationStep('error'));
      yield* call(captureException, error);
    }
  }
}

export function* watchUser() {
  yield* all([
    fork(watchProgressiveWebApp),
    fork(watchBrandReview),
    fork(cancelUserSubscriptionWorker),
    takeEvery(setUserId, function* ({ payload }) {
      yield* put(setId(payload));
      yield* fork(getSubscriptionStatus);
      yield* fork(function* () {
        try {
          const username = yield* call(fetchUsername);
          yield* put(setUsername(username));
        } catch {
          const email = yield* select(getEmail);
          yield* put(setUsername(email.split('@')[0]));
        }
      });
    }),
    yield* takeEvery(authResetState, function* () {
      yield* put(resetState());
    }),
  ]);
}
