import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as CheckSvg } from 'assets/check.svg';
import { ClassNames } from 'theme/emotion';
import { filterProps } from 'utils/helpers';

import { useStreakCalendarService } from './hooks';

import type { Classname, Classnames } from 'utils/types';

interface IStreakCalendar extends Classname, Classnames<'day' | 'icon'> {}

const Calendar = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    column-gap: 1.25rem;
    border-radius: 4rem;
    background-color: ${colors.primary.main};
    padding: 2.5rem 3rem 3rem;
    width: 100%;
  `}
`;

const Day = styled('span', filterProps('active'))<{ active: boolean }>`
  ${({ theme: { colors, tp, utils }, active }) => css`
    ${tp.p1};

    position: relative;
    display: grid;
    justify-items: center;
    row-gap: 1rem;
    text-align: center;
    color: ${utils.alphaHex(colors.neutral.white, 60)};

    & > div {
      border-color: ${active ? 'transparent' : utils.alphaHex(colors.neutral.white, 12)};
    }

    & > div {
      background-color: ${active ? colors.primary.light : 'transparent'};

      & > svg {
        fill: ${active ? colors.neutral.white : 'transparent'};
      }
    }
  `}
`;

const Icon = styled.div`
  ${({ theme: { mq } }) => css`
    box-sizing: border-box;
    display: grid;
    justify-items: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border: 0.25rem solid transparent;
    border-radius: 50%;

    & > svg {
      width: 2rem;
      height: 2rem;
      fill: transparent;
    }

    ${mq.md.down} {
    }
  `}
`;

const StreakCalendar: React.FC<IStreakCalendar> = ({ className, classNames }) => {
  const { streakCalendar } = useStreakCalendarService();

  return (
    <Calendar className={className}>
      <ClassNames classNames={classNames}>
        {c =>
          streakCalendar.map(({ day, active }) => (
            <Day key={day} active={active} className={c.day}>
              {day}
              <Icon className={c.icon}>
                <CheckSvg />
              </Icon>
            </Day>
          ))
        }
      </ClassNames>
    </Calendar>
  );
};

export default StreakCalendar;
