import React from 'react';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { Formik } from 'formik';

import { ReactComponent as RefreshSvg } from 'assets/refresh.svg';
import { ReactComponent as CheckSvg } from 'assets/check.svg';
import Button from 'atoms/Button';
import TextArea from 'molecules/TextArea';
import { filterProps } from 'utils/helpers';

import { FeedbackSchema, IFeedbackForm, FEEDBACK_MAXIMUM_LENGTH } from './form';
import { useProfileSendFeedbackService } from './hooks';

import type { InterpolationWithTheme } from 'utils/types';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-template-rows: repeat(3, max-content);
    row-gap: 3rem;
    padding-bottom: 7rem;

    ${mq.md.down} {
      grid-template-rows: min-content max-content 1fr;
    }
  `}
`;

const Description = styled.h2`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p1};

    margin: 0 0 1rem;
    color: ${utils.alphaHex(colors.primary.main, 55)};
  `}
`;

const StyledTextArea = styled(TextArea, filterProps('submitted'))<{ submitted: boolean }>`
  ${({ theme: { colors, durations, mq, utils }, disabled: submitted }) => css`
    ${submitted
      ? css`
          & > * {
            pointer-events: none;
          }
        `
      : ''}

    & > div {
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: block;
        top: 0;
        left: 0;
        border-radius: inherit;
        width: 100%;
        height: 100%;
        background-color: ${submitted ? utils.alphaHex(colors.primary.dark, 10) : 'transparent'};
        transition: background-color ${durations.regular};
        z-index: ${submitted ? 1 : -1};
      }
    }

    ${mq.md.down} {
    }
  `}
`;

const StyledTextAreaWrapperStyle: InterpolationWithTheme = ({ colors }) => css`
  background-color: ${colors.neutral.white};
`;

const rotateAnimation = keyframes`
  ${css`
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  `}
`;

const StyledButton = styled(Button, filterProps('submitted', 'loading'))<{
  submitted: boolean;
  loading: boolean;
}>`
  ${({ theme: { colors, durations, mq }, submitted, loading }) => css`
    align-self: flex-start;
    justify-self: flex-start;
    min-width: 20rem;
    transition: all ${durations.regular};

    ${submitted
      ? css`
          background-color: ${colors.secondary.main};
          pointer-events: none;

          && {
            border-color: ${colors.secondary.main};
          }
        `
      : ''}

    ${loading
      ? css`
          & > svg {
            animation: ${rotateAnimation} ${durations.long} linear infinite;
          }
        `
      : ''}

    ${mq.md.down} {
      justify-self: center;
      align-self: flex-end;
    }
  `}
`;

const ProfileSendFeedback: React.FC = () => {
  const { handleFeedback, submitted } = useProfileSendFeedbackService();

  return (
    <Container>
      <Description>Let us know what you think of Leya</Description>
      <Formik
        initialValues={{ feedback: '' } as IFeedbackForm}
        validationSchema={FeedbackSchema}
        onSubmit={handleFeedback}
      >
        {({ handleSubmit, isSubmitting, errors }) => (
          <>
            <StyledTextArea
              name="feedback"
              placeholder="Please provide any feedback you have for us here"
              maxLength={FEEDBACK_MAXIMUM_LENGTH}
              submitted={submitted || isSubmitting}
              disabled={submitted || isSubmitting}
              classNames={{ wrapper: StyledTextAreaWrapperStyle }}
            />
            <StyledButton
              onClick={() => handleSubmit()}
              disabled={!submitted && (isSubmitting || !!Object.keys(errors).length)}
              type="submit"
              submitted={submitted}
              loading={isSubmitting}
            >
              {isSubmitting ? (
                <RefreshSvg />
              ) : submitted ? (
                <>
                  Thanks for the feedback
                  <CheckSvg />
                </>
              ) : (
                'Send feedback'
              )}
            </StyledButton>
          </>
        )}
      </Formik>
    </Container>
  );
};

export default ProfileSendFeedback;
