import React from 'react';
import styled from '@emotion/styled';

import { GLOBAL_STYLE_VARIABLES, GlobalStyle } from 'theme/emotion';

import Chat from './Chat';
import BrowserSupportProblem from './BrowserSupportProblem';
import Layout from 'atoms/Layout';

const Container = styled(Layout)`
  padding: calc(var(${GLOBAL_STYLE_VARIABLES.headerHeight}) + 2rem) 0 0;
  justify-items: center;

  & > main {
    grid-auto-rows: min-content;
  }
`;

const ChatContainer = () => {
  return (
    <>
      <GlobalStyle />
      <Container>
        <Chat isVisible />
        <BrowserSupportProblem isVisible={false} />
      </Container>
    </>
  );
};

export default ChatContainer;
