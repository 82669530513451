import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { resetState, setCurrentStep } from '../slice';
import { getCurrentJourney, getCurrentStep } from '../selectors';

import journeys from './journeys';

import type { Journey, JourneyConfig, JourneyStep, StepByJourney, SerializedId } from './types';

export const useGetJourneyStepId = () =>
  useCallback(
    <
      JourneyKey extends Journey = Journey,
      StepKey extends StepByJourney<JourneyKey> = StepByJourney<JourneyKey>,
      Id extends SerializedId<JourneyKey> = SerializedId<JourneyKey>
    >(
      journey: Journey,
      step: StepKey
    ): Id | null =>
      (journeys[journey] as JourneyConfig).reduce((acm, { name }, index) => {
        if (name !== step) return acm;
        return `${journey}-${name}-${index}` as Id;
      }, null as Id | null),
    []
  );

export const useGotoJourneyStep = () => {
  const currentJourney = useSelector(getCurrentJourney);
  const currentStep = useSelector(getCurrentStep);
  const dispatch = useDispatch();

  const gotoStep = useCallback(
    (step: StepByJourney | null) => {
      dispatch(setCurrentStep(step));
    },
    [dispatch]
  );

  return useCallback(
    <JourneyKey extends Journey = Journey>(overrideStep?: StepByJourney<JourneyKey> | null) => {
      if (currentJourney) {
        if (overrideStep !== undefined) {
          return gotoStep(overrideStep);
        }

        if (currentStep) {
          const currentJourneyConfig = journeys[currentJourney];

          const currentJourneyStepIndex = currentJourneyConfig.findIndex(
            ({ name }) => name === currentStep
          );

          const { indexType }: JourneyStep = currentJourneyConfig[currentJourneyStepIndex];

          if (indexType === 'skip') {
            const nextJourneyStepIndex = currentJourneyStepIndex + 1;

            if (nextJourneyStepIndex < currentJourneyConfig.length) {
              return gotoStep(currentJourneyConfig[nextJourneyStepIndex].name);
            }
          } else {
            const indexedJourneyConfig = (currentJourneyConfig as JourneyConfig).reduce(
              (acm, step, currentIndex, array) => {
                if (step.indexType === 'skip') return acm;

                if (step.name !== currentStep && step.indexType) {
                  const sameIndexedStepsExist = array.find(
                    (element, index) => element.indexType === step.indexType && index < currentIndex
                  );

                  if (!!sameIndexedStepsExist) return acm;
                }

                return [...acm, step];
              },
              [] as JourneyConfig
            );

            const currentIndexedJourneyStepIndex = indexedJourneyConfig.findIndex(
              ({ name }) => name === currentStep
            );

            const nextIndexedJourneyStepIndex = currentIndexedJourneyStepIndex + 1;
            if (nextIndexedJourneyStepIndex < indexedJourneyConfig.length) {
              return gotoStep(
                indexedJourneyConfig[nextIndexedJourneyStepIndex].name as StepByJourney
              );
            }
          }

          dispatch(resetState());
        }
      }
    },
    [currentJourney, currentStep, gotoStep]
  );
};

export const useGetCurrentSteps = () => {
  const currentJourney = useSelector(getCurrentJourney);
  const currentStep = useSelector(getCurrentStep);

  const currentJourneyConfig = journeys[currentJourney as Journey];

  const indexedJourneyConfig = (currentJourneyConfig as JourneyConfig).reduce(
    (acm, step, currentIndex, array) => {
      if (step.indexType === 'skip') return acm;

      if (step.name !== currentStep && step.indexType) {
        const sameIndexedStepsExist = array.find(
          (element, index) => element.indexType === step.indexType && index < currentIndex
        );

        if (!!sameIndexedStepsExist) return acm;
      }

      return [...acm, step];
    },
    [] as JourneyConfig
  );

  const currentIndex = indexedJourneyConfig.findIndex(({ name }) => name === currentStep);

  return { current: currentIndex + 1, total: indexedJourneyConfig.length };
};
