import { createSlice } from 'utils/redux';

import { NAME } from './constants';
import { progressiveWebAppSlice } from './ProgressiveWebApp/slice';
import { brandReviewSlice } from './BrandReview/slice';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { UserSubscription, UserSubscriptionCancellationStep } from './types';

export interface IUser {
  id: string;
  username: string;
  email: string;
  subscription: UserSubscription | null;
  cancellationStep: UserSubscriptionCancellationStep;
}

export const userSlice = createSlice(
  {
    name: NAME,
    initialState: {
      id: '',
      username: '',
      email: 'test@email.com',
      subscription: null,
      cancellationStep: null,
    } as IUser,
    reducers: {
      setId: (state, action: PayloadAction<IUser['id']>) => {
        state.id = action.payload;
      },
      setUsername: (state, action: PayloadAction<IUser['username']>) => {
        state.username = action.payload;
      },
      setEmail: (state, action: PayloadAction<IUser['email']>) => {
        state.email = action.payload;
      },
      setSubscription: (state, action: PayloadAction<IUser['subscription']>) => {
        state.subscription = action.payload;
      },
      setCancellationStep: (state, action: PayloadAction<IUser['cancellationStep']>) => {
        state.cancellationStep = action.payload;
      },
    },
  },
  { progressiveWebAppSlice, brandReviewSlice }
);

export const { setId, setUsername, setEmail, setSubscription, setCancellationStep, resetState } =
  userSlice.actions;
