import React, { ComponentProps } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Pronunciation from '../Pronunciation';
import Settings from '../Settings';

import { usePreambleService } from './hooks';
import ChatHeader from 'features/Chat/components/ChatHeader';
import { ROUTES } from 'navigation/constants';

interface IPreamble extends ComponentProps<typeof Pronunciation> {
  title: string;
}

const Container = styled.main`
  ${({ theme: { mq } }) => css`
    display: grid;
    width: 100%;
    padding: 0 0 2rem;
    align-items: flex-start;
    grid-template-rows: min-content 1fr;

    ${mq.md.down} {
      padding-top: 2rem;
    }
  `}
`;

const Preamble: React.FC<IPreamble> = ({ title, ...pronunciationProps }) => {
  const { isMobile } = usePreambleService();

  return (
    <Container>
      <ChatHeader
        backText="Task selection"
        backTo={ROUTES.LESSON.buildPath({ id: pronunciationProps.lessonId })}
        title={isMobile ? '' : title}
      ></ChatHeader>
      <Settings>
        <Pronunciation {...pronunciationProps} />
      </Settings>
    </Container>
  );
};

export default Preamble;
