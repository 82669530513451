import { call, put, take } from 'typed-redux-saga/macro';
import { eventChannel } from 'redux-saga';
import actions from 'app/audio/actions';

export function* initializeWebAudioAccess() {
  const clickChannel = eventChannel<'click'>(emit => {
    const clickEventListener = () => {
      emit('click');
    };
    document.addEventListener('click', clickEventListener);

    return () => {
      document.removeEventListener('click', clickEventListener);
    };
  });

  while (true) {
    try {
      const event = yield* take(clickChannel);

      if (event === 'click') {
        const audioInputContext = new AudioContext();
        yield* call([audioInputContext, audioInputContext.close]);
        break;
      }
    } catch {}
  }

  clickChannel.close();

  yield* put(actions.setWebAudioAccessGranted(true));
}
