import { createSlice } from 'utils/redux';

import { NAME } from './constants';

import { inputSlice } from './Input/slice';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { IAudio } from './types';

const initialState: IAudio = {
  audioSession: null,
  webAudioAccessGranted: false,
};

export const audioSlice = createSlice(
  {
    name: NAME,
    initialState,
    reducers: {
      setAudioSession: (state, action: PayloadAction<IAudio['audioSession']>) => {
        state.audioSession = action.payload;
      },
      setWebAudioAccessGranted: (state, action: PayloadAction<true>) => {
        state.webAudioAccessGranted = action.payload;
      },
    },
  },
  { inputSlice }
);
