import { createSelector } from '@reduxjs/toolkit';

import { NAME } from './constants';

import type { TRootState } from 'store';

const getOnboardingSlice = (state: TRootState) => state[NAME];

export const getCurrentJourney = createSelector(getOnboardingSlice, slice => slice.currentJourney);

export const getCurrentStep = createSelector(getOnboardingSlice, slice => slice.currentStep);

export const getCallbackTriggerId = createSelector(
  getOnboardingSlice,
  slice => slice.callbackTriggerId
);

export default {
  getCurrentJourney,
  getCurrentStep,
};
