import 'regenerator-runtime';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RecoilRoot } from 'recoil';

import store from 'store';

import App from './App';
import { APP_ROOT_ID } from 'utils/constants';

const queryClient = new QueryClient();

Sentry.init({
  dsn: process.env.VITE_SENTRY_DSN,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
  release: process.env.VITE_VERSION,
});

TagManager.initialize({
  gtmId: process.env.VITE_GTM_ID,
});

ReactDOM.createRoot(document.getElementById(APP_ROOT_ID) as HTMLElement).render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <RecoilRoot>
        <App />
      </RecoilRoot>
    </Provider>
  </QueryClientProvider>
);
