import { call, put, select, take } from 'typed-redux-saga/macro';
import { END, eventChannel } from 'redux-saga';
import { captureException } from '@sentry/react';
import callApi, { METHODS, PATHS } from 'api';
import { Howl } from 'howler';

import actions from '../actions';

import selectors from '../selectors';
import { arrayBufferToBase64 } from 'utils/helpers';

function* fetchTextToSpeech(text: string) {
  try {
    const { data } = yield* call(callApi<{ text: string }, ArrayBuffer>, {
      method: METHODS.POST,
      mainPath: PATHS.TEXT_TO_SPEECH,
      data: {
        text,
      },
      authorized: true,
      responseType: 'arraybuffer',
    });

    return data;
  } catch (error) {
    yield* call(captureException, error);
    throw new Error('Failed to fetch');
  }
}

export function* reviewMessageToSpeech({
  payload: index,
}: ReturnType<typeof actions.reviewMessageToSpeech>) {
  const messages = yield* select(selectors.getMessages);

  const { improvement, textToSpeechState } = messages[index];

  if (improvement && textToSpeechState === 'idle') {
    yield* put(actions.startLoadingTTSMessage(index));

    const arrayBuffer = yield* call(fetchTextToSpeech, improvement);

    const base64Data = yield* call(arrayBufferToBase64, arrayBuffer);

    const header = 'data:audio/mp3;base64,';
    const base64DataWithHeader = header + base64Data;

    const sound = new Howl({
      src: [base64DataWithHeader],
      format: ['mp3'],
    });

    const soundEventsChannel = eventChannel<
      { type: 'started'; payload: { durationMs: number } } | { type: 'ended' }
    >(emit => {
      sound.once('play', () => {
        emit({ type: 'started', payload: { durationMs: sound.duration() * 1000 } });
      });

      sound.once('end', () => {
        emit(END);
      });

      let loadedAmount = 0;
      sound.once('loaderror', () => {
        if (!loadedAmount) {
          sound.once('unlock', () => {
            sound.play();
          });
        } else {
          emit(END);
        }

        loadedAmount++;
      });

      sound.once('playerror', () => {
        emit(END);
      });

      return () => {
        sound.unload();
      };
    });

    sound.play();

    try {
      while (true) {
        const soundEvent = yield* take(soundEventsChannel);

        if (soundEvent.type === 'started') {
          yield* put(actions.startPlayingTTSMessage(index));
        }
      }
    } catch {
    } finally {
      yield* put(actions.stopPlayingTTSMessage());

      sound.unload();
    }
  }
}
