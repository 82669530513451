import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';
import { GetActions, getActionTypes } from 'utils/redux';

export interface IPronunciation {
  isLoading: boolean;
  sentences: Sentence[];
  currentSentenceIndex: number;
  pushToTalkState: PushToTalkState;
  textToSpeechState: TextToSpeechState;
  evaluate: boolean;
}

export type PushToTalkState = 'idle' | 'active' | 'disabled' | 'loading' | 'retry';
export type TextToSpeechState = 'idle' | 'active' | 'disabled' | 'loading';

interface Sentence {
  sentence: string;
  scores: number[];
  words: Word[];
}

export interface Word {
  word: string;
  pronunciation?: number;
}

const initialState: IPronunciation = {
  pushToTalkState: 'disabled',
  textToSpeechState: 'disabled',
  isLoading: false,
  sentences: [],
  currentSentenceIndex: 0,
  evaluate: false,
};

export const pronunciationSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setPushToTalkState: (state, action: PayloadAction<IPronunciation['pushToTalkState']>) => {
      state.pushToTalkState = action.payload;
      return state;
    },
    setTextToSpeechState: (state, action: PayloadAction<IPronunciation['textToSpeechState']>) => {
      state.textToSpeechState = action.payload;
      return state;
    },
    setIsLoading: (state, action: PayloadAction<IPronunciation['isLoading']>) => {
      state.isLoading = action.payload;
      return state;
    },
    setCurrentSentenceIndex: (state, action: PayloadAction<number>) => {
      state.currentSentenceIndex = action.payload;
      state.pushToTalkState = 'idle';

      return state;
    },
    initializeSentences: (state, action: PayloadAction<string[]>) => {
      state.sentences = action.payload.map(sentence => ({
        sentence,
        scores: [],
        words: sentence.split(' ').map(word => ({ word })),
      }));

      state.currentSentenceIndex = 0;

      return state;
    },
    updateSentence: (
      state,
      action: PayloadAction<{ index: number; score: number; words: Word[] }>
    ) => {
      const { index, score, words } = action.payload;

      if (index < state.sentences.length) {
        if (score) {
          state.sentences[index].scores.push(score);
        }
        state.sentences[index].words = words;
      }

      return state;
    },
    setEvaluate: (state, action: PayloadAction<IPronunciation['evaluate']>) => {
      state.evaluate = action.payload;

      state.pushToTalkState = 'disabled';
      state.textToSpeechState = 'disabled';
      return state;
    },
    resetSentence: state => {
      const index = state.currentSentenceIndex;
      if (index < state.sentences.length) {
        state.sentences[index].scores.push(0);
        state.sentences[index].words = state.sentences[index].words.map(({ word }) => ({ word }));
      }

      state.pushToTalkState = 'idle';

      return state;
    },
    resetState: () => ({
      ...initialState,
    }),
  },
});

export const {
  setPushToTalkState,
  setTextToSpeechState,
  setIsLoading,
  setCurrentSentenceIndex,
  initializeSentences,
  updateSentence,
  setEvaluate,
  resetSentence,
  resetState,
} = pronunciationSlice.actions;

export const actionTypes = getActionTypes(pronunciationSlice);

export type Actions = GetActions<typeof pronunciationSlice.actions>;
