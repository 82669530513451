import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

import { getEmail, getNextPaymentDate, getUsername } from 'features/User/selectors';
import { useMatchMedia } from 'theme/emotion';
import { useCallback, useLayoutEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'navigation/constants';
import { logout } from 'features/Auth/actions';

export const useProfileInfoService = () => {
  const { mq } = useTheme();
  const navigate = useNavigate();

  const isDesktop = useMatchMedia([mq.md.up]);

  useLayoutEffect(() => {
    if (isDesktop) {
      navigate(ROUTES.PROFILE.EDIT.path);
    }
  }, [isDesktop, navigate]);

  const username = useSelector(getUsername);
  const email = useSelector(getEmail);
  const nextPaymentDate = useSelector(getNextPaymentDate);

  const nextBillingDate = useMemo(() => {
    switch (nextPaymentDate) {
      case 'Invalid date':
      case '': {
        return '';
      }
      default: {
        return `Next billing date ${nextPaymentDate}`;
      }
    }
  }, [nextPaymentDate]);
  const dispatch = useDispatch();

  const handleLogout = useCallback(() => {
    navigate(ROUTES.HOME.path);
    dispatch(logout());
  }, [dispatch]);

  return {
    username,
    email,
    nextBillingDate,
    handleLogout,
  };
};
