import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';

import { getCancellationStep } from 'features/User/selectors';
import { setCancellationStep } from 'features/User/slice';

export const useCancelPlanModalService = () => {
  const { durations } = useTheme();

  const step = useSelector(getCancellationStep);

  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    dispatch(setCancellationStep(null));
  }, [step, dispatch]);

  const timeout = useMemo(() => Number(durations.regular.slice(0, -2)), [durations]);

  return { step, handleClose, timeout };
};
