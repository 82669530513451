import React, { Fragment } from 'react';

import { useOnboardingService } from './hooks';

const Onboarding: React.FC = () => {
  const { elements } = useOnboardingService();

  return (
    <Fragment>
      {elements.map(({ id, Element, journey, step }) => (
        <Element key={id} journey={journey} step={step} />
      ))}
    </Fragment>
  );
};

export default Onboarding;
