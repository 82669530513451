import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Button from 'atoms/Button';
import { ReactComponent as CancelSvg } from 'assets/cancel.svg';

import CancelPlanModal from './CancelPlanModal';
import { useProfileManageSubscriptionService } from './hooks';
import { useHandleSupport } from 'utils/hooks/useHandleSupport';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    row-gap: 3rem;
    padding-bottom: 7rem;

    ${mq.md.up} {
      grid-auto-rows: min-content;
    }

    ${mq.md.down} {
      grid-template-rows: min-content 1fr;
    }
  `}
`;

const Card = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-template-columns: max-content max-content;
    grid-auto-rows: min-content;
    justify-content: space-between;
    align-self: flex-start;
    row-gap: 0.5rem;
    background-color: ${colors.neutral.white};
    border-radius: 2.5rem;
    padding: 2.5rem 2.5rem 2.5rem 3rem;

    ${mq.md.down} {
      padding: 2rem 2rem 2rem 2.5rem;
    }
  `}
`;

const Plan = styled.h2`
  ${({ theme: { tp } }) => css`
    ${tp.h3};

    grid-column: 1 / -1;
    margin: 0;
  `}
`;

const Caption = styled.span`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p2};

    color: ${utils.alphaHex(colors.primary.main, 55)};
    text-align: center;
  `}
`;

const ButtonContainer = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-auto-columns: max-content;
    grid-auto-rows: min-content;

    ${mq.md.up} {
      grid-auto-flow: column;
      column-gap: 3rem;
    }

    ${mq.md.down} {
      justify-self: center;
      align-self: flex-end;
      row-gap: 3rem;
    }
  `}
`;

const CancelPlanButton = styled(Button)`
  ${({ theme: { colors, mq, utils } }) => css`
    background-color: transparent;
    color: ${colors.error.main};
    align-self: center;
    padding: 1rem;
    margin: -1rem;

    & > svg {
      fill: ${colors.error.main};
    }

    &:active {
      background-color: ${utils.alphaHex(colors.primary.main, 10)};
    }

    ${mq.hover} {
      &:hover {
        background-color: ${utils.alphaHex(colors.primary.main, 5)};
      }
    }

    ${mq.md.down} {
      justify-self: center;
    }
  `}
`;

const ProfileManageSubscription: React.FC = () => {
  const handleSupport = useHandleSupport();
  const { plan, nextBillingDate, canCancel, paymentAmountPerFrequencyMonth, handleCancelPlan } =
    useProfileManageSubscriptionService();

  return (
    <>
      <Container>
        <Card>
          <Plan>{plan}</Plan>
          <Caption>{nextBillingDate}</Caption>
          <Caption>{paymentAmountPerFrequencyMonth}</Caption>
        </Card>
        <ButtonContainer>
          <Button onClick={handleSupport}>Change payment plan</Button>
          {canCancel ? (
            <CancelPlanButton variant="outlined" bare onClick={handleCancelPlan}>
              Cancel plan
              <CancelSvg />
            </CancelPlanButton>
          ) : null}
        </ButtonContainer>
      </Container>
      <CancelPlanModal />
    </>
  );
};

export default ProfileManageSubscription;
