import { actionChannel, call, put, select, take } from 'typed-redux-saga/macro';

import { SAMPLE_RATE } from '../constants';
import audioInputWorklets from 'utils/audioWorklets/inputWorklets?worker&url';
import audioInputWorkletsProcessorNames from 'utils/audioWorklets/names';

import audioActions from '../../actions';

import actions from '../actions';
import selectors from '../selectors';

import { inputBroadcastChannel } from './inputBroadcastChannel';

function* initializeAudioContextWithWorklets() {
  const browserIsSupported = yield* select(selectors.isBrowserSupported);

  if (!browserIsSupported) {
    while (true) {
      const { payload } = yield* take(actions.setBrowserIsSupported);
      if (payload) break;
    }
  }

  const audioContext = new AudioContext({ sampleRate: SAMPLE_RATE });

  yield* call([audioContext.audioWorklet, audioContext.audioWorklet.addModule], audioInputWorklets);

  return audioContext;
}

function* getInputStream() {
  const inputStream = yield* call([navigator.mediaDevices, navigator.mediaDevices.getUserMedia], {
    audio: { sampleRate: SAMPLE_RATE, echoCancellation: true },
  });

  const tracks = inputStream.getTracks();

  tracks.forEach(track => {
    track.enabled = true;
  });

  const stopInputStream = () => {
    tracks.forEach(track => {
      track.stop();
    });
  };

  return { inputStream, stopInputStream };
}

export function* startListening() {
  const bufferedStopListeningActions = yield* actionChannel(actions.stopListening);
  const isAlreadyListening = yield* select(selectors.matchState('active'));

  if (isAlreadyListening) return;

  // Requesting device stream
  yield* put(actions.setState('pending'));
  yield* put(audioActions.setAudioSession('play-and-record'));

  const audioContext = yield* call(initializeAudioContextWithWorklets);

  const inputStream: MediaStream = yield* call(
    [navigator.mediaDevices, navigator.mediaDevices.getUserMedia],
    {
      audio: { sampleRate: SAMPLE_RATE, echoCancellation: true },
    }
  );

  inputStream.getTracks().forEach(track => {
    track.enabled = true;
  });

  const splicerNode = new AudioWorkletNode(
    audioContext,
    audioInputWorkletsProcessorNames.audioInputSplicer,
    { channelInterpretation: 'speakers', channelCountMode: 'explicit', channelCount: 1 }
  );

  const inputSource = audioContext.createMediaStreamSource(inputStream);
  inputSource.connect(splicerNode);

  if (audioContext.state === 'suspended') {
    yield* call([audioContext, audioContext.resume]);
  }

  // Has started listening -- not capturing
  yield* put(actions.setState('active'));

  // wrap in event channel
  splicerNode.port.onmessage = ({ data }) => {
    inputBroadcastChannel.put({ type: 'data', payload: data.audio });
  };

  yield* take(bufferedStopListeningActions);

  splicerNode.port.postMessage({ type: 'end' });
  yield* put(actions.setState('inactive'));

  splicerNode.port.onmessage = ({ data }) => {
    if (data.isFinal) {
      inputBroadcastChannel.put({ type: 'end' });

      inputSource.disconnect();
      inputStream.getTracks().forEach(track => {
        track.stop();
      });
      splicerNode.disconnect();
      audioContext.close();

      if (navigator?.audioSession) {
        navigator.audioSession.type = 'playback';
      }
    }
  };
}
