import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';

import hideable from 'utils/hideable';
import { CONVERSATION_SPEED } from '../constants';
import { Highlight } from 'features/Onboarding';

interface IConversationControlRadio {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const StyledRadioGroup = styled(RadioGroup)`
  row-gap: 1rem;
`;

const StyledLabel = styled(FormControlLabel)({
  '.MuiTypography-root': {
    fontWeight: 500,
    fontSize: 14,
  },
});

const StyledRadio = styled(Radio)(({ theme: { palette } }) => ({
  '&.Mui-checked svg': {
    fill: palette.primary.main,
  },
  '& svg': {
    fontSize: '3rem',
  },
}));

const SettingTitle = styled('div')({
  fontSize: 16,
  lineHeight: '22px',
  marginTop: 24,
  fontWeight: 600,
  marginBottom: 12,
});

const ConversationSpeed = ({ onChange, value }: IConversationControlRadio) => (
  <StyledRadioGroup onChange={onChange} value={value}>
    <SettingTitle>Conversation speed</SettingTitle>
    <Highlight journey="lessons" step="conversationSpeed">
      {({ ref }) => (
        <StyledLabel
          value={CONVERSATION_SPEED.SLOW}
          control={<StyledRadio />}
          label="Slow"
          ref={ref}
        />
      )}
    </Highlight>
    <Highlight journey="lessons" step="conversationSpeed" highlightOnly>
      {({ ref }) => (
        <StyledLabel
          value={CONVERSATION_SPEED.NORMAL}
          control={<StyledRadio />}
          label="Normal"
          ref={ref}
        />
      )}
    </Highlight>
    <Highlight journey="lessons" step="conversationSpeed" highlightOnly>
      {({ ref }) => (
        <StyledLabel
          value={CONVERSATION_SPEED.FAST}
          control={<StyledRadio />}
          label="Fast"
          ref={ref}
        />
      )}
    </Highlight>
  </StyledRadioGroup>
);

export default hideable(ConversationSpeed);
