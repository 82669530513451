import { call } from 'typed-redux-saga/macro';

import { sttSocket } from '../../sttSocket';

import type {
  ClientEvents as BaseClientEvents,
  ClientMarkEvent,
  ClientStartEvent,
  TranscriptionSocketInstanceInfo,
  TranscriptionSocketInstancePayload,
} from './types';
import type { getClientEventCallback, mergeEvents, SocketEvent } from '../../../utils/startSocket';

function* transcriptionSocket<
  CustomClientEvents extends SocketEvent = never,
  CustomServerEvents extends SocketEvent = never
>({ id, sequenceNumber }: TranscriptionSocketInstanceInfo) {
  type ExtendedClientEvents = mergeEvents<BaseClientEvents, CustomClientEvents>;
  type ClientEvents = Utils.snakeObjectToCamel<ExtendedClientEvents>;

  const {
    on,
    send,
    sendStart: sttSendStart,
    sendMark: sttSendMark,
    ...restSttSocket
  } = yield* call(sttSocket<ExtendedClientEvents, CustomServerEvents>, 'transcriptions' as const);

  type StartPayload = Omit<
    Parameters<getClientEventCallback<ClientStartEvent, ClientEvents>>[0],
    keyof Utils.snakeObjectToCamel<TranscriptionSocketInstancePayload>
  >;
  const sendStart = ((payload: StartPayload) => {
    sttSendStart({
      ...(payload || {}),
      conversationId: id,
      sequenceNumber,
    });
  }) as {} extends StartPayload ? () => void : (payload: StartPayload) => void;

  type MarkPayload = Omit<
    Parameters<getClientEventCallback<ClientMarkEvent, ClientEvents>>[0],
    'lastChunkNumber'
  >;
  const sendMark = () => {
    sttSendMark({
      conversationId: id,
      sequenceNumber,
    } as MarkPayload);
  };

  return {
    on,
    send,
    sendMark,
    sendStart,
    ...restSttSocket,
  };
}

export default transcriptionSocket;
