import PushToTalkTooltip from './PushToTalkTooltip';
import WordEvaluationTooltip from './WordEvaluationTooltip';

import type { JourneyConfig } from '../types';

const pronunciationJourney = [
  {
    name: 'pushToTalk',
    Element: PushToTalkTooltip,
    indexType: 'skip',
  },
  {
    name: 'wordEvaluation',
    Element: WordEvaluationTooltip,
    indexType: 'skip',
  },
] as const satisfies JourneyConfig;

export default pronunciationJourney;
