import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { FONT_FAMILIES } from 'theme/emotion/typography';
import CircularProgress, { CIRCULAR_PROGRESS_CLASSNAMES } from 'atoms/CircularProgress';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';
import { useTaskInfoService } from './hooks';
import { filterProps } from 'utils/helpers';
import type { Classname } from 'utils/types';

export interface ITaskInfo extends Classname {
  isVisible: boolean;
  taskId: string;
  lessonId: string;
  currentPoints: number;
  totalPoints: number;
}

enum TASK_INFO_AREAS {
  description = 'description',
  helper = 'helper',
  progress = 'progress',
}

const Container = styled('div', filterProps('hasHelper'))<{ hasHelper: boolean }>`
  ${({ theme: { colors, mq, utils }, hasHelper }) => css`
    display: grid;
    position: sticky;
    top: var(${GLOBAL_STYLE_VARIABLES.headerHeight});
    grid-template-areas: ${utils.composeTemplateAreas([
      [
        TASK_INFO_AREAS.description,
        ...(hasHelper ? [TASK_INFO_AREAS.helper] : []),
        TASK_INFO_AREAS.progress,
      ],
    ])};
    grid-template-columns: ${hasHelper ? '2fr 3fr 2fr' : '1fr max-content'};
    column-gap: 2rem;
    align-items: center;
    background-color: ${colors.primary.dark};
    padding: 2rem 2rem 2rem 3rem;
    border-radius: 3rem;
    width: 100%;
    margin-top: 6rem;
    z-index: 1;

    &::before {
      content: '';
      display: block;
      width: 100%;
      background: ${colors.neutral.grey};
      position: absolute;
      z-index: -1;
      height: calc(6rem + var(${GLOBAL_STYLE_VARIABLES.headerHeight}));
      top: 1px;
      transform: translateY(-100%);
    }

    ${mq.md.up} {
      max-width: 68rem;
    }

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        [TASK_INFO_AREAS.description, TASK_INFO_AREAS.progress],
        ...(hasHelper ? [[TASK_INFO_AREAS.helper, TASK_INFO_AREAS.progress]] : []),
      ])};
      grid-template-columns: 1fr max-content;
      column-gap: 1rem;
      row-gap: 1rem;
      border-radius: 2rem;
      padding: 1.5rem 1.5rem 1.5rem 2.5rem;
      margin-top: 2rem;

      &::before {
        height: calc(2rem + var(${GLOBAL_STYLE_VARIABLES.headerHeight}));
      }
    }
  `}
`;

const Description = styled.span`
  ${({ theme: { colors, mq, utils } }) => css`
    grid-area: ${TASK_INFO_AREAS.description};
    color: ${utils.alphaHex(colors.neutral.white, 60)};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 1.5rem;

    ${mq.md.down} {
      align-self: center;
    }
  `}
`;

const Helper = styled.div`
  grid-area: ${TASK_INFO_AREAS.helper};
  display: grid;
`;

const Progress = styled.div`
  ${({ theme: { mq } }) => css`
    grid-area: ${TASK_INFO_AREAS.progress};
    display: grid;
    width: 10.25rem;
    height: 10.25rem;
    justify-self: flex-end;
    align-items: center;
    justify-items: center;

    ${mq.md.down} {
      width: 9rem;
      height: 9rem;
    }
  `}
`;

const StyledCircularProgress = styled(CircularProgress)`
  ${({ theme: { colors } }) => css`
    grid-row: 1 / 1;
    grid-column: 1 / 1;

    .${CIRCULAR_PROGRESS_CLASSNAMES.trail} {
      stroke: ${colors.primary.main};
    }
  `}
`;

const ProgressText = styled.span`
  ${({ theme: { colors, utils } }) => css`
    grid-row: 1 / 1;
    grid-column: 1 / 1;
    color: ${colors.neutral.white};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 1.5rem;

    & > span {
      color: ${utils.alphaHex(colors.neutral.grey, 60)};
    }
  `}
`;

const TaskInfo: React.FC<ITaskInfo> = props => {
  const { progress, description, helperElement } = useTaskInfoService(props);
  const { isVisible, currentPoints, totalPoints, className } = props;

  return isVisible ? (
    <Container hasHelper={helperElement !== null} className={className}>
      <Description>{description}</Description>
      {helperElement ? <Helper>{helperElement}</Helper> : null}
      <Progress>
        <StyledCircularProgress progress={progress} strokeWidth={7} />
        <ProgressText>
          {currentPoints}
          <span>/{totalPoints}</span>
        </ProgressText>
      </Progress>
    </Container>
  ) : null;
};

export default TaskInfo;
