import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import CircularProgress, { CIRCULAR_PROGRESS_CLASSNAMES } from 'atoms/CircularProgress';
import { StreakIndicator } from 'features/Streaks';
import { FONT_FAMILIES } from 'theme/emotion/typography';
import { filterProps } from 'utils/helpers';

interface IProgressCard {
  lessonsCompleted: number;
  lessonsTotal: number;
  pointsEarned: number;
}

const Container = styled('div', filterProps('columns'))<{ columns?: number }>`
  ${({ theme: { colors, mq }, columns = 2 }) => css`
    display: grid;
    grid-template-columns: repeat(${columns}, 1fr);
    grid-template-rows: min-content 1fr;
    justify-items: center;
    column-gap: 2rem;
    background-color: ${colors.primary.dark};
    border-radius: 3rem;
    padding: 4rem 4rem 4.5rem;

    ${mq.md.up} {
      min-height: 32.5rem;
    }
    ${mq.md.down} {
      border-radius: 2.5rem;
      padding: 3rem;
      row-gap: 2.5rem;
    }
  `}
`;

const Chip = styled.span`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p3};

    grid-column: 1 / -1;
    grid-row: 1 / 1;
    display: grid;
    align-items: center;
    justify-items: center;
    justify-self: flex-start;
    border: 1px solid ${colors.neutral.white};
    border-radius: 3rem;
    color: ${colors.neutral.white};
    padding: 0 1.25rem;
    height: 3rem;

    ${mq.md.down} {
      padding: 0 1rem;
      height: 2.5rem;
    }
  `}
`;

const InfoContainer = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    align-self: flex-end;
    row-gap: 1.5rem;
    width: 10.25rem;

    ${mq.md.down} {
      row-gap: 1rem;
      width: 9rem;
    }
  `}
`;

const InfoLabel = styled.span`
  ${({ theme: { colors, utils } }) => css`
    text-align: center;
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 1.5rem;
    color: ${utils.alphaHex(colors.neutral.white, 60)};
  `}
`;

const Progress = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    width: 10.25rem;
    height: 10.25rem;
    align-items: center;
    justify-items: center;

    ${mq.md.down} {
      width: 9rem;
      height: 9rem;
    }
  `}
`;

const StyledCircularProgress = styled(CircularProgress)`
  ${({ theme: { colors } }) => css`
    grid-row: 1 / 1;
    grid-column: 1 / 1;

    .${CIRCULAR_PROGRESS_CLASSNAMES.trail} {
      stroke: ${colors.primary.main};
    }
  `}
`;

const ProgressText = styled.h4`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.h3}

    grid-row: 1 / 1;
    grid-column: 1 / 1;
    margin: 0;
    color: ${colors.neutral.white};

    & > span {
      color: ${utils.alphaHex(colors.neutral.grey, 60)};
    }
  `}
`;

const PointsEarned = styled.span`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h3}

    position: relative;
    display: grid;
    justify-items: center;
    align-items: center;
    width: 10.25rem;
    height: 10.25rem;
    border-radius: 50%;
    text-align: center;
    background-color: ${colors.primary.main};
    color: ${colors.neutral.white};

    ${mq.md.down} {
      width: 9rem;
      height: 9rem;
    }
  `}
`;

const StyledStreakIndicator = styled(StreakIndicator)`
  ${({ theme: { colors } }) => css`
    background: ${colors.neutral.white};
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
`;

const ProgressCard = forwardRef<HTMLDivElement, IProgressCard>(function ProgressCardWithRef(
  { lessonsCompleted, lessonsTotal, pointsEarned },
  ref
) {
  return (
    <Container ref={ref} columns={3}>
      <Chip>Progress overview</Chip>
      <InfoContainer>
        <Progress>
          <StyledCircularProgress
            progress={(lessonsCompleted / lessonsTotal) * 100}
            strokeWidth={7}
          />
          <ProgressText>
            {lessonsCompleted}
            <span>/{lessonsTotal}</span>
          </ProgressText>
        </Progress>
        <InfoLabel>Lessons completed</InfoLabel>
      </InfoContainer>
      <InfoContainer>
        <PointsEarned>
          ⭐
          <br />
          {pointsEarned}
        </PointsEarned>
        <InfoLabel>Points earned</InfoLabel>
      </InfoContainer>
      <InfoContainer>
        <PointsEarned>
          <StyledStreakIndicator />
        </PointsEarned>
        <InfoLabel>Current streak</InfoLabel>
      </InfoContainer>
    </Container>
  );
});

export default ProgressCard;
