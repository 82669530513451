import { useDispatch, useSelector } from 'react-redux';

import actions from '../../actions';
import selectors from '../../selectors';
import { useMemo } from 'react';

export const useReviewService = () => {
  const dispatch = useDispatch();
  const messages = useSelector(selectors.getMessages);

  const firstImprovedMessageIndex = useMemo(
    () => messages.findIndex(message => !!message?.improvement),
    [messages]
  );

  const handleTextToSpeech = (sequenceNumber: number) => {
    dispatch(actions.reviewMessageToSpeech(sequenceNumber));
  };

  return { messages, handleTextToSpeech, firstImprovedMessageIndex };
};
