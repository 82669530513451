import { styled } from '@mui/material';

import ConversationControl from 'features/ConversationControl/components/ConversationControlRadio.service';
import ConversationSpeed from 'features/ConversationControl/components/ConversationSpeed.service';
import hideable from 'utils/hideable';

const Container = styled('div')({
  marginTop: 48,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const SettingContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: 280,
  width: '100%',
});

const ChatSettings = () => (
  <Container>
    <SettingContainer>
      <ConversationControl />
    </SettingContainer>
    <SettingContainer>
      <ConversationSpeed />
    </SettingContainer>
  </Container>
);

export default hideable(ChatSettings);
