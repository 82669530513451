import { atom, DefaultValue, selectorFamily } from 'recoil';

export const containersLoadingAtom = atom<Record<string, boolean>>({
  key: 'containersLoadingAtom',
  default: {},
});

export const containersLoadingState = selectorFamily<boolean, string>({
  key: 'containersLoaderSelector',
  get:
    id =>
    ({ get }) =>
      get(containersLoadingAtom)?.[id] ?? false,
  set:
    id =>
    ({ set }, value) => {
      set(containersLoadingAtom, state => ({
        ...state,
        [id]: value instanceof DefaultValue ? false : value,
      }));
    },
});
