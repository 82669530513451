import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { ReactComponent as DownloadSvg } from 'assets/download.svg';
import Button from 'atoms/Button';

import { CLASSNAME_PREFIX } from '../constants';

import { usePromptStepService } from './hooks';

const areas = {
  title: 'title',
  card: 'card',
  download: 'button',
  browser: 'browser',
};

const Container = styled.div`
  ${({ theme: { durations, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [areas.title],
      [areas.card],
      [areas.download],
      [areas.browser],
    ])};
    row-gap: 3rem;
    justify-items: center;
    padding: 4rem;
    width: 58rem;
    grid-row: 1/ 1;
    grid-column: 1/ 1;
    transition: opacity ${durations.regular};

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        ['.'],
        [areas.title],
        [areas.card],
        ['.'],
        [areas.download],
        [areas.browser],
      ])};
      grid-template-rows: 3fr min-content min-content 2fr min-content min-content;
      align-self: stretch;
      align-items: flex-end;
      justify-items: center;
      padding: 2.5rem 0;
      width: 100%;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h1};

    display: grid;
    grid-area: ${areas.title};
    row-gap: 1rem;
    color: ${colors.neutral.white};
    text-align: center;
    margin: 0;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0;
    }
  `}
`;

const Card = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-area: ${areas.card};
    grid-auto-rows: min-content;
    row-gap: 3rem;
    border-radius: 3rem;
    background-color: ${colors.primary.main};
    padding: 5rem;

    ${mq.md.down} {
      row-gap: 2rem;
      padding: 3rem;
    }
  `}
`;

const Benefit = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-template-columns: min-content 1fr;
    row-gap: 1rem;
    column-gap: 1.5rem;
    justify-content: flex-start;

    ${mq.md.down} {
      row-gap: 0.5rem;
    }
  `}
`;

const BenefitEmoji = styled.span`
  ${({ theme: { tp } }) => css`
    ${tp.h3}

    grid-area: 1 / 1 / span 1 / span 1;
  `}
`;

const BenefitHeader = styled.h3`
  ${({ theme: { colors, tp } }) => css`
    ${tp.h3}

    margin: 0;
    color: ${colors.neutral.white};
    grid-area: 1 / 2 / span 1 / span 1;
  `}
`;

const BenefitDescription = styled.h4`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p3}

    margin: 0;
    color: ${utils.alphaHex(colors.neutral.white, 60)};
    grid-area: 2 / 2 / span 1 / span 1;
  `}
`;

const DownloadButton = styled(Button)`
  ${({ theme: { colors, mq } }) => css`
    grid-area: ${areas.download};

    ${mq.hover} {
      border-color: ${colors.primary.dark};
    }
  `}
`;

const BrowserButton = styled(Button)`
  ${({ theme: { colors, mq } }) => css`
    grid-area: ${areas.browser};
    background-color: ${colors.primary.dark};
    color: ${colors.primary.light};

    ${mq.hover} {
      border-color: ${colors.primary.dark};
    }
  `}
`;

const PromptStep: React.FC = () => {
  const { benefits, handleDownload, handleClose } = usePromptStepService();

  return (
    <Container>
      <Title>
        <span>⚡</span>Supercharge Your English Learning Experience!
      </Title>
      <Card>
        {benefits.map(benefit => (
          <Benefit key={JSON.stringify(benefit)}>
            <BenefitEmoji>{benefit.emoji}</BenefitEmoji>
            <BenefitHeader>{benefit.title}</BenefitHeader>
            <BenefitDescription>{benefit.description}</BenefitDescription>
          </Benefit>
        ))}
      </Card>
      <DownloadButton onClick={handleDownload}>
        Download the app
        <DownloadSvg />
      </DownloadButton>
      <BrowserButton variant="outlined" transitionText bare onClick={handleClose}>
        Use the app in the browser
        <ArrowRightSvg />
      </BrowserButton>
    </Container>
  );
};

export default PromptStep;
