import { BASE_EM, BASE_REM } from 'theme/emotion/constants';

const composeRawRemSize = ({
  scaleMax = 100,
  scaleMin = 100,
  priorityWidth = undefined,
  precision = 4,
  widthMax,
  widthMin,
}: {
  scaleMax?: number;
  scaleMin?: number;
  priorityWidth?: number;
  precision?: number;
  widthMax: number;
  widthMin: number;
}): string => {
  const baseScale = (BASE_REM / BASE_EM) * 100;
  const baseAbsoluteSize = BASE_EM;

  const medianWidth = priorityWidth ? priorityWidth : (widthMax - widthMin) / 2 + widthMin;

  const widthDiff = widthMax - widthMin;
  const median = ((medianWidth - widthMin) / widthDiff) * 100;

  const scaleDiff = (scaleMax - scaleMin) / 100;
  const relativeDiff = scaleDiff * baseScale;

  const medianScaleDiff = (relativeDiff * median) / 100;

  const absoluteMedianDiff = (medianScaleDiff * baseAbsoluteSize) / 100;

  const medianWidthDiff = (widthDiff * median) / 100;
  const zeroGradientWidth = widthMax - medianWidthDiff;

  const gradient = absoluteMedianDiff / medianWidthDiff;

  const intersect = -1 * gradient * zeroGradientWidth;

  const viewWidthScale = `${(gradient * 100).toFixed(precision)}vw`;

  const staticScale = `(${viewWidthScale} + ${intersect.toFixed(precision)}px)`;

  const absoluteMaxScale = (scaleMax / 100) * baseScale;

  const dynamicScale = `(${((absoluteMaxScale - medianScaleDiff) / 100).toFixed(
    precision
  )} * ${BASE_EM}px)`;

  return `calc(${dynamicScale} + ${staticScale})`;
};

export default composeRawRemSize;
