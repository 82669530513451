import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import ReviewableMessage from './ReviewableMessage';
import { useReviewService } from './hooks';

import type { Classname } from 'utils/types';
import { HighlightProps } from 'features/Onboarding/components/Highlight/types';

interface IReview extends Classname {
  reviewableMessageHighlightProps?: HighlightProps;
}

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 2rem;

    & > div {
      max-width: 73.5%;
    }

    ${mq.md.down} {
      row-gap: 1.5rem;
      & > div {
        max-width: 82.5%;
      }
    }
  `}
`;

const BotMessage = styled.div`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p1};

    justify-self: flex-end;
    background-color: ${colors.neutral.white};
    padding: 2rem 2.5rem;
    border-radius: 1.5rem 1.5rem 0.25rem 1.5rem;

    ${mq.md.down} {
      padding: 2rem;
    }
  `}
`;

const Review: React.FC<IReview> = ({ className, reviewableMessageHighlightProps }) => {
  const { messages, handleTextToSpeech, firstImprovedMessageIndex } = useReviewService();

  return (
    <Container className={className}>
      {messages.map(({ entity, score, text, improvement, textToSpeechState }, index) => {
        switch (entity) {
          case 'user': {
            return (
              <ReviewableMessage
                key={index}
                text={text}
                score={score}
                improvement={improvement}
                textToSpeechState={textToSpeechState}
                handleTextToSpeech={() => handleTextToSpeech(index)}
                highlightProps={
                  firstImprovedMessageIndex === index ? reviewableMessageHighlightProps : void 0
                }
              />
            );
          }
          case 'bot':
          default: {
            return <BotMessage key={index}>{text}</BotMessage>;
          }
        }
      })}
    </Container>
  );
};

export default Review;
