import { styled } from '@mui/material';
import { Link } from 'react-router-dom';

interface IAuthLink {
  mainText?: string;
  linkText: string;
  link: string;
}

const StyledText = styled('div')({
  fontSize: 14,
  fontWeight: 600,
});

const StyledLink = styled(Link)(props => ({
  fontSize: 14,
  fontWeight: 600,
  color: props.theme.palette.primary.main,
}));

const AuthLink = ({ mainText, link, linkText, ...rest }: IAuthLink) => (
  <StyledText {...rest}>
    {mainText ? `${mainText} ` : ''}
    <StyledLink to={link}>{linkText}</StyledLink>
  </StyledText>
);

export default AuthLink;
