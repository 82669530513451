import { TRootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

import { MINIMUM_WEEKDAYS_TO_SHOW, INDEXED_WEEKDAYS } from './constants';

export const isDailyStreakComplete = (state: TRootState) => state.streaks.dailyStreakComplete;

export const getStreakCount = (state: TRootState) => state.streaks.streakCount;

export const isModalOpen = (state: TRootState) => state.streaks.isModalOpen;

export const getStreakCalendar = createSelector(getStreakCount, streakCount => {
  const daysToShow =
    streakCount < MINIMUM_WEEKDAYS_TO_SHOW ? MINIMUM_WEEKDAYS_TO_SHOW : INDEXED_WEEKDAYS.length;

  const currentWeekdayIndex = new Date().getDay();

  if (streakCount > INDEXED_WEEKDAYS.length) {
    const streakOverflowRemainder = currentWeekdayIndex % INDEXED_WEEKDAYS.length;
    return INDEXED_WEEKDAYS.map((day, index) => ({
      day,
      active: index <= streakOverflowRemainder,
    }));
  } else {
    return [...new Array(daysToShow)].map((_, dayIndex) => {
      return {
        day: INDEXED_WEEKDAYS[(currentWeekdayIndex + dayIndex) % INDEXED_WEEKDAYS.length],
        active: dayIndex < streakCount,
      };
    });
  }
});
