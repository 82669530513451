import React from 'react';
import styled from '@emotion/styled';

import { ReactComponent as StarOutlineSvg } from 'assets/starOutline.svg';
import { ReactComponent as StarFilledSvg } from 'assets/starFilled.svg';
import { css } from '@emotion/react';
import type { ScoreType } from '../types';

interface IScoreStars {
  type: ScoreType;
}

const Container = styled.div<{ type: IScoreStars['type'] }>`
  ${({ theme: { colors, mq }, type }) => css`
    display: grid;
    grid-auto-flow: column;
    column-gap: 0.25rem;

    & > svg {
      width: 2rem;
      height: 2rem;
      fill: ${{ good: colors.secondary.main, basic: colors.warning.main, bad: colors.error.main }[
        type
      ]};
    }

    ${mq.md.down} {
      & > svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  `}
`;

const ScoreStars: React.FC<IScoreStars> = ({ type }) => (
  <Container type={type}>
    {[...new Array(3)].map((_, index) =>
      index < { bad: 1, basic: 2, good: 3 }[type] ? (
        <StarFilledSvg key={index + type} />
      ) : (
        <StarOutlineSvg key={index + type} />
      )
    )}
  </Container>
);

export default ScoreStars;
