import actions from './actions';
import selectors from './selectors';

export const reviewActions = {
  fetchReview: actions.fetchReview,
};

export const reviewSelectors = {
  getScores: selectors.getScores,
  getStatus: selectors.getStatus,
};

export { Review } from './components/Review';
export { ScoreBarChart } from './components/ScoreBarChart';
