import React, { useState } from 'react';
import { styled } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import InputAdornment from '@mui/material/InputAdornment';

import FieldInput, { TFieldInput } from 'components/FieldInput';

const Adornment = styled(InputAdornment)(() => ({
  cursor: 'pointer'
}));

const PasswordField: React.FC<TFieldInput> = props => {
  const [passwordShown, setPasswordShown] = useState(false);

  return (
    <FieldInput
      type={passwordShown ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <Adornment
            position="end"
            onClick={() => setPasswordShown(passwordShown => !passwordShown)}
          >
            {passwordShown ? <Visibility /> : <VisibilityOff />}
          </Adornment>
        ),
      }}
      {...props}
    />
  );
};

export default PasswordField;
