import { styled } from '@mui/material';

import hideable from 'utils/hideable';

const StyledText = styled('div')({
  fontSize: 24,
  lineHeight: '32px',
  fontWeight: 600,
  maxWidth: 500,
});

const BrowserSupportProblem = () => (
  <StyledText>Browser does not support speech recognition.</StyledText>
);

export default hideable(BrowserSupportProblem);
