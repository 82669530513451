export const CURRENT_PROTOCOL_VERSION = 2;

export enum METADATA_KEYS {
  PROTOCOL_VERSION = 'protocolVersion',
  CHUNK_NUMBER = 'chunkNumber',
  RESERVED = 'reserved',
}

export const METADATA_SCHEMA = [
  {
    key: METADATA_KEYS.PROTOCOL_VERSION,
    length: 2,
  },
  {
    key: METADATA_KEYS.CHUNK_NUMBER,
    length: 2,
  },
  {
    key: METADATA_KEYS.RESERVED,
    length: 16,
  },
];

export enum ERRORS {
  NO_ACCESS_TOKEN = `No access token found`,
}

export enum CLOSE_REASONS {
  UNAUTHORIZED = 'User is not authorized to perform this action',
}
