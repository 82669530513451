import { FunctionComponent, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentJourney } from '../../selectors';
import journeys, {
  useGetJourneyStepId,
  JourneyConfig,
  StepByJourney,
  SerializedId,
  Journey,
} from '../../journeys';
import { FlatJourneyProps } from 'features/Onboarding/journeys/types';

type Elements = {
  id: SerializedId;
  Element: FunctionComponent<FlatJourneyProps>;
  journey: Journey;
  step: StepByJourney;
}[];

export const useOnboardingService = () => {
  const currentJourney = useSelector(getCurrentJourney);

  const getJourneyStepId = useGetJourneyStepId();

  const elements: Elements = useMemo(
    () =>
      currentJourney
        ? (journeys[currentJourney] as JourneyConfig).reduce((acm, journeyStep) => {
            const Element = journeyStep.Element;

            if (!Element) return acm;

            const name = journeyStep.name as StepByJourney;
            const id = getJourneyStepId(currentJourney, name);

            if (!id) return acm;

            return [...acm, { id, Element, journey: currentJourney, step: name }];
          }, [] as Elements)
        : [],
    [currentJourney, getJourneyStepId]
  );

  return { elements };
};
