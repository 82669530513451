import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

export interface IPushNotifications {
  isInitialized: boolean;
  isPushNotificationsEnabled: boolean | null;
  isModalOpen: boolean;
  retriggerCount: number;
}

const initialState: IPushNotifications = {
  isInitialized: false,
  isPushNotificationsEnabled: null,
  isModalOpen: false,
  retriggerCount: 0,
};

export const pushNotificationsSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setIsInitialized: (state, action: PayloadAction<boolean>) => {
      state.isInitialized = action.payload;
    },
    setIsPushNotificationsEnabled: (state, action: PayloadAction<boolean | null>) => {
      state.isPushNotificationsEnabled = action.payload;
    },
    setIsModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isModalOpen = action.payload;
    },
    setRetriggerCount: (state, action: PayloadAction<number>) => {
      state.retriggerCount = action.payload;
    },
    resetState: () => ({
      ...initialState,
    }),
  },
});

export const {
  setIsInitialized,
  setIsPushNotificationsEnabled,
  setIsModalOpen,
  setRetriggerCount,
  resetState,
} = pushNotificationsSlice.actions;
