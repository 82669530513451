import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { FONT_FAMILIES } from 'theme/emotion/typography';
import { ClassNames } from 'theme/emotion';
import { Classnames } from 'utils/types';
import { useFeedbackEvaluation } from 'utils/hooks/useFeedbackEvaluation';

export interface IFeedback extends Classnames<'task' | 'points' | 'feedback'> {
  taskName: string | null;
  pointsEarned: number;
  taskEvaluationId: string;
}

const Task = styled.span`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p1};

    text-align: center;
    color: ${utils.alphaHex(colors.primary.dark, 55)};
    margin: 0 0 2rem;

    ${mq.md.down} {
    }
  `}
`;

const PointsContainer = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: flex;
    align-items: center;
    background-color: ${colors.neutral.white};
    border-radius: 2rem;
    padding: 2rem 3rem;
    column-gap: 2rem;

    ${mq.md.down} {
      column-gap: 1.5rem;
    }
  `}
`;

const PointsHelperText = styled.span`
  ${({ theme: { colors, mq, utils } }) => css`
    color: ${utils.alphaHex(colors.primary.dark, 55)};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 1.5rem;
    margin: 0;

    ${mq.md.down} {
    }
  `}
`;

const PointsEarned = styled.span`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h3}

    color: ${colors.primary.dark};
    margin: 0;

    ${mq.md.down} {
    }
  `}
`;

const FeedbackSurveyContainer = styled.div`
  max-width: 100%;
  border-radius: 2rem;
  overflow: hidden;

  && [class^='_hj'] {
    & [class$='__Feedback__isEmbedded'] {
      width: 100%;
    }

    & [class$='__ExpandedWidget__isEmbedded'] {
      margin: 0;
    }
    & [class$='__EmotionStep__isEmbedded'] {
      min-height: initial;
    }

    & [class$='__EmotionStep__noBranding'] {
      height: initial;
    }

    & [class$='__FinalStep__isEmbedded'] {
      min-height: initial;
    }

    & [class$='__EmotionOption__isEmbedded'] > :last-child {
      padding-top: 0.5rem;
    }
  }
`;

const Feedback: React.FC<IFeedback> = ({
  taskName,
  pointsEarned,
  taskEvaluationId,
  classNames,
}) => {
  const feedbackRef = useFeedbackEvaluation({ userTaskProgressId: taskEvaluationId });

  return (
    <ClassNames classNames={classNames}>
      {c => (
        <>
          {taskName ? <Task className={c.task}>{`"${taskName}" task completed`}</Task> : null}
          <PointsContainer className={c.points}>
            <PointsHelperText>Points earned</PointsHelperText>
            <PointsEarned>⭐ {pointsEarned}</PointsEarned>
          </PointsContainer>
          <FeedbackSurveyContainer
            data-feedback-id="task-complete"
            className={c.feedback}
            ref={feedbackRef}
          />
        </>
      )}
    </ClassNames>
  );
};

export default Feedback;
