import { useCallback, useEffect } from 'react';
import OneSignal from 'react-onesignal';
import TagManager from 'react-gtm-module';

import { isDev, isIOS } from 'utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import { getUserId } from 'features/Auth/selectors';
import {
  setIsInitialized,
  setIsModalOpen,
  setIsPushNotificationsEnabled,
} from 'features/PushNotifications/slice';
import { isInitialized } from 'features/PushNotifications/selectors';

export const useInitializePushNotificationsService = () => {
  const userId = useSelector(getUserId);
  const initialized = useSelector(isInitialized);
  const dispatch = useDispatch();

  useEffect(() => {
    if (initialized && userId) {
      OneSignal.setExternalUserId(userId);

      OneSignal.on('notificationPermissionChange', permissionChange => {
        if (permissionChange.to === 'granted') {
          TagManager.dataLayer({
            dataLayer: {
              event: 'pushNotificationAccessGranted',
              userId,
            },
          });
        } else if (permissionChange.to === 'denied') {
          TagManager.dataLayer({
            dataLayer: {
              event: 'pushNotificationAccessDenied',
              userId,
            },
          });
        }

        (async () => {
          dispatch(setIsPushNotificationsEnabled(await OneSignal.isPushNotificationsEnabled()));
        })();
      });
    }
  }, [initialized, userId]);

  return async () => {
    if (!initialized) {
      await OneSignal.init({
        appId: process.env.VITE_ONE_SIGNAL_APP_ID as string,
        safari_web_id: process.env.VITE_ONE_SIGNAL_SAFARI_WEB_ID as string,
        allowLocalhostAsSecureOrigin: isDev,
        serviceWorkerPath: 'push/onesignal/OneSignalSDKWorker.js',
        serviceWorkerParam: {
          scope: '/push/onesignal/',
        },
      });

      dispatch(setIsInitialized(true));
      dispatch(setIsPushNotificationsEnabled(await OneSignal.isPushNotificationsEnabled()));
    }
  };
};

export const useShowPushNotificationsModal = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    const isPwaInstalled = !!(window.navigator as unknown as { standalone: boolean })?.standalone;

    if (!isIOS || isPwaInstalled) {
      dispatch(setIsModalOpen(true));
    }
  }, []);
};
