import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NAME } from './constants';

export interface IForgotPassword {
  email: string;
  success: string;
  error: string;
  isLoading: boolean;
}

const initialState: IForgotPassword = {
  email: '',
  error: '',
  success: '',
  isLoading: false,
};

export const forgotPasswordSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setSuccess: (state, action: PayloadAction<string>) => {
      state.success = action.payload;
    },
    resetState: () => initialState,
  },
});

export const { setEmail, setLoading, setError, setSuccess, resetState } =
  forgotPasswordSlice.actions;
