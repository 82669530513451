import { useDispatch, useSelector } from 'react-redux';

import { getBrowserNotSupported, getMicrophoneAccess } from 'features/AudioInput/selectors';
import { useEffect } from 'react';
import { promptForMicrophoneAccess } from 'features/AudioInput/actions';

export const useSettingsService = () => {
  const microphoneAccess = useSelector(getMicrophoneAccess);
  const isBrowserSupported = !useSelector(getBrowserNotSupported);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(promptForMicrophoneAccess());
  }, []);

  return { microphoneAccess, isBrowserSupported };
};
