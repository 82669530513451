import { TRootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { UserSubscriptionCurrency } from 'features/User/types';

export const getUser = (state: TRootState) => state.user;

export const getId = createSelector(getUser, ({ id }) => id);

export const getUsername = createSelector(getUser, ({ username }) => username);

export const getEmail = createSelector(getUser, ({ email }) => email);

export const getCancellationStep = createSelector(
  getUser,
  ({ cancellationStep }) => cancellationStep
);

export const getSubscription = createSelector(getUser, ({ subscription }) => subscription);

export const getTier = createSelector(getSubscription, subscription => subscription?.tier);
export const getStatus = createSelector(getSubscription, subscription => subscription?.status);

export const getNextPaymentDate = createSelector(getSubscription, subscription => {
  if (!subscription) return '';

  const { nextPaymentDate } = subscription;

  return moment(nextPaymentDate).format('MMM DD, YYYY');
});

export const getPaymentFrequencyMonths = createSelector(
  getSubscription,
  subscription => subscription?.paymentFrequencyMonths ?? 6
);

export const getPaymentAmount = createSelector(getSubscription, subscription => {
  if (!subscription) return null;

  const { paymentAmount, currency } = subscription;

  const currencySign = (
    {
      USD: '$',
      Euro: '€',
    } as Record<UserSubscriptionCurrency, string>
  )[currency ?? 'USD'];

  const normalizedPaymentAmount = Number(paymentAmount || 0)
    .toFixed(2)
    .split('.')
    .join(',');

  return `${currencySign}${normalizedPaymentAmount}`;
});
