import callApi, { METHODS, PATHS } from 'api';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';

export interface ILeaderboardParams {
  leaderboard_metric: 'words_count' | 'active_days_count';
}

export interface ILeaderboardRanking {
  username: string;
  rank: number;
  score: number;
}

export interface ILeaderboard {
  username: string;
  user_represented_quantile: number;
  top_rankings: ILeaderboardRanking[];
  metric: ILeaderboardParams['leaderboard_metric'];
}

export const useLeaderboard = () => {
  return useQuery({
    queryKey: ['useLeaderboard'],
    queryFn: async () => {
      const response: AxiosResponse<ILeaderboard, {}> = await callApi({
        method: METHODS.GET,
        mainPath: PATHS.USER_LEADERBOARD,
        authorized: true,
        queryParams: [
          {
            key: 'leaderboard_metric',
            value: 'active_days_count',
          },
        ],
      });
      return response.data;
    },
  });
};
