import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as FullLogo } from 'assets/logo/fullLogo.svg';
import { ReactComponent as IconLogo } from 'assets/logo/iconLogo.svg';
import { ReactComponent as NamedLogo } from 'assets/logo/namedLogo.svg';
import { filterProps } from 'utils/helpers';
import { Classname } from 'utils/types';

export enum LOGO_VARIANTS {
  icon = 'icon',
  named = 'named',
  full = 'full',
}

type TLogo = Classname & {
  variant?: LOGO_VARIANTS;
} & (
    | { variant?: LOGO_VARIANTS.icon; light?: never }
    | ({
        variant: LOGO_VARIANTS.full | LOGO_VARIANTS.named;
      } & Partial<ILogoInterpolation>)
  );

interface ILogoInterpolation {
  light: boolean;
}

const StyledIconLogo = styled(IconLogo)`
  width: auto;
  height: auto;
`;

const StyledFullLogo = styled(FullLogo, filterProps('light'))<ILogoInterpolation>`
  ${({ theme: { durations, colors }, light }) => css`
    ${StyledIconLogo.__emotion_styles}

    ${light
      ? css`
          fill: ${colors.neutral.white};
          transition: fill ${durations.regular};
        `
      : ''}
  `}
`;

const StyledNamedLogo = styled(NamedLogo, filterProps('light'))<ILogoInterpolation>`
  ${({ theme: { durations, colors }, light }) => css`
    ${StyledIconLogo.__emotion_styles}

    ${light
      ? css`
          fill: ${colors.neutral.white};
          transition: fill ${durations.regular};
        `
      : ''}
  `}
`;

const Logo: React.FC<TLogo> = ({ className, variant = LOGO_VARIANTS.icon, light = false }) => {
  switch (variant) {
    case LOGO_VARIANTS.full: {
      return <StyledFullLogo className={className} light={light} />;
    }
    case LOGO_VARIANTS.named: {
      return <StyledNamedLogo className={className} light={light} />;
    }
    case LOGO_VARIANTS.icon:
    default: {
      return <StyledIconLogo className={className} />;
    }
  }
};

export default Logo;
