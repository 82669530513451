import React, { forwardRef, PropsWithChildren, ReactNode } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import Button from 'atoms/Button';
import CancelButton from 'atoms/CancelButton';
import { ClassNames, FONT_FAMILIES } from 'theme/emotion';
import { filterProps } from 'utils/helpers';

import { STACK_LEVEL } from '../../constants';

import { useTooltipService } from './hooks';

import type { Classnames } from 'utils/types';
import type { Directions, TooltipProps } from './types';

type TTooltip = PropsWithChildren &
  Classnames<
    'outline' | 'position' | 'container' | 'arrow' | 'content' | 'cancel' | 'progress' | 'button'
  > &
  TooltipProps & {
    proceedElement?: ReactNode;
  };

export enum TOOLTIP_VARIABLES {
  inset = '--inset',
  distance = '--distance',
  arrowSpacing = '--arrow-spacing',
  arrowDistance = '--arrow-distance',
}

enum TOOLTIP_AREAS {
  content = 'content',
  cancel = 'cancel',
  progress = 'progress',
  button = 'button',
}

const CLASSNAME_PREFIX = 'fade';

const Outline = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const Position = styled('div', filterProps('cardinals', 'transforms'))<{
  cardinals: Record<Directions, string>;
  transforms: { x: number; y: number };
}>`
  ${({ theme: { depths, durations, mq }, cardinals, transforms }) => css`
    ${TOOLTIP_VARIABLES.inset}: -2rem;

    position: absolute;
    z-index: ${depths.priority + STACK_LEVEL.tooltip};
    top: ${cardinals.top};
    right: ${cardinals.right};
    bottom: ${cardinals.bottom};
    left: ${cardinals.left};
    transform: translate(${transforms.x}%, ${transforms.y}%);

    ${mq.md.down} {
      ${TOOLTIP_VARIABLES.inset}: -1.5rem;
    }

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }
  `}
`;

const Container = styled.main`
  ${({ theme: { colors, utils } }) => css`
    position: relative;
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [TOOLTIP_AREAS.content, TOOLTIP_AREAS.content, TOOLTIP_AREAS.cancel],
      [TOOLTIP_AREAS.progress, TOOLTIP_AREAS.button, TOOLTIP_AREAS.button],
    ])};
    column-gap: 1.25rem;
    row-gap: 1.5rem;
    padding: 1.5rem 1.5rem 1.5rem 2rem;
    background-color: ${colors.primary.dark};
    border-radius: 1.5rem;
  `}
`;

const Arrow = styled('div', filterProps('cardinals', 'transforms'))<{
  cardinals: Record<Directions, string>;
  transforms: { x: number; y: number; rotate: number };
}>`
  ${({ theme: { colors, mq }, cardinals, transforms }) => css`
    ${TOOLTIP_VARIABLES.distance}: 1rem;
    ${TOOLTIP_VARIABLES.arrowSpacing}: 2.5rem;
    ${TOOLTIP_VARIABLES.arrowDistance}: calc((var(${TOOLTIP_VARIABLES.inset}) * -1) - var(${TOOLTIP_VARIABLES.distance}));

    position: absolute;
    background-color: ${colors.primary.dark};
    border-bottom-left-radius: 0.5rem;
    width: 2rem;
    height: 2rem;
    top: ${cardinals.top};
    right: ${cardinals.right};
    bottom: ${cardinals.bottom};
    left: ${cardinals.left};
    transform: translate(${transforms.x}%, ${transforms.y}%) rotate(${transforms.rotate}deg);

    ${mq.md.down} {
      ${TOOLTIP_VARIABLES.distance}: 0.5rem;
      ${TOOLTIP_VARIABLES.arrowSpacing}: 2rem;
    }
  `}
`;

const Content = styled.div`
  grid-area: ${TOOLTIP_AREAS.content};
  padding-top: 0.5rem;
`;

const Progress = styled.span`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p2}

    grid-area: ${TOOLTIP_AREAS.progress};
    align-self: center;
    background: initial;
    border: initial;
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    color: ${utils.alphaHex(colors.neutral.white, 60)};

    & > span {
      color: ${colors.neutral.white};
    }
  `}
`;

const StyledProceedButton = styled(Button)`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p2}

    grid-area: ${TOOLTIP_AREAS.button};
    justify-self: flex-end;
    column-gap: 0.5rem;

    && {
      padding: 0 2rem;
      min-height: 4.5rem;
      border-width: 0.25rem;

      & > svg {
        width: 2rem;
        height: 2rem;
      }
    }

    ${mq.hover} {
      border-color: ${colors.primary.dark};
    }
  `}
`;

const StyledCancelButton = styled(CancelButton)`
  grid-area: ${TOOLTIP_AREAS.cancel};
  align-self: flex-start;
  justify-self: flex-end;

  & > svg {
    width: 2rem;
    height: 2rem;
  }
`;

const Tooltip = forwardRef<HTMLDivElement, TTooltip>(
  (
    {
      children,
      classNames,
      proceedElement = (
        <>
          Next
          <ArrowRightSvg />
        </>
      ),
      ...props
    },
    ref
  ) => {
    const {
      handleProceed,
      tooltipPortal,
      handleCancel,
      positionCardinals,
      arrowCardinals,
      positionTransforms,
      arrowTransforms,
      current,
      total,
    } = useTooltipService(props);

    return tooltipPortal(
      <ClassNames classNames={classNames}>
        {c => (
          <Outline className={c.outline} ref={ref}>
            <CSSTransition in timeout={300} appear classNames={CLASSNAME_PREFIX}>
              <Position
                className={c.position}
                cardinals={positionCardinals}
                transforms={positionTransforms}
              >
                <Container className={c.container}>
                  <Arrow
                    className={c.arrow}
                    cardinals={arrowCardinals}
                    transforms={arrowTransforms}
                  />
                  <Content className={c.content}>{children}</Content>
                  <StyledCancelButton className={c.cancel} onClick={handleCancel} />
                  {total ? (
                    <Progress className={c.progress}>
                      <span>{current}</span>/{total}
                    </Progress>
                  ) : null}
                  <StyledProceedButton className={c.button} onClick={handleProceed} inverted>
                    {proceedElement}
                  </StyledProceedButton>
                </Container>
              </Position>
            </CSSTransition>
          </Outline>
        )}
      </ClassNames>
    );
  }
);

export default Tooltip;
