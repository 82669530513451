import React, { forwardRef, PropsWithChildren } from 'react';
import styled from '@emotion/styled';

import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';

import type { Classname } from 'utils/types';
import { css } from '@emotion/react';

export enum LAYOUT_TEMPLATE_COLUMNS {
  gridStart = 'grid-start',
  contentStart = 'content-start',
  contentEnd = 'content-end',
  gridEnd = 'grid-end',
}

export const layoutStyles = css`
  display: grid;
  grid-template-columns:
    [ ${LAYOUT_TEMPLATE_COLUMNS.gridStart}]
    var(${GLOBAL_STYLE_VARIABLES.margin})
    [ ${LAYOUT_TEMPLATE_COLUMNS.contentStart}]
    var(${GLOBAL_STYLE_VARIABLES.container})
    [ ${LAYOUT_TEMPLATE_COLUMNS.contentEnd}]
    var(${GLOBAL_STYLE_VARIABLES.margin})
    [ ${LAYOUT_TEMPLATE_COLUMNS.gridEnd}];

  > * {
    grid-column: ${LAYOUT_TEMPLATE_COLUMNS.gridStart} / ${LAYOUT_TEMPLATE_COLUMNS.gridEnd};
  }

  > header,
  > footer,
  > main {
    grid-column: ${LAYOUT_TEMPLATE_COLUMNS.contentStart} / ${LAYOUT_TEMPLATE_COLUMNS.contentEnd};
  }
`;

const StyledLayout = styled.div`
  ${layoutStyles};
  position: relative;
  grid-template-rows: min-content 1fr min-content;
  z-index: 0;
  min-height: 100vh;

  > header,
  > footer,
  > main {
    z-index: 0;
  }

  > first-child:not(header) {
    grid-row-end: span 2;
  }
`;

const Layout = forwardRef<HTMLDivElement, PropsWithChildren<Classname>>(
  ({ children, className }, ref) => (
    <StyledLayout className={className} ref={ref}>
      {children}
    </StyledLayout>
  )
);

export default Layout;
