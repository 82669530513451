import React from 'react';
import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as ChevronRightSvg } from 'assets/chevronRight.svg';
import { Classname } from 'utils/types';
import { filterProps } from 'utils/helpers';

type TProfileLink = Classname & {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  children?: string;
} & ({ url: string; onClick?: never } | { url?: never; onClick: () => void });

const Container = styled(Link)`
  ${({ theme: { colors, durations, mq, utils } }) => css`
    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    column-gap: 1rem;
    row-gap: 0.5rem;
    padding: 1rem;
    margin: 0 -1rem;
    user-select: none;
    transition: all ${durations.regular};
    border-radius: 2rem;

    text-decoration: none;

    & > svg {
      width: 2.25rem;
      height: 2.25rem;
    }

    &:active {
      background-color: ${utils.alphaHex(colors.primary.main, 10)};
    }

    ${mq.hover} {
      &:hover {
        background-color: ${utils.alphaHex(colors.primary.main, 5)};
      }
    }

    ${mq.md.down} {
      grid-template-columns: min-content 1fr min-content;
      padding: 2rem;
      margin: 0 -2rem;

      & > svg {
        width: 2rem;
        height: 2rem;
      }
    }
  `}
`;

const Label = styled.span`
  ${({ theme: { colors, tp } }) => css`
    ${tp.p1};

    color: ${colors.primary.main};
  `}
`;

const Caption = styled('span', filterProps('show'))<{ show: boolean }>`
  ${({ theme: { colors, mq, tp, utils }, show }) => css`
    ${tp.p3};

    display: ${show ? 'inline-block' : 'none'};
    grid-row-start: 2;
    grid-column-start: 2;
    color: ${utils.alphaHex(colors.primary.main, 55)};

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const ChevronRight = styled(ChevronRightSvg)`
  ${({ theme: { mq } }) => css`
    grid-row: 1 / -1;
    grid-column: 3 / span 1;

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const ProfileLink: React.FC<TProfileLink> = ({
  className,
  url = '#',
  label,
  Icon,
  onClick,
  children,
}) => (
  <Container to={url} onClick={onClick} className={className}>
    <>
      <Icon />
      <Label>{label}</Label>
      <ChevronRight />
      <Caption show={!!children}>{children}</Caption>
    </>
  </Container>
);

export default ProfileLink;
