import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';

import { useLessonEvaluation, useTaskEvaluation } from 'features/Task/queries';
import { useLessonTasks } from 'features/TaskSelection/queries';
import { useLessons } from 'features/Lessons/queries';
import { ROUTES } from 'navigation/constants';
import { useScrollLock } from 'utils/hooks';

import type { ITaskComplete } from './TaskComplete';

export const useTaskCompleteService = ({ taskId, lessonId, handleClose }: ITaskComplete) => {
  const navigate = useNavigate();

  const [lock, unlock] = useScrollLock();

  const lessonsData = useLessons();
  const lessonTasksData = useLessonTasks(lessonId);
  const lessonEvaluationData = useLessonEvaluation(lessonId);
  const taskEvaluationData = useTaskEvaluation(lessonId, taskId);

  const [step, setStep] = useState<'loading' | 'results' | 'review'>('loading');
  const hasLoadedRef = useRef<boolean>(false);

  const goToResults = useCallback(() => setStep('results'), [step]);

  const goToReview = useCallback(() => setStep('review'), [step]);

  useEffect(() => {
    lock();

    return () => {
      unlock();
    };
  }, []);

  useEffect(
    function transitionToResultsFromLoading() {
      if (
        !lessonsData.isLoading &&
        !lessonTasksData.isLoading &&
        !lessonEvaluationData.isLoading &&
        !taskEvaluationData.isLoading &&
        !hasLoadedRef.current
      ) {
        goToResults();
        hasLoadedRef.current = true;
      }
    },
    [lessonsData, lessonTasksData, lessonEvaluationData, taskEvaluationData]
  );

  const task = useMemo(
    () =>
      (!lessonTasksData.isLoading &&
        lessonTasksData.data &&
        lessonTasksData.data.length &&
        lessonTasksData.data.find(({ id }) => taskId === id)) ||
      null,
    [lessonTasksData, taskId]
  );

  const taskName = useMemo(() => task?.name ?? null, [task]);

  const taskEvaluation = useMemo(
    () =>
      (!taskEvaluationData.isLoading && taskEvaluationData.data && taskEvaluationData.data) || null,
    [taskEvaluationData]
  );

  const lessonEvaluation = useMemo(
    () =>
      (!lessonEvaluationData.isLoading && lessonEvaluationData.data && lessonEvaluationData.data) ||
      null,
    [lessonEvaluationData]
  );

  const pointsEarned = useMemo(
    () => (taskEvaluation?.points ?? lessonEvaluation?.points_earned ?? 0) || 0,
    [taskEvaluation, lessonEvaluation]
  );

  const { durations } = useTheme();

  const timeout = useMemo(() => Number(durations.regular.slice(0, -2)), [durations]);

  const goToTaskSelection = useCallback(() => {
    handleClose();
    navigate(ROUTES.LESSON.buildPath({ id: lessonId }));
  }, [lessonId, handleClose]);

  return {
    step,
    timeout: 0,
    taskName,
    pointsEarned,
    taskEvaluationId: taskEvaluation?.id ?? '',
    goToResults,
    goToReview,
    goToTaskSelection,
    canReview: task?.type !== 'pronunciation_game',
  };
};
