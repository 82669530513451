import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { filterProps } from 'utils/helpers';

import PushToTalkButton from './PushToTalkButton';
import { TranscribedText } from './TranscribedText';
import { usePushToTalkWorkspaceService } from './hooks';

interface IPushToTalkWorkspace {}

const Container = styled('div', filterProps('position', 'hasTopPadding'))<{
  position: 'fixed' | 'absolute';
  hasTopPadding: boolean;
}>`
  ${({ theme: { colors, mq }, position, hasTopPadding }) => css`
    position: ${position};
    display: grid;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: ${colors.neutral.white};
    border-radius: 4rem 4rem 0 0;
    padding: ${hasTopPadding ? 3 : 0}rem 3rem 0;
    box-shadow: 0px -1.5rem 2.5rem 0px rgba(43, 20, 59, 0.04);
    z-index: 5;

    ${mq.md.down} {
      border-radius: 3rem 3rem 0 0;
    }
  `}
`;

const PushToTalkWorkspace: React.FC<IPushToTalkWorkspace> = () => {
  const { shouldRender, pushToTalkActive, isOnboarding } = usePushToTalkWorkspaceService();

  return shouldRender ? (
    <Container position={isOnboarding ? 'absolute' : 'fixed'} hasTopPadding={pushToTalkActive}>
      <TranscribedText />
      {pushToTalkActive ? <PushToTalkButton /> : null}
    </Container>
  ) : null;
};

export default PushToTalkWorkspace;
