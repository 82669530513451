import React, { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { filterProps } from 'utils/helpers';
import { FONT_FAMILIES } from 'theme/emotion';

import type { Word } from 'features/Pronunciation/slice';
import type { Classname } from 'utils/types';
import { Highlight } from 'features/Onboarding';

interface IProse extends Classname {
  words: Word[];
}

export type HighlightState = 'default' | 'low' | 'medium' | 'high';

const Sentence = styled.span`
  ${({ theme: { tp } }) => css`
    ${tp.h1};

    line-height: 1.4;
  `}
  text-align: center;
`;

const Word = styled('span', filterProps('highlight'))<{ highlight: HighlightState }>`
  ${({ theme: { colors, durations, utils }, highlight }) => css`
    transition: ${durations.regular} ease-out;
    transition-property: color;

    font-weight: ${['default', 'low'].includes(highlight)
      ? FONT_FAMILIES.Poppins.regular
      : FONT_FAMILIES.Poppins.semiBold};
    color: ${highlight === 'high'
      ? colors.secondary.main
      : utils.alphaHex(colors.primary.main, highlight === 'low' ? 25 : 55)};
  `}
`;

const useScaleFontSizeToContainer = (words: unknown[] = []) => {
  const underlayRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLSpanElement>(null);

  const scaleFont = useCallback(() => {
    if (textRef.current) {
      const sentenceElement = textRef.current;

      sentenceElement.style.fontSize = '';

      const isOverflown = () => {
        return document.body.scrollHeight > window.innerHeight;
      };

      let fontSize = parseInt(
        window.getComputedStyle(sentenceElement, null).getPropertyValue('font-size'),
        10
      );

      const isSmallest = () => {
        const currentHeight = textRef.current?.parentElement?.parentElement?.offsetHeight ?? 0;
        const minHeight = underlayRef?.current?.offsetHeight ?? 0;
        if (currentHeight <= minHeight) return true;
        return false;
      };

      while ((!isSmallest() || fontSize <= 0) && isOverflown()) {
        fontSize = fontSize - 0.5;
        sentenceElement.style.fontSize = fontSize + 'px';
      }
    }
  }, []);

  useEffect(() => {}, [words]);

  useLayoutEffect(() => {
    scaleFont();
    setTimeout(scaleFont, 50);
  }, [words, scaleFont]);

  return { underlayRef, textRef };
};

const evaluatePronunciation = (pronunciation: number | undefined): HighlightState => {
  switch (true) {
    case pronunciation === undefined: {
      return 'default';
    }
    case Number(pronunciation) > 85: {
      return 'high';
    }
    case Number(pronunciation) > 60: {
      return 'medium';
    }
    default: {
      return 'low';
    }
  }
};

const Underlay = styled.div`
  ${({ theme: { depths, mq } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 20rem;
    width: 1px;
    pointer-events: none;
    z-index: ${depths.deepdive};

    ${mq.md.down} {
      height: 18rem;
    }
  `}
`;

const Wrapper = styled.div`
  height: fit-content;
  text-align: center;
`;

const Prose: React.FC<IProse> = ({ className, words }) => {
  const { underlayRef, textRef } = useScaleFontSizeToContainer(words);

  return (
    <>
      <Underlay ref={underlayRef} />
      <Highlight<HTMLDivElement>
        journey="pronunciation"
        step="wordEvaluation"
        proceedStep={null}
        directStep={null}
      >
        {({ ref }) => (
          <Wrapper ref={ref}>
            <Sentence className={className} ref={textRef}>
              {words.map(({ word, pronunciation }, index) => (
                <Word key={word + index} highlight={evaluatePronunciation(pronunciation)}>
                  {`${word} `}
                </Word>
              ))}
            </Sentence>
          </Wrapper>
        )}
      </Highlight>
    </>
  );
};

export default Prose;
