import { channel } from 'redux-saga';
import { all, call, fork, put, race, select, take } from 'typed-redux-saga/macro';
import {
  initializePronunciationTask,
  startPronunciation,
  startTextToSpeech,
  stopTextToSpeech,
} from 'features/Pronunciation/actions';
import { captureException, captureMessage } from '@sentry/react';
import callApi, { METHODS, PATHS } from 'api';

import {
  initializeSentences,
  resetState,
  setCurrentSentenceIndex,
  setEvaluate,
  setIsLoading,
  setPushToTalkState,
  setTextToSpeechState,
  updateSentence,
} from 'features/Pronunciation/slice';
import {
  getCurrentScore,
  getCurrentSentence,
  getCurrentSentenceIndex,
} from 'features/Pronunciation/selectors';
import { setCurrentJourney, setCurrentStep } from 'features/Onboarding/slice';
import { COOKIE_KEY, getCookie } from 'utils/cookies';
import { checkStreak } from 'features/Streaks/actions';
import { startListeningWithStream } from 'app/audio/Input/sagas/startListeningWithStream';
import pronunciationSocket, { OnResultCallback } from 'app/api/stt/pronunciation';
import audioInputActions from 'app/audio/Input/actions';
import { OnCloseBeforeMarkCallback } from 'app/api/stt/sttSocket';
import { arrayBufferToBase64 } from 'utils/helpers';

import type { OggVorbisDecoder } from '@wasm-audio-decoders/ogg-vorbis';

function* fetchPronunciationRoundInfo(taskId: string) {
  try {
    const { data } = yield* call(callApi, {
      method: METHODS.POST,
      mainPath: PATHS.GET_PRONUNCIATION_INFO,
      extraPath: `${taskId}/`,
      authorized: true,
    });

    return data as {
      pronunciation_round_id: string;
      sentences: { sentence: string; order: number }[];
    };
  } catch (error) {
    yield* call(captureException, error);
    throw new Error('Failed to fetch');
  }
}

function* fetchPronunciationEvaluation({
  pronunciationRoundId,
  audioFilename,
  evaluationJson,
  order,
}: {
  pronunciationRoundId: string;
  audioFilename: string;
  evaluationJson: Record<string, unknown>;
  order: number;
}) {
  try {
    const { data } = yield* call(callApi, {
      method: METHODS.POST,
      mainPath: PATHS.GET_PRONUNCIATION_EVALUATION,
      extraPath: `${pronunciationRoundId}/`,
      data: {
        order,
        audio_filename: audioFilename,
        evaluation_json: evaluationJson,
      },
      authorized: true,
    });

    return data as {
      pronunciation_round_id: string;
      overall_score: number;
      sentences: { sentence: string; order: number; id: string; best_score: number }[];
      current_evaluation: {
        pronunciation: number;
        words: { word: string; order: number; pronunciation: number }[];
      };
    };
  } catch (error) {
    yield* call(captureException, error);
    throw new Error('Failed to fetch');
  }
}

function* startPronunciationTranscription(id: string) {
  const { sentence } = yield* select(getCurrentSentence);
  const sequenceNumber = yield* select(getCurrentSentenceIndex);

  const stream = yield* call(startListeningWithStream);

  const { sendAudio, sendMark, onResult, onCloseBeforeMark } = yield* call(pronunciationSocket, {
    id,
    sequenceNumber,
    sentence,
  } as const);

  yield* fork(onResult, function* ({ audioFilename, evaluationJson }) {
    const {
      current_evaluation: { pronunciation, words },
    } = yield* call(fetchPronunciationEvaluation, {
      pronunciationRoundId: id,
      order: sequenceNumber,
      audioFilename,
      evaluationJson,
    });

    yield* put(
      updateSentence({
        index: sequenceNumber,
        score: pronunciation,
        words,
      })
    );
  } as OnResultCallback);

  yield* fork(onCloseBeforeMark, function* () {
    yield* put(
      updateSentence({
        index: Number.MAX_SAFE_INTEGER,
        score: 0,
        words: [],
      })
    );
  } as OnCloseBeforeMarkCallback);

  yield* call(function* () {
    const reader = stream.getReader();
    try {
      while (true) {
        const { done, value: audio } = yield* call([reader, reader.read]);

        if (done) {
          break;
        } else {
          yield* call(sendAudio, audio);
        }
      }
    } finally {
      yield* call(sendMark);
      reader.releaseLock();
    }
  });
}

function* fetchTextToSpeech(text: string) {
  try {
    const { data } = yield* call(callApi<{ text: string }, ArrayBuffer>, {
      method: METHODS.POST,
      mainPath: PATHS.TEXT_TO_SPEECH,
      data: {
        text,
      },
      authorized: true,
      responseType: 'arraybuffer',
    });

    return data;
  } catch (error) {
    yield* call(captureException, error);
    throw new Error('Failed to fetch');
  }
}

function* startTextToSpeechPlayback(
  audioOutputContext: AudioContext,
  sentenceArrayBuffers: AudioBuffer[]
) {
  while (true) {
    const source = audioOutputContext.createBufferSource();
    try {
      yield* take(startTextToSpeech);

      const currentSentenceIndex = yield* select(getCurrentSentenceIndex);

      yield* put(setTextToSpeechState('loading'));

      source.buffer = sentenceArrayBuffers[currentSentenceIndex];

      if (audioOutputContext.state === 'suspended') {
        yield* call([audioOutputContext, audioOutputContext.resume]);
      }

      source.connect(audioOutputContext.destination);

      yield* call([source, source.start], 0);

      yield* put(setTextToSpeechState('active'));

      const endedChannel = yield* call(channel);
      source.addEventListener('ended', () => {
        endedChannel.put('');
      });

      yield* race([take(endedChannel), take(stopTextToSpeech), take(setCurrentSentenceIndex)]);
    } finally {
      source.disconnect();
      yield* put(setTextToSpeechState('idle'));
    }
  }
}

let decoder: OggVorbisDecoder;

function* fetchSentencesTTSData(sentences: string[], audioOutputContext: AudioContext) {
  return yield* all(
    sentences.map(sentence =>
      call(function* () {
        const arrayBuffer = yield* call(fetchTextToSpeech, sentence);
        const base64Data = yield* call(arrayBufferToBase64, arrayBuffer);

        const binaryData = window.atob(base64Data);
        const buffer = new ArrayBuffer(binaryData.length);
        let view = new Uint8Array(buffer);

        for (let i = 0; i < binaryData.length; i++) {
          view[i] = binaryData.charCodeAt(i);
        }

        try {
          return yield* call([audioOutputContext, audioOutputContext.decodeAudioData], arrayBuffer);
        } catch {
          yield* call(async () => {
            captureMessage('WebAudioAPI decoding failed');

            if (!decoder) {
              const WasmDecoders = await import('@wasm-audio-decoders/ogg-vorbis');
              decoder = new WasmDecoders.OggVorbisDecoder();
            }
          });

          yield decoder.ready;
          const { errors, sampleRate, channelData } = yield decoder.decodeFile(view);

          yield decoder.reset;

          if (!errors.length && channelData.length === 1) {
            const audioBuffer = yield* call(
              [audioOutputContext, audioOutputContext.createBuffer],
              1,
              channelData[0].length,
              sampleRate
            );

            const audioBufferChannelData = yield* call(
              [audioBuffer, audioBuffer.getChannelData],
              0
            );
            audioBufferChannelData.set(channelData[0]);

            return audioBuffer;
          }
        }
      })
    )
  );
}

export function* watchPronunciation() {
  while (true) {
    yield* race([
      call(function* () {
        const { payload: taskId } = yield* take(initializePronunciationTask);
        const audioOutputContext = new AudioContext();

        try {
          yield* put(setIsLoading(true));
          yield* put(setPushToTalkState('loading'));

          const {
            data: { pronunciationRoundId, sentenceAudioBuffers },
          } = yield* all({
            data: call(function* () {
              try {
                const { pronunciation_round_id, sentences: sentencesData } = yield* call(
                  fetchPronunciationRoundInfo,
                  taskId
                );

                const sentences = sentencesData.map(({ sentence }) => sentence);

                yield* put(initializeSentences(sentences));

                const audioBuffers = (yield* call(
                  fetchSentencesTTSData,
                  sentences,
                  audioOutputContext
                )) as unknown as AudioBuffer[];

                yield* put(setIsLoading(false));
                yield* put(setPushToTalkState('disabled'));

                return {
                  pronunciationRoundId: pronunciation_round_id,
                  sentenceAudioBuffers: audioBuffers,
                };
              } catch {
                yield* put(setIsLoading(false));
                yield* put(setPushToTalkState('disabled'));
                throw Error();
              }
            }),
          });

          yield* put(setPushToTalkState('idle'));
          yield* put(setTextToSpeechState('idle'));

          yield* put(setCurrentJourney('pronunciation'));
          yield* put(setCurrentStep('pushToTalk'));

          yield* race([
            call(function* () {
              yield* fork(startTextToSpeechPlayback, audioOutputContext, sentenceAudioBuffers);

              yield* put(startTextToSpeech());

              while (true) {
                yield* take(startPronunciation);
                yield* put(stopTextToSpeech());

                yield* fork(startPronunciationTranscription, pronunciationRoundId);
                yield* put(setPushToTalkState('active'));
                yield* put(setTextToSpeechState('disabled'));

                yield* take(audioInputActions.stopListening);
                yield* put(setPushToTalkState('loading'));
                yield* put(setIsLoading(true));
                yield* take(updateSentence);
                yield* put(setIsLoading(false));

                const currentScore = yield* select(getCurrentScore);

                if (currentScore) {
                  const wordEvaluationSeen = getCookie(COOKIE_KEY.wordEvaluationSeen);
                  if (wordEvaluationSeen !== 'true') {
                    yield* put(setCurrentJourney('pronunciation'));
                    yield* put(setCurrentStep('wordEvaluation'));
                  }
                }

                yield* put(setPushToTalkState(currentScore ? 'retry' : 'idle'));
                yield* put(setTextToSpeechState('idle'));
              }
            }),
            take(setEvaluate),
          ]);
        } finally {
          yield* call([audioOutputContext, audioOutputContext.close]);
          yield* put(checkStreak());
        }
      }),
      take(resetState),
    ]);
    yield* put(setCurrentJourney(null));
    yield* put(setCurrentStep(null));
    yield* put(audioInputActions.stopListening());
  }
}
