import {
  ActionReducerMapBuilder,
  CaseReducer,
  createSlice as baseCreateSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { keysOf } from 'utils/helpers';

import type { NoInfer } from 'react-redux';
import type {
  CaseReducerActions,
  SliceCaseReducers,
  CreateSliceOptions,
  Slice,
} from '@reduxjs/toolkit';
import type { KeysOf } from 'utils/types';

export type GetActions<A extends CaseReducerActions<SliceCaseReducers<unknown>, string>> = {
  [P in keyof A]: ReturnType<A[P]>;
};

export const getActionTypes = <
  Actions extends CaseReducerActions<SliceCaseReducers<unknown>, string>,
  Keys extends KeysOf<Actions>,
  Name extends string,
  ActionTypesMap extends { [P in Keys]: `${Name}/${P}` }
>({
  actions,
  name,
}: {
  actions: Actions;
  name: Name;
}): ActionTypesMap =>
  keysOf(actions).reduce((acm, actionName) => {
    return { ...acm, [actionName]: `${name}/${actionName}` };
  }, {} as ActionTypesMap);

type NormalizeSliceName<SliceName extends string | number | symbol> =
  SliceName extends `${infer Name}Slice` ? Name : SliceName;

const normalizeSliceName = <SliceName extends string>(name: SliceName) =>
  name.replace(/Slice$/, '') as NormalizeSliceName<SliceName>;

export const createSlice = <
  State,
  CaseReducers extends SliceCaseReducers<State>,
  Name extends string = string,
  ChildSlices extends Record<string, Slice> = {},
  StateWithChildSlices = State & {
    [Key in keyof ChildSlices as NormalizeSliceName<Key>]: ReturnType<
      ChildSlices[Key]['getInitialState']
    >;
  }
>(
  { extraReducers, initialState, ...options }: CreateSliceOptions<State, CaseReducers, Name>,
  childSlices?: ChildSlices
) => {
  const extraReducersWithChildState: CreateSliceOptions<
    StateWithChildSlices,
    SliceCaseReducers<StateWithChildSlices>,
    Name
  >['extraReducers'] = builder => {
    Object.entries(childSlices || {}).forEach(([childSliceName, childSlice]) => {
      const sliceName = normalizeSliceName(childSliceName) as keyof StateWithChildSlices;

      Object.values(childSlice.actions).forEach(childSliceAction => {
        builder.addCase(childSliceAction.type, (state, action) => {
          const stateWithChildSlices = state as StateWithChildSlices;

          stateWithChildSlices[sliceName] = childSlice.reducer(
            stateWithChildSlices[sliceName],
            action
          );
        });
      });
    });

    if (typeof extraReducers === 'function') {
      extraReducers(builder as unknown as ActionReducerMapBuilder<NoInfer<State>>);
    } else {
      return extraReducers;
    }
  };

  const initialStateWithChildState = Object.entries(childSlices || {}).reduce(
    (acm, [childSliceName, childSlice]) => ({
      ...acm,
      [normalizeSliceName(childSliceName)]: childSlice.getInitialState(),
    }),
    {} as StateWithChildSlices
  );

  (
    options.reducers as typeof options.reducers & {
      resetState: CaseReducer<StateWithChildSlices, PayloadAction>;
    }
  ).resetState = () => ({ ...initialState, ...initialStateWithChildState });

  return baseCreateSlice({
    ...(options as unknown as Omit<
      CreateSliceOptions<StateWithChildSlices, SliceCaseReducers<StateWithChildSlices>, Name>,
      'initialState' | 'extraReducers'
    >),
    initialState: { ...initialState, ...initialStateWithChildState },
    extraReducers: extraReducersWithChildState,
  }) as unknown as Omit<Slice<State, CaseReducers, Name>, 'getInitialState'> &
    Omit<
      Slice<
        StateWithChildSlices,
        SliceCaseReducers<StateWithChildSlices> & {
          resetState: CaseReducer<StateWithChildSlices, PayloadAction>;
        },
        Name
      >,
      'getInitialState'
    > & {
      getInitialState: () => StateWithChildSlices;
    };
};
