import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import StreakAudio from 'assets/streak.wav';

import { setIsModalOpen } from '../../slice';
import { getStreakCalendar, getStreakCount, isModalOpen } from '../../selectors';

import { ANIMATION_DELAY_MS } from './animations';

export const useStreaksModalService = () => {
  const showModal = useSelector(isModalOpen);
  const streakCount = useSelector(getStreakCount);
  const streakCalendar = useSelector(getStreakCalendar);

  const dispatch = useDispatch();

  const [localStreakCount, setLocalStreakCount] = useState(streakCount - 1);

  const handleClose = useCallback(() => {
    dispatch(setIsModalOpen(false));
  }, [dispatch]);

  useEffect(() => {
    if (showModal) {
      [...new Array(streakCalendar.length)].forEach((_, index) => {
        setTimeout(() => {
          if (index >= streakCalendar.length - 3) {
            setLocalStreakCount(streakCount);
          }

          new Audio(StreakAudio).play();
        }, ANIMATION_DELAY_MS * index);
      });

      setLocalStreakCount(streakCount - 1);
    }
  }, [streakCalendar, showModal]);

  const streakCountAnimationDelay = useMemo(
    () => Math.max(0, streakCalendar.length - 4) * ANIMATION_DELAY_MS,
    [streakCalendar]
  );

  return {
    showModal,
    handleClose,
    streakCount: localStreakCount,
    streakCountAnimationDelay,
  };
};
