import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import { useTheme } from '@emotion/react';
import { useRecoilValue } from 'recoil';

import { containersLoadingAtom } from 'molecules/ContainerLoader/state';
import { APP_ROOT_ID } from 'utils/constants';
import { useAwaitElement, useAwaitNode } from 'utils/hooks';

import { STACK_LEVEL } from '../../constants';
import { useDeferredOnboardingCallback } from '../../hooks';
import journeys, {
  Journey,
  StepByJourney,
  useGetJourneyStepId,
  useGotoJourneyStep,
} from '../../journeys';
import { getCurrentJourney, getCurrentStep } from '../../selectors';

import Outline from './Outline';

import type { JourneyStep } from '../../journeys/types';
import type { PositionProps, HighlightProps } from './types';
import ClassNames from 'theme/emotion/ClassNames';

const getRootId = <JourneyKey extends Journey = Journey>(
  journey: JourneyKey,
  step: StepByJourney<JourneyKey>
) => {
  const journeyConfig = journeys?.[journey];

  if (!journeyConfig) return null;

  const stepIndex = journeyConfig.findIndex(({ name }) => name === step);

  if (stepIndex < 0) return null;

  const stepConfig: JourneyStep = journeyConfig[stepIndex];

  if (!stepConfig) return null;

  return stepConfig?.rootId ?? null;
};

export const useAncestorStack = (rootId: string) => {
  const mutatedElements = useRef<HTMLElement[]>([]);

  const setParentStack = (element: HTMLElement) => {
    const parent = element.parentElement;

    if (parent && parent !== document.querySelector(`#${rootId}`)) {
      const { zIndex } = getComputedStyle(parent);

      if (zIndex !== 'auto') {
        parent.style.setProperty('z-index', 'auto');
        mutatedElements.current.push(parent);
      }

      setParentStack(parent);
    }
  };

  const undoReset = () => {
    mutatedElements.current.forEach(mutatedElement => {
      mutatedElement.style.setProperty('z-index', '');
    });
    mutatedElements.current = [];
  };

  return [setParentStack, undoReset];
};

const getPositionProps = (element: HTMLElement | null): PositionProps | null =>
  element
    ? {
        top: element.getBoundingClientRect().top,
        left: element.getBoundingClientRect().left,
        width: element.offsetWidth,
        height: element.offsetHeight,
      }
    : null;

const usePosition = (element: HTMLElement | null) => {
  const [positionProps, setPositionProps] = useState<PositionProps | null>(
    getPositionProps(element)
  );

  useEffect(() => {
    const updateElementPosition = () => {
      const props = getPositionProps(element);
      setPositionProps(props);
    };
    const observer = new ResizeObserver(updateElementPosition);

    observer.observe(document.body);
    document.addEventListener('scroll', updateElementPosition);

    return () => {
      observer.disconnect();
      document.removeEventListener('scroll', updateElementPosition);
    };
  }, [element]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const checkForPositionUpdates = (
      lastUpdatedPosition: PositionProps,
      currentElement: HTMLElement,
      verificationCount = 0
    ) => {
      if (verificationCount === 500) return;

      const currentPosition = getPositionProps(currentElement);

      if (JSON.stringify(lastUpdatedPosition) === JSON.stringify(currentPosition)) {
        // check again a bunch of times
        timeout = setTimeout(() => {
          checkForPositionUpdates(lastUpdatedPosition, currentElement, verificationCount + 1);
        }, 10);
      } else {
        setPositionProps(currentPosition);
      }
    };
    if (positionProps && element) checkForPositionUpdates(positionProps, element);

    return () => {
      clearTimeout(timeout);
    };
  }, [positionProps, element]);

  return positionProps;
};

export const useHighlightService = <
  RefType extends HTMLElement,
  Callback extends Function = Function
>({
  journey,
  step,
  localTrigger = 'highlight',
  proceedStep,
  directStep,
  remoteTrigger,
  hasRendered = true,
  forwardedCallback,
  highlightOnly = false,
  classNames,
}: HighlightProps<Callback>) => {
  const { depths } = useTheme();

  const containerLoaders = useRecoilValue(containersLoadingAtom);
  const currentJourney = useSelector(getCurrentJourney);
  const currentStep = useSelector(getCurrentStep);

  const rootId = getRootId(journey, step) ?? APP_ROOT_ID;

  const [ref, element] = useAwaitElement<RefType>(hasRendered);

  const position = usePosition(element);
  const getJourneyStepId = useGetJourneyStepId();
  const gotoJourneyStep = useGotoJourneyStep();
  const [normalizeAncestorStack, resetAncestorStack] = useAncestorStack(rootId);

  const elementHasLoaded = useDeferredOnboardingCallback({
    journey,
    step,
    remoteTrigger: 'tooltip',
  });

  const isStepActive = useMemo(
    () => journey === currentJourney && step === currentStep,
    [journey, step, currentJourney, currentStep]
  );

  const id = useMemo(() => getJourneyStepId(journey, step), [journey, step]);

  const loadedRef = useRef(false);

  const isLoading = useMemo(
    () => Object.values(containerLoaders).some(loading => loading),
    [containerLoaders]
  );
  useEffect(() => {
    if (!loadedRef.current && isStepActive && element && !isLoading) {
      setTimeout(() => {
        element.style.setProperty('z-index', `${depths.priority + STACK_LEVEL.element}`);
        normalizeAncestorStack(element);

        loadedRef.current = true;
        elementHasLoaded();
        document.body.scrollTo();
      }, 0);
    }

    return () => {
      if (loadedRef.current && element && !isStepActive) {
        element.style.setProperty('z-index', '');
        resetAncestorStack(element);
      }
    };
  }, [isStepActive, element, elementHasLoaded, isLoading]);

  useEffect(() => {
    if (!hasRendered && loadedRef.current) {
      gotoJourneyStep(proceedStep);
    }
  }, [hasRendered, gotoJourneyStep, proceedStep]);

  const onboardingNode = useAwaitNode(`#${rootId}`, isStepActive && !!element);

  const outlinePortal = useMemo(() => {
    if (!highlightOnly && isStepActive && position && id && onboardingNode) {
      return createPortal(
        <ClassNames classNames={classNames}>
          {c => (
            <Outline id={id} position={position} className={isStepActive ? c.outline : void 0} />
          )}
        </ClassNames>,
        onboardingNode
      );
    }

    return null;
  }, [isStepActive, position, id, onboardingNode, highlightOnly]);

  const callback = useDeferredOnboardingCallback(
    {
      journey,
      step,
      ...(remoteTrigger
        ? { remoteTrigger }
        : {
            localTrigger,
            proceedStep,
            directStep,
          }),
    },
    trigger => {
      if (trigger === 'local') {
        gotoJourneyStep(directStep);
      }
      if (trigger === 'remote') {
        gotoJourneyStep(proceedStep);
      }

      if (forwardedCallback) {
        forwardedCallback();
      }
    }
  );

  return { ref, outlinePortal, callback };
};
