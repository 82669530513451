import { createSelector } from '@reduxjs/toolkit';

import { NAME } from './constants';
import type { TRootState } from 'store';

const getPronunciation = (state: TRootState) => state[NAME];

export const isPronunciationLoading = createSelector(
  getPronunciation,
  ({ isLoading }) => isLoading
);

export const getCurrentSentenceIndex = createSelector(
  getPronunciation,
  ({ currentSentenceIndex }) => currentSentenceIndex
);

export const getPushToTalkButtonState = createSelector(
  getPronunciation,
  ({ pushToTalkState }) => pushToTalkState
);

export const getTextToSpeechButtonState = createSelector(
  getPronunciation,
  ({ textToSpeechState }) => textToSpeechState
);

export const getSentences = createSelector(getPronunciation, ({ sentences }) => sentences);

export const getCurrentSentence = createSelector(
  getPronunciation,
  getSentences,
  ({ currentSentenceIndex }, sentences) => sentences?.[currentSentenceIndex] ?? null
);

export const getCurrentWords = createSelector(
  getCurrentSentence,
  sentence => sentence?.words ?? []
);

export const getCurrentScore = createSelector(getCurrentSentence, sentence => {
  if (sentence) {
    const { scores } = sentence;
    return scores.length ? scores[scores.length - 1] : 0;
  }
  return 0;
});

export const getCurrentSentencePronunciationAttempts = createSelector(
  getCurrentSentence,
  sentence => sentence?.scores.reduce((acm, score) => (score > 0 ? acm + 1 : acm), 0) ?? 0
);

export const shouldEvaluate = createSelector(getPronunciation, ({ evaluate }) => evaluate);
