import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from './actions';
import selectors from './selectors';

export const useBrandReviewPrompt = () => {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector(selectors.getModalStep);
  const canReview = useSelector(selectors.canReview);

  const open = useCallback(() => {
    dispatch(actions.setModalStep('prompt'));
  }, [modalIsOpen, canReview]);

  return { canReview, open } as const;
};
