import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Journey, StepByJourney, useGetJourneyStepId } from './journeys';
import { getCallbackTriggerId, getCurrentJourney, getCurrentStep } from './selectors';
import { resetState, setCallbackTriggerId } from './slice';

import type { TriggerOptions } from './types';

export const useIsOnboardingStepActive = <JourneyKey extends Journey = Journey>(
  journey: JourneyKey,
  step: StepByJourney<JourneyKey>
) => {
  const currentJourney = useSelector(getCurrentJourney);
  const currentStep = useSelector(getCurrentStep);

  return useMemo(
    () => journey === currentJourney && step === currentStep,
    [journey, step, currentJourney, currentStep]
  );
};

export const useStopOnboarding = () => {
  const currentJourney = useSelector(getCurrentJourney);
  const dispatch = useDispatch();

  return useCallback(() => {
    if (currentJourney !== null) {
      dispatch(resetState());
    }
  }, [currentJourney]);
};

export const useDeferredOnboardingCallback = <JourneyKey extends Journey = Journey>(
  {
    journey,
    step,
    localTrigger,
    remoteTrigger,
  }: {
    journey: JourneyKey;
    step: StepByJourney<JourneyKey>;
  } & TriggerOptions,
  callback: (trigger?: 'local' | 'remote') => void = () => void 0
) => {
  const currentJourney = useSelector(getCurrentJourney);
  const currentStep = useSelector(getCurrentStep);
  const callbackTriggerId = useSelector(getCallbackTriggerId);
  const dispatch = useDispatch();

  const getJourneyStepId = useGetJourneyStepId();

  const isStepActive = useMemo(
    () => journey === currentJourney && step === currentStep,
    [journey, step, currentJourney, currentStep]
  );

  const id = useMemo(() => getJourneyStepId(journey, step), [journey, step]);

  useEffect(() => {
    if (localTrigger && callbackTriggerId === `${localTrigger}@${id}`) {
      dispatch(setCallbackTriggerId(null));
      callback('remote');
    }
  }, [callbackTriggerId, callback, localTrigger, dispatch]);

  return useCallback(() => {
    if (isStepActive) {
      if (id) {
        callback('local');

        if (remoteTrigger) dispatch(setCallbackTriggerId(`${remoteTrigger}@${id}`));
      }
    } else {
      callback();
    }
  }, [callback, id, remoteTrigger, dispatch]);
};
