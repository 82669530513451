import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import selectors, { getSequenceMessage } from 'features/Chat/selectors';
import { getConversationMethod } from 'features/ConversationControl/selectors';

const useTranscribingState = () => {
  const pushToTalkState = useSelector(selectors.getPushToTalkState);
  const handsFreeState = useSelector(selectors.getHandsFreeState);

  const conversationMethod = useSelector(getConversationMethod);

  if (conversationMethod === 'hands_free') {
    return handsFreeState;
  } else {
    return pushToTalkState;
  }
};

export const useTranscribedTextService = () => {
  const conversationMethod = useSelector(getConversationMethod);
  const id = useSelector(selectors.getConversationId);
  const sequenceNumber = useSelector(selectors.getSequenceNumber);
  const currentMessage = useSelector(
    getSequenceMessage({ id: id!, sequenceNumber: sequenceNumber!, entity: 'user' })
  );

  const state = useTranscribingState();

  const textRenderedRef = useRef(true);
  const textDeletedRef = useRef(false);
  const [renderKey, setRenderKey] = useState(0);
  const [effectKey, setEffectKey] = useState(0);
  const [shouldRender, setShouldRender] = useState<boolean>(false);
  const [sequence, setSequence] = useState<(number | string | (() => void))[]>([
    '',
    0,
    '',
    () => {
      textRenderedRef.current = true;
    },
  ]);

  useEffect(
    function setShouldRenderWorker() {
      if (state === 'listening' || state === 'transcribing') {
        setShouldRender(true);
      } else {
        if (state === 'idle' || textDeletedRef.current) {
          setShouldRender(false);
        }
      }
    },
    [state, effectKey]
  );

  useEffect(
    function updateSequenceBasedOnCurrentMessageChanges() {
      if (textRenderedRef.current) {
        textRenderedRef.current = false;
        textDeletedRef.current = false;

        setSequence(currentSequence => {
          const current = currentSequence[2] as string;

          const isDeleting = current.length > currentMessage.length;
          return [
            current,
            isDeleting ? 500 : 0,
            currentMessage,
            () => {
              textRenderedRef.current = true;

              if (isDeleting) {
                textDeletedRef.current = true;
                setEffectKey(key => key + 1);
              }

              if (currentMessage && currentMessage !== current) {
                setEffectKey(key => key + 1);
              }
            },
          ];
        });
        setRenderKey(key => key + 1);
      }
    },
    [currentMessage, effectKey]
  );

  return {
    shouldRender,
    shouldRenderMarginTop: conversationMethod === 'hands_free' && shouldRender,
    state,
    renderKey,
    sequence,
  };
};
