import { FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';

import hideable from 'utils/hideable';
import { filterProps } from 'utils/materialUI';
import { CONVERSATION_METHOD } from '../constants';
import { Highlight } from 'features/Onboarding';

interface IConversationControlRadio {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IStyledLabel {
  isBeta?: boolean;
  isUpgraded?: boolean;
}

const StyledRadioGroup = styled(RadioGroup)`
  row-gap: 1rem;
`;

const StyledLabel = styled(
  FormControlLabel,
  filterProps('isBeta', 'isUpgraded')
)<IStyledLabel>(({ theme, isBeta = false, isUpgraded = false }) => ({
  '.MuiTypography-root': {
    fontWeight: 500,
    fontSize: 14,
  },
  ...(isUpgraded
    ? {
        '.MuiFormControlLabel-label': {
          display: 'inline-flex',
          columnGap: 8,
          '&::after': {
            display: 'block',
            padding: '0 6px 0',
            color: 'white',
            borderRadius: 4,
            background: 'orange',
            content: '"Upgraded"',
          },
        },
      }
    : {}),
  ...(isBeta
    ? {
        '.MuiFormControlLabel-label': {
          display: 'inline-flex',
          columnGap: 8,
          '&::after': {
            display: 'block',
            padding: '0 6px 0',
            color: theme.palette.secondary.contrastText,
            borderRadius: 4,
            background: theme.palette.secondary.main,
            content: '"Beta"',
          },
        },
      }
    : {}),
}));

const StyledRadio = styled(Radio)(({ theme: { palette } }) => ({
  '&.Mui-checked svg': {
    fill: palette.primary.main,
  },
  '& svg': {
    fontSize: '3rem',
  },
}));

const SettingTitle = styled('div')({
  fontSize: 16,
  lineHeight: '22px',
  marginTop: 24,
  fontWeight: 600,
  marginBottom: 12,
});

const ConversationControlRadio = ({ onChange, value }: IConversationControlRadio) => (
  <StyledRadioGroup onChange={onChange} value={value}>
    <SettingTitle>Conversation control method</SettingTitle>
    <Highlight journey="lessons" step="conversationControl">
      {({ ref }) => (
        <StyledLabel
          value={CONVERSATION_METHOD.PUSH_TO_TALK}
          control={<StyledRadio />}
          label="Push to talk"
          isUpgraded
          ref={ref}
        />
      )}
    </Highlight>
    <Highlight journey="lessons" step="conversationControl" highlightOnly>
      {({ ref }) => (
        <StyledLabel
          value={CONVERSATION_METHOD.HANDS_FREE}
          control={<StyledRadio />}
          label="Hands free"
          isBeta
          ref={ref}
        />
      )}
    </Highlight>
  </StyledRadioGroup>
);

export default hideable(ConversationControlRadio);
