import * as Yup from 'yup';

import { IRegistrationForm } from './types';
import { PASSWORD_MAXIMUM_LENGTH, PASSWORD_MINIMUM_LENGTH } from 'features/Registration/constants';

export const initialValues: IRegistrationForm = { email: '', password: '', confirmPassword: '' };

export const RegistrationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('An email is required'),
  password: Yup.string()
    .min(
      PASSWORD_MINIMUM_LENGTH,
      `Password must be at least ${PASSWORD_MINIMUM_LENGTH} characters long`
    )
    .max(
      PASSWORD_MAXIMUM_LENGTH,
      `Password cannot be longer than ${PASSWORD_MAXIMUM_LENGTH} characters`
    )
    .required('A password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Passwords must match'),
});
