import { TRootState } from 'store';

export const isInitialized = (state: TRootState) => state.pushNotifications.isInitialized;

export const isPushNotificationsEnabled = (state: TRootState) =>
  state.pushNotifications.isPushNotificationsEnabled;

export const isModalOpen = (state: TRootState) => state.pushNotifications.isModalOpen;

export const getRetriggerCount = (state: TRootState) => state.pushNotifications.retriggerCount;
