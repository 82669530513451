import { route, string } from 'react-router-typesafe-routes/dom';

import { roleplaysRoutes } from 'features/Roleplays';
import { userRoutes } from 'features/User';

export const ROUTES = {
  HOME: route(''),
  TASK: route('task/:id', {
    params: { id: string().defined() },
    state: { lessonId: string().defined() },
  }),
  LESSON: route('lesson/:id', { params: { id: string().defined() } }),
  LOGIN: route('login'),
  REGISTRATION: route('registration'),
  FORGOT_PASSWORD: route('forgot-password'),
  CHAT: route('chat/:id', { params: { id: string().defined() } }),
  LEADERBOARD: route('leaderboard'),
  NO_SUBSCRIPTION: route('no-subscription'),
  ...roleplaysRoutes,
  ...userRoutes,
};
