import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Button from 'atoms/Button';
import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import { FONT_FAMILIES } from 'theme/emotion';

import { CLASSNAME_PREFIX } from '../constants';

import ReviewPanel from './ReviewPanel';
import { useResultsStepService } from './hooks';

export interface IResultsStep {
  taskName: string;
  pointsEarned: number;
  taskEvaluationId: string;
  canReview: boolean;
  goToTaskSelection: () => void;
  goToReview: () => void;
  handleClose: () => void;
}

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    grid-area: 1 / 1 / 1 / 1;
    align-self: center;
    justify-self: center;
    justify-items: center;
    width: 100%;
    max-width: 56.25rem;

    & > * {
      z-index: 1;
    }

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${0} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${0} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }

    ${mq.md.up} {
      row-gap: 3rem;
    }

    ${mq.md.down} {
      grid-template-rows: 1fr min-content 1fr min-content;
      height: 100%;
      padding-bottom: 7rem;

      &::before {
        content: '';
        width: 100%;
        min-height: 10rem;
        grid-row: 3 / span 1;
      }
    }
  `}
`;

const Content = styled.div`
  ${({ theme: { mq } }) => css`
    position: relative;
    display: grid;
    grid-auto-rows: min-content;
    align-self: center;
    justify-self: center;
    justify-items: center;
    row-gap: 1rem;
    width: 100%;

    ${mq.md.down} {
      grid-row: 2 / span 1;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h1};

    display: grid;
    row-gap: 1rem;
    text-align: center;
    margin: 0 0 2rem;
    padding: 0 4rem;
    color: ${colors.neutral.white};

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const Card = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    justify-self: stretch;
    justify-items: center;
    row-gap: 1rem;
    background-color: ${colors.neutral.grey};
    padding: 4.5rem 4rem;
    border-radius: 3rem;

    ${mq.md.down} {
      padding: 4rem 3rem 3rem;
    }
  `}
`;

const Task = styled.span`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p1};

    text-align: center;
    color: ${utils.alphaHex(colors.primary.dark, 55)};
    margin-bottom: 2rem;

    & > strong {
      color: ${colors.primary.dark};
      font-weight: ${FONT_FAMILIES.Poppins.regular};
    }

    ${mq.md.down} {
      margin-bottom: 1rem;
    }
  `}
`;

const PointsPanel = styled.div`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    justify-content: space-between;
    background-color: ${colors.neutral.white};
    padding: 1.5rem 2.5rem;
    border-radius: 2rem;
    width: 100%;

    & > span {
      ${tp.h4};

      color: ${utils.alphaHex(colors.primary.dark, 55)};
    }

    & > strong {
      ${tp.h3};
    }

    ${mq.md.down} {
      border-radius: 1.5rem;
      padding: 1.5rem 2rem;

      & > span {
        line-height: 1.25;
      }
    }
  `}
`;

const EvaluationPanel = styled.div`
  max-width: 100%;
  border-radius: 2rem;
  overflow: hidden;

  ${({ theme: { colors, utils } }) => css`
    && [class^='_hj'] {
      & [class$='__Feedback__isEmbedded'] {
        width: 100%;
      }

      & [class$='__ExpandedWidget__isEmbedded'] {
        margin: 0;
        background-color: ${colors.primary.main};

        & p {
          color: ${utils.alphaHex(colors.neutral.white, 60)} !important;
        }
      }

      & [class$='__EmotionStep__isEmbedded'] {
        min-height: initial;
      }

      & [class$='__EmotionStep__noBranding'] {
        height: initial;
      }

      & [class$='__FinalStep__isEmbedded'] {
        min-height: initial;
      }

      & [class$='__EmotionOption__isEmbedded'] > :last-child {
        padding-top: 0.5rem;
      }
    }
  `}
`;

const Buttons = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    justify-items: center;
    row-gap: 2.5rem;

    ${mq.md.down} {
      row-gap: 3rem;
      grid-row: 4 / span 1;
    }
  `}
`;

const TaskButton = styled(Button)`
  ${({ theme: { colors, mq } }) => css`
    ${mq.hover} {
      border-color: ${colors.primary.dark};
    }
  `}
`;

const ContinueButton = styled(Button)`
  ${({ theme: { colors } }) => css`
    background-color: transparent;
    color: ${colors.primary.lightDark};

    & > svg {
      fill: ${colors.primary.lightDark};
    }
  `}
`;

const ResultsStep: React.FC<IResultsStep> = ({
  taskName,
  pointsEarned,
  taskEvaluationId,
  canReview,
  goToTaskSelection,
  handleClose,
  goToReview: baseGoToReview,
}) => {
  const { feedbackRef, goToReview, reviewOnboardingProceedStep, reviewStatus } =
    useResultsStepService({
      taskEvaluationId,
      goToReview: baseGoToReview,
      canReview,
    });

  return (
    <Container>
      <Content>
        <Title>Congratulations!</Title>
        <Card>
          <Task>
            <strong>{taskName}</strong>
            {` task completed`}
          </Task>
          <PointsPanel>
            <span>Points earned</span>
            <strong>{pointsEarned}</strong>
          </PointsPanel>
          <ReviewPanel
            status={reviewStatus}
            onboardingProceedStep={reviewOnboardingProceedStep}
            goToReview={goToReview}
          />
        </Card>
        <EvaluationPanel data-feedback-id="task-complete" ref={feedbackRef} />
      </Content>
      <Buttons>
        <TaskButton onClick={goToTaskSelection}>
          Proceed to the next task
          <ArrowRightSvg />
        </TaskButton>
        <ContinueButton onClick={handleClose} variant="outlined" bare transitionText>
          <ArrowLeftSvg />
          Continue conversation
        </ContinueButton>
      </Buttons>
    </Container>
  );
};

export default ResultsStep;
