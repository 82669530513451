import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';

import { FONT_FAMILIES } from 'theme/emotion/typography';
import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import { ROUTES } from 'navigation/constants';
import Feedback, { IFeedback } from './Feedback';
import Button from 'atoms/Button';

interface ILessonEvaluation extends IFeedback {
  lessonName: string | null;
  handleClose: () => void;
}

const Container = styled.div`
  ${({ theme: { colors, mq } }) => css`
    position: relative;
    display: grid;
    grid-auto-rows: min-content;
    justify-items: center;
    row-gap: 1rem;
    width: 68rem;
    background-color: ${colors.primary.dark};
    padding: 6rem;
    overflow-y: auto;

    ${mq.md.down} {
      grid-template-rows: 1fr repeat(5, min-content);
      padding: 15rem 3rem 10.5rem;
      width: 100%;
      height: 100%;

      &::before {
        content: '';
      }
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h1};
    display: grid;
    row-gap: 1rem;
    color: ${colors.neutral.white};
    text-align: center;
    margin: 0;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const Subtitle = styled.h3`
  ${({ theme: { colors, mq, tp, utils } }) => css`
    ${tp.p1};

    text-align: center;
    color: ${utils.alphaHex(colors.neutral.white, 60)};
    margin: 0;
    padding: 0 4rem;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const TopicInfo = styled.div`
  ${({ theme: { colors, mq } }) => css`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 11rem max-content;
    align-items: center;
    column-gap: 2rem;
    background-color: ${colors.primary.main};
    border-radius: 2rem;
    padding: 2rem 3rem;
    margin: 2rem 0;

    ${mq.md.down} {
      padding: 1.5rem 2rem;
      border-radius: 1.5rem;
    }
  `}
`;

const TopicHelperText = styled.span`
  ${({ theme: { colors, utils } }) => css`
    color: ${utils.alphaHex(colors.neutral.white, 60)};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 1.5rem;
    margin: 0;
  `}
`;

const Topic = styled.h6`
  ${({ theme: { colors, tp } }) => css`
    ${tp.h3};

    text-align: center;
    color: ${colors.neutral.white};
    margin: 0;
  `}
`;

const Card = styled.div`
  ${({ theme: { colors } }) => css`
    display: grid;
    justify-self: stretch;
    justify-items: center;
    row-gap: 1rem;
    background-color: ${colors.neutral.grey};
    border-radius: 3rem;
    padding: 4.5rem 3rem;
    margin-top: 3rem;
  `}
`;

const LessonButton = styled(Button)`
  ${({ theme: { colors, mq } }) => css`
    ${mq.hover} {
      border-color: ${colors.primary.dark};
    }
  `}
`;

const ContinueButton = styled(Button)`
  background-color: transparent;
`;

const LessonEvaluation: React.FC<ILessonEvaluation> = ({
  taskName,
  lessonName,
  pointsEarned,
  handleClose,
  taskEvaluationId,
}) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Title>
        <span>👍</span>
        Good job!
      </Title>
      <Subtitle>You have successfully finished the lesson</Subtitle>
      <TopicInfo>
        <TopicHelperText>The new topic learned</TopicHelperText>
        {lessonName ? <Topic>{lessonName}</Topic> : null}
      </TopicInfo>
      <LessonButton
        onClick={() => {
          handleClose();
          navigate(ROUTES.HOME.path);
        }}
      >
        Choose the next lesson
        <ArrowRightSvg />
      </LessonButton>
      <Card>
        <Feedback
          pointsEarned={pointsEarned}
          taskName={taskName}
          taskEvaluationId={taskEvaluationId}
        />
        <ContinueButton onClick={handleClose} variant="outlined" bare transitionText>
          <ArrowLeftSvg />
          Continue conversation
        </ContinueButton>
      </Card>
    </Container>
  );
};

export default LessonEvaluation;
