import type { KeysOf } from 'utils/types';

export const isBrowser = () => typeof window !== 'undefined';

export const filterProps = (...propNames: string[]) => ({
  shouldForwardProp: (propName: string) => propNames.indexOf(propName) < 0,
});

export const capitalize = (string: string) =>
  string.toLowerCase().replace(/\b[a-z](?=[a-z]{2})/g, function (letter) {
    return letter.toUpperCase();
  });

export const getDistanceFromPageTop = ({ offsetTop, offsetParent }: HTMLElement): number =>
  offsetParent ? offsetTop + getDistanceFromPageTop(offsetParent as HTMLElement) : offsetTop;

export const getDistanceFromPageLeft = ({ offsetLeft, offsetParent }: HTMLElement): number =>
  offsetParent ? offsetLeft + getDistanceFromPageLeft(offsetParent as HTMLElement) : offsetLeft;

export const getWindow = () => (typeof window !== 'undefined' ? window : null);

export const encodeParametersFromObject = <Keys extends string>(
  parameters: Record<Keys, string>
) => {
  const parameterEntries = Object.entries(parameters);

  if (parameterEntries.length) {
    return parameterEntries
      .reduce((acm, [key, value]) => {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(value as string);

        return `${acm}${encodedKey}=${encodedValue}+`;
      }, '')
      .slice(0, -1);
  } else return '';
};

export const keysOf = <Object extends object>(o: Object) => Object.keys(o) as KeysOf<Object>[];

export const matchPath = (pattern: string, path: string) => {
  const regexPattern = new RegExp('^' + pattern.replace(/:[^\s/]+/g, '([\\w-]+)') + '$');

  return path.match(regexPattern);
};
export function arrayBufferToBase64(buffer: ArrayBuffer): Promise<string> {
  return new Promise((resolve, reject) => {
    const blob = new Blob([buffer], { type: 'audio/ogg' });
    const reader = new FileReader();

    reader.onloadend = () => {
      if (reader?.result) {
        const result = reader.result as string;

        const base64String = result.split(',')[1];
        resolve(base64String);
      }
    };

    reader.onerror = reject;

    reader.readAsDataURL(blob);
  });
}
