import lessonsJourney from './lessons';
import pronunciationJourney from './pronunciation';
import reviewJourney from './review';

import type { JourneysConfig } from './types';

const journeys = {
  lessons: lessonsJourney,
  pronunciation: pronunciationJourney,
  review: reviewJourney,
} as const satisfies JourneysConfig;

export default journeys;
