import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { useScrollLock } from 'utils/hooks';
import { useSetRecoilState } from 'recoil';
import { containersLoadingState } from 'molecules/ContainerLoader/state';

export const useContainerLoaderService = (showLoader: boolean) => {
  const { current: id } = useRef(uuid());

  const setContainerLoading = useSetRecoilState(containersLoadingState(id));

  const [lockScroll, unlockScroll, syncScrollWidth] = useScrollLock();
  const [shouldRenderLoader, setShouldRenderLoader] = useState(showLoader);
  const [shouldHideLoader, setShouldHideLoader] = useState(!showLoader);
  const contentRef = useRef<HTMLDivElement>(null);
  const loaderRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (shouldRenderLoader) setContainerLoading(shouldRenderLoader);
  }, [shouldRenderLoader]);

  useEffect(() => {
    if (!showLoader) {
      setShouldHideLoader(true);
    }
  }, [showLoader]);

  useEffect(() => {
    if (shouldHideLoader) {
      setShouldRenderLoader(false);
    }
  }, [shouldHideLoader]);

  const handleLoaderExited = useCallback(() => {
    unlockScroll();
    syncScrollWidth();
    setContainerLoading(false);
  }, []);

  const handleContentEnter = useCallback(lockScroll, []);

  return {
    renderLoader: shouldRenderLoader,
    renderContent: shouldHideLoader,
    handleLoaderExited,
    handleContentEnter,
    contentRef,
    loaderRef,
  };
};
