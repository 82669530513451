import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import actions from '../../../actions';

export const usePromptStepService = () => {
  const dispatch = useDispatch();

  const handleDownload = useCallback(() => {
    dispatch(actions.promptProgressiveWebAppInstallation());
  }, [dispatch]);

  const handleClose = useCallback(() => {
    dispatch(actions.setModalStep(null));
  }, [dispatch]);

  const benefits = [
    {
      emoji: '📲',
      title: 'Instant Access',
      description: 'Quick and easy access from your home screen',
    },
    {
      emoji: '🌟',
      title: 'Seamless Practice',
      description: 'Improve your English skills anytime, anywhere',
    },
    {
      emoji: '🚀',
      title: 'Improved Performance',
      description: 'Enjoy a smoother and more efficient app experience',
    },
    {
      emoji: '🔐',
      title: 'Enhanced Security',
      description: 'Your learning journey is safeguarded at every step',
    },
  ];

  return { benefits, handleDownload, handleClose };
};
