import { useMutation } from "react-query";
import { AxiosError, AxiosResponse } from "axios";

import callApi, { METHODS, PATHS } from "api";

interface IUseStartConversation {
  onSuccess: (conversationId: string) => void;
  onError: () => void;
}

export interface IStartConversationParams {
  topicSituationId: string;
}

export interface IStartConversationResponse {
  expires_at: string;
  id: string;
}

export const useStartConversation = ({
  onSuccess,
  onError,
}: IUseStartConversation) =>
  useMutation<
    AxiosResponse<IStartConversationResponse>,
    AxiosError,
    IStartConversationParams
  >(
    ({ topicSituationId }) =>
      callApi({
        method: METHODS.POST,
        mainPath: PATHS.START_CONVERSATION,
        authorized: true,
        queryParams: [
          {
            key: 'topic_situation_id',
            value: topicSituationId,
          },
        ],
      }),
    {
      onSuccess: (response) => {
        onSuccess(response.data.id);
      },
      onError,
    }
  );
