import { takeEvery, takeLeading } from 'typed-redux-saga/macro';

import actions from '../actions';

import { fetchReview } from './fetchReview';
import { reviewMessageToSpeech } from './reviewMessageToSpeech';

export function* watchReview() {
  yield* takeLeading(actions.fetchReview, fetchReview);
  yield* takeEvery(actions.reviewMessageToSpeech, reviewMessageToSpeech);
}
