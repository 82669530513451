import * as constants from './constants';
import { chatSlice } from './slice';

import { reviewActions, reviewSelectors } from './Review';

export const chatActions = {
  review: reviewActions,
};

export const chatSelectors = {
  review: reviewSelectors,
};

export { Review, ScoreBarChart } from './Review';

export default { constants, chatSlice };
