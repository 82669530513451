import React, { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { filterProps } from 'utils/helpers';

import { menuIn, menuOut } from './animations';
import { embeddedRem } from './Wrapper';

import type { Classname } from 'utils/types';

interface ILinksContainer extends PropsWithChildren, Classname {
  isOpen: boolean | null;
  linksCount: number;
}

const Container = styled('ul', filterProps('isOpen', 'linksCount'))<ILinksContainer>`
  ${({ theme: { colors, durations, mq }, isOpen, linksCount }) => css`
    position: relative;
    display: flex;
    column-gap: ${embeddedRem(5)};
    align-items: center;
    align-self: center;
    justify-content: center;
    margin: 0;
    height: ${embeddedRem(5)};

    ${mq.md.down} {
      display: grid;
      position: absolute;
      top: 0;
      left: 0;
      grid-template-rows: repeat(${linksCount}, min-content) 1fr;
      row-gap: ${embeddedRem(5)};
      align-content: flex-start;
      align-items: flex-start;
      justify-items: center;
      background-color: ${colors.neutral.grey};
      width: 0;
      height: 0;
      overflow: hidden;

      ${isOpen
        ? css`
            width: 100vw;
            animation: ${menuIn} ${durations.long} linear both;
          `
        : css`
            width: 100vw;
            pointer-events: none;

            ${isOpen === false
              ? css`
                  animation: ${menuOut} ${durations.long} linear forwards;
                `
              : ''}
          `}
    }
  `}
`;

const LinksContainer: React.FC<ILinksContainer> = ({ children, isOpen, linksCount, className }) => {
  return (
    <Container isOpen={isOpen} linksCount={linksCount} className={className}>
      {children}
    </Container>
  );
};

export default LinksContainer;
