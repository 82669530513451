import React from 'react';
import { styled } from '@mui/material';

import { filterProps } from 'utils/materialUI';
import { ILeaderboardRanking } from '../queries';

interface IRankings {
  username: string;
  rankings: ILeaderboardRanking[];
}

const rankedColors = ['#d9ab4c', '#8ca5c3', '#a7671e', '#d35759', '#5da55e'];

const Container = styled('div')({
  display: 'grid',
  rowGap: 24,
});

const Title = styled('span')(({ theme }) => ({
  ...theme.typography.h3,
  fontWeight: 500,
  textAlign: 'center',
  marginBottom: 16,
}));

const Ranker = styled(
  'div',
  filterProps('color')
)<{ color: string }>(({ color }) => ({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  columnGap: 32,
  padding: '16px 32px',
  borderRadius: 16,
  backgroundColor: color,
  boxShadow: '2px 2px 4px 2px #00000042',
  overflow: 'hidden',
  transition: 'all 300ms ease-in-out',
  cursor: 'pointer',
  '&::before': {
    position: 'absolute',
    display: 'block',
    top: '0',
    left: '0',
    background: 'linear-gradient(to right, transparent 30%, #ffffff50)',
    width: '100%',
    height: '100%',
    content: '""',
  },
  ':hover': {
    transform: 'scale(1.05)',
  },
}));

const Info = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: 24,
  zIndex: 2,
});

const Rank = styled('span')(({ theme }) => ({
  ...theme.typography.h3,
  textShadow: '1px 1px 4px black',
  color: 'white',
}));

const Name = styled('span')(({ theme }) => ({
  ...theme.typography.h6,
  fontSize: '1.125rem',
  textShadow: '1px 1px 2px black',
  color: 'white',
  wordBreak: 'break-word',
}));

const Score = styled('span')(({ theme }) => ({
  ...theme.typography.h4,
  fontSize: '2rem',
  textShadow: '1px 1px 4px black',
  color: 'white',
  zIndex: 2,
}));

const Rankings: React.FC<IRankings> = ({ username, rankings }) => (
  <Container>
    <Title>Top users</Title>
    {rankings.map((rankings, index) => (
      <Ranker key={rankings.username} color={rankedColors[index]}>
        <Info>
          <Rank>{index + 1}</Rank>
          <Name>{rankings.username === username ? 'You' : rankings.username}</Name>
        </Info>
        <Score>{rankings.score}</Score>
      </Ranker>
    ))}
  </Container>
);

export default Rankings;
