import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link, Outlet } from 'react-router-dom';

import { ReactComponent as UserSvg } from 'assets/user.svg';
import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import { ReactComponent as AddUserSvg } from 'assets/addUser.svg';
import { ReactComponent as ChatBubblesSvg } from 'assets/chatBubbles.svg';
import { ReactComponent as QuestionMarkSvg } from 'assets/questionMark.svg';
import { ReactComponent as LogoutSvg } from 'assets/logout.svg';
import { GlobalStyle } from 'theme/emotion';
import { ROUTES } from 'navigation/constants';

import { useProfileService } from './hooks';
import ProfileLink from '../ProfileLink';
import Layout from 'atoms/Layout';
import Button from 'atoms/Button';
import { filterProps } from 'utils/helpers';
import { useHandleSupport } from 'utils/hooks/useHandleSupport';

const areas = {
  title: 'title',
  navigation: 'navigation',
  outlet: 'outlet',
};

const StyledLayout = styled(Layout)`
  grid-template-rows: 1fr;

  @supports (height: 100dvh) {
    min-height: 100dvh;
  }
`;

const Container = styled.main`
  ${({ theme: { durations, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([[areas.navigation], [areas.outlet]])};
    grid-template-rows: min-content 1fr;
    transition: all ${durations.regular};

    ${mq.md.up} {
      grid-template-areas: ${utils.composeTemplateAreas([
        ['.', areas.title],
        [areas.navigation, areas.outlet],
        ['.', areas.outlet],
      ])};
      grid-template-columns: 28rem 1fr;
      column-gap: 7.5rem;
      row-gap: 4rem;
      padding: 0 18rem;
    }

    ${mq.lg.up} {
      padding: 0 24rem;
    }

    ${mq.md.down} {
      row-gap: 2.5rem;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { tp, mq } }) => css`
    ${tp.h1};

    grid-area: ${areas.title};
    margin: 0;

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const DesktopNavigation = styled.div`
  ${({ theme: { mq } }) => css`
    grid-area: ${areas.navigation};
    display: grid;
    grid-auto-rows: min-content;
    align-self: flex-start;

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const MobileNavigation = styled(Link)`
  ${({ theme: { mq } }) => css`
    display: contents;

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const ProfileLinkButton = styled(Button)`
  ${({ theme: { mq } }) => css`
    grid-area: ${areas.navigation};
    justify-self: flex-start;
    background-color: transparent;
    padding: 0.5rem;
    margin: 0 -0.5rem -0.5rem;

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const OutletContainer = styled.div`
  grid-area: ${areas.outlet};
  display: grid;
`;

const StyledProfileLink = styled(ProfileLink, filterProps('active'))<{ active: boolean }>`
  ${({ theme: { colors }, active }) =>
    active
      ? css`
          & > span {
            color: ${colors.primary.light};
          }

          & > svg {
            fill: ${colors.primary.light};
          }
        `
      : ''}
`;

const SignoutLink = styled(ProfileLink)`
  ${({ theme: { colors } }) => css`
    grid-template-columns: min-content max-content;

    & > span {
      color: ${colors.error.main};
    }

    & > svg {
      fill: ${colors.error.main};
    }
  `}
`;

const Profile: React.FC = () => {
  const handleSupport = useHandleSupport();
  const { handleLogout, currentRouteName } = useProfileService();

  return (
    <>
      <GlobalStyle />
      <StyledLayout>
        <Container>
          <Title>Profile</Title>
          <DesktopNavigation>
            <StyledProfileLink
              label="Profile"
              Icon={UserSvg}
              url={ROUTES.PROFILE.EDIT.path}
              active={currentRouteName === ROUTES.PROFILE.EDIT.path}
            />
            <StyledProfileLink
              label="Manage subscription"
              Icon={AddUserSvg}
              url={ROUTES.PROFILE.MANAGE_SUBSCRIPTION.path}
              active={currentRouteName === ROUTES.PROFILE.MANAGE_SUBSCRIPTION.path}
            />
            <ProfileLink
              label="Send feedback"
              Icon={ChatBubblesSvg}
              url={ROUTES.PROFILE.SEND_FEEDBACK.path}
            />
            <ProfileLink label="Support center" Icon={QuestionMarkSvg} onClick={handleSupport} />
            <SignoutLink label="Sign out" Icon={LogoutSvg} onClick={handleLogout} />
          </DesktopNavigation>
          <MobileNavigation to={ROUTES.PROFILE.path}>
            {currentRouteName !== ROUTES.PROFILE.path ? (
              <ProfileLinkButton inverted bare transitionText>
                <ArrowLeftSvg />
                Profile
              </ProfileLinkButton>
            ) : null}
          </MobileNavigation>
          <OutletContainer>
            <Outlet />
          </OutletContainer>
        </Container>
      </StyledLayout>
    </>
  );
};

export default Profile;
