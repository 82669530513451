import { createSelector } from '@reduxjs/toolkit';

import { getUser } from '../selectors';

import { MAX_PROMPTS, MINIMUM_VALID_CONVERSATIONS, PROMPT_TIMEOUT } from './constants';
import { getUserTotalConversationCount } from 'features/Auth/selectors';

const getBrandReview = createSelector(getUser, ({ brandReview }) => brandReview);

const canReview = createSelector(getBrandReview, brandReview => brandReview.canReview);

const getModalStep = createSelector(getBrandReview, ({ modalStep }) => modalStep);

const getShownState = createSelector(getBrandReview, ({ timesShown, lastShown, hasReviewed }) => ({
  timesShown,
  lastShown,
  hasReviewed,
}));

const getTimesShown = createSelector(getShownState, ({ timesShown }) => timesShown ?? 0);
const hasReviewedAlready = createSelector(getShownState, ({ hasReviewed }) => hasReviewed);

const canPrompt = createSelector(
  getShownState,
  getUserTotalConversationCount,
  ({ timesShown, lastShown, ...hasReviewed }, totalConversationCount) => {
    if ((totalConversationCount ?? 0) <= MINIMUM_VALID_CONVERSATIONS) return false;
    if (!lastShown || !timesShown) return true;
    return (
      (totalConversationCount ?? 0) > MINIMUM_VALID_CONVERSATIONS &&
      !hasReviewed &&
      Date.now() - lastShown > PROMPT_TIMEOUT &&
      timesShown < MAX_PROMPTS
    );
  }
);

export default {
  canReview,
  getModalStep,
  getShownState,
  getTimesShown,
  hasReviewedAlready,
  canPrompt,
};
