import { captureException } from '@sentry/react';
import { call, put, select } from 'typed-redux-saga/macro';

import callApi, { METHODS, PATHS } from 'api';

import chatSelectors from '../../selectors';

import actions from '../actions';

import type { IReview } from '../types';

const transformResponse = (
  data: ConversationFeedbackResponse
): Pick<IReview, 'scores' | 'messages'> => {
  const scores: IReview['scores'] = {
    good: data.message_score_percentages.good,
    basic: data.message_score_percentages.basic,
    bad: data.message_score_percentages.bad + data.message_score_percentages.error,
  };

  const sortedMessages = data.messages.sort((a, b) => a.sequence_number - b.sequence_number);

  const messages = sortedMessages.map(({ text, score, side, improved_response }) => {
    if (side === 'bot') {
      return {
        entity: 'bot',
        text,
        score: null,
        improvement: null,
        textToSpeechState: null,
      };
    }

    // Human
    const entity = 'user';

    switch (score) {
      case 'good': {
        return {
          entity,
          text,
          score,
          improvement: null,
          textToSpeechState: null,
        };
      }
      case 'error': {
        return {
          entity,
          text,
          score: 'bad',
          improvement: improved_response,
          textToSpeechState: 'idle',
        };
      }
      default: {
        return {
          entity,
          text,
          score,
          improvement: improved_response,
          textToSpeechState: 'idle',
        };
      }
    }
  }) as IReview['messages'];

  return { scores, messages };
};

export function* fetchReview() {
  try {
    const conversationId = yield* select(chatSelectors.getConversationId);

    if (!conversationId) return;

    yield* put(actions.setStatus('loading'));

    const { data } = yield* call(callApi<ConversationFeedbackResponse>, {
      method: METHODS.GET,
      mainPath: PATHS.CONVERSATION_FEEDBACK,
      extraPath: `${conversationId}/feedback/`,
      authorized: true,
    });

    const { scores, messages } = yield* call(transformResponse, data);

    yield* put(actions.setScores(scores));
    yield* put(actions.setMessages(messages));

    yield* put(actions.setStatus('success'));
  } catch (error) {
    yield* call(captureException, error, { severity: 'error', tags: { feature: 'Chat' } });
    yield* put(actions.setStatus('failed'));
  }
}

interface ConversationFeedbackResponse {
  messages: {
    conversation_id: string;
    side: 'bot' | 'human';
    text: string;
    sequence_number: number;
    input_type: null;
    start_time: string;
    end_time: string;
    input_tokens: null;
    output_tokens: null;
    model_used: null;
    id: string;
    created_at: string;
    updated_at: string | null;
    score: null | 'error' | 'bad' | 'basic' | 'good';
    improved_response: string | null;
  }[];
  message_score_percentages: {
    error: number;
    bad: number;
    basic: number;
    good: number;
  };
}
