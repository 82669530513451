import React, { useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';

import { FONT_FAMILIES, useMatchMedia } from 'theme/emotion';

import Tooltip, { AnchorAlignments } from '../../components/Tooltip';

import type { InterpolationWithTheme } from 'utils/types';
import type { FlatJourneyProps, JourneyProps } from '../types';

const Content = styled.div`
  ${({ theme: { mq, tp } }) => css`
    ${tp.p2}

    display: grid;
    row-gap: 0.5rem;

    ${mq.md.up} {
      max-width: 26rem;
    }

    ${mq.md.down} {
      min-width: 24rem;
    }
  `}
`;

const Title = styled.span`
  ${({ theme: { colors } }) => css`
    color: ${colors.neutral.white};
  `}
`;

const Subtitle = styled.span`
  ${({ theme: { colors } }) => css`
    margin-top: 0.5rem;
    color: ${colors.neutral.white};
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
  `}
`;

const Description = styled.span`
  ${({ theme: { colors, utils } }) => css`
    color: ${utils.alphaHex(colors.neutral.white, 60)};
  `}
`;

const outlineStyle: InterpolationWithTheme = ({ colors }) => css`
  background-color: ${colors.neutral.white};
  border-radius: 4rem;

  &::before,
  &::after {
    content: '';
    position: absolute;
    transform: translateY(100%);
    width: 100%;
    height: 100%;
    border-radius: inherit;
    background-color: inherit;
  }

  &::before {
    bottom: -1rem;
  }
  &::after {
    bottom: calc(-5rem - 18px);
  }
`;

const positionStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.down} {
    margin-top: -0.5rem;
  }
`;

const arrowStyle: InterpolationWithTheme = ({ mq }) => css`
  ${mq.md.up} {
    top: calc(9px + 0.5rem);
  }

  ${mq.md.down} {
    left: calc(9px + 0.75rem);
  }
`;

const ConversationSpeedTooltip: React.FC<FlatJourneyProps> = props => {
  const { mq } = useTheme();

  const isMobile = useMatchMedia([mq.md.down]);

  const anchorAlignments = useMemo(() => {
    const breakpoint = isMobile ? 'mobile' : 'desktop';

    const anchorAlignmentsMap: Record<typeof breakpoint, AnchorAlignments> = {
      desktop: { anchor: 'left', alignment: 'top' },
      mobile: { anchor: 'top', alignment: 'left' },
    };

    return anchorAlignmentsMap[breakpoint];
  }, [isMobile]);

  return (
    <Tooltip
      {...(props as JourneyProps)}
      {...anchorAlignments}
      classNames={{
        outline: outlineStyle,
        position: positionStyle,
        arrow: arrowStyle,
      }}
    >
      <Content>
        <Title>Choose conversation speed:</Title>
        <Subtitle>1. Slow</Subtitle>
        <Description>Speaks slowly and clearly. Easy to understand</Description>
        <Subtitle>2. Normal</Subtitle>
        <Description>The standard speaking speed. A balanced pace for most users</Description>
        <Subtitle>3. Fast</Subtitle>
        <Description>Quick and efficient speech. Ideal for experienced users</Description>
      </Content>
    </Tooltip>
  );
};

export default ConversationSpeedTooltip;
