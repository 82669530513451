import { createAction } from '@reduxjs/toolkit';

import { TSignIn, ISetAuthData } from './types';
import {
  SIGN_IN,
  SET_AUTH_DATA,
  INITIAL_AUTH,
  FETCH_USER_INFO,
  FETCH_USER_USAGE,
  LOGOUT,
} from './actionTypes';

export const signIn = createAction<TSignIn>(SIGN_IN);
export const setAuthData = createAction<ISetAuthData>(SET_AUTH_DATA);
export const initialAuth = createAction(INITIAL_AUTH);
export const fetchUserInfo = createAction(FETCH_USER_INFO);
export const fetchUserUsage = createAction(FETCH_USER_USAGE);
export const logout = createAction(LOGOUT);
