import { styled } from '@mui/material';

export default styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh',
  justifyContent: 'center',
  textAlign: 'center',
});
