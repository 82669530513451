import React, { forwardRef } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as ArrowRightSvg } from 'assets/arrowRight.svg';
import { ReactComponent as RefreshSvg } from 'assets/refresh.svg';
import { ReactComponent as CheckSvg } from 'assets/check.svg';
import { filterProps } from 'utils/helpers';
import { FONT_FAMILIES } from 'theme/emotion/typography';

interface ITaskCard {
  id: string;
  label: string;
  title: string;
  description: string;
  isCompleted: boolean;
  onClick: () => void;
  chip?: string;
}

enum TASK_CARD_AREAS {
  chip = 'chip',
  badge = 'badge',
  title = 'title',
  description = 'description',
  button = 'button',
}

const Container = styled.div`
  ${({ theme: { colors, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [TASK_CARD_AREAS.chip, TASK_CARD_AREAS.badge],
      [TASK_CARD_AREAS.title, TASK_CARD_AREAS.button],
      [TASK_CARD_AREAS.description, TASK_CARD_AREAS.button],
    ])};
    grid-template-columns: 1fr max-content;
    grid-auto-rows: min-content;
    row-gap: 1.5rem;
    column-gap: 2rem;
    background-color: ${colors.neutral.white};
    border-radius: 3rem;
    padding: 4rem;

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        [TASK_CARD_AREAS.chip, TASK_CARD_AREAS.badge, TASK_CARD_AREAS.badge],
        [TASK_CARD_AREAS.title, TASK_CARD_AREAS.title, TASK_CARD_AREAS.button],
        [TASK_CARD_AREAS.description, TASK_CARD_AREAS.description, TASK_CARD_AREAS.button],
      ])};
      grid-template-columns: max-content 1fr max-content;
      column-gap: 1rem;
      row-gap: 0.5rem;
      padding: 2.5rem;
      border-radius: 2.5rem;
    }
  `}
`;

const ChipContainer = styled.div`
  display: flex;
  grid-area: ${TASK_CARD_AREAS.chip};
  column-gap: 1rem;
`;

const Chip = styled.span`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.p3};

    display: grid;
    align-items: center;
    justify-self: flex-start;
    border: 1px solid ${colors.primary.dark};
    border-radius: 3rem;
    padding: 0 1.25rem;
    margin-bottom: 1.5rem;
    height: 3rem;

    ${mq.md.down} {
      padding: 0 1rem;
      height: 2.5rem;
      margin-bottom: 1rem;
    }
  `}
`;

const Badge = styled('span', filterProps('variant'))<{ variant?: 'primary' | 'secondary' }>`
  ${({ theme: { colors, mq, tp, utils }, variant = 'secondary' }) => css`
    ${tp.p3};

    grid-area: ${TASK_CARD_AREAS.badge};
    display: flex;
    column-gap: 0.75rem;
    align-items: center;
    justify-self: flex-end;
    border-radius: 3rem;
    background: linear-gradient(
      225deg,
      ${utils.alphaHex(colors[variant].dark, 12)} 0%,
      ${utils.alphaHex(colors[variant].light, 12)} 100%
    );
    color: ${colors[variant].main};
    padding: 0 1.25rem;
    height: 3rem;

    & > svg {
      fill: ${colors[variant].main};
      width: 2rem;
      height: 2rem;
    }

    ${mq.md.down} {
      column-gap: 0.5rem;
      padding: 0 1rem;
      height: 2.5rem;

      & > svg {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
  `}
`;

const Title = styled.h2`
  ${({ theme: { tp } }) => css`
    ${tp.h2};

    grid-area: ${TASK_CARD_AREAS.title};
    margin: 0;
  `}
`;

const Description = styled.h5`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p2};

    grid-area: ${TASK_CARD_AREAS.description};
    margin: 0;
    color: ${utils.alphaHex(colors.primary.dark, 55)};
  `}
`;

const Button = styled('button', filterProps('isCompleted'))<Pick<ITaskCard, 'isCompleted'>>`
  ${({ theme: { colors, durations, mq }, isCompleted }) => css`
    grid-area: ${TASK_CARD_AREAS.button};
    display: grid;
    grid-auto-flow: column;
    justify-self: flex-start;
    column-gap: 1rem;
    align-self: flex-end;
    align-items: center;
    background-color: ${isCompleted ? colors.primary.light : colors.neutral.white};
    border: 0.25rem solid ${colors.primary.light};
    border-radius: 4.5rem;
    text-align: center;
    text-decoration: initial;
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 1.75rem;
    padding: 0 5rem;
    height: 6rem;
    transition: border-width ${durations.regular};
    cursor: pointer;

    & > span {
      color: inherit;
    }

    & > svg {
      width: 2.5rem;
      height: 2.5rem;
    }

    ${isCompleted
      ? css`
          background-color: ${colors.neutral.white};
          color: ${colors.primary.light};

          & > svg {
            fill: ${colors.primary.light};
          }
        `
      : css`
          background-color: ${colors.primary.light};
          color: ${colors.neutral.white};

          & > svg {
            fill: ${colors.neutral.white};
          }
        `}

    &:active {
      opacity: 0.9;
    }

    &:disabled {
      opacity: 0.25;
      pointer-events: none;
    }

    ${mq.hover} {
      &:hover:not(:disabled) {
        border-width: 0.375rem;
      }
    }

    ${mq.md.down} {
      padding: 1.5rem;
      justify-self: flex-end;

      & > span {
        display: none;
      }
    }
  `}
`;

const TaskCard = forwardRef<HTMLDivElement, ITaskCard>(function TaskCardWithRef(
  { label, title, description, isCompleted, onClick, chip },
  ref
) {
  return (
    <Container ref={ref}>
      <ChipContainer>
        <Chip>{label}</Chip>
        {chip ? (
          <Badge variant="secondary">
            <CheckSvg />
            New
          </Badge>
        ) : null}
      </ChipContainer>
      {isCompleted ? (
        <Badge>
          <CheckSvg />
          Completed
        </Badge>
      ) : null}
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Button isCompleted={isCompleted} onClick={onClick}>
        {isCompleted ? (
          <>
            <span>Practice again</span>
            <RefreshSvg />
          </>
        ) : (
          <>
            <span>Start practice</span>
            <ArrowRightSvg />
          </>
        )}
      </Button>
    </Container>
  );
});

export default TaskCard;
