import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Tooltip from '../../components/Tooltip';

import type { FlatJourneyProps, JourneyProps } from '../types';
import { FONT_FAMILIES } from 'theme/emotion';
import { filterProps } from 'utils/helpers';
import { HighlightState } from 'features/Pronunciation/components/Pronunciation/Prose';

const Content = styled.div`
  ${({ theme: { tp } }) => css`
    ${tp.p2}

    display: grid;
    grid-template-columns: min-content 1fr;
    align-items: center;
    align-content: center;
    row-gap: 1.5rem;
    column-gap: 1rem;
    min-width: 30rem;
  `}
`;

const Title = styled.span`
  ${({ theme: { colors } }) => css`
    color: ${colors.neutral.white};
    grid-column: 1 / span 2;
  `}
`;

const Subtitle = styled('span', filterProps('highlight'))<{ highlight: HighlightState }>`
  ${({ theme: { colors, durations, tp, utils }, highlight }) => css`
    ${tp.h2};

    transition: ${durations.regular} ease-out;
    transition-property: color;
    background-color: ${colors.neutral.grey};
    text-align: center;
    padding: 0.25rem 1.5rem;
    border-radius: 4rem;

    font-weight: ${['default', 'low'].includes(highlight)
      ? FONT_FAMILIES.Poppins.regular
      : FONT_FAMILIES.Poppins.semiBold};
    color: ${highlight === 'high'
      ? colors.secondary.main
      : utils.alphaHex(colors.primary.main, highlight === 'low' ? 25 : 55)};
  `}
`;

const Description = styled.span`
  ${({ theme: { colors, utils } }) => css`
    color: ${utils.alphaHex(colors.neutral.white, 60)};
  `}
`;

const arrowStyle = css`
  right: calc(25px - 1rem);
`;

const cancelStyle = css`
  display: none;
`;

const WordEvaluationTooltip: React.FC<FlatJourneyProps> = props => {
  return (
    <Tooltip
      {...(props as JourneyProps)}
      anchor="bottom"
      alignment="right"
      classNames={{ arrow: arrowStyle, cancel: cancelStyle }}
      proceedElement="Ok"
    >
      <Content>
        <Title>Word evaluation:</Title>
        <Subtitle highlight="high">High</Subtitle>
        <Description>Correctly pronounced</Description>
        <Subtitle highlight="medium">Medium</Subtitle>
        <Description>Partially correct</Description>
        <Subtitle highlight="low">Low</Subtitle>
        <Description>Needs practice</Description>
      </Content>
    </Tooltip>
  );
};

export default WordEvaluationTooltip;
