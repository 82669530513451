import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { ReactComponent as ArrowLeftSvg } from 'assets/arrowLeft.svg';
import Button from 'atoms/Button';
import { LAYOUT_TEMPLATE_COLUMNS } from 'atoms/Layout';
import Modal from 'molecules/Modal';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';

import { CLASSNAME_PREFIX } from './constants';

import PromptStep from './PromptStep';
import ErrorStep from './ErrorStep';
import FeedbackStep from './FeedbackStep';
import LoadingStep from './LoadingStep';
import SuccessStep from './SuccessStep';
import { useCancelPlanModalService } from './hooks';

import type { InterpolationWithTheme } from 'utils/types';

const StyledModal = styled(Modal)`
  ${({ theme: { colors, mq } }) => css`
    overflow-y: auto;

    ${mq.md.down} {
      background-color: ${colors.neutral.white};

      &::before,
      &::after {
        content: '';
        background-color: ${colors.neutral.white};
        width: 100%;
        height: 100%;
      }
    }
  `}
`;

const ModalContent = styled.section`
  ${({ theme: { durations } }) => css`
    display: grid;
    transition: all ${durations.regular};
  `}
`;

const modalStyle: InterpolationWithTheme = ({ mq }) => css`
  position: relative;
  grid-auto-rows: initial;

  ${mq.md.up} {
    justify-self: center;
    align-self: center;
    border-radius: 4rem;
  }

  ${mq.md.down} {
    padding: var(${GLOBAL_STYLE_VARIABLES.headerHeight}) 0 7rem;

    && {
      grid-column: ${LAYOUT_TEMPLATE_COLUMNS.contentStart} / ${LAYOUT_TEMPLATE_COLUMNS.contentEnd};
    }
  }
`;

const MobileBackButton = styled(Button)`
  ${({ theme: { mq } }) => css`
    position: absolute;
    top: calc(var(${GLOBAL_STYLE_VARIABLES.headerHeight}) + 2rem);
    justify-self: flex-start;

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const CancelPlanModal: React.FC = () => {
  const { step, handleClose, timeout } = useCancelPlanModalService();

  return (
    <StyledModal isOpen={step !== null} onClick={handleClose} classNames={{ modal: modalStyle }}>
      <MobileBackButton inverted bare transitionText onClick={handleClose}>
        <ArrowLeftSvg />
        Manage subscription
      </MobileBackButton>
      <TransitionGroup component={ModalContent}>
        <CSSTransition key={step} timeout={timeout} classNames={CLASSNAME_PREFIX}>
          {() => {
            switch (step) {
              case 'prompt': {
                return <PromptStep />;
              }
              case 'feedback': {
                return <FeedbackStep />;
              }
              case 'success': {
                return <SuccessStep />;
              }
              case 'error': {
                return <ErrorStep />;
              }
              case 'loading':
              default: {
                return <LoadingStep />;
              }
            }
          }}
        </CSSTransition>
      </TransitionGroup>
    </StyledModal>
  );
};

export default CancelPlanModal;
