import React from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { TypeAnimation } from 'react-type-animation';

import { useTranscribedTextService } from './hooks';
import { filterProps } from 'utils/helpers';

interface ITranscribedText {}

const Container = styled('div', filterProps('shouldRender', 'shouldRenderMarginTop'))<{
  shouldRender: boolean;
  shouldRenderMarginTop: boolean;
}>`
  ${({ theme: { colors, durations, mq }, shouldRender, shouldRenderMarginTop }) => css`
    position: relative;
    justify-self: center;
    width: 100%;
    height: ${shouldRender ? 12 : 0}rem;
    max-width: 68.125rem;
    background-color: ${colors.neutral.grey};
    border-radius: 1.5rem;
    overflow: hidden;
    transition: all ${durations.regular} ease-out;
    margin-bottom: ${shouldRender ? 3 : 0}rem;
    margin-top: ${shouldRenderMarginTop ? 3 : 0}rem;

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1.75rem;
      top: 0;
      background: linear-gradient(
        180deg,
        ${colors.neutral.grey} 0%,
        rgba(245, 244, 248, 0) 191.67%
      );
      z-index: 1;
    }

    ${mq.md.down} {
      height: ${shouldRender ? 8 : 0}rem;
    }
  `}
`;

const Text = styled('span')<{ isActive: boolean }>`
  ${({ theme: { colors, durations, mq, tp }, isActive }) => css`
    ${tp.p1};

    position: absolute;
    width: 100%;
    min-height: 100%;
    bottom: 0;
    padding: 2rem 2.5rem;
    text-align: center;

    & > span {
      color: ${isActive ? colors.primary.light : colors.primary.dark};
      transition: color ${durations.long} ease-out;

      &::after {
        content: none;
      }
    }

    ${mq.md.down} {
      padding: 2rem;
    }
  `}
`;

const Cursor = styled.span`
  ${({ theme: { colors } }) => css`
    display: inline-block;
    width: 1rem;
    height: 1rem;
    background-color: ${colors.primary.light};
    margin-left: 0.5ch;
    border-radius: 50%;
  `}
`;

const TranscribedText: React.FC<ITranscribedText> = () => {
  const { shouldRender, shouldRenderMarginTop, state, renderKey, sequence } =
    useTranscribedTextService();

  return (
    <Container shouldRender={shouldRender} shouldRenderMarginTop={shouldRenderMarginTop}>
      <Text isActive={state === 'listening'}>
        <TypeAnimation
          key={renderKey}
          preRenderFirstString
          sequence={sequence}
          speed={75}
          deletionSpeed={99}
        />
        <Cursor />
      </Text>
    </Container>
  );
};

export default TranscribedText;
