import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as InfoSvg } from 'assets/info.svg';
import { useWordWithDefinitionService } from 'features/Task/components/TaskInfo/WordWithDefinition/hooks';
import WordDefinition from 'features/Task/components/TaskInfo/WordWithDefinition/WordDefinition';

interface IWordDefinition {
  taskId: string;
}

const Word = styled.h4`
  ${({ theme: { colors, mq, tp } }) => css`
    ${tp.h2}

    display: flex;
    align-items: center;
    column-gap: 1rem;
    margin: 0;
    color: ${colors.neutral.white};
    cursor: pointer;

    & > svg {
      fill: ${colors.neutral.white};
      width: 2.75rem;
      height: 2.75rem;
    }

    ${mq.md.up} {
      justify-self: center;
    }

    ${mq.md.down} {
      align-self: flex-start;
      column-gap: 0.5rem;

      & > svg {
        width: 2.25rem;
        height: 2.25rem;
      }
    }
  `}
`;

const WordWithDefinition: React.FC<IWordDefinition> = ({ taskId }) => {
  const { openDefinition, closeDefinition, isDefinitionOpen, word, definition } =
    useWordWithDefinitionService(taskId);

  return (
    <>
      <Word onClick={openDefinition}>
        {word}
        <InfoSvg />
      </Word>
      <WordDefinition
        isOpen={isDefinitionOpen}
        handleClose={closeDefinition}
        word={word as string}
        definition={definition as string}
      />
    </>
  );
};

export default WordWithDefinition;
