import { createSlice } from 'utils/redux';

import { NAME } from './constants';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { IReview } from './types';

const initialState: IReview = {
  status: null,
  scores: {
    good: 0,
    basic: 0,
    bad: 0,
  },
  messages: [],
};

export const reviewSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setStatus: (state, action: PayloadAction<IReview['status']>) => {
      state.status = action.payload;
    },
    setScores: (state, action: PayloadAction<IReview['scores']>) => {
      state.scores = action.payload;
    },
    setMessages: (state, action: PayloadAction<IReview['messages']>) => {
      state.messages = action.payload;
    },
    startLoadingTTSMessage: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.map((message, index) => {
        if (message.improvement) {
          const { textToSpeechState, ...rest } = message;
          return {
            ...rest,
            textToSpeechState: index === action.payload ? 'loading' : 'disabled',
          };
        }

        return message;
      });
    },
    startPlayingTTSMessage: (state, action: PayloadAction<number>) => {
      state.messages = state.messages.map((message, index) => {
        if (message.improvement) {
          const { textToSpeechState, ...rest } = message;
          return {
            ...rest,
            textToSpeechState: index === action.payload ? 'active' : 'disabled',
          };
        }

        return message;
      });
    },
    stopPlayingTTSMessage: state => {
      state.messages = state.messages.map(message => {
        if (message.improvement) {
          const { textToSpeechState, ...rest } = message;
          return {
            ...rest,
            textToSpeechState: 'idle',
          };
        }

        return message;
      });
    },
  },
});
