import { combineReducers } from '@reduxjs/toolkit';

import audio from 'app/audio';
import analytics from 'app/Analytics';
import audioInput from 'features/AudioInput';
import audioOutput from 'features/AudioOutput';
import auth from 'features/Auth';
import login from 'features/Login';
import forgotPassword from 'features/ForgotPassword';
import pressToTalk from 'features/ConversationControl';
import chat from 'features/Chat';
import onboarding from 'features/Onboarding';
import pushNotifications from 'features/PushNotifications';
import streaks from 'features/Streaks';
import pronunciation from 'features/Pronunciation';
import user from 'features/User';

const rootReducer = combineReducers({
  ...audio.reducer,
  ...analytics.reducer,
  [audioInput.constants.NAME]: audioInput.audioInputSlice.reducer,
  [audioInput.constants.NAME]: audioInput.audioInputSlice.reducer,
  [audioOutput.constants.NAME]: audioOutput.audioOutputSlice.reducer,
  [auth.constants.NAME]: auth.authSlice.reducer,
  [login.constants.NAME]: login.loginSlice.reducer,
  [forgotPassword.constants.NAME]: forgotPassword.forgotPasswordSlice.reducer,
  [pressToTalk.constants.NAME]: pressToTalk.pressToTalkSlice.reducer,
  [chat.constants.NAME]: chat.chatSlice.reducer,
  [onboarding.constants.NAME]: onboarding.onboardingSlice.reducer,
  [pushNotifications.constants.NAME]: pushNotifications.pushNotificationsSlice.reducer,
  [streaks.constants.NAME]: streaks.streaksSlice.reducer,
  [pronunciation.constants.NAME]: pronunciation.pronunciationSlice.reducer,
  [user.constants.NAME]: user.userSlice.reducer,
});

export default rootReducer;
