import { NAME } from './constants';

import type { TRootState } from 'store';
import { createSelector } from '@reduxjs/toolkit';

const getAudio = (state: TRootState) => state[NAME];

const getAudioSession = createSelector(getAudio, ({ audioSession }) => audioSession);

const isWebAudioAccessGranted = createSelector(
  getAudio,
  ({ webAudioAccessGranted }) => webAudioAccessGranted
);

export default {
  getAudio,
  getAudioSession,
  isWebAudioAccessGranted,
};
