import { createTheme } from '@mui/material';

export default createTheme({
  typography: {
    fontFamily: '"Poppins", sans-serif',
  },
  palette: {
    primary: {
      main: '#7400F6',
    },
    secondary: {
      main: '#E669CB',
    },
    background: {
      default: '#F3F6F8',
      paper: '#F3F6F8',
    },
  },
});
