export enum GLOBAL_STYLE_VARIABLES {
  baseRemRatio = '--base-rem-ratio',
  scrollbarWidth = '--scrollbar-width',
  scrollbarCompensation = '--scrollbar-compensation',
  margin = '--margin',
  container = '--container',
  headerHeight = '--header-height',
  headerHeightInverse = '--header-height-inverse',
}

export const BASE_REM = 8;
export const BASE_EM = 16;

export const TINY_MOBILE: Breakpoint = {
  width: 240,
  margin: 24,
  inclusive: true,
};

export const SMALL_MOBILE: Breakpoint = {
  width: 375,
  margin: 24,
  fluid: {
    scaleMax: 100,
    scaleMin: 80,
    priorityWidth: 320,
  },
};

export const MOBILE: Breakpoint = {
  width: 480,
  margin: 24,
  inclusive: true,
};

export const LARGE_MOBILE: Breakpoint = {
  width: 768,
  container: MOBILE.width - MOBILE.margin * 2,
  fluid: {
    scaleMax: 120,
    scaleMin: 100,
    priorityWidth: 576,
  },
};

export const TABLET: Breakpoint = {
  width: 1024,
  margin: 32,
  fluid: {
    scaleMax: 87.5,
    scaleMin: 75,
    priorityWidth: 960,
  },
};

export const DESKTOP: Breakpoint = {
  width: 1440,
  container: 1106,
  inclusive: true,
  fluid: {
    scaleMax: 100,
    scaleMin: 87.5,
    priorityWidth: 1280,
  },
};

export type Breakpoint = {
  width: number;
  inclusive?: boolean;
  fluid?: {
    scaleMax: number;
    scaleMin: number;
    priorityWidth: number;
  };
} & (
  | { container: number; margin?: never }
  | {
      container?: never;
      margin: number;
    }
);
