import { CircularProgress, styled } from '@mui/material';
import hideable from 'utils/hideable';

interface ILoader {
  size?: number;
}

const StyledProgress = styled(CircularProgress)((props) => ({
  '& circle': {
    stroke: props.theme.palette.primary.main,
  },
}));

const Loader = ({ size = 18, ...rest }: ILoader) => (
  <StyledProgress color="primary" size={size} {...rest} />
);

export default hideable(Loader);
