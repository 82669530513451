import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { ReactComponent as ChevronRightSvg } from 'assets/chevronRight.svg';
import { ReactComponent as UserSvg } from 'assets/user.svg';
import { ReactComponent as MailSvg } from 'assets/mail.svg';

import { useProfileEditService } from './hooks';

const Container = styled.div`
  ${({ theme: { colors, mq, utils } }) => css`
    display: grid;
    grid-auto-rows: min-content;
    row-gap: 1rem;

    ${mq.md.down} {
      & > span:not(:first-of-type) {
        position: relative;

        &::before {
          position: absolute;
          content: '';
          top: -0.5rem;
          left: 0;
          width: 100%;
          height: 1px;
          background-color: ${utils.alphaHex(colors.primary.main, 5)};
        }
      }
    }
  `}
`;

const Title = styled.h2`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h3};

    margin: 0 0 1rem;

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const areas = {
  icon: 'icon',
  label: 'label',
  content: 'content',
  chevron: 'chevron',
};

const Input = styled.span`
  ${({ theme: { colors, durations, mq, utils } }) => css`
    display: grid;
    grid-template-areas: ${utils.composeTemplateAreas([
      [areas.icon, areas.label],
      [areas.icon, areas.content],
    ])};
    grid-template-columns: min-content 1fr;
    align-items: center;
    column-gap: 1.5rem;
    padding: 1.5rem 3rem;
    cursor: pointer;
    transition: all ${durations.regular};

    &:active {
      background-color: ${utils.alphaHex(colors.primary.main, 10)};
    }

    ${mq.hover} {
      &:hover {
        background-color: ${utils.alphaHex(colors.primary.main, 5)};
      }
    }

    ${mq.md.up} {
      background-color: ${colors.neutral.white};
      border-radius: 3.75rem;
    }

    ${mq.md.down} {
      grid-template-areas: ${utils.composeTemplateAreas([
        [areas.label, areas.content, areas.chevron],
      ])};
      grid-template-columns: max-content 1fr min-content;
      column-gap: 0.5rem;
      padding: 2rem;
      margin: 0 -2rem;
      border-radius: 1rem;
    }
  `}
`;

const InputIcon = styled.div`
  ${({ theme: { mq } }) => css`
    grid-area: ${areas.icon};

    & > svg {
      width: 2rem;
      height: 2rem;
    }

    ${mq.md.down} {
      display: none;
    }
  `}
`;

const InputLabel = styled.label`
  ${({ theme: { colors, tp, utils } }) => css`
    ${tp.p2};

    grid-area: ${areas.label};
    color: ${utils.alphaHex(colors.primary.main, 55)};
    user-select: none;
  `}
`;

const InputContent = styled.label`
  ${({ theme: { mq, tp } }) => css`
    ${tp.p1};

    grid-area: ${areas.content};

    ${mq.md.down} {
      justify-self: flex-end;
    }
  `}
`;

const InputChevron = styled(ChevronRightSvg)`
  ${({ theme: { mq } }) => css`
    grid-area: ${areas.chevron};
    width: 2rem;
    height: 2rem;

    ${mq.md.up} {
      display: none;
    }
  `}
`;

const ProfileEdit: React.FC = () => {
  const { username, email } = useProfileEditService();

  return (
    <Container>
      <Title>Account</Title>
      <Input>
        <InputIcon>
          <UserSvg />
        </InputIcon>
        <InputLabel>Your name</InputLabel>
        <InputContent>{username}</InputContent>
        <InputChevron />
      </Input>
      <Input>
        <InputIcon>
          <MailSvg />
        </InputIcon>
        <InputLabel>E-mail</InputLabel>
        <InputContent>{email}</InputContent>
        <InputChevron />
      </Input>
    </Container>
  );
};

export default ProfileEdit;
