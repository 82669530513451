import Onboarding from './components/Onboarding';
import Highlight from './components/Highlight';
import { NAME } from './constants';
import { onboardingSlice } from './slice';

export { useDeferredOnboardingCallback, useStopOnboarding } from './hooks';
export { useLessonsJourneyTrigger } from './journeys';
export { ONBOARDING_NODE_ID } from './constants';

export { Onboarding, Highlight };
export default { constants: { NAME }, onboardingSlice };
