import { NAME } from './constants';

import { createSlice, getActionTypes } from 'utils/redux';

import { reviewSlice } from './Review/slice';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { IChat } from './types';

const initialState: IChat = {
  conversationId: null,
  sequenceNumber: null,
  isConversationStarted: false,
  conversations: {},
  isLoading: false,
  pushToTalkState: null,
  handsFreeState: null,
};

export const chatSlice = createSlice(
  {
    name: NAME,
    initialState,
    reducers: {
      setConversationId: (state, action: PayloadAction<IChat['conversationId']>) => {
        state.conversationId = action.payload;
      },
      setSequenceNumber: (state, action: PayloadAction<IChat['sequenceNumber']>) => {
        state.sequenceNumber = action.payload;
      },
      setIsLoading: (state, action: PayloadAction<boolean>) => {
        state.isLoading = action.payload;
      },
      setConversationIsStarted: (state, action: PayloadAction<boolean>) => {
        state.isConversationStarted = action.payload;
      },
      setPushToTalkState: (state, action: PayloadAction<IChat['pushToTalkState']>) => {
        state.pushToTalkState = action.payload;
      },
      setHandsFreeState: (state, action: PayloadAction<IChat['handsFreeState']>) => {
        state.handsFreeState = action.payload;
      },
      resetConversationEntity: (
        state,
        {
          payload: { id, sequenceNumber, entity },
        }: PayloadAction<{
          id: string;
          sequenceNumber: number;
          entity: 'user' | 'bot';
        }>
      ) => {
        if (state.conversations?.[id]?.[sequenceNumber]?.[entity]) {
          state.conversations[id][sequenceNumber][entity] = {};
        }

        return state;
      },
      addConversationChunk: (
        state,
        {
          payload: { id, sequenceNumber, entity, chunkNumber, text, isLast, duration },
        }: PayloadAction<{
          id: string;
          sequenceNumber: number;
          entity: 'user' | 'bot';
          chunkNumber: number;
          text: string;
          isLast: boolean;
          duration?: number;
        }>
      ) => {
        if (entity === 'bot') {
          if (state.pushToTalkState !== null) {
            state.pushToTalkState = 'disabled';
          }
        }

        if (!state.conversations[id]) {
          state.conversations[id] = {};
        }

        if (!state.conversations[id][sequenceNumber]) {
          state.conversations[id][sequenceNumber] = {
            user: {},
            bot: {},
          };
        }

        if (!state.conversations[id][sequenceNumber][entity][chunkNumber]) {
          state.conversations[id][sequenceNumber][entity][chunkNumber] = {
            text,
            isLast,
            duration,
          };
        }

        return state;
      },
      markConversationChunk: (
        state,
        {
          payload: { id, sequenceNumber, entity, chunkNumber },
        }: PayloadAction<{
          id: string;
          sequenceNumber: number;
          entity: 'user' | 'bot';
          chunkNumber: number;
        }>
      ) => {
        if (state.conversations?.[id]?.[sequenceNumber]?.[entity]?.[chunkNumber]) {
          state.conversations[id][sequenceNumber][entity][chunkNumber].isLast = true;
        }

        return state;
      },
      setPlayedChunkDuration: (
        state,
        {
          payload: { id, sequenceNumber, chunkNumber, duration },
        }: PayloadAction<{
          id: string;
          sequenceNumber: number;
          chunkNumber: number;
          duration: number;
        }>
      ) => {
        if (state.conversations?.[id]?.[sequenceNumber]?.bot?.[chunkNumber]) {
          state.conversations[id][sequenceNumber].bot[chunkNumber].duration = duration;
          state.conversations[id][sequenceNumber].bot[chunkNumber].playedState = 'started';
        }

        return state;
      },
      setChunkPlayedState: (
        state,
        {
          payload: { id, sequenceNumber, chunkNumber, playedState },
        }: PayloadAction<{
          id: string;
          sequenceNumber: number;
          chunkNumber: number;
          playedState: null | 'finished' | 'started';
        }>
      ) => {
        if (state.conversations?.[id]?.[sequenceNumber]?.bot?.[chunkNumber]) {
          state.conversations[id][sequenceNumber].bot[chunkNumber].playedState = playedState;
        }

        return state;
      },
      resetConversation: (state, { payload: id }: PayloadAction<string>) => {
        if (state.conversations[id]) {
          delete state.conversations[id];
        }

        return state;
      },
    },
  },
  { reviewSlice }
);

export const {
  setConversationId,
  setIsLoading,
  setConversationIsStarted,
  addConversationChunk,
  markConversationChunk,
  resetConversation,
  setPlayedChunkDuration,
  setChunkPlayedState,
  resetState,
} = chatSlice.actions;

export const actionTypes = getActionTypes(chatSlice);
