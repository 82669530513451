import { styled } from '@mui/material';

import hideable from 'utils/hideable';

const StyledText = styled('div')({
  fontSize: 24,
  lineHeight: '32px',
  fontWeight: 600,
  maxWidth: 500,
  textAlign: 'center',
});

const MicrophoneProblem = () => (
  <StyledText>
    Giving us permission to use your microphone will enhance your user
    experience and enable you to use our app's audio-based features.
  </StyledText>
);

export default hideable(MicrophoneProblem);
