import React from 'react';
import { createPortal } from 'react-dom';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CSSTransition } from 'react-transition-group';

import { LAYOUT_TEMPLATE_COLUMNS, layoutStyles } from 'atoms/Layout';
import { ClassNames } from 'theme/emotion';

import { useModalService } from './hooks';

import type { Classname, Classnames } from 'utils/types';

export type TModal = Classname &
  Classnames<'modal'> & {
    isOpen: boolean;
    onClick: Required<JSX.IntrinsicElements['div']>['onClick'];
    showBackdropOverride?: boolean | null;
    portalTarget?: string | Element | DocumentFragment;
    scrollLock?: boolean;
  } & Omit<JSX.IntrinsicElements['main'], 'onClick' | 'className'>;

const CLASSNAME_PREFIX = 'fade';

const Backdrop = styled.div`
  ${({ theme: { colors, depths, durations, utils } }) => css`
    ${layoutStyles};

    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: ${utils.alphaHex(colors.neutral.black, 60)};
    z-index: ${depths.modal};

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }

    && {
      padding: 0;
    }
  `}
`;

const ModalContent = styled.main`
  ${({ theme: { colors, durations, mq } }) => css`
    display: grid;
    grid-auto-rows: min-content;
    justify-items: center;
    background-color: ${colors.neutral.white};

    &.${CLASSNAME_PREFIX} {
      &-appear,
      &-enter {
        opacity: 0;

        &-active {
          opacity: 1;
          transition: opacity ${durations.regular} ease-in;
        }

        &-done {
          opacity: 1;
        }
      }

      &-exit {
        opacity: 1;

        &-active {
          opacity: 0;
          transition: opacity ${durations.regular} ease-out;
        }

        &-done {
          opacity: 0;
        }
      }
    }

    ${mq.md.down} {
      && {
        grid-column: ${LAYOUT_TEMPLATE_COLUMNS.gridStart} / ${LAYOUT_TEMPLATE_COLUMNS.gridEnd};
      }
    }
  `}
`;

const Modal = ({
  className,
  isOpen,
  showBackdropOverride,
  onClick,
  portalTarget,
  scrollLock,
  classNames,
  id,
  ...props
}: TModal) => {
  const { backdropRef, modalRef, showModal, portalTargetNode, handleModalContentClickPropagation } =
    useModalService({
      isOpen,
      showBackdropOverride,
      portalTarget,
      scrollLock,
    });

  return (
    <CSSTransition
      in={showModal}
      timeout={300}
      appear
      classNames={CLASSNAME_PREFIX}
      nodeRef={backdropRef}
      mountOnEnter
      unmountOnExit
    >
      {() =>
        createPortal(
          <Backdrop id={id} className={className} onClick={onClick} ref={backdropRef}>
            <ClassNames classNames={classNames}>
              {c => (
                <CSSTransition
                  in={isOpen}
                  timeout={300}
                  appear
                  classNames={CLASSNAME_PREFIX}
                  nodeRef={modalRef}
                  mountOnEnter
                  unmountOnExit
                >
                  <ModalContent
                    {...props}
                    className={c.modal}
                    onClick={handleModalContentClickPropagation}
                    ref={modalRef}
                  />
                </CSSTransition>
              )}
            </ClassNames>
          </Backdrop>,
          portalTargetNode as Element
        )
      }
    </CSSTransition>
  );
};

export default Modal;
