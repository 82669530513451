import { useState } from 'react';
import { register } from 'register-service-worker';

export const useServiceWorker = () => {
  const [appNeedsRefresh, setAppNeedsRefresh] = useState(false);

  register('/sw.js', {
    registrationOptions: { scope: '/' },
    updated: async () => {
      setAppNeedsRefresh(true);
    },
  });

  return appNeedsRefresh;
};
