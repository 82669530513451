import { createSlice } from 'utils/redux';

import { NAME } from './constants';

import type { PayloadAction } from '@reduxjs/toolkit';
import type { IInput } from './types';

const initialState: IInput = {
  state: 'inactive',
  browserIsSupported: null,
  microphonePermission: null,
};

export const inputSlice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    setState: (state, action: PayloadAction<IInput['state']>) => {
      state.state = action.payload;
    },
    setBrowserIsSupported: (state, action: PayloadAction<boolean>) => {
      state.browserIsSupported = action.payload;
    },
    setMicrophonePermission: (state, action: PayloadAction<IInput['microphonePermission']>) => {
      state.microphonePermission = action.payload;
    },
  },
});
