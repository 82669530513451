import { useTheme } from '@emotion/react';

import { useMatchMedia } from 'theme/emotion';

export const usePreambleService = () => {
  const { mq } = useTheme();

  const isMobile = useMatchMedia([mq.md.down]);

  return { isMobile };
};
