import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import ScoreStars from '../ScoreStars';

import { useScoreBarChartService } from './hooks';

import type { Classname } from 'utils/types';
import type { ScoreType } from 'features/Chat/Review/types';

const Container = styled.div`
  ${({ theme: { mq } }) => css`
    display: grid;
    row-gap: 0.5rem;

    ${mq.md.down} {
    }
  `}
`;

const Bar = styled.div<{ type: ScoreType; progress: number }>`
  ${({ theme: { colors, mq, tp, utils }, type, progress }) => css`
    ${tp.p1};

    display: grid;
    position: relative;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    column-gap: 1.25rem;
    padding: 0.75rem 1rem;
    width: ${progress}%;
    color: ${{ good: colors.secondary.main, basic: colors.warning.main, bad: colors.error.main }[
      type
    ]};

    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: ${utils.alphaHex(
        { good: colors.secondary.main, basic: colors.warning.main, bad: colors.error.main }[type],
        8
      )};
      border-radius: 2rem;
    }

    ${mq.md.down} {
      column-gap: 1rem;
    }
  `}
`;

const ScoreBarChart: React.FC<Classname> = ({ className }) => {
  const { goodScore, basicScore, badScore, goodProgress, basicProgress, badProgress } =
    useScoreBarChartService();

  return (
    <Container className={className}>
      <Bar type="good" progress={goodProgress}>
        <ScoreStars type="good" />
        {goodScore}%
      </Bar>
      <Bar type="basic" progress={basicProgress}>
        <ScoreStars type="basic" />
        {basicScore}%
      </Bar>
      <Bar type="bad" progress={badProgress}>
        <ScoreStars type="bad" />
        {badScore}%
      </Bar>
    </Container>
  );
};

export default ScoreBarChart;
