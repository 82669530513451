import callApi, { METHODS, PATHS } from 'api';
import { AxiosResponse } from 'axios';
import { useQuery } from 'react-query';
import { captureException } from '@sentry/react';

export interface ILesson {
  id: string;
  name: string;
  description: string;
  emoji: string;
  type: string;
  level: string;
  max_points: number;
  order: number;
  tasks_completed: number;
  tasks_total: number;
  points_earned: number;
}

export interface ILessons {
  lessons_completed: number;
  lessons_total: number;
  points_earned: number;
  lessons: ILesson[];
}

export const useLessons = () =>
  useQuery<unknown, unknown, ILessons>({
    queryKey: ['useLessons'],
    queryFn: async () => {
      try {
        const response: AxiosResponse<ILessons> = await callApi({
          method: METHODS.GET,
          mainPath: PATHS.LESSONS,
          authorized: true,
        });

        return response.data;
      } catch (error) {
        captureException(error);
      }
    },
  });
