import { css } from '@emotion/react';

import mq from './mediaQueries';

export const CUSTOM_FONTS = ['Polly'];

export const FONT_FAMILIES = {
  Poppins: {
    thin: '100',
    lightest: '200',
    light: '300',
    regular: '400',
    lightBold: '500',
    semiBold: '600',
    bold: '700',
    veryBold: '800',
    black: '900',
  },

  Polly: {
    thin: '100',
    light: '300',
    regular: '400',
    bold: '700',
  },
} as const;

const typography = {
  primaryFont: 'Poppins',
  primaryWeights: FONT_FAMILIES.Poppins,
  secondaryFont: 'Polly',
  secondaryWeights: FONT_FAMILIES.Polly,
  h1: css`
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 3rem;

    ${mq.md.down} {
      line-height: 1.15;
      font-size: 2.75rem;
    }
  `,
  h2: css`
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.25;
    font-size: 2.5rem;

    ${mq.md.down} {
      line-height: 1.15;
      font-size: 2rem;
    }
  `,
  h3: css`
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.35;
    font-size: 2rem;

    ${mq.md.down} {
      font-size: 1.75rem;
    }
  `,
  h4: css`
    font-weight: ${FONT_FAMILIES.Poppins.semiBold};
    line-height: 1.35;
    font-size: 1.5rem;
  `,
  p1: css`
    font-weight: ${FONT_FAMILIES.Poppins.regular};
    line-height: 1.35;
    font-size: 2rem;

    ${mq.md.down} {
      font-size: 1.75rem;
    }
  `,
  p2: css`
    font-weight: ${FONT_FAMILIES.Poppins.regular};
    line-height: 1.25;
    font-size: 1.5rem;
  `,
  p3: css`
    font-weight: ${FONT_FAMILIES.Poppins.regular};
    line-height: 1.25;
    font-size: 1.5rem;

    ${mq.md.down} {
      font-size: 1.375rem;
    }
  `,
};

export default typography;
