import { NAME } from './constants';
import { watchAnalytics } from './sagas';
import selectors from './selectors';
import { analyticsSlice } from './slice';

const config = {
  reducer: {
    [NAME]: analyticsSlice.reducer,
  },
  saga: watchAnalytics,
};

export const analyticsSelectors = {
  isStatsigInitialized: selectors.isStatsigInitialized,
};
export default config;
