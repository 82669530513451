import React, { ReactNode } from 'react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Button from 'atoms/Button';
import CancelButton from 'atoms/CancelButton';
import { LAYOUT_TEMPLATE_COLUMNS } from 'atoms/Layout';
import Modal from 'molecules/Modal';
import VidyardPlayer from 'molecules/VidyardPlayer';

import { ONBOARDING_NODE_ID } from '../../constants';

import { useVideosModal } from './hooks';

import type { JourneyProps } from '../types';
import type { InterpolationWithTheme } from 'utils/types';

enum MODAL_AREAS {
  title = 'title',
  primary = 'primary',
}

const modalStyle: InterpolationWithTheme = ({ colors, mq, utils }) => css`
  grid-template-areas: ${utils.composeTemplateAreas([
    [MODAL_AREAS.title],
    ['.'],
    [MODAL_AREAS.primary],
  ])};

  background-color: ${colors.neutral.white};
  padding: 6rem;
  align-self: center;
  border-radius: 4rem;
  row-gap: 3rem;

  ${mq.md.down} {
    padding: 4rem 2rem 7rem;
    && {
      grid-column: ${LAYOUT_TEMPLATE_COLUMNS.contentStart} / ${LAYOUT_TEMPLATE_COLUMNS.contentEnd};
    }
  }
`;

const StyledCancelButton = styled(CancelButton)`
  ${({ theme: { colors, mq, utils } }) => css`
    & > svg {
      fill: ${utils.alphaHex(colors.primary.dark, 55)};
    }

    grid-area: ${MODAL_AREAS.title};
    grid-row: 1 / 1;
    justify-self: flex-end;
    align-self: flex-start;
    transform: translate(100%, -100%);

    ${mq.md.down} {
      margin-right: 3rem;
      margin-top: 1rem;
    }
  `}
`;

const Title = styled.h1`
  ${({ theme: { mq, tp } }) => css`
    ${tp.h1};

    grid-area: ${MODAL_AREAS.title};
    grid-row: 1 / 1;
    margin: 0;
    padding: 0 4rem;
    text-align: center;

    ${mq.md.down} {
      padding: 0 3rem;
    }
  `}
`;

const NextButton = styled(Button)`
  grid-area: ${MODAL_AREAS.primary};
`;

interface ITutorialVideoModal extends JourneyProps {
  title: string;
  vidyardId: string;
  buttonElement: ReactNode;
}

const TutorialVideoModal: React.FC<ITutorialVideoModal> = ({
  journey,
  step,
  title,
  vidyardId,
  buttonElement,
}) => {
  const { isActive, handleClose, handleNext } = useVideosModal({ journey, step });

  return (
    <Modal
      isOpen={isActive}
      onClick={() => void 0}
      portalTarget={ONBOARDING_NODE_ID}
      classNames={{ modal: modalStyle }}
    >
      <StyledCancelButton onClick={handleClose} />
      <Title>{title}</Title>
      <VidyardPlayer id={vidyardId} />
      <NextButton onClick={() => handleNext()}>{buttonElement}</NextButton>
    </Modal>
  );
};

export default TutorialVideoModal;
