import React, { PropsWithChildren } from 'react';

import PlayButton from 'features/Chat/components/PlayButton';
import ChatSettings from 'features/Chat/components/ChatSettings';
import MicrophoneProblem from 'features/Chat/components/MicrophoneProblem';
import ChromeInstructions from 'features/Chat/components/ChromeInstructions';
import SafariInstructions from 'features/Chat/components/SafariInstructions';
import IOSInstructions from 'features/Chat/components/IOSInstructions';
import { isChrome, isSafari } from 'utils/constants';

import { useSettingsService } from './hooks';

const Settings: React.FC<PropsWithChildren> = ({ children }) => {
  const { microphoneAccess, isBrowserSupported } = useSettingsService();

  if (!isBrowserSupported) {
    return <IOSInstructions isVisible />;
  }

  switch (microphoneAccess) {
    case 'granted': {
      return <>{children}</>;
    }
    case 'denied': {
      if (isChrome()) return <ChromeInstructions isVisible />;
      if (isSafari()) return <SafariInstructions isVisible />;
    }
    case 'prompt':
    default: {
      return <MicrophoneProblem isVisible />;
    }
  }
};

export default Settings;
