import callApi, { METHODS, PATHS } from 'api';

interface IRefreshToken {
  refreshToken: string;
}

interface IRefreshTokenBody {
  refresh_token: string;
}

export default ({ refreshToken }: IRefreshToken) =>
  callApi<IRefreshTokenBody>({
    method: METHODS.POST,
    mainPath: PATHS.REFRESH_TOKEN,
    data: {
      refresh_token: refreshToken,
    },
  });
