import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import { LAYOUT_TEMPLATE_COLUMNS } from 'atoms/Layout';
import Modal from 'molecules/Modal';
import { GLOBAL_STYLE_VARIABLES } from 'theme/emotion';
import hideable from 'utils/hideable';

import { useTaskCompleteService } from './hooks';

import { CLASSNAME_PREFIX, MODAL_CONTAINER_ID } from './constants';
import LoadingStep from './LoadingStep';
import ResultsStep from './ResultsStep';
import ReviewStep from './ReviewStep';

import type { InterpolationWithTheme } from 'utils/types';

export interface ITaskComplete {
  taskId: string;
  lessonId: string;
  handleClose: () => void;
}

const StyledModal = styled(Modal)`
  ${({ theme: { colors, depths } }) => css`
    grid-template-rows: var(${GLOBAL_STYLE_VARIABLES.headerHeight}) 1fr;
    z-index: ${depths.modal};
    background-color: initial;

    &::before {
      display: block;
      content: '';
      background-color: ${colors.primary.dark};
      height: calc(
        var(${GLOBAL_STYLE_VARIABLES.headerHeight}) -
          var(${GLOBAL_STYLE_VARIABLES.headerHeightInverse})
      );
      align-self: flex-end;
      grid-area: 1 / 1 / span 1 / -1;
      z-index: -2;
    }

    &::after {
      grid-area: 2 / 1 / span 1 / -1;
      display: block;
      content: '';
      background-color: ${colors.primary.dark};
      position: relative;
      z-index: -2;
    }

    & > main {
      grid-row: 2 / span 1;
    }
  `}
`;

const ModalContent = styled.section`
  ${({ theme: { durations } }) => css`
    display: contents;
    transition: all ${durations.regular};
  `}
`;

const modalContent: InterpolationWithTheme = ({ mq }) => css`
  display: grid;
  grid-auto-rows: initial;
  overflow-y: auto;
  max-height: calc(100vh - var(${GLOBAL_STYLE_VARIABLES.headerHeight}));
  background-color: initial;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  ${mq.md.down} {
    && {
      grid-column: ${LAYOUT_TEMPLATE_COLUMNS.contentStart} / ${LAYOUT_TEMPLATE_COLUMNS.contentEnd};
    }
  }
`;

const TaskComplete: React.FC<ITaskComplete> = ({ taskId, lessonId, handleClose }) => {
  const {
    step,
    pointsEarned,
    taskName,
    taskEvaluationId,
    timeout,
    goToResults,
    goToReview,
    goToTaskSelection,
    canReview,
  } = useTaskCompleteService({
    taskId,
    lessonId,
    handleClose,
  });

  return (
    <StyledModal
      isOpen
      onClick={handleClose}
      classNames={{ modal: modalContent }}
      id={MODAL_CONTAINER_ID}
    >
      <TransitionGroup component={ModalContent}>
        <CSSTransition key={step} timeout={timeout} classNames={CLASSNAME_PREFIX}>
          {() => {
            switch (step) {
              case 'results': {
                return (
                  <ResultsStep
                    taskName={taskName!}
                    pointsEarned={pointsEarned}
                    taskEvaluationId={taskEvaluationId}
                    canReview={canReview}
                    goToReview={goToReview}
                    goToTaskSelection={goToTaskSelection}
                    handleClose={handleClose}
                  />
                );
              }
              case 'review': {
                return (
                  <ReviewStep
                    taskName={taskName!}
                    taskEvaluationId={taskEvaluationId}
                    goToResults={goToResults}
                    goToTaskSelection={goToTaskSelection}
                  />
                );
              }
              case 'loading':
              default: {
                return <LoadingStep />;
              }
            }
          }}
        </CSSTransition>
      </TransitionGroup>
    </StyledModal>
  );
};

export default hideable(TaskComplete);
