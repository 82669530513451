import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { animateScroll } from 'react-scroll';

import { useDeferredOnboardingCallback } from 'features/Onboarding';

import actions from '../../../actions';
import selectors from '../../../selectors';

export const usePushToTalkButtonService = () => {
  const state = useSelector(selectors.getPushToTalkState);
  const dispatch = useDispatch();

  const onboardingCallback = useDeferredOnboardingCallback({
    journey: 'lessons',
    step: 'pushToTalk',
    remoteTrigger: 'highlight',
  });

  const handlePush = useCallback(() => {
    if (state === 'idle') {
      dispatch(actions.startControlledListening());
      animateScroll.scrollToBottom();
    }
  }, [state, onboardingCallback]);

  const handleRelease = useCallback(() => {
    if (state !== 'disabled') dispatch(actions.stopListening());
  }, [state]);

  useEffect(() => {
    const composeSpaceKeyEvent = (callback: () => void) => (event: KeyboardEvent) => {
      if (event.key == ' ' || event.code == 'Space' || event.keyCode == 32) {
        return callback();
      }
    };

    const onSpaceDown = composeSpaceKeyEvent(handlePush);
    const onSpaceUp = composeSpaceKeyEvent(handleRelease);

    document.addEventListener('keydown', onSpaceDown);
    document.addEventListener('keyup', onSpaceUp);

    return () => {
      document.removeEventListener('keydown', onSpaceDown);
      document.removeEventListener('keyup', onSpaceUp);
    };
  }, [state, handlePush, handleRelease]);

  return { state, handlePush, handleRelease };
};
