import React, { useMemo } from 'react';
import { styled, Tooltip } from '@mui/material';
import Container from '@mui/material/Container';

import Loader from 'components/Loader';
import { useLeaderboard } from '../queries';

import Rankings from './Rankers';
import UserQuantile from './UserQuantile';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'min-content min-content 1fr',
  rowGap: 16,
  paddingTop: 56,
  minHeight: 'min(100vh, 1080px)',
  [theme.breakpoints.down('md')]: {
    minHeight: '100vh',
    paddingBottom: 56,
  },
}));

const StyledLoader = styled(Loader)({
  justifySelf: 'center',
  alignSelf: 'center',
});

const Title = styled('h2')(({ theme }) => ({
  ...theme.typography.h2,
  fontWeight: '500',
  textAlign: 'center',
  marginBottom: 0,
  [theme.breakpoints.down('md')]: {
    fontSize: '2.5rem',
  },
}));

const Subtitle = styled('h4')(({ theme }) => ({
  ...theme.typography.h4,
  fontWeight: '400',
  textAlign: 'center',
  margin: 0,
  cursor: 'pointer',
  [theme.breakpoints.down('md')]: {
    fontSize: '1.875rem',
  },
}));

const LeaderboardsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr 3fr',
  alignItems: 'center',
  justifyItems: 'center',
  columnGap: 24,
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'initial',
    rowGap: 40,
  },
}));

const Leaderboard: React.FC = () => {
  const { data: leaderboardData, isLoading, isSuccess } = useLeaderboard();

  const subtitle = useMemo(
    () =>
      leaderboardData?.metric === 'words_count'
        ? 'Word count ℹ️'
        : leaderboardData?.metric === 'active_days_count'
        ? 'Days active ℹ️'
        : '',
    [leaderboardData?.metric]
  );

  return (
    <StyledContainer>
      <>
        <Title>Leaderboard</Title>
        <Tooltip title="Metrics are calculated based on previous 30 days activity">
          <Subtitle>{subtitle}</Subtitle>
        </Tooltip>
        {isLoading || !isSuccess ? (
          <StyledLoader isVisible size={80} />
        ) : (
          <LeaderboardsContainer>
            <UserQuantile quantile={leaderboardData?.user_represented_quantile} />
            <Rankings
              username={leaderboardData.username}
              rankings={leaderboardData?.top_rankings ?? []}
            />
          </LeaderboardsContainer>
        )}
      </>
    </StyledContainer>
  );
};

export default Leaderboard;
