import { Button, styled } from '@mui/material';

import { ERROR_MESSAGES, LINKS } from 'utils/constants';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'center',
});

const ContentContainer = styled('div')({
  padding: '120px 24px 24px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const StyledText = styled('div')({
  fontSize: 16,
  lineHeight: '24px',
  fontWeight: 400,
  opacity: 0.8,
  textAlign: 'center',
  marginBottom: 24,
  maxWidth: 600,
});

const NotAuthorizedTier = () => {
  const onLinkPress = () => {
    window.open(LINKS.FUNNEL_CHECKOUT_PLANS, '_blank', 'noreferrer');
  };

  return (
    <Container>
      <ContentContainer>
        <StyledText>{ERROR_MESSAGES.NO_SUBSCRIPTION}</StyledText>
        <Button onClick={onLinkPress} variant="outlined">
          Purchase a subscription
        </Button>
      </ContentContainer>
    </Container>
  );
};

export default NotAuthorizedTier;
